import React, { useState, useEffect } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import InputComponent from "../_common/inputComponent";
import { TextField } from "@mui/material";

const AddQuestionModal = (props) => {
  const [question, setQuestion] = useState("");
  const [ansA, setAnsA] = useState(null);
  const [ansB, setAnsB] = useState("");
  const [ansC, setAnsC] = useState("");
  const [ansD, setAnsD] = useState("");
  const [correctAns, setCorrectAns] = useState("");
  const [questionErr, setQuestionErr] = useState(false);
  const [ansErr, setAnsErr] = useState(false);
  const [correctAnsErr, setCorrectAnsErr] = useState(false);

  useEffect(() => {
    if (props?.edit) {
      setPropsToState();
    } // eslint-disable-next-line
  }, [props?.data]);

  const setPropsToState = () => {
    setQuestion(props?.data?.questionTitle);
    setAnsA(props?.data?.questionOptions[0]?.optionTitle);
    setAnsB(props?.data?.questionOptions[1]?.optionTitle);
    setAnsC(props?.data?.questionOptions[2]?.optionTitle);
    setAnsD(props?.data?.questionOptions[3]?.optionTitle);
    setCorrectAns(
      props?.data?.questionOptions[0]?.isAnswer
        ? "ansA"
        : props?.data?.questionOptions[1]?.isAnswer
        ? "ansB"
        : props?.data?.questionOptions[2]?.isAnswer
        ? "ansC"
        : props?.data?.questionOptions[3]?.isAnswer
        ? "ansD"
        : ""
    );
  };

  const handleValidation = () => {
    let flag = true;
    if (!question) {
      setQuestionErr(true);
      flag = false;
    }
    if (!ansA) {
      setAnsErr(true);
      flag = false;
    }
    if (!ansB) {
      setAnsErr(true);
      flag = false;
    }
    if (!ansC) {
      setAnsErr(true);
      flag = false;
    }
    if (!ansD) {
      setAnsErr(true);
      flag = false;
    }
    if (!correctAns) {
      setCorrectAnsErr(true);
      flag = false;
    }
    return flag;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (await handleValidation()) {
      let ansArray = [];
      ansArray.push({ optionTitle: ansA, isAnswer: correctAns === "ansA" });
      ansArray.push({ optionTitle: ansB, isAnswer: correctAns === "ansB" });
      ansArray.push({ optionTitle: ansC, isAnswer: correctAns === "ansC" });
      ansArray.push({ optionTitle: ansD, isAnswer: correctAns === "ansD" });
      let obj = {
        questionTitle: question,
        questionOptions: JSON.stringify(ansArray),
      };
      props.closeModal(obj);
    }
  };

  return (
    <Modal
      open={props.open}
      onClose={() => props.closeModal()}
      center
      classNames={{ modal: "admin-modal" }}
    >
      <div>
        <p className="admin-banner">{props?.edit ? "Edit" : "Add"} Question</p>
      </div>
      <form onSubmit={handleSubmit} className="form-own px-3">
        <div className="row px-2">
          <div className="col-12 px-2">
            <div className="form-own-group own-textarea form-group">
              {/* <label
                htmlFor="question"
                className="form-own-label"
                style={{
                  position: "relative",
                  top: "20px",
                  left: "20px",
                  background: "white",
                  padding: "1px 5px",
                }}
              >
                Question <span className="color-red">*</span>
              </label> */}
              {/* <textarea
                className="form-control"
                type="text"
                name="question"
                id="question"
                placeholder="Question"
                onChange={(evt) => setQuestion(evt.target.value)}
                value={question}
                rows={4}
              /> */}
              <TextField
                label={
                  <>
                    Question <span className="color-red">*</span>
                  </>
                }
                variant="outlined"
                fullWidth
                name="question"
                id="question"
                placeholder="Question"
                onChange={(evt) => setQuestion(evt.target.value)}
                value={question}
                multiline
                rows={4}
              />
              <div className="error-text">
                {!question && questionErr && <span>Question is required</span>}
              </div>
            </div>
          </div>
          <div className="col-6 px-2">
            <div className="form-own-group">
              <InputComponent
                placeholder="Answer"
                label={
                  <>
                    Answer A <span className="color-red">*</span>
                  </>
                }
                type="text"
                name="ansA"
                theme="white"
                value={ansA}
                handleCallback={(val) => {
                  setAnsA(val);
                }}
              />
              <div className="ml-auto pl-2 mark-input-wrapper">
                <input
                  type="radio"
                  value="ansA"
                  className="mr-1"
                  id="ansAinput"
                  checked={correctAns === "ansA"}
                  onChange={(evt) => setCorrectAns(evt.target.value)}
                />
                <label htmlFor="ansAinput">Correct answer</label>
              </div>
              <div className="error-text">
                {!ansA && ansErr && <span>Answer is required</span>}
              </div>
            </div>
          </div>
          <div className="col-6 px-2">
            <div className="form-own-group">
              <InputComponent
                placeholder="Answer"
                label={
                  <>
                    Answer B <span className="color-red">*</span>
                  </>
                }
                type="text"
                name="ansB"
                theme="white"
                value={ansB}
                handleCallback={(val) => {
                  setAnsB(val);
                }}
              />
              <div className="ml-auto pl-2 mark-input-wrapper">
                <input
                  type="radio"
                  value="ansB"
                  className="mr-1"
                  id="ansBinput"
                  checked={correctAns === "ansB"}
                  onChange={(evt) => setCorrectAns(evt.target.value)}
                />
                <label htmlFor="ansBinput">Correct answer</label>
              </div>
              <div className="error-text">
                {!ansB && ansErr && <span>Answer is required</span>}
              </div>
            </div>
          </div>
          <div className="col-6 px-2">
            <div className="form-own-group">
              <InputComponent
                placeholder="Answer"
                label={
                  <>
                    Answer C <span className="color-red">*</span>
                  </>
                }
                type="text"
                name="ansC"
                theme="white"
                value={ansC}
                handleCallback={(val) => {
                  setAnsC(val);
                }}
              />
              <div className="ml-auto pl-2 mark-input-wrapper">
                <input
                  type="radio"
                  value="ansC"
                  className="mr-1"
                  id="ansCinput"
                  checked={correctAns === "ansC"}
                  onChange={(evt) => setCorrectAns(evt.target.value)}
                />
                <label htmlFor="ansCinput">Correct answer</label>
              </div>
              <div className="error-text">
                {!ansC && ansErr && <span>Answer is required</span>}
              </div>
            </div>
          </div>
          <div className="col-6 px-2">
            <div className="form-own-group">
              <InputComponent
                placeholder="Answer"
                label={
                  <>
                    Answer D <span className="color-red">*</span>
                  </>
                }
                type="text"
                name="ansD"
                theme="white"
                value={ansD}
                handleCallback={(val) => {
                  setAnsD(val);
                }}
              />
              <div className="ml-auto pl-2 mark-input-wrapper">
                <input
                  type="radio"
                  value="ansD"
                  className="mr-1"
                  id="ansDinput"
                  checked={correctAns === "ansD"}
                  onChange={(evt) => setCorrectAns(evt.target.value)}
                />
                <label htmlFor="ansDinput">Correct answer</label>
              </div>
              <div className="error-text">
                {!ansD && ansErr && <span>Answer is required</span>}
              </div>
            </div>
          </div>

          {!correctAns && correctAnsErr && (
            <div className="error-text col-12 px-2 pt-2 pb-3">
              <span>Correct Answer needs to be selected</span>
            </div>
          )}
        </div>
        <div className="admin-btn">
          <button
            onClick={() => props.closeModal()}
            className="btn secondary-btn mr-2"
            type="button"
          >
            Back
          </button>
          <button type="submit" className="btn primary-btn ml-2">
            Submit
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default AddQuestionModal;
