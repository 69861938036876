import React, { useState, useEffect } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { globalAlert, globalLoader } from "../../actions/commonActions";
import InputComponent from "../_common/inputComponent";

const AddEditEducationModal = (props) => {
  const [title, setTitle] = useState("");
  const [titleErr, setTitleErr] = useState(false);
  const [author, setAuthor] = useState("");
  const [file, setFile] = useState(null);
  const [fileErr, setFileErr] = useState(false);
  const [fileLink, setFileLink] = useState("");
  const [fileLinkErr, setFileLinkErr] = useState("");
  const [linkOrFile, setLinkOrFile] = useState("file");
  const [sendNotification, setSendNotification] = useState(false);
  const [filePreview, setFilePreview] = useState(null)
  const [filePreviewErr, setFilePreviewErr] = useState(false);
  const [filePreviewSrc, setFilePreviewSrc] = useState(null)


  useEffect(() => {
    setPropsToState(); // eslint-disable-next-line
  }, [props?.data]);

  const setPropsToState = () => {
    // setVideoTitle(props?.data?.videoTitle);
    // setVideoDescription(props?.data?.videoDescription);
    // setFile(props?.data?.file);
  };

  const handleValidation = () => {
    let flag = true;
    if (!title) {
      setTitleErr(true);
      flag = false;
    }
    if (props.type === 'video' && !filePreview) {
      setFilePreviewErr(true)
      flag = false;
    }

    if (linkOrFile === "file" && !file) {
      setFileErr(true);
      flag = false;
    }
    else if (linkOrFile === "link" && !fileLink) {
      setFileLinkErr(true);
      flag = false;
    }
    return flag;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (await handleValidation()) {
      globalLoader(true);
      let formData = new FormData();
      if (props?.type === "video") {
        formData.append("videoTitle", title);
        formData.append("videoAuthor", author);
        linkOrFile === "file"
          ? formData.append("videoFile", file)
          : formData.append("videoUrl", fileLink);
        formData.append("filePreview", filePreview);

      } else {
        formData.append("documentTitle", title);
        formData.append("file", file);
      }
      formData.append("notifyUsers", sendNotification);
      props.closeModal(formData);
    }
  };

  const setFileFunc = (ev) => {
    if (props?.type === "video") {
      if (
        ev?.target?.files[0]?.type === "video/mp4" ||
        ev?.target?.files[0]?.type === "video/x-m4v"
      ) {
        const file = ev.target.files[0];
        setFile(file);
      } else {
        globalAlert("error", "Only MP4 file allowed");
      }
    } else {
      if (ev?.target?.files[0]?.type === "application/pdf") {
        const file = ev.target.files[0];
        setFile(file);
      } else {
        globalAlert("error", "Only PDF file allowed");
      }
    }
  };

  const setFilePreviewFunc = (ev) => {
    const file = ev.target.files[0];
    if (file) {
      if (
        file?.type === "image/png" ||
        file?.type === "image/jpeg"
      ) {
        setFilePreviewSrc(URL.createObjectURL(file))
        setFilePreview(file);
      } else {
        globalAlert("error", "Only JPEG and PNG file allowed");
      }
    }
  }

  return (
    <Modal
      open={props.open}
      onClose={() => props.closeModal()}
      center
      classNames={{ modal: "admin-modal modal-own" }}
    >
      <div>
        <p className="admin-banner">
          {props?.edit ? "Edit" : "Add"}{" "}
          <span className="text-capitalize">{props?.type}</span>
        </p>
      </div>

      {props?.type === "video" && (<div className="tab-own-wrapper text-center">

        <ul className="tab-wrapper-list d-inline-flex flex-wrap list-unstyled">
          <li
            className={`${linkOrFile === "file" ? "active" : ""}`}
            onClick={() =>
              setLinkOrFile("file")
            }
          >
            Upload the file
          </li>
          <li
            className={`${linkOrFile === "link" ? "active" : ""}`}
            onClick={() =>
              setLinkOrFile("link")
            }
          >
            Provide the link
          </li>
        </ul>
      </div>
      )}
      <form onSubmit={handleSubmit} className="form-own px-3">
        <div className="row px-2">
          <div className="col-12 px-2">
            <div className="form-own-group">
              <InputComponent
                placeholder="Title"
                label={
                  <>
                    Title <span className="color-red">*</span>
                  </>
                }
                type="text"
                name="videoTitle"
                theme="white"
                value={title}
                handleCallback={(val) => {
                  setTitle(val);
                }}
              />
              <div className="error-text">
                {!title && titleErr && (
                  <span>Title is required</span>
                )}
              </div>
            </div>
          </div>

          {linkOrFile === "file" ? (
            <div className="col-12 px-2">
              <div className="form-own-group">
                <label className="form-own-label">
                  Upload file <small>{
                      props?.type === "video"
                        ? "(Only MP4 file allowed)"
                        : "(Only PDF file allowed)"
                    }</small> <span className="color-red">*</span>
                </label>
                <div className="upload-btn upload-btn-dashed" id="previewLogo">
                  {file && file.name && (
                    <div className="show-icon-wrapper pb-2 mb-1">
                      {file.name}
                    </div>
                  )}

                  <div className="upload-btn-txt btn ">
                    {file ? "CHANGE FILE" : "+ ADD FILE"}
                  </div>
                  <input
                    type="file"
                    accept={
                      props?.type === "video"
                        ? "video/mp4,video/x-m4v"
                        : "application/pdf"
                    }
                    className="upload-input cursor"
                    onChange={setFileFunc}
                  />
                </div>
                <div className="error-text">
                  {!file && fileErr && <span>File is required</span>}
                </div>
              </div>
            </div>
          ) : (
            <div className="col-12 px-2">
              <div className="form-own-group">
                <InputComponent
                  placeholder="Link"
                  label={
                    <>
                      Link <span className="color-red">*</span>
                    </>
                  }
                  type="text"
                  name="videoTitle"
                  theme="white"
                  value={fileLink}
                  handleCallback={(val) => {
                    setFileLink(val);
                  }}
                />
                <div className="error-text">
                  {!fileLink && fileLinkErr && (
                    <span>Link is required</span>
                  )}
                </div>
              </div>
            </div>
          )}
          {props?.type === "video" && (
            <div className="col-12 px-2 pt-3">
              <div className="form-own-group">
                <label className="form-own-label">
                  Thumbnail file <small>(JPEG and PNG files allowed)</small> <span className="color-red">*</span>
                </label>
                <div className="upload-btn upload-btn-dashed" id="previewLogo">
                  {filePreview && filePreview.name && (
                    <div className="show-icon-wrapper pb-2 mb-1">
                      {filePreview.name}
                    </div>
                  )}
                  {filePreviewSrc &&
                    <div className="img-preview px-3 py-3">
                      <img src={filePreviewSrc} alt="img" />
                    </div>}
                  <div className="upload-btn-txt btn ">
                    {filePreview ? "CHANGE FILE" : "+ ADD FILE"}
                  </div>

                  <input
                    type="file"
                    accept={
                      'image/png,image/jpeg'
                    }
                    className="upload-input cursor"
                    onChange={setFilePreviewFunc}
                  />
                </div>
                <div className="error-text">
                  {!filePreview && filePreviewErr && <span>Preview File is required</span>}
                </div>
              </div>
            </div>
          )}
          <div className="col-12 px-2">
            <div className="pt-3">
              <input
                type="checkbox"
                name="sendNotification"
                id="sendNotification"
                checked={sendNotification}
                onChange={() => setSendNotification((a) => !a)}
                style={{
                  height:'16px',
                  width:'16px'
                }}
              />
              <label htmlFor="sendNotification" className="form-own-label pl-2 position-relative" style={{top:'-3px'}}>
              Check this box to notify users
              </label>
            </div>
          </div>
          {/* {props?.type === "video" && (
            <div className="col-12 text-center pt-3">
              <div className="or-wrapper">Or</div>

              <div
                className="link-wrapper cursor color-primary primary-color"
                onClick={() =>
                  setLinkOrFile(linkOrFile === "file" ? "link" : "file")
                }
              >
                {linkOrFile === "file" ? "Provide the link" : "Upload the file"}
              </div>
            </div>
          )} */}
        </div>
        <div className="admin-btn">
          <button
            onClick={() => props.closeModal()}
            className="btn secondary-btn mr-2"
            type="button"
          >
            Back
          </button>
          <button type="submit" className="btn primary-btn ml-2">
            Submit
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default AddEditEducationModal;
