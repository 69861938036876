import React from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  Autocomplete,
  InfoBox,
  InfoWindow,
} from "@react-google-maps/api";
import OutsideClickHandler from "react-outside-click-handler";
import { Scrollbars } from "react-custom-scrollbars";
import { GOOGLE_MAPS_API_KEY } from "../../actionTypes/types";

const libraries = ["places"];
const options = { closeBoxURL: "", enableEventPropagation: true };

class MapComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.autocomplete = null;
    this.state = {
      inputVal: "",
      maxHeight: "54px",
      containerStyle: {
        width: "100%",
        height: "calc(100vh - 54px)",
      },
      markerValue: null,
    };
    this.myRef = React.createRef();
    this.test = React.createRef();
  }

  static getDerivedStateFromProps(props, state) {
    if (props.maxHeight !== state.selected) {
      return {
        selected: props.selected,
      };
    }

    // Return null if the state hasn't changed
    return null;
  }

  onLoad = (autocomplete) => {
    this.autocomplete = autocomplete;
    this.autocomplete.addListener("place_changed", this.onPlaceChanged);
  };
  onLoad2 = (infoBox) => {};

  onPlaceChanged = () => {
    if (this.autocomplete !== null) {
      let mapDetails = this.autocomplete.getPlace();

      this.props.setInputVal(this.autocomplete.getPlace().formatted_address);

      let obj = {
        latitude: mapDetails.geometry.location.lat(),
        longitude: mapDetails.geometry.location.lng(),
      };
      this.props.setLetLongObj({
        lat: mapDetails.geometry.location.lat(),
        lng: mapDetails.geometry.location.lng(),
      });
      this.props.callBasicApi(obj);
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  mapLoad = (data) => {};

  setOpenFunc = (val) => {
    this.props.setOpen();
    this.setState({
      markerValue: val,
    });
  };

  static getDerivedStateFromProps(props, state) {
    if (props.mobileView !== state.maxHeight && !props?.mobileView) {
      return {
        containerStyle: {
          width: "100%",
          height: `calc(100vh - ${props?.maxHeight}px)`,
        },
        maxHeight: `${props?.maxHeight}px`,
      };
    } else if (props.mobileView !== state.maxHeight && props?.mobileView) {
      return {
        containerStyle: {
          width: "100%",
          height: `calc(60vh - ${props?.maxHeight}px)`,
        },
        maxHeight: `${props?.maxHeight}px`,
      };
    }

    // Return null if the state hasn't changed
    return null;
  }

  mapDirection = (ev, val = null) => {
    ev.stopPropagation();
    if (val) {
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${val?.latitude},${val?.longitude}`,
        "_blank"
      );
    }
  };

  render() {
    const {
      latLongObj,
      storesList,
      setOpen,
      inputVal,
      setInputVal,
      searchStoreList,
      listClicked,
      closeList,
    } = this.props;

    return (
      <LoadScript
        googleMapsApiKey={GOOGLE_MAPS_API_KEY}
        libraries={libraries}
      >
        <OutsideClickHandler onOutsideClick={closeList}>
          <div
            className="map-search-wrapper"
            style={{ zIndex: this.state?.markerValue ? "-2" : 4 }}
          >
            <div className="map-search-container w-100">
              <input
                value={inputVal}
                onChange={(ev) => setInputVal(ev.target.value)}
                type="text"
                placeholder="Search store"
                className="map-search-input"
              />
          {searchStoreList?.length>0 &&
             <Scrollbars style={{ height: 200 }}>
             <ul className="list-unstyled stores-list-wrapper">
               {searchStoreList.map(data=>(
            <li key={data?.storeId} onClick={()=>listClicked(data)}>{data?.storeName}</li>

               ))}
               



          </ul>
          </Scrollbars>}
          </div>
          </div>
        </OutsideClickHandler>

        <GoogleMap
          mapContainerStyle={this.state.containerStyle}
          center={latLongObj}
          zoom={12}
          onLoad={this.mapLoad}
        >
          {storesList?.length > 0 &&
            storesList.map((x) => (
              <Marker
                key={x?.storeId}
                position={{ lat: x?.latitude, lng: x?.longitude }}
                onClick={() => this.setOpenFunc(x)}
                icon={"/assets/icons/map.png"}
              >
                {/* <i class="fab fa-accessible-icon"></i> */}
                <i className="fas fa-accessible-icon primary-color"></i>
              </Marker>
            ))}

          {this.state?.markerValue && (
            <InfoWindow
              onLoad={this.onLoad2}
              position={{
                lat: this.state?.markerValue?.latitude + 0.01234,
                lng: this.state?.markerValue?.longitude,
              }}
              onCloseClick={() =>
                this.setState({
                  markerValue: null,
                })
              }
            >
              <div
                style={{
                  backgroundColor: "#fff",
                  opacity: 0.95,
                  padding: "10px 15px",
                }}
              >
                <div className="map-info-wrapper d-flex flex-wrap font-900">
                  <div className="info-wrapper flex-1 ">
                    <h1 className="font-16 text-color-primary">
                      {this?.state?.markerValue?.storeName}
                    </h1>
                    <div>
                      Address :{" "}
                      <span className="text-color-primary">
                        {this?.state?.markerValue?.address && (
                          <span>{this?.state?.markerValue?.address}, </span>
                        )}
                        {this?.state?.markerValue?.street},{" "}
                        {this?.state?.markerValue?.city},{" "}
                        {this?.state?.markerValue?.postalCode}
                      </span>
                    </div>
                    <div
                      className="store-direction"
                      onClick={(ev) =>
                        this.mapDirection(ev, this.state?.markerValue)
                      }
                    >
                      DIRECTIONS
                    </div>
                  </div>
                  <div className="store-distance">
                    {this?.state?.markerValue?.distance} mi
                  </div>
                </div>
              </div>
            </InfoWindow>
          )}
          {/* <Autocomplete
            onLoad={this.onLoad}
            onPlacesChanged={this.onPlaceChanged}
          >
            <input
              value={inputVal}
              onChange={(ev) => setInputVal(ev.target.value)}
              type="text"
              placeholder="Search store"
              className="map-search-input"
              
            />
          </Autocomplete> */}
        </GoogleMap>
      </LoadScript>
    );
  }
}

export default MapComponent;
