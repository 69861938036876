import React, { useEffect, useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { withRouter } from "react-router-dom";
import { globalLoader, globalAlert } from "../../actions/commonActions";
import { validEmail } from "../../_shared/commonFunction";
import InputComponent from "../_common/inputComponent";

const ManageStockModal = (props) => {
  const [quantity, setQuantity] = useState(props?.data?.stockQuantity || 0);
  const [quantityErr, setQuantityErr] = useState(false);
  const [currentBtn, setCurrentBtn] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (quantity) {
      globalLoader(true);
      let obj = {
        stockQuantity: quantity,
      };
      props.closeModal(obj);
    } else {
      setQuantityErr(true);
    }
  };

  const handleQuantity = (val) => {
    const newVal = quantity + val;
    if (val === -1) {
      setCurrentBtn("minus");
    }
    if (val === 1) {
      setCurrentBtn("plus");
    }
    if (newVal < 0) {
      globalAlert("error", "Quantity cannot be less than zero");
    } else {
      setQuantity(newVal);
    }
  };

  return (
    <Modal
      open={props.open}
      onClose={() => props.closeModal()}
      center
      classNames={{ modal: "admin-modal modal-lg" }}
    >
      <div>
        <p className="admin-banner">Manage Stock</p>
      </div>

      <p
        className={`${
          !props?.data?.stockQuantity || Number(props?.data?.stockQuantity) <= 0
            ? "color-red"
            : ""
        }`}
      >
        Total Available Quantity : {props?.data?.stockQuantity || 0}
      </p>
      <form onSubmit={handleSubmit} className="form-own px-3">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="d-flex align-items-center justify-content-between px-3">
              {/* <div className="d-flex justify-content-between"> */}
              <div
                className={`stock-count ${
                  currentBtn === "minus" ? "minus-active" : "minus"
                } cursor `}
                onClick={() => handleQuantity(-1)}
              >
                <i className="fas fa-minus "></i>
              </div>
              {/* <InputComponent
                  placeholder="Quantity"
                  label={
                    <>
                      Quantity <span className="color-red">*</span>
                    </>
                  }
                  type="number"
                  name="quantity"
                  theme="white"
                  value={quantity}
                  handleCallback={(val) => {
                    setQuantity(val);
                    setCurrentBtn(null);
                  }}
                /> */}
              <div>{quantity}</div>
              <div
                className={`stock-count ${
                  currentBtn === "plus" ? "plus-active" : "plus"
                } cursor `}
                onClick={() => handleQuantity(1)}
              >
                <i className="fas fa-plus "></i>
              </div>
            </div>
            {/* <div className="error-text">
              {!quantity && quantityErr && <span>Field is required</span>}
            </div> */}
          </div>
        </div>
        <div className="admin-btn">
          <button
            onClick={() => props.closeModal()}
            className="btn secondary-btn mr-2"
            type="button"
          >
            Cancel
          </button>
          <button type="submit" className="btn primary-btn ml-2">
            Add
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default withRouter(ManageStockModal);
