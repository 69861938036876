import React, { useEffect, useState } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import format from "date-fns/format";

const timerProps = {
  isPlaying: true,
  size: 60,
  strokeWidth: 3,
};
const minuteSeconds = 60;
const hourSeconds = 3600;
const daySeconds = 86400;
let value;
let ownInterVal;
let days;
let daysDuration;

const CountdownTimer = (props) => {

  const [endTime, setEndTime] = useState(null);
  const [remainingTime, setRemainingTime] = useState(null);
  const [stratTime, setStartTime] = useState(null);
  const [hours, setHours] = useState("24");
  const [minutes, setMinutes] = useState("00");
  const [seconds, setSeconds] = useState("00");
  const [daysLeft, setDaysLeft] = useState("00");
  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    setTimeLeft(props?.timeRemaining?.nextClaimInfo?.seconds);
    setStartTime(Date.now() / 1000);
    setEndTime(
      Date.now() / 1000 + props?.timeRemaining?.nextClaimInfo?.seconds
    );
    value = props?.timeRemaining?.nextClaimInfo?.seconds;
    ownTimer();
    return () => clearInterval(ownInterVal);
  }, []);

  const ownTimer = () => {
    ownInterVal = setInterval(() => {
      value = value - 1;
      if (value <= 0) {
        clearInterval(ownInterVal);
        // eslint-disable-next-line
        props?.callBack(true);
      }
    }, 1000);
  };

  useEffect(() => {
    if (endTime && stratTime) {
      setRemainingTime(endTime - stratTime);
      days = Math.ceil((endTime - stratTime) / daySeconds);
      daysDuration = days * daySeconds;
    }
  }, [endTime, stratTime]);

  const getTimeSeconds = (time) => (minuteSeconds - time) | 0;
  const getTimeMinutes = (time) => ((time % hourSeconds) / minuteSeconds) | 0;
  const getTimeHours = (time) => ((time % daySeconds) / hourSeconds) | 0;
  const getTimeDays = (time) => (time / daySeconds) | 0;

  const renderTime = (dimension, time) => {
    return (
      <div className="time-wrapper">
        <div className="time">{time}</div>
        <div>{dimension}</div>
      </div>
    );
  };

  useEffect(() => {
    startTimer();
    return () => startTimer();
  }, [timeLeft]);

  const startTimer = () => {
    setTimeout(() => {
      if (timeLeft > 0) {
        let remaining = timeLeft - 1;
        let d = Math.floor(remaining / 86400).toString();
        let h = Math.floor(remaining / 3600).toString();
        let m = (Math.floor(remaining / 60) - h * 60).toString();
        let s = Math.floor(remaining % 60).toString();
        setHours(h.length === 1 ? `0${h}` : h);
        setMinutes(m.length === 1 ? `0${m}` : m);
        setSeconds(s.length === 1 ? `0${s}` : s);
        setDaysLeft(d.length === 1 ? `0${d}` : d);
        setTimeLeft(remaining);
      } else {
        props.callBack();
      }
    }, 1000);
  };

  return (
    <>
      {/* <div className="wrap">
{props?.timeRemaining?.prevClaimDate &&
      <div className="prev-eligible-heading text-center px-3">
        <span className="text-container font-12">You have already claimed on :- <span className="font-600 text-color-primary">{format(new Date(props?.timeRemaining?.prevClaimDate), 'dd MMM yyyy')}</span></span>

      </div>}
      {props?.timeRemaining?.nextClaimDate &&
      <div className="next-eligible-heading font-12">
        <span className="text-container">Your next transaction will unlock on :- <span className="font-600 text-color-primary">{format(new Date(props?.timeRemaining?.nextClaimDate), 'dd MMM yyyy')}</span></span>

      </div>}
      {endTime && remainingTime&& 
      <div className="count-down-wrapper">

        <CountdownCircleTimer
          {...timerProps}
          colors={[["#7E2E84"]]}
          duration={daysDuration}
          initialRemainingTime={remainingTime}
        >
          {({ elapsedTime }) =>
            renderTime("days", getTimeDays(daysDuration - elapsedTime))
          }
        </CountdownCircleTimer>

        <CountdownCircleTimer
          {...timerProps}
          colors={[["#D14081"]]}
          duration={daySeconds}
          initialRemainingTime={remainingTime % daySeconds}
          onComplete={(totalElapsedTime) => [
            remainingTime - totalElapsedTime > hourSeconds
          ]}
        >
          {({ elapsedTime }) =>
            renderTime("hours", getTimeHours(daySeconds - elapsedTime))
          }
        </CountdownCircleTimer>
        <CountdownCircleTimer
          {...timerProps}
          colors={[["#EF798A"]]}
          duration={hourSeconds}
          initialRemainingTime={remainingTime % hourSeconds}
          onComplete={(totalElapsedTime) => [
            remainingTime - totalElapsedTime > minuteSeconds
          ]}
        >
          {({ elapsedTime }) =>
            renderTime("minutes", getTimeMinutes(hourSeconds - elapsedTime))
          }
        </CountdownCircleTimer>
        <CountdownCircleTimer
          {...timerProps}
          colors={[["#218380"]]}
          duration={minuteSeconds}
          initialRemainingTime={remainingTime % minuteSeconds}
          onComplete={(totalElapsedTime) => [
            remainingTime - totalElapsedTime > 0
          ]}
        >
          {({ elapsedTime }) =>
            renderTime("seconds", getTimeSeconds(elapsedTime))
          }
        </CountdownCircleTimer>
      </div>}

      <div className="eligible-timer-support px-3 font-12 text-center pt-5">
      If you feel this is some technical glitch, please drop a mail at <a href="mailto:support@providedigital.com">support@providedigital.com</a>
      </div>
    </div>
          */}

      <div className="wrap w-100 pt-5">
        {props?.timeRemaining?.nextClaimDate && (
          <div className="next-eligible-heading font-14 pt-5">
            <span className="text-container px-3">
              Thank you for your recent transaction on{" "}
              <span className="font-600 text-color-primary">
              {format(
                  new Date(props?.timeRemaining?.prevClaimDate),
                  "dd MMM yyyy"
                )}
              </span>
              <br />
              You can make your next pick-up after
            </span>
          </div>
        )}
        <div className="countdown pb-4">
          <div className="bloc-time hours" data-init-value="24">
            <span className="count-title">Days</span>

            <div className="figure hours hours-1">
              <span className="top">{daysLeft.toString()[0]}</span>
              
            </div>

            <div className="figure hours hours-2">
              <span className="top">{daysLeft.toString()[1]}</span>
              
            </div>
          </div>

          <div className="bloc-time hours" data-init-value="24">
            <span className="count-title">Hours</span>

            <div className="figure hours hours-1">
              <span className="top">{hours.toString()[0]}</span>
             
            </div>

            <div className="figure hours hours-2">
              <span className="top">{hours.toString()[1]}</span>
             
            </div>
          </div>

          <div className="bloc-time min" data-init-value="0">
            <span className="count-title">Minutes</span>

            <div className="figure min min-1">
              <span className="top">{minutes.toString()[0]}</span>
             
            </div>

            <div className="figure min min-2">
              <span className="top">{minutes.toString()[1]}</span>
             
            </div>
          </div>

          <div className="bloc-time sec" data-init-value="0">
            <span className="count-title">Seconds</span>

            <div className="figure sec sec-1">
              <span className="top">{seconds.toString()[0]}</span>
              
            </div>

            <div className="figure sec sec-2">
              <span className="top">{seconds.toString()[1]}</span>
             
            </div>
          </div>
        </div>
      </div>

      {/* <div className="eligible-timer-support px-3 font-12 text-center pt-5">
        If you feel this is some technical glitch, please drop a mail at{" "}
        <a href="mailto:support@providedigital.com">
          support@providedigital.com
        </a>
      </div> */}
    </>
  );
};

export default CountdownTimer;
