import React, { useEffect, useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { withRouter } from "react-router-dom";
import { globalLoader } from "../../actions/commonActions";
import { validEmail } from "../../_shared/commonFunction";
import InputComponent from "../_common/inputComponent";

const AddAdminModal = (props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstNameErr, setFirstNameErr] = useState(false);
  const [lastNameErr, setLastNameErr] = useState(false);
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);

  useEffect(() => {
    setPropsToState();
  }, [props?.data]);

  const setPropsToState = () => {
    setFirstName(props?.data?.firstName);
    setLastName(props?.data?.lastName);
    setEmail(props?.data?.email);
  };

  const handleValidation = () => {
    let flag = true;
    if (!firstName) {
      setFirstNameErr(true);
      flag = false;
    }
    if (!lastName) {
      setLastNameErr(true);
      flag = false;
    }
    if (!email) {
      setEmailErr(true);
      flag = false;
    }
    if (invalidEmail) {
      flag = false;
    }
    return flag;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (await handleValidation()) {
      globalLoader(true);
      let obj = {
        firstName: firstName,
        lastName: lastName,
        email: email,
      };
      props.closeModal(obj);
    }
  };
  const validateMail = async (ev) => {
    let value = ev;
    let valid = await validEmail(value);
    if (valid) {
      setInvalidEmail(false);
    } else {
      setInvalidEmail(true);
    }
    setEmail(value);
  };

  return (
    <Modal
      open={props.open}
      onClose={() => props.closeModal()}
      center
      classNames={{ modal: "admin-modal" }}
    >
      <div>
        <p className="admin-banner">
          {props?.edit ? "Edit" : "Add"} {props?.role}
        </p>
      </div>
      <form onSubmit={handleSubmit} className="form-own px-3">
        <div className="row">
          <div className="col-6">
            <div className="form-own-group">
              {/* <label htmlFor="firstName" className="form-own-label">
                First Name <span className="color-red">*</span>
              </label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                placeholder="First Name"
                onChange={(ev) => {
                  setFirstName(ev.target.value);
                }}
                className="form-control"
                value={firstName}
              /> */}
              <InputComponent
                placeholder="First Name"
                label={
                  <>
                    First Name <span className="color-red">*</span>
                  </>
                }
                type="text"
                name="firstName"
                theme="white"
                value={firstName}
                handleCallback={(val) => {
                  setFirstName(val);
                }}
              />
              <div className="error-text">
                {!firstName && firstNameErr && (
                  <span>First Name is required</span>
                )}
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="form-own-group">
              {/* <label htmlFor="firstName" className="form-own-label">
                Last Name <span className="color-red">*</span>
              </label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                placeholder="Last Name"
                onChange={(ev) => {
                  setLastName(ev.target.value);
                }}
                className="form-control"
                value={lastName}
              /> */}
              <InputComponent
                placeholder="Last Name"
                label={
                  <>
                    Last Name <span className="color-red">*</span>
                  </>
                }
                type="text"
                name="lastName"
                theme="white"
                value={lastName}
                handleCallback={(val) => {
                  setLastName(val);
                }}
              />
              <div className="error-text">
                {!lastName && lastNameErr && <span>Last Name is required</span>}
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="form-own-group">
              {/* <label htmlFor="postCode" className="form-own-label">
                Email <span className="color-red">*</span>
              </label>
              <input
                type="text"
                name="email"
                placeholder="Email"
                onChange={(ev) => {
                  validateMail(ev);
                }}
                maxLength="40"
                disabled={props?.data}
                value={email}
                className="form-control"
              /> */}
              <InputComponent
                placeholder="Email"
                label={
                  <>
                    Email <span className="color-red">*</span>
                  </>
                }
                type="text"
                name="email"
                theme="white"
                value={email}
                handleCallback={(val) => {
                  validateMail(val);
                }}
                maxLength="40"
                disabled={props?.data}
              />
              <div className="error-text">
                {!email && emailErr && <span>Email is required</span>}
                {email && invalidEmail && <span>Email is invalid</span>}
              </div>
            </div>
          </div>
        </div>
        <div className="admin-btn">
          <button
            onClick={() => props.closeModal()}
            className="btn secondary-btn mr-2"
            type="button"
          >
            Back
          </button>
          <button type="submit" className="btn primary-btn ml-2">
            Submit
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default withRouter(AddAdminModal);
