import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ADMIN_SIDEBAR, APP_VERSION } from "../../actionTypes/types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { logOut } from "../../_shared/commonFunction";

import {
  globalAlert,
  globalLoader,
  addOrgDetailsFunc,
} from "../../actions/commonActions";
import { userBrandInfo } from "../../user/_userActions";
export const SidebarComponent = React.memo(
  ({ navbarToggle, active, roleType = null, ...props }) => {

    const [maxHeight, setMaxHeight] = useState(0);
    const [subMenuOpen, setSubMenuOpen] = useState(false);
    const [menuVal, setMenuval] = useState(null);
    const ref = useRef();

    useEffect(() => {
      callBasicApi();
    }, []);

    useEffect(() => {
      maxHeightSidebar();
    });

    const maxHeightSidebar = () => {
      let windowHeight = window.innerHeight;
      ref && setMaxHeight(windowHeight - ref.current.offsetHeight - 50);
    };

    const callBasicApi = async () => {
      if (!props?.orgLogo && !props?.orgName) {
        try {
          globalLoader(true);

          let res = await userBrandInfo();
          if (res?.data?.status === 200) {
            addOrgDetailsFunc(res?.data?.data?.orgBrandInfo);
            globalLoader(false);
          } else {
            globalAlert(res?.data?.message);
            logOut(props?.history, `/${props?.roleType}/login`);
          }
          globalLoader(false);
        } catch (error) {
          globalAlert("error", error?.response?.data?.message);
          if (error?.response?.data?.status === 401) {
            logOut(props?.history, `${props?.roleType}/login`);
          }
          globalLoader(false);
        }
      }
    };


    return (
      <nav className="sidebar" id="sidebar">
        <div
          className="sidebar-container"
          style={{ maxHeight: maxHeight, overflowY: "auto" }}
        >
          <div className="sidebar-header pb-3">
            <div>
              <img
                alt="EC Card"
                className="sidebar-header-logo"
                src="/assets/icons/eC-Card-Logo-Square.png"
              />
            </div>
          </div>
          {ADMIN_SIDEBAR && ADMIN_SIDEBAR.length ? (
            <ul className="list-unstyled components">
              {ADMIN_SIDEBAR.map((item, index) => {
                return (
                  <li key={item.text}>
                    {item.subMenu ? (
                      <div
                        className={`d-flex sidebar-link cursor flex-wrap align-items-center menu-inner-wrapper ${props?.menu===item?.value || index===menuVal?'active-tab':''}`}
                        onClick={() => {
                          setSubMenuOpen(!subMenuOpen);
                          setMenuval(index === menuVal ? null : index);
                        }}
                      >
                        <div className="sidebar-icon-wrapper">
                        <i className={`sidebar-icon ${item.icon}`}></i>
                        </div>
                        <div className="sidebar-text flex-1">
                          {item?.text}{" "}
                        </div>
                        <i className={`sidebar-arrow-icon fa fa-angle-down ${props?.menu===item?.value || index===menuVal?'active':''}`}></i>

                      </div>
                    ) : (
                      <Link
                        to={`/admin/${item.link}`}
                        className={`sidebar-link ${active === `/admin/${item.link}` ? "active-tab" : ""
                          }`}
                      >
                        <i className={`sidebar-icon ${item.icon}`}></i>
                        <span className="sidebar-text">{item.text}</span>
                      </Link>)}
                      {item.subMenu &&
                  (index === menuVal || props.menu === item.value) ? (
                    <ul className="list-unstyled sidebar-submenu-list-wrapper w-100">
                      {item.subMenu.map((subMenu, index) => {
                        return (
                          <li
                            key={index}
                            className={`${
                              subMenu.value === props.subMenu ? "active" : ""
                            }`}
                          >
                            <Link to={`/admin/${subMenu.link}`}>
                              <div className={`d-flex sidebar-link flex-wrap align-items-center submenu-inner-wrapper ${props?.subMenu===subMenu?.value?'active-tab':''}`}>
                                <div className="sidebar-icon-wrapper">
                                <i className={`sidebar-icon ${item.icon}`}></i>
                                </div>
                                <div className="list-txt-wrapper sidebar-text flex-1">
                                  {subMenu?.text}
                                </div>
                              </div>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  ) : null}
                  </li>
                );
              })}
            </ul>
          ) : null}
        </div>
        <div className="footer-logo" ref={ref}>
          <img
            alt="EC Card"
            className="sidebar-header-logo"
            src={props?.orgDetails?.brandLogo}
          />
          <p className="text-white">v {APP_VERSION}</p>
        </div>
      </nav>
    );
  }
);

const mapStateProps = (state) => ({
  orgDetails: state.common.orgDetails,
});

export default connect(mapStateProps)(withRouter(SidebarComponent));
