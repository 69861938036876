import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import LoginComponent from "../template/auth/loginComponent";
import { globalLoader } from "../actions/commonActions";

const UserLogin = () => {
  useEffect(() => {
    globalLoader(false);
  }, []);
  return <LoginComponent roleType={"user"} role={"3"} />;
};

export default withRouter(UserLogin);
