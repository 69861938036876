import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { globalLoader, globalAlert } from "../../actions/commonActions";
import { validEmail } from "../../_shared/commonFunction";
import {
  superAdminGenResetApi,
  superAdminValidateMailApi,
} from "../../superadmin/_actions";
import { validateMailApi, genResetApi } from "../../admin/_actions";
import InputComponent from "../_common/inputComponent";

const ForgotPasswordComponent = (props) => {
  const [email, setEmail] = useState("");
  const [emptyEmail, setEmptyEmail] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [validtedEmail, setValidtedEmail] = useState(false);
  const [linkSent, setLinkSent] = useState(false);
  const [sentMsg, setSentMsg] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email && !invalidEmail) {
      const roleType = props.roleType;
      globalLoader(true);
      let res;
      let obj = {
        email: email,
      };
      if (!validtedEmail) {
        if (roleType === "admin") {
          res = await validateMailApi(obj);
        } else if (roleType === "superadmin") {
          res = await superAdminValidateMailApi(obj);
        }

        if (res?.data?.status === 200) {
          setValidtedEmail(true);
        } else {
          globalAlert("error", res?.data?.message);
        }
      } else {
        if (roleType === "admin") {
          res = await genResetApi(obj);
        } else if (roleType === "superadmin") {
          res = await superAdminGenResetApi(obj);
        }

        if (res?.data?.status === 200) {
          setLinkSent(true);
          setSentMsg(res?.data?.message);
        } else {
          globalAlert("error", res?.data?.message);
        }
      }

      globalLoader(false);
    }
  };

  const validateMail = async (ev) => {
    let value = ev.target.value;
    let valid = await validEmail(value);
    if (valid) {
      setInvalidEmail(false);
    } else {
      setInvalidEmail(true);
    }
    setEmail(value);
  };

  return (
    <>
      <div className="auth-screen-wrapper">
        <div className="fix-width-wrapper px-3 mx-auto">
          <div className="auth-body-wrapper">
            <div className="logo-wrapper text-center">
              <img
                src="../assets/icons/eC-Card-Logo.png"
                alt="EC Card Logo"
                className="logo"
              />
            </div>

            {linkSent ? (
              <div className="forgot-msg-wrapper">
                <div className="forgot-msg-txt py-4">{sentMsg}</div>
                <Link to={`/${props.roleType}/login`}>
                  <Button
                    type="button"
                    className="btn btn-black btn-block btn-own"
                  >
                    Back to Login
                  </Button>
                </Link>
              </div>
            ) : (
              <form onSubmit={(e) => handleSubmit(e)} className="form-own">
                <>
                  <div className="pt-1">
                    <p className="login-text text-center">
                      Enter below details
                    </p>

                    <div className="form-group reg-date">
                      {/* <input
                        name="email"
                        placeholder="Email"
                        onChange={(ev) =>
                          validtedEmail ? null : validateMail(ev)
                        }
                        maxLength="40"
                        value={email}
                        className="form-control"
                        disabled={validtedEmail}
                      /> */}
                      <InputComponent
                        placeholder="Email"
                        label="Email"
                        name="email"
                        type="text"
                        theme="primary"
                        inputFor="number"
                        maxLength={40}
                        value={email}
                        autoComplete={"email" + Math.random()}
                        handleCallback={(val, flag) => {
                          setInvalidEmail(flag);
                          setEmail(val);
                        }}
                      />
                      <div className="error-text font-12 pl-2">
                        {!email && emptyEmail && <span>Email is required</span>}
                        {email && invalidEmail && <span>Email is invalid</span>}
                      </div>
                    </div>

                    <div className="form-group pt-3">
                      <Button
                        type="submit"
                        className="btn btn-secondary-own btn-block btn-own"
                      >
                        {validtedEmail
                          ? "Send link to your email"
                          : "Validate email"}
                      </Button>
                    </div>

                    <div className="pb-3  pt-2 text-center">
                      <Link to={`/${props.roleType}/login`}>
                        <span className="text-white">Back to Login</span>
                      </Link>
                    </div>
                  </div>
                </>
              </form>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(ForgotPasswordComponent);
