import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
  MarkerClusterer,
} from "@react-google-maps/api";
import { GOOGLE_MAPS_API_KEY } from "../../actionTypes/types";
// import OutsideClickHandler from "react-outside-click-handler";

const MapMarkerComponent = React.memo((props) => {

  const {
    latLongObj,
    storesList,
  } = props;
  // const [autocomplete, setAutocomplete] = useState(null);
  const [markerValue, setMarkerValue] = useState(null);
  const containerStyle = { width: "100%", height: "calc(100vh - 142px)" };
  const libraries = ["places"];
  // const options = { imagePath: "/assets/icons/map.png" };

  useEffect(() => {

    if (props?.active > -1) {
      setMarkerValue(null)
    }

  }, [props.active])

  // function createKey(location) {
  //   return location.lat + location.lng;
  // }

  // const onLoad = (autocomplete) => {
  //   setAutocomplete(autocomplete);
  //   autocomplete.addListener("place_changed", onPlaceChanged);
  // };

  const onLoad2 = (infoBox) => { };

  // const onPlaceChanged = () => {
  //   if (!autocomplete) {
  //     let mapDetails = autocomplete.getPlace();
  //     props?.setInputVal(autocomplete.getPlace().formatted_address);
  //     let obj = {
  //       latitude: mapDetails.geometry.location.lat(),
  //       longitude: mapDetails.geometry.location.lbg(),
  //     };
  //     props?.setLetLongObj({
  //       lat: mapDetails.geometry.location.lat(),
  //       lng: mapDetails.geometry.location.lng(),
  //     });
  //     props?.callBasicApi(obj);
  //   } else {
  //     console.log("Autocomplete is not loaded yet!");
  //   }
  // };

  const mapLoad = (data) => { };

  const setOpenFunc = (val) => {
    // eslint-disable-next-line
    props?.setOpen();
    setMarkerValue(val);
  };

  const mapDirection = (ev, val = null) => {
    ev.stopPropagation();
    if (val) {
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${val?.latitude},${val?.longitude}`,
        "_blank"
      );
    }
  };

  return (
    <LoadScript
      googleMapsApiKey={GOOGLE_MAPS_API_KEY}
      libraries={libraries}
    >
      {/* <OutsideClickHandler onOutsideClick={closeList}></OutsideClickHandler> */}
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={latLongObj}
        zoom={12}
        onLoad={mapLoad}
      >
        <MarkerClusterer
          gridSize={10}
          minimumClusterSize={2}
          imagePath="https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m"
        // styles={[
        //   {
        //     url: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
        //     height: 26,
        //     width: 26,
        //     textSize: 15,
        //     fontFamily: "Lato",
        //     textColor: "red",
        //   },
        // ]}
        >
          {(clusterer) =>
            storesList?.length > 0 &&
            storesList.map((x, index) => (
              <Marker
                key={index}
                position={{ lat: x?.latitude, lng: x?.longitude }}
                onClick={() => setOpenFunc(x)}
                clusterer={clusterer}
                icon={x.storeId ? "/assets/icons/map.png" : "/assets/icons/ivts-icon.png"}
              // animation={2}

              >
                <i className="fas fa-accessible-icon primary-color"></i>
              </Marker>
            ))
          }
          {/* {(clusterer) =>
            testLocations.map((location) => (
              <Marker
                key={createKey(location)}
                position={location}
                clusterer={clusterer}
              />
            ))
          } */}
        </MarkerClusterer>
        {markerValue && (
          <InfoWindow
            onLoad={onLoad2}
            position={{
              lat: markerValue?.latitude + 0.000008,
              lng: markerValue?.longitude,
            }}
            onCloseClick={() => setMarkerValue(null)}
          >
            <div
              style={{
                backgroundColor: "#fff",
                opacity: 0.95,
                padding: "10px 15px",
              }}
            >
              <div className="map-info-wrapper d-flex flex-wrap font-900">
                <div className="info-wrapper flex-1 ">
                  <h1 className="font-16 text-color-primary">
                    {markerValue?.storeName || markerValue.name}
                  </h1>
                  <div>
                    Address :{" "}
                    <span className="text-color-primary">
                      {markerValue?.address && (
                        <span>{markerValue?.address}, </span>
                      )}
                      {markerValue?.street}, {markerValue?.city},{" "}
                      {markerValue?.postalCode}
                    </span>
                  </div>
                  <div
                    className="store-direction"
                    onClick={(ev) => mapDirection(ev, markerValue)}
                  >
                    DIRECTIONS
                  </div>
                </div>
                <div className="store-distance">{markerValue?.distance} mi</div>
              </div>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </LoadScript>
  );
});

export default MapMarkerComponent;
