import React, { useEffect, useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { organizationListApi } from "../../superadmin/_actions";
import { globalAlert, globalLoader } from "../../actions/commonActions";
import { logOut, validEmail } from "../../_shared/commonFunction";
import InputComponent from "../_common/inputComponent";

const AddAdminModal = (props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [org, setOrg] = useState("");
  const [firstNameErr, setFirstNameErr] = useState(false);
  const [lastNameErr, setLastNameErr] = useState(false);
  const [orgErr, setOrgErr] = useState(false);
  const [orgList, setOrgList] = useState([]);
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);

  useEffect(() => {
    callBasicApi(); // eslint-disable-next-line
  }, []);

  const callBasicApi = async () => {
    try {
      globalLoader(true);
      let res = await organizationListApi();
      if (res?.data?.status === 200) {
        setOrgList(res?.data?.data?.organizationList);
      } else {
        globalAlert("error", res?.data?.message);
      }
      globalLoader(false);
    } catch (error) {
      globalAlert("error", error?.response?.data?.message);
      if (error?.response?.data?.status === 401) {
        logOut(props?.history, "/superadmin/login");
      }
      globalLoader(false);
    }
  };

  useEffect(() => {
    setPropsToState(); // eslint-disable-next-line
  }, [props?.data]);

  const setPropsToState = () => {
    setFirstName(props?.data?.firstName);
    setLastName(props?.data?.lastName);
    setOrg(props?.data?.orgId);
    setEmail(props?.data?.email);
  };

  const handleValidation = () => {
    let flag = true;
    if (!firstName) {
      setFirstNameErr(true);
      flag = false;
    }
    if (!lastName) {
      setLastNameErr(true);
      flag = false;
    }
    if (!org) {
      setOrgErr(true);
      flag = false;
    }
    if (!email) {
      setEmailErr(true);
      flag = false;
    }
    if (invalidEmail) {
      flag = false;
    }
    return flag;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (await handleValidation()) {
      globalLoader(true);
      let obj = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        orgId: org,
      };
      props.closeModal(obj);
    }
  };
  const validateMail = async (ev) => {
    // const caret = ev.target.selectionStart;
    // const element = ev.target;
    // window.requestAnimationFrame(() => {
    //   element.selectionStart = caret;
    //   element.selectionEnd = caret;
    // });
    // let value = ev.target.value;
    let value = ev;
    let valid = await validEmail(value);
    if (valid) {
      setInvalidEmail(false);
    } else {
      setInvalidEmail(true);
    }
    setEmail(value);
  };

  return (
    <Modal
      open={props.open}
      onClose={() => props.closeModal()}
      center
      classNames={{ modal: "admin-modal" }}
    >
      <div>
        <p className="admin-banner">
          {props?.edit ? "Edit" : "Add"} {props?.role}
        </p>
      </div>
      <form onSubmit={handleSubmit} className="form-own px-3">
        <div className="row">
          <div className="col-6">
            <div className="form-own-group">
              <InputComponent
                placeholder="First Name"
                label={
                  <>
                    First Name <span className="color-red">*</span>
                  </>
                }
                type="text"
                name="firstName"
                theme="white"
                value={firstName}
                handleCallback={(val) => {
                  setFirstName(val);
                }}
              />
              <div className="error-text">
                {!firstName && firstNameErr && (
                  <span>First Name is required</span>
                )}
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="form-own-group">
              <InputComponent
                placeholder="Last Name"
                label={
                  <>
                    Last Name <span className="color-red">*</span>
                  </>
                }
                type="text"
                name="lastName"
                theme="white"
                value={lastName}
                handleCallback={(val) => {
                  setLastName(val);
                }}
              />
              <div className="error-text">
                {!lastName && lastNameErr && <span>Last Name is required</span>}
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="form-own-group">
              <InputComponent
                placeholder="Email"
                label={
                  <>
                    Email <span className="color-red">*</span>
                  </>
                }
                type="text"
                name="email"
                theme="white"
                value={email}
                handleCallback={(val) => {
                  validateMail(val);
                }}
                maxLength="40"
                disabled={props?.data}
              />
              <div className="error-text">
                {!email && emailErr && <span>Email is required</span>}
                {email && invalidEmail && <span>Email is invalid</span>}
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="form-own-group custom-select-negative-top">
              <label htmlFor="org" className="select-own-label">
                Organisation <span className="color-red">*</span>
              </label>
              {orgList && orgList.length && (
                <select
                  className="form-control select-own"
                  name="org"
                  id="org"
                  value={org}
                  onChange={(ev) => {
                    setOrg(ev.target.value);
                  }}
                >
                  <option defaultChecked value="">
                    Select an option
                  </option>
                  {orgList.map((item) => {
                    return (
                      <option key={item?.orgId} value={item?.orgId}>
                        {item?.orgName}
                      </option>
                    );
                  })}
                </select>
              )}
              <div className="error-text">
                {!org && orgErr && <span>You must select an organisation</span>}
              </div>
            </div>
          </div>
        </div>
        <div className="admin-btn">
          <button
            onClick={() => props.closeModal()}
            className="btn secondary-btn mr-2"
            type="button"
          >
            Back
          </button>
          <button type="submit" className="btn primary-btn ml-2">
            Submit
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default AddAdminModal;
