import React from "react";
import { Link } from "react-router-dom";
import HeadingComponent from "../_common/headingComponent";

const WelcomeComponent = (props) => {
  return (
    <div>
      <HeadingComponent text="Well done! Registration Successfull" />
      <div className="mx-3 text-justify terms">
        <p>
          You are now registered to the eC-Card App. To access the App you will
          need to successfully complete the quiz that follows the videos. If you
          have been given access code you will be given the opportunity to enter
          this.
        </p>
      </div>
      <div className="fixed-bottom stripe py-3 border-top fixed-bottom-own">
        <div className="col-md-12 text-center">
          <Link to={`/${props.roleType}/video`}>
            <button className="btnFooter">Continue</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default WelcomeComponent;
