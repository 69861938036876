import React from "react";
import format from "date-fns/format";

const NotificationTable = (props) => {

  return (
    <div className="table-wrapper px-3 pt-3">
      <p className="table-type">All {props?.type}</p>
      <div className="table-wrapper-scroll-y table-scrollbar">
        <div className="table-own table-responsive">
          <table className="table table-striped table-hover table-admin">
            <thead className="table-head">
              <tr>
                <th
                  className={`sort-header cursor ${props?.sortObj?.sortVal === "storeName" ? "active" : ""
                    } ${props?.sortObj?.sortType ? "aesc" : "desc"}`}
                // onClick={() => props.arraySorting("storeName")}
                >
                  <span>Title</span>{" "}
                  {props?.sortObj?.sortType === "aesc" && (
                    <i className="fas fa-caret-down"></i>
                  )}
                  {props?.sortObj?.sortType === "desc" && (
                    <i className="fas fa-caret-up"></i>
                  )}
                </th>
                <th>
                  <span>{props?.type === "template" ? "Name" : "Count"}</span>
                </th>
                {/* <th>
                  <span>Summary</span>
                </th> */}
                <th>
                  <span>Created</span>
                </th>
                {props?.type === "template" ? (
                  <>
                    <th className="text-center" style={{ width: "200px" }}>
                      <span>View Template</span>
                    </th>

                    <th style={{ width: "100px" }}>
                      <span>Action</span>
                    </th>
                  </>
                ) : (
                  <th style={{ width: "100px" }}>
                    <span>Action</span>
                  </th>
                )}
              </tr>
            </thead>
            <tbody className="table-body">
              {props.dataList.map((data) => {
                return (
                  <tr
                    key={
                      data?.notificationTemplateId || data?.notificationReportId
                    }
                  >
                    <td style={{ overflowWrap: "anywhere", maxWidth: "500px" }}>
                      {data?.title || "N/A"}
                    </td>
                    <td>
                      {props?.type === "template"
                        ? data?.name || "N/A"
                        : data?.usersCount || "N/A"}
                    </td>
                    {/* <td>{ReactHtmlParser(data?.body) || "N/A"}</td> */}
                    <td>
                      {data.created
                        ? format(new Date(data?.created), "dd/MM/yyyy")
                        : "NA"}
                    </td>

                    {props?.type === "template" ? (
                      <>
                        <td className="table-data-status text-center">
                          <button
                            onClick={() => props.sendNotification(data)}
                            className="btn btn-own-admin secondary-btn btn-sm-own table-action"
                          >
                            View
                          </button>
                        </td>

                        <td className="nowrap">
                          <span
                            className="btn btn-own-admin primary-btn btn-sm-own table-action"
                            onClick={() => props.deleteData(data)}
                          >
                            Delete
                          </span>
                        </td>
                      </>
                    ) : (
                      <td className="nowrap">
                        <span
                          className="btn btn-own-admin secondary-btn btn-sm-own table-action"
                          onClick={() => props.viewNotification(data)}
                        >
                          View
                        </span> | <span
                          className="btn btn-own-admin primary-btn btn-sm-own table-action"
                          onClick={() => props.deleteData(data)}
                        >
                          Delete
                        </span>

                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default NotificationTable;
