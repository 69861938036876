import React, { useState, useEffect } from "react";

const SidebarArrow = (props) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    props.handleSidebarToggle(isCollapsed); // eslint-disable-next-line
  }, [isCollapsed]);

  const handleSidebar = () => {
    if (isCollapsed) {
      setIsCollapsed(false);
    } else {
      setIsCollapsed(true);
    }
  };

  return (
    <div onClick={handleSidebar} className="sidebar-arrow">
      <i
        className={`arrow cursor ${
          isCollapsed
            ? "fas fa-chevron-circle-right"
            : "fas fa-chevron-circle-left"
        }`}
      ></i>
    </div>
  );
};

export default SidebarArrow;
