import React, { useState } from "react";

const SearchList = (props) => {
  const [search, setSearch] = useState();
  return (
    <>
      {/* <div className="d-flex">
        <div className="search-wrapper">
          <input
            type="text"
            name="search"
            value={search}
            onChange={(ev) => setSearch(ev.target.value)}
            placeholder="Search"
          />
          <i className="fas fa-search"></i>
        </div>
        <button className="btn text-muted">Cancel</button>
      </div> */}

      <ul className="list-unstyled list-wrapper">
        {props?.storesList?.length > 0 &&
          props?.storesList.map(
            (store) =>
            (
              <li
                key={store?.storeId}
                className="cursor"
                onClick={() =>
                  props?.getLetLongObj(store?.latitude, store?.longitude)
                }
              >
                <div className="map-detail-wrapper d-flex flex-wrap">
                  <div className="flex-1">
                    <div className="store-name">{store?.name}</div>
                    <div className="store-address">
                      {" "}
                      Address :{" "}
                      <span className="font-600">
                        {store?.storeDisplayAddress && (
                          <span>{store?.storeDisplayAddress} </span>
                        )}
                      </span>
                    </div>
                    <div className="store-address">
                      {" "}
                      Notes : <span className="font-600">{store?.notes}</span>
                    </div>
                    <div
                      className="store-direction"
                      onClick={(ev) => props?.setMapDirection(ev, store)}
                    >
                      DIRECTIONS
                    </div>
                  </div>

                  <div className="store-distance">{store?.distance} mi</div>
                </div>
              </li>
            )
          )}
        <li style={{ height: "70px" }}></li>
      </ul>
    </>
  );
};

export default SearchList;
