import React from "react";
import { Route, Switch } from "react-router-dom";
import UserLogin from "./userLogin";
import UserTerms from "./userTerms";
import UserRegister from "./userRegister";
import UserSuccess from "./userWelcome";
import UserVideo from "./userVideo";
import UserQuiz from "./userQuiz";
import Dashboard from "./dashboard";

const UserRouter = (props) => {
  return (
    <Switch>
      <Route exact path={`${props.match.path}/login`} component={UserLogin} />
      <Route
        exact
        path={`${props.match.path}/terms-and-conditions`}
        component={UserTerms}
      />
      <Route
        exact
        path={`${props.match.path}/register`}
        component={UserRegister}
      />
      <Route
        exact
        path={`${props.match.path}/welcome`}
        component={UserSuccess}
      />
      <Route exact path={`${props.match.path}/video`} component={UserVideo} />

      <Route exact path={`${props.match.path}/quiz`} component={UserQuiz} />
      <Route
        exact
        path={`${props.match.path}/dashboard`}
        component={Dashboard}
      />
      {/* <Route
        exact
        path={`${props.match.path}/settings`}
        component={UserSettings}
      /> */}
    </Switch>
  );
};

export default UserRouter;
