/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { logOut } from '../../_shared/commonFunction'
import { Select, FormControl, MenuItem, InputLabel } from "@material-ui/core";
import { globalLoader, globalAlert } from "../../actions/commonActions";
import { getStoreDropdownApi } from "../../admin/_actions";



const AddStockModal = (props) => {
    const [product, setProduct] = useState("");   
    const [productErr, setProductErr] = useState(false);
    const [storeDropDownList, setStoreDropDownList]=useState(null)
   


    useEffect(() => {
      
        callBasicApi();
      }, []);
    
      const callBasicApi = async () => {
    
        if (props?.id) {
          try {
        
            globalLoader(true);
            const res = await getStoreDropdownApi(props?.id);
            if (res?.data?.status === 200) {
              
                setStoreDropDownList(res?.data?.data?.list);
              globalLoader(false);
    
            }
    
            else {
              globalAlert("error", res?.data?.message);
              props.history.push('/admin/manage-stores')
            }
          }
          catch (error) {
            globalAlert("error", error?.response?.data?.message);
    
            if (error?.response?.data?.status === 401) {
              logOut(props?.history, "/admin/login");
            }
            globalLoader(false);
          }
    
        }
      };



    const handleSubmit = async (e) => {
        e.preventDefault();

        if(!product){
            setProductErr(true)
        }
        else{
            let obj = {
                orgProductId: product,
               
            };
            props.closeModal(obj);
        }
        
          
        
    };

    return (
        <Modal
            open={props.open}
            onClose={() => props.closeModal()}
            center
            classNames={{ modal: "admin-modal" }}
        >
            <div>
                <p className="admin-banner">{props?.edit ? "Edit" : "Add"} Stock</p>
            </div>
            <form onSubmit={handleSubmit} className="form-own px-3">
                <div className="row">
                <div className="col-12">
                        <div className="form-own-group">
                            <FormControl variant="outlined">
                                <InputLabel id="show_per_page">Product Name</InputLabel>
                                <Select
                                labelId="show_per_page"
                                id="demo-simple-select-outlined"
                                value={product || null}
                                onChange={(ev)=>setProduct(ev?.target?.value)}
                                label="Product Name"
                                name="pageSize"
                                >
                                {
                                    storeDropDownList?.length > 0 &&
                                    storeDropDownList?.map((data, index) => (
                                    <MenuItem value={data?.value} key={index}>
                                        {data?.text}
                                    </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            {!product && productErr &&<div className="error-text">Product is required</div>}
                        </div>
                    </div>
                 
                  
                </div>
                <div className="admin-btn">
                    <button
                        onClick={() => props.closeModal()}
                        className="btn secondary-btn mr-2"
                        type="button"
                    >
                        Back
                    </button>
                    <button type="submit" className="btn primary-btn ml-2">
                        Submit
                    </button>
                </div>
            </form>
        </Modal>
    );
};

export default AddStockModal;
