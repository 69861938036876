import React, { useState, useEffect } from "react";
import Header from "../template/_common/header";
import SidebarComponent from "../template/admin/sidebarComponent";
import EducationTable from "../template/admin/educationTable";
import SidebarArrow from "../template/_common/sidebarArrow";
import { globalAlert, globalLoader } from "../actions/commonActions";
import AddEditEducationModal from "../template/admin/addEditEducationModal";
import {
  getEducationManagementList,
  toggleToggleEducationApi,
  addEducationApi,
  updateStoreApi,
  deleteEducationApi,
} from "./_actions";
// import ViewQrModal from "../template/admin/viewQrModal";
import ConfirmationModal from "../template/_common/confirmModal";
import PaginationOwn from "../template/_common/ownPagination";
import { compareValues, logOut } from "../_shared/commonFunction";
import { Helmet } from "react-helmet";
import arraySort from 'array-sort';
import VideoPlayerModal from "../template/_common/videoPlayerModal";

const EducationManagement = (props) => {
  const [navbarToggle, setNavbarToggle] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [currentData, setCurrentData] = useState(null);
  const [openAddStoreModal, setopenAddStoreModal] = useState(false);
  const [editDataIs, setEditDataIs] = useState(false);
  // const [openViewStore, setOpenViewStore] = useState(false);
  const [currentViewData, setCurrentViewData] = useState(null);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [confirmaModalOpen, setConfirmaModalOpen] = useState(false);
  const [pageSize, setPageSize] = useState(25);
  const [searchVal, setSearchVal] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentDataList, setCurrentDataList] = useState([]);
  const [sortObj, setSortObj] = useState({});
  const [currentDataSorted, setCurrentDataSorted] = useState([]);
  const [notFilteredRun, setNotFilteredRun] = useState(false);
  const [totalCount, setTotalCount] = useState(false);
  const [allowView, setAllowView] = useState(false);
  const [deleteInfo, setDeleteInfo] = useState(null);
  const [type, setType] = useState("video");
  const [videoModalOpen, setVideoModalOpen] = useState(false);

  const handleSidebar = (data) => {
    setNavbarToggle(data);
  };

  useEffect(() => {
    callBasicApi(); // eslint-disable-next-line
  }, []);
  useEffect(() => {
    callBasicApi(); // eslint-disable-next-line
  }, [type]);

  const callBasicApi = async () => {
    try {
      globalLoader(true);
      let res = await getEducationManagementList(type);
      if (res?.data?.status === 200) {
        setAllowView(true);
        // setDataList(res?.data?.data?.storesList);
        let currData =
          type === "video"
            ? res?.data?.data?.videos
            : res?.data?.data?.documents;
        setDataList(currData);
        setCurrentDataSorted(
          type === "video"
            ? res?.data?.data?.videos
            : res?.data?.data?.documents
        );
        !searchVal &&
          setTotalCount(
            type === "video"
              ? res?.data?.data?.videos?.length
              : res?.data?.data?.documents?.length
          );
      } else {
        globalAlert("error", res?.data?.message);
      }
      globalLoader(false);
    } catch (error) {
      globalAlert("error", error?.response?.data?.message);

      if (error?.response?.data?.status === 401) {
        logOut(props?.history, "/admin/login");
      }
      globalLoader(false);
    }
  };

  const closeAddEducationModal = async (val = null) => {
    if (val) {
      try {
        globalLoader(true);
        let res;
        if (editDataIs) {
          res = await updateStoreApi(val, currentData?.storeId);
        } else {
          res = await addEducationApi(type, val);
        }
        if (res?.data?.status === 200) {
          globalAlert("success", res?.data?.message);
          if (!editDataIs) {
            setSearchVal("");
          }
          setNotFilteredRun(false);
          setEditDataIs(false);
          setCurrentData(null);
          setopenAddStoreModal(false);
          callBasicApi();
        } else {
          globalAlert("error", res?.data?.message);
          globalLoader(false);
        }
      } catch (err) {
        globalLoader(false);
      }
    } else {
      setEditDataIs(false);
      setCurrentData(null);
      setopenAddStoreModal(false);
    }
  };

  const editData = (data) => {
    setEditDataIs(true);
    setCurrentData(data);
    setopenAddStoreModal(true);
  };

  const confirmModalFunc = async (val) => {
    if (val) {
      globalLoader(true);

      let res = await deleteEducationApi(type, currentUserId);
      if (res?.data?.status === 200) {
        setEditDataIs(false);
        setCurrentData(null);
        setNotFilteredRun(false);
        if (totalCount === 1) setSearchVal("");
        callBasicApi();
      } else {
        globalAlert("error", res?.data?.message);
        globalLoader(false);
      }
    }
    setConfirmaModalOpen(false);
  };

  const deleteData = (data) => {
    setCurrentUserId(data?.educationId);
    let obj = [`Title :  ${type === "video" ? data?.videoTitle : data?.documentTitle}`];
    setDeleteInfo(obj);
    setConfirmaModalOpen(true);
  };

  useEffect(() => {
    if (!notFilteredRun) {
      filteredDataFunc();
    } // eslint-disable-next-line
  }, [pageSize, currentPage, sortObj, currentDataSorted, dataList]);

  const filteredDataFunc = (dataArray = null, localPage = null) => {
    let arr = [...dataList];
    let filterArr = [];
    let filteredData = [];
    if (dataArray) {
      arr = [...dataArray]
    }
    else {
      arr = arraySort(arr, compareValues(sortObj?.sortVal), { reverse: sortObj?.sortType === 'desc' ? true : false });
    }
    if (searchVal.length > 0) {
      filterArr = arr.filter((data) => {
        if (
          data?.storeName.toLowerCase().includes(searchVal.toLowerCase()) ||
          data?.address.toLowerCase().includes(searchVal.toLowerCase())
        ) {
          return data;
        } else {
          return null;
        }
      });
    } else {
      filterArr = arr;
    }
    if (filterArr && filterArr.length > 0) {
      setTotalCount(filterArr.length);
      for (
        let i = (localPage || currentPage) * pageSize - pageSize;
        i < (localPage || currentPage) * pageSize && i < filterArr.length;
        i++
      ) {
        filteredData.push(filterArr[i]);
      }
    }

    setCurrentDataList(filteredData);
  };

  const changePageSizeFunc = async (val) => {
    setNotFilteredRun(false);
    setPageSize(val.target.value);
    setCurrentPage(1);
    setSortObj(null);
  };

  // const searchFilter = (ev) => {
  //   ev.preventDefault();
  //   let arr = [...dataList];
  //   let filterArr = arr.filter(
  //     (data) =>
  //       data?.storeName.toLowerCase().includes(searchVal.toLowerCase()) ||
  //       data?.address.toLowerCase().includes(searchVal.toLowerCase())
  //   );
  //   setTotalCount(filterArr.length);
  //   setNotFilteredRun(true);
  //   setCurrentDataList(filterArr);
  //   setCurrentPage(1);
  //   setSortObj(null);
  // };

  const goToPageFunc = (val, data) => {
    setNotFilteredRun(false);
    if (data === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (
      data === "next" &&
      currentPage < Math.ceil(dataList?.length / pageSize)
    ) {
      setCurrentPage(currentPage + 1);
    } else {
      setCurrentPage(val?.target?.value);
    }
  };

  const arraySorting = (val) => {
    let arr = [...dataList];
    let sortedArr;
    let sortType = "aesc";
    if (val === sortObj?.sortVal && sortObj?.sortType === "aesc") {
      sortType = "desc";
    }
    sortedArr = arraySort(arr, compareValues(val), { reverse: sortType === 'desc' ? true : false })

    setNotFilteredRun(true);
    setCurrentPage(1);
    setSortObj({
      sortVal: val,
      sortType: sortType,
    });
    filteredDataFunc(sortedArr, 1);
  };
  const toggleData = async (id) => {
    globalLoader(true);
    let res = await toggleToggleEducationApi(type, id);
    if (res?.data?.status === 200) {
      setNotFilteredRun(false);
      callBasicApi();
    } else {
      globalAlert("error", res?.data?.message);
      globalLoader(false);
    }
  };

  const viewVideo = (data) => {
    setCurrentViewData(data);
    setVideoModalOpen(true);
  };
  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=1200, initial-scale=1" />
      </Helmet>
      {allowView && (
        <div className="admin-portal">
          <Header roleType="admin" />
          <div className={`${navbarToggle ? "active" : ""} wrapper`}>
            <SidebarComponent
              navbarToggle={navbarToggle}
              active={props.match.path}
            />
            <div className="main">
              <div className="main-wrapper">
                <div>
                  <p className="admin-banner">Education Management</p>
                </div>

                <div className="tab-own-wrapper text-center">
                  <ul className="tab-wrapper-list d-inline-flex flex-wrap list-unstyled">
                    <li
                      className={`${type === "video" ? "active" : ""}`}
                      onClick={() => setType("video")}
                    >
                      Video
                    </li>
                    <li
                      className={`${type === "document" ? "active" : ""}`}
                      onClick={() => setType("document")}
                    >
                      Document
                    </li>
                  </ul>
                </div>
                <SidebarArrow handleSidebarToggle={handleSidebar} />
                <div>
                  <div className="container-fluid">
                    <div className="admin-add-new mt-4 mt-lg-0 d-flex justify-content-end">
                      <button
                        onClick={() => setopenAddStoreModal(true)}
                        className="btn secondary-btn modal-btn"
                      >
                        <i className="fas fa-plus"></i> Add {type}
                      </button>
                    </div>
                    <div className="row admin-controls">
                      {dataList?.length > 0 && (
                        <div
                          className="px-3 col-12 pt-3"
                          style={{ borderTop: "2px solid #ebeef1" }}
                        >
                          <PaginationOwn
                            pageSize={pageSize}
                            changePageSize={changePageSizeFunc}
                            goToPage={goToPageFunc}
                            totalCount={totalCount}
                            currentPage={currentPage}
                            applicationType={
                              currentDataList?.length > 1 ? "stores" : "store"
                            }
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {currentDataList?.length > 0 ? (
                  <EducationTable
                    dataList={currentDataList}
                    arraySorting={arraySorting}
                    basicApiCall={callBasicApi}
                    sortObj={sortObj}
                    editData={editData}
                    deleteData={deleteData}
                    type={type}
                    toggleData={toggleData}
                    viewVideo={viewVideo}
                  />
                ) : (
                  <div className="px-3 pt-3">
                    <div className="no-data-available">No data available</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {openAddStoreModal && (
        <AddEditEducationModal
          open={openAddStoreModal}
          closeModal={closeAddEducationModal}
          data={currentData}
          edit={editDataIs}
          type={type}
        />
      )}

      {confirmaModalOpen && (
        <ConfirmationModal
          open={confirmaModalOpen}
          deleteInfo={deleteInfo}
          description={`Do you want to delete this ${type === 'video' ? 'video' : 'document'}?`}
          onCloseModal={confirmModalFunc}
        />
      )}

      {videoModalOpen && currentViewData && (
        <VideoPlayerModal
          open={videoModalOpen}
          closeModal={() => {
            setVideoModalOpen(false);
            setCurrentViewData(null);
          }}
          src={currentViewData?.videoUrl}
          title={currentViewData?.videoTitle}
        />
      )}
    </>
  );
};

export default EducationManagement;
