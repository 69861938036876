import React, { useState, useEffect } from "react";
import { MsgFiles } from "../../actionTypes/msgFiles";
import MsgComp from "./msgComp";
import { getNotificationApi, readNotificationApi, userViewSingleEducationApi } from '../../user/_userActions'
import { globalAlert, globalLoader } from "../../actions/commonActions";
import ViewNotificationModal from './notificationViewModal';
import VideoPlayerModal from "../_common/videoPlayerModal";


const MessageComponent = (props) => {
  const [search, setSearch] = useState("");
  const [dataList, setDataList] = useState(null);
  const [notificationModalOpen, setNotificationModalOpen] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [currentViewData, setCurrentViewData] = useState(null);
  const [videoModalOpen, setVideoModalOpen] = useState(false);


  useEffect(() => {
    callBasicApi();
  }, []);

  const callBasicApi = async () => {
    try {
      globalLoader(true);
      let res = await getNotificationApi();

      if (res?.data?.status === 200) {
        setDataList(res?.data?.data?.notifications);

      }
      else {
        globalAlert('error', res?.data?.message)
      }
      globalLoader(false)

    }
    catch {

    }
    // let res = MsgFiles;
    // setDataList(res);
  };

  const openViewModal = (val, key, index) => {
    readNotificationApi(val?.notificationId)
    let localArr = [...dataList[key]];
    localArr[index].isRead = true;
    let obj = { ...dataList, [key]: localArr };
    setDataList(obj)
    setCurrentData(val)
    setNotificationModalOpen(true)
  }

  const viewVideo = async (val) => {
    try {
      globalLoader(true);
      let res = await userViewSingleEducationApi('video', val?.extraParams?.educationId);

      if (res?.data?.status === 200) {
        setCurrentViewData(res?.data?.data?.record);
        setNotificationModalOpen(false)
        setCurrentData(null)
        setVideoModalOpen(true)
      }
      else {
        globalAlert('error', res?.data?.message)
      }
      globalLoader(false)

    }
    catch (err) {

    }

  }

  const viewEducation = async (val) => {
    try {
      globalLoader(true);
      let res = await userViewSingleEducationApi('document', val?.extraParams?.educationId);
      
      if (res?.data?.status === 200) {
        setNotificationModalOpen(false)
        setCurrentData(null)
        window.location.href = res?.data?.data?.record?.documentFile;
      }
      else {
        globalAlert('error', res?.data?.message)
      }
      globalLoader(false)

    }
    catch (err) {

    }

  }
  return (
    <div className="msg-comp-wrapper mx-md-auto pt-4">
      {/* <div className="search-msg-wrapper">
        <input
          type="text"
          name="search"
          value={search}
          onChange={(ev) => setSearch(ev.target.value)}
          placeholder="Search"
        />
        <i className="fas fa-search"></i>
      </div> */}

      {dataList && Object.keys(dataList).length > 0 ?
      
      Object.keys(dataList).map((data, i) => (
        <div key={i}>
          <div style={{ paddingLeft: "2rem", fontWeight: "600" }}>{data}</div>

          <ul className="list-unstyled pt-2">
            {dataList[data]?.length > 0 && dataList[data].map((list, index) => (
              <li className="pb-2 cursor" onClick={() => openViewModal(list, data, index)} key={index}>{list && <MsgComp data={list} />}</li>
            ))}

          </ul>
        </div>
      )):

      <div className="text-center">No new notification</div>
      
      }

      {notificationModalOpen && (
        <ViewNotificationModal open={notificationModalOpen} closeModal={() => setNotificationModalOpen(false)} data={currentData}
          viewVideo={viewVideo} viewEducation={viewEducation}/>
      )}

      {videoModalOpen && currentViewData && (
        <VideoPlayerModal
          open={videoModalOpen}
          closeModal={() => {
            setVideoModalOpen(false);
            setCurrentViewData(null);
          }}
          src={currentViewData?.videoUrl}
          title={currentViewData?.videoTitle}
          autoPlay={true}
        />
      )}


    </div>
  );
};

export default MessageComponent;
