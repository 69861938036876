import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
// import TextFieldComponent from "../_common/textFieldComponent";
import {
  userLoginApi,
  userVerifyLoginApi,
  userLevelProgressApi,
} from "../../user/_userActions";
import { globalLoader, globalAlert } from "../../actions/commonActions";
import { superAdminLoginApi } from "../../superadmin/_actions";

import { adminLoginApi, adminLoginMFAApi, } from "../../admin/_actions";
import TimerComp from "../../_shared/timer";
import InputComponent from "../_common/inputComponent";
// import { phone } from "faker/locale/en_AU_ocker";

const LoginComponent = (props) => {
  const [values, setValues] = useState({
    phone: "",
    otp: "",
  });
  const [mobileError, setMobileError] = useState(false);
  const [mobileMinError, setMobileMinError] = useState(false);
  const [email, setEmail] = useState("");
  const [emptyEmail, setEmptyEmail] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [password, setPassword] = useState("");
  const [emptyPassword, setEmptyPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [toggleOtp, setToggleOtp] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isMFA, setIsMFA] = useState(false);
  const [MFAOTP, setMFAOTP] = useState('');
  const [emptyMFAOTP, setEmptyMFAOTP] = useState('');


  useEffect(() => {
    document.querySelector("body").classList.remove("overflow-hidden-own");
    document.querySelector("html").classList.remove("overflow-hidden-own");
    basicAuth();
  }, []);



  const basicAuth = async () => {
    localStorage.removeItem("ecauthtoken");
    localStorage.removeItem("ecsecuretoken");
    localStorage.removeItem("userEmail");
    globalLoader(false);
  };

  const phoneValidation = (num) => {
    if (!num) {
      setErrors({ phoneMsg: "Phone number is empty" });
      setMobileError(true);
      return false;
    }
    if (num.length < 10) {
      setErrors({ phoneMsg: "Phone number has 10 digits" });
      setMobileMinError(true);
      return false;
    }
    return true;
  };

  const adminValidation = () => {
    let flag = true;
    if (!email) {
      setEmptyEmail(true);
      flag = false;
    }
    if (!password) {
      setEmptyPassword(true);
      flag = false;
    }
    if (invalidEmail) {
      flag = false;
    }
    return flag;
  };

  const otpValidation = () => {
    let otp = values.otp;
    if (!otp) {
      setErrors({ otpMsg: "OTP is required" });
      return false;
    } else {
      setErrors({ otpMsg: "" });
      return true;
    }
  };

  const handleOtp = async () => {
    let phoneValid = await phoneValidation(values?.phone);

    if (phoneValid) {
      globalLoader(true);
      let obj = {
        mobile: values?.phone,
      };
      let res;
      const roleType = props.roleType;
      if (roleType === "superadmin") {
      } else if (roleType === "admin") {
      } else {
        res = await userLoginApi(obj);
      }

      if (res?.data?.status === 200) {
        setToggleOtp(true);
        setValues({ ...values, otp: "" });
        globalAlert("success", res?.data?.message);
        setOtpSent(true);
      } else {
        globalAlert("error", res?.data?.message);
      }
      globalLoader(false);

      // setErrors({});
      // setToggleOtp(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const roleType = props.roleType;
    if (!toggleOtp && roleType === "user") {
      handleOtp();
    } else {
      globalLoader(true);

      let res;
      let obj;
      if (otpValidation() && roleType === "user") {
        obj = {
          mobile: values?.phone,
          otp: values?.otp,
        };
        res = await userVerifyLoginApi(obj);
        if (res?.data?.status === 200) {
          globalAlert("success", res?.data?.message);

          let progress = await userLevelProgressApi();
          if (progress?.data?.status === 200) {
            if(!progress?.data?.data?.skipVideosPage) {
              props.history.push(`/${props.roleType}/video`);
            } else if(progress?.data?.data?.skipVideosPage && !progress?.data?.data?.skipQuizPage) {
              props.history.push(`/${props.roleType}/quiz`);
            } else if (progress?.data?.data?.quizLevelPassed) {
              props.history.push(`/${props.roleType}/dashboard`);
            } else {
              props.history.push(`/${props.roleType}/video`);
            }
          } else {
            globalAlert("error", res?.data?.message);
            window.location.reload();
          }

          // props.history.push(`/${props.roleType}/quiz`);
        } else {
          globalAlert("error", res?.data?.message);
        }
      } else {
        if (adminValidation()) {
          obj = {
            email: email,
            password: password,
          };
          if (roleType === "superadmin") {
            res = await superAdminLoginApi(obj);
            if (res?.data?.status === 200) {
              globalAlert("success", res?.data?.message);
              localStorage.setItem("userEmail", email);
              props.history.push(`/${roleType}/manage-organisations`);
            } else {
              globalAlert("error", res?.data?.message);
            }
          } else if (roleType === "admin") {
            res = await adminLoginApi(obj);
            if (res?.data?.status === 200) {
              localStorage.setItem("userEmail", email);
              if(res?.data?.data?.isMfaRequired){
                setIsMFA(true)
                setOtpSent(true)
              }
              else{
                globalAlert("success", res?.data?.message);
                props.history.push(`/${roleType}/manage-users`);
              }
              
            } else {
              globalAlert("error", res?.data?.message);
            }
          }
        }
      }
    }
    globalLoader(false);
  };

  const handleSubmitMFA = async (e) => {
    e.preventDefault();
    try {
      if (MFAOTP?.length > 5) {
        const roleType = props.roleType;
        globalLoader(true);
        const res = await adminLoginMFAApi({ otp: MFAOTP });
        if (res?.data?.status === 200) {
          localStorage.setItem("userEmail", email);
          globalAlert("success", res?.data?.message);
              props.history.push(`/${roleType}/manage-users`);

        } else {
          globalLoader(false);
          globalAlert("error", res?.data?.message);
        }
      }
      else {
        setEmptyMFAOTP(true)
      }

    }
    catch (err) {
      if(err?.response?.status===401){
        setIsMFA(false)
        setMFAOTP('')
        
      }
      globalAlert('error', err?.response?.data?.message || "Something went wrong")
      globalLoader(false);
    }
  }

  const setTimerOver = () => {
    setOtpSent(false);
  };

  return (
    <>
      <div className="auth-screen-wrapper">
        <div className="fix-width-wrapper px-3 mx-auto">
          <div className="auth-body-wrapper">
            <div className="logo-wrapper text-center">
              <img
                src="../assets/icons/eC-Card-Logo.png"
                alt="EC Card Logo"
                className="logo"
              />
            </div>
            {!isMFA ?
              <>
                <form onSubmit={(e) => handleSubmit(e)} className="form-own">

                  {props.roleType === "user" ? (
                    <>
                      <div className="form-group reg-date pt-3">
                        <InputComponent
                          placeholder="Enter Mobile Number"
                          label="Mobile Number"
                          type="text"
                          name="phone"
                          theme="primary"
                          inputFor="number"
                          maxLength="11"
                          disabled={toggleOtp}
                          value={values?.phone}
                          autoComplete="off"
                          handleCallback={(val) => {
                            const name = "phone";
                            setValues({ ...values, [name]: val });
                          }}
                        />
                        <span className="error-text font-12 pl-2">
                          {values?.phone && mobileMinError && errors.phoneMsg}
                          {!values?.phone && mobileError && errors.phoneMsg}
                        </span>
                      </div>
                      {toggleOtp && (
                        <>
                          <div className="form-group">
                            <InputComponent
                              placeholder="Enter OTP"
                              label="Enter OTP"
                              type="text"
                              name="otp"
                              inputFor="text"
                              theme="primary"
                              maxLength={null}
                              value={values?.otp}
                              autoComplete="off"
                              handleCallback={(val) => {
                                const name = "otp";
                                setValues({ ...values, [name]: val });
                              }}
                            />

                            <span className="error-text font-12 pl-2">
                              {errors.otpMsg}
                            </span>
                          </div>

                          <div className="resend-otp-txt text-center mb-3">
                            {otpSent ? (
                              <TimerComp setTimerOver={setTimerOver} />
                            ) : (
                              <span
                                className="cursor text-color-secondary"
                                onClick={handleOtp}
                              >
                                Resend OTP
                              </span>
                            )}
                          </div>
                          <div className="form-group">
                            <Button
                              type="submit"
                              className="btn btn-secondary-own btn-block btn-own"
                            >
                              Login
                            </Button>
                          </div>
                        </>
                      )}

                      {!toggleOtp && (
                        <div className="form-group pt-3 pb-5">
                          <Button
                            variant="primary"
                            // onClick={handleOtp}
                            className="btn-secondary-own button btn btn-block btn-own"
                            type="submit"
                          >
                            Send OTP
                          </Button>
                        </div>
                      )}
                      <div className="form-group pt-5 d-flex justify-content-center">
                        New User?
                        <Link
                          className="pl-1 text-color-secondary"
                          to={`/${props.roleType}/register`}
                        >
                          Register Now
                        </Link>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="pt-1">
                        <p className="login-text text-center">
                          Login below to access
                        </p>

                        <div className="form-group reg-date">
                          <InputComponent
                            placeholder="Email"
                            label="Email"
                            name="email"
                            type="text"
                            keyType="email"
                            theme="primary"
                            inputFor="number"
                            maxLength={40}
                            disabled={toggleOtp}
                            value={email}
                            autoComplete={"email" + Math.random()}
                            handleCallback={(val, flag) => {
                              setInvalidEmail(flag);
                              setEmail(val);
                            }}
                          />
                          <div className="error-text font-12 pl-2">
                            {!email && emptyEmail && <span>Email is required</span>}
                            {email && invalidEmail && <span>Email is invalid</span>}
                          </div>
                        </div>
                        <div className="form-group reg-date position-relative">
                          {/* <input
                        placeholder="Password"
                        className="form-control"
                        type={passwordVisible ? "text" : "password"}
                        onChange={(ev) => setPassword(ev.target.value)}
                        maxLength={30}
                        autoComplete="off"
                        value={password}
                      /> */}
                          <InputComponent
                            placeholder="Password"
                            label="Password"
                            name="password"
                            type={passwordVisible ? "text" : "password"}
                            theme="primary"
                            maxLength={30}
                            value={password}
                            autoComplete="off"
                            handleCallback={(val) => {
                              setPassword(val);
                            }}
                          />
                          <div
                            className="login-password cursor"
                            onClick={() => {
                              setPasswordVisible((current) => !current);
                            }}
                          >
                            {passwordVisible ? (
                              <i className="far fa-eye"></i>
                            ) : (
                              <i className="far fa-eye-slash"></i>
                            )}
                          </div>
                          <div className="error-text font-12 pl-2">
                            {!password && emptyPassword && (
                              <span>Password is required</span>
                            )}
                          </div>
                        </div>

                        <div className="form-group pt-3">
                          <Button
                            type="submit"
                            className="btn btn-secondary-own btn-block btn-own"
                          >
                            Login
                          </Button>
                        </div>

                        {props.roleType !== "user" && (
                          <div className="pb-3  pt-2 text-center d-flex flex-wrap justify-content-between">
                            <Link to={`/${props?.roleType}/activate-account`}>
                              <span className=" text-color-secondary">
                                Activate account
                              </span>
                            </Link>
                            <Link to={`/${props.roleType}/forgot-password`}>
                              <span className=" text-color-secondary">
                                Forgot Password?
                              </span>
                            </Link>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </form></> :

              // MFA
              <>
                <form onSubmit={(e) => handleSubmitMFA(e)} className="form-own">
                  {/* <h2 className="mb-0 pt-4">MFA</h2> */}
                  <p className="text-center">We've sent a six-digit code to your registered email.</p>

                  <div className="form-group reg-date">
                    <InputComponent
                      placeholder="Authentication code"
                      label="Authentication code"
                      name="otp"
                      type="number"
                      keyType="email"
                      theme="primary"
                      inputFor="number"
                      maxLength={6}
                      value={MFAOTP}

                      handleCallback={(val) => {
                        setMFAOTP(val)
                      }}
                    />
                    <div className="error-text font-12 pl-2">
                      {!MFAOTP && emptyMFAOTP && <span>Authentication code is required</span>}

                    </div>
                  </div>

                  <div className="form-group pt-3">
                    <Button
                      type="submit"
                      className="btn btn-secondary-own btn-block btn-own"
                    >
                      Submit
                    </Button>
                  </div>

                  <div className="pb-3  pt-2 text-center d-flex flex-wrap justify-content-between">

                  {otpSent ? (
                              <TimerComp setTimerOver={setTimerOver} msg={"Didn't receive a code? Resend Code"} />
                            ) : (
                              <span
                                className="cursor text-color-secondary text-center"
                                onClick={handleSubmit}
                              >
                                Didn't receive a code? Resend Code
                              </span>
                            )}
                           
                          </div>
                </form>
              </>
            }

          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(LoginComponent);
