import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { activateAdminUserApi } from "../../admin/_actions";
import { activateSuperAdminUserApi } from "../../superadmin/_actions";
import { validEmail } from "../../_shared/commonFunction";
import { withRouter } from "react-router-dom";
import { globalLoader, globalAlert } from "../../actions/commonActions";
import InputComponent from "../_common/inputComponent";

const VerifyAccount = (props) => {
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [emptyEmail, setEmptyEmail] = useState(false);
  const [emptyCode, setEmptyCode] = useState(false);
  const [password, setPassword] = useState("");
  const [emptyPassword, setEmptyPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [emptyConfirmPassword, setEmptyConfirmPassword] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [minSixChar, setMinSixChar] = useState(false);
  const [oneVarIncluded, setOneVarIncluded] = useState(false);
  const [numberIncluded, setNumberIncluded] = useState(false);
  const [specialCharacter, setSpecialCharacter] = useState(false);

  const handlePassword = (ev) => {
    let val = ev;
    let containsNumber = /\d+/; // eslint-disable-next-line
    let specailChar = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    let variableChar = /[A-z]/;
    if (variableChar.test(val)) {
      setOneVarIncluded(true);
    } else {
      setOneVarIncluded(false);
    }
    if (containsNumber.test(val)) {
      setNumberIncluded(true);
    } else {
      setNumberIncluded(false);
    }
    if (specailChar.test(val)) {
      setSpecialCharacter(true);
    } else {
      setSpecialCharacter(false);
    }
    if (val.length < 6) {
      setMinSixChar(false);
    } else {
      setMinSixChar(true);
    }

    setPassword(val);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let valid = await handleValidation();
    if (valid) {
      globalLoader(true);
      let obj = {
        email: email,
        activationCode: code,
        password: password,
      };
      let res;
      if (props?.roleType === "superadmin") {
        res = await activateSuperAdminUserApi(obj);
        if (res?.data?.status === 200) {
          localStorage.setItem("userEmail", email);
          globalAlert("success", res?.data?.message);
          props.history.push("/superadmin/manage-organisations");
        } else {
          globalAlert("error", res?.data?.message);
          globalLoader(false);
        }
      } else if (props?.roleType === "admin") {
        res = await activateAdminUserApi(obj);
        if (res?.data?.status === 200) {
          localStorage.setItem("userEmail", email);
          globalAlert("success", res?.data?.message);
          props.history.push("/admin/manage-user");
        } else {
          globalAlert("error", res?.data?.message);
          globalLoader(false);
        }
      }
    }
  };

  const handleValidation = () => {
    let flag = true;
    if (!email) {
      setEmptyEmail(true);
      flag = false;
    }
    if (!code) {
      setEmptyCode(true);
      flag = false;
    }
    if (!password) {
      setEmptyPassword(true);
      flag = false;
    }
    if (!confirmPassword) {
      setEmptyConfirmPassword(true);
      flag = false;
    }
    if (password && confirmPassword && password !== confirmPassword) {
      setPasswordMatch(false);
      flag = false;
    }
    return flag;
  };

  const verifyMail = async (ev) => {
    let value = ev.target.value;
    let valid = await validEmail(value);
    if (valid) {
      setInvalidEmail(false);
    } else {
      setInvalidEmail(true);
    }
    setEmail(value);
  };

  return (
    <>
      <div className="auth-screen-wrapper">
        <div className="fix-width-wrapper px-3 mx-auto">
          <div className="auth-body-wrapper">
            <div className="logo-wrapper text-center">
              <img
                src="../assets/icons/eC-Card-Logo.png"
                alt="EC Card Logo"
                className="logo"
              />
            </div>
            <form onSubmit={handleSubmit} className="form-own">
              <div className="pt-1">
                <p className="login-text text-center">
                  Enter details to activate your account
                </p>
                <div className="form-group reg-date">
                  <InputComponent
                    placeholder="Email"
                    label="Email"
                    name="email"
                    type="text"
                    theme="primary"
                    inputFor="number"
                    maxLength={40}
                    value={email}
                    autoComplete={"email" + Math.random()}
                    handleCallback={(val, flag) => {
                      setInvalidEmail(flag);
                      setEmail(val);
                    }}
                  />
                  <div className="error-text font-12 pl-2">
                    {!email && emptyEmail && <span>Email is required</span>}
                    {email && invalidEmail && <span>Email is invalid</span>}
                  </div>
                </div>
                <div className="form-group">
                  <InputComponent
                    placeholder="Activation Code"
                    label="Activation Code"
                    name="code"
                    type="text"
                    theme="primary"
                    value={code}
                    handleCallback={(val) => setCode(val)}
                  />
                  <div className="error-text font-12 pl-2">
                    {!code && emptyCode && (
                      <span>Activation Code is required</span>
                    )}
                  </div>
                </div>
                <div className="form-group position-relative">
                  <InputComponent
                    placeholder="Password"
                    label="Password"
                    name="password"
                    type={passwordVisible ? "text" : "password"}
                    theme="primary"
                    value={password}
                    autoComplete="off"
                    handleCallback={(val) => {
                      handlePassword(val);
                    }}
                  />
                  <div
                    className="login-password cursor"
                    onClick={() => {
                      setPasswordVisible((current) => !current);
                    }}
                  >
                    {passwordVisible ? (
                      <i className="far fa-eye"></i>
                    ) : (
                      <i className="far fa-eye-slash"></i>
                    )}
                  </div>
                  <div className="error-text font-12 pl-2">
                    {!password && emptyPassword && (
                      <span>Password is required</span>
                    )}
                    {!passwordMatch && <span>Passwords do not match</span>}
                  </div>
                </div>
                <div className="form-group position-relative">
                  <InputComponent
                    placeholder="Confirm Password"
                    label="Confirm Password"
                    name="confirmPassword"
                    type={confirmPasswordVisible ? "text" : "password"}
                    theme="primary"
                    value={confirmPassword}
                    autoComplete="off"
                    handleCallback={(val) => {
                      setConfirmPassword(val);
                    }}
                  />
                  <div
                    className="login-password cursor"
                    onClick={() => {
                      setConfirmPasswordVisible((current) => !current);
                    }}
                  >
                    {confirmPasswordVisible ? (
                      <i className="far fa-eye"></i>
                    ) : (
                      <i className="far fa-eye-slash"></i>
                    )}
                  </div>
                  <div className="error-text font-12 pl-2">
                    {!confirmPassword && emptyConfirmPassword && (
                      <span>Confirm Password is required</span>
                    )}
                    {!passwordMatch && <span>Passwords do not match</span>}
                  </div>
                </div>
                <div className="password-helper">
                  <ul className="list-unstyled helper-list pl-3">
                    <li className={`${minSixChar ? "text-white" : ""}`}>
                      Minimum 6 character
                    </li>
                    <li className={`${oneVarIncluded ? "text-white" : ""}`}>
                      At least one variable case
                    </li>
                    <li className={`${numberIncluded ? "text-white" : ""}`}>
                      At least one number
                    </li>
                    <li className={`${specialCharacter ? "text-white" : ""}`}>
                      At least one special character
                    </li>
                  </ul>
                </div>
                <div className="form-group pt-3">
                  <Button
                    type="submit"
                    className="btn btn-secondary-own btn-block btn-own"
                  >
                    Submit
                  </Button>
                </div>
                <div className="pt-2 text-center pb-3">
                  <Link to={`/${props.roleType}/login`}>
                    <span className="text-white">Back to Login</span>
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(VerifyAccount);
