import React from "react";

const ProgressBar = React.memo(({ count, currentVal, ...props }) => {
  return (
    <div className="progress-own">
      <span
        className="progress-bar-own"
        style={{ width: `${(currentVal / count) * 100}%` }}
      ></span>
    </div>
  );
});

export default ProgressBar;
