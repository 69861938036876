import React, { useEffect, useState, useCallback } from "react";
import DocumentsComponent from "./documentsComponent";
import VideosComponent from "./videosComponent";
import { userViewEducationApi } from '../../user/_userActions'
import { logOut } from "../../_shared/commonFunction";
import { debounce } from "lodash";
import { withRouter } from "react-router-dom";
import {
  globalAlert,
  globalLoader,
  geoLocationApi,
} from "../../actions/commonActions";

let timeOut;
let timeOutVal;

const EducationComponent = (props) => {
  const [activeTab, setActiveTab] = useState(0);
  const [search, setSearch] = useState("");
  const [data, setData] = useState(null)


  useEffect(() => {
    callBasicApi()
  }, [])

  useEffect(() => {
    setSearch('')
    setData(null)
    setTimeout(() => {
    callBasicApi()
      
    }, 100);
  }, [activeTab])

  const callBasicApi = async (val = null) => {
    try {
      let interNalVal;
      if (val) {
        interNalVal = val;
      }
      globalLoader(true);
      let res = await userViewEducationApi(activeTab === 0 ? 'video' : 'document', val?val:'');
      if (res?.data?.status === 200) {
        activeTab === 0 ? setData(res?.data?.data?.videos) : setData(res?.data?.data?.documents)
        globalLoader(false);

      } else if (res?.data?.status === 401) {
        globalAlert("error", res?.data?.message);
        logOut(props.history, "/user/login");
      } else {
        globalAlert("error", res?.data?.message);
        globalLoader(false);

      }
    } catch (error) {
      globalAlert("error", error?.response?.data?.message);
      if (error?.response?.data?.status === 401) {
        logOut(props?.history, "/user/login");
      }
      globalLoader(false);
    }
  };

  const changeTab = (id) => {
    setActiveTab(id);
  };

  const searchEducation =(val)=>{
    setSearch(val)
   if(!timeOutVal){
      clearTimeout(timeOut)
      timeOut =setTimeout(() => {
        setData(null);
        callBasicApi(val)      
      }, 500);
     
    }  

  }


  return (
    <div className="bg-light-grey">

    <div className="edu-comp-wrapper mx-md-auto">
      <div className="menu-wrapper">
        <div
          className={`menu cursor ${activeTab === 0 ? "active" : ""}`}
          onClick={() => changeTab(0)}
        >
          <div className="mb-2">Videos</div>
          <div className="marker"></div>
        </div>
        <div
          className={`menu cursor ${activeTab === 1 ? "active" : ""}`}
          onClick={() => changeTab(1)}
        >
          <div className="mb-2">Documents</div>
          <div className="marker"></div>
        </div>
      </div>
      <div className="education-search-wrapper search-wrapper px-4 pt-3">
        <input
          type="text"
          name="search"
          value={search}
          onChange={(ev) => searchEducation(ev.target.value)}
          placeholder="Search"
        />
        <i className="fas fa-search"></i>
      </div>
      <div className="edu-content-wrapper">
        {activeTab === 0 && data? <VideosComponent data={data} /> : <DocumentsComponent data={data} />}
      </div>
    </div>
    </div>
  );
};

export default withRouter(EducationComponent);
