import React from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

const ConfirmationModal = React.memo((props) => {
  return (
    <Modal
      showCloseIcon={false}
      classNames={{ modal: "modal-own", root: "modal-own-wrapper confirm-modal" }}
      open={props.open}
      onClose={() => props.onCloseModal()}
      center
      closeOnOverlayClick={true}
    >
      <div className="video-player-wrapper px-2 text-center">
        {props.description && (
          <h3 className="font-20 primary-color py-3">{props.description}</h3>
        )}
        <div className="primary-color">
          {props?.deleteInfo && (
            <ul className="list-unstyled">
              {props?.deleteInfo?.map((data) => {
                return <li>{data}</li>;
              })}
            </ul>
          )}
        </div>
        <div className="border-bottom-own pt-2 mb-3"></div>
        <div className="pb-3 btn-wrapper pt-3">
          <button
            type="button"
            onClick={() => props.onCloseModal(null)}
            className="btn secondary-btn mb-3 mb-md-0 full-width-xs-mb btn-own btn-own-grey min-height-btn min-width-btn-md mr-md-3"
          >
            NO
          </button>
          <button
            type="submit"
            onClick={() => props.onCloseModal(true)}
            className="btn primary-btn full-width-xs btn-own btn-own-primary min-height-btn min-width-btn-md"
          >
            YES
          </button>
        </div>
      </div>
    </Modal>
  );
});

export default ConfirmationModal;
