import React from "react";
import { Link, withRouter } from "react-router-dom";

const Home = () => {
  return (
    <div>
      <h1>
        <Link to={"/superadmin/login"}>Super Admin Login</Link>
      </h1>
      <h1>
        <Link to={"/admin/login"}>Admin Login</Link>
      </h1>
      <h1>
        <Link to={"/user/login"}>User Login</Link>
      </h1>
    </div>
  );
};

export default withRouter(Home);
