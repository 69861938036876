import React from "react";
import { connect } from "react-redux";
import { globalAlertRemove } from "../actions/commonActions";

const AlertComponent = ({ alertArray }) => {
  const update = () => {
    setTimeout(() => {
      globalAlertRemove();
    }, 4000);
  };

  return (
    <React.Fragment>
      {alertArray && alertArray.length > 0 ? (
        <div className="alert-box-wrapper">
          <ul>
            {alertArray.map((x, index) => (
              <li
                key={index}
                className={
                  x.alertType === "success"
                    ? "alert-list success list-unstyled"
                    : "alert-list error list-unstyled"
                }
              >
                {update()}
                {x.alertMessage}
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </React.Fragment>
  );
};

const mapStateProps = (state) => ({
  alertArray: state.common.alertArray,
  alertArrayLength: state.common.alertArrayLength,
});

export default connect(mapStateProps)(AlertComponent);
