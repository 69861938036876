import React from "react";

const QuestionTable = (props) => {
  const correctAns = (data) => {
    if (data?.length > 0) {
      let correctAns = data.find((x) => x.isAnswer);
      if (correctAns) {
        return correctAns?.optionTitle;
      } else return "N/A";
    } else return "N/A";
  };
  return (
    <div className="table-wrapper px-3 pt-3">
      <p className="table-type">All Questions</p>
      <div className="table-wrapper-scroll-y table-scrollbar">
        <div className="table-responsive table-own">
          <table className="table table-striped table-hover table-admin">
            <thead className="table-head">
              <tr>
                <th
                  className={`sort-header cursor ${
                    props?.sortObj?.sortVal === "questionTitle" ? "active" : ""
                  } ${props?.sortObj?.sortType ? "aesc" : "desc"}`}
                  onClick={() => props.arraySorting("questionTitle")}
                >
                  <span>Question</span>
                  {props?.sortObj?.sortType === "aesc" && (
                    <i className="fas fa-caret-down"></i>
                  )}
                  {props?.sortObj?.sortType === "desc" && (
                    <i className="fas fa-caret-up"></i>
                  )}
                </th>
                <th>
                  <span>Correct Answer</span>
                </th>
                <th className="sort-header cursor">
                  <span>Active</span>
                </th>
                <th className="sort-header">
                  <span>Action</span>
                </th>
              </tr>
            </thead>
            <tbody className="table-body">
              {props?.dataList?.map((data) => {
                return (
                  <tr key={data?.questionBankId}>
                    <td>{data?.questionTitle}</td>
                    <td>{correctAns(data?.questionOptions)}</td>
                    <td>
                      {/* <input
                        type="checkbox"
                        checked={data?.isActivated}
                        onChange={() => props?.toggleData(data?.questionBankId)}
                      /> */}
                      <div className="table-switch d-flex">
                        <input
                          className="toggle-input"
                          type="checkbox"
                          id={data?.questionBankId}
                          onChange={() =>
                            props?.toggleData(data?.questionBankId)
                          }
                          checked={data?.isActivated}
                        />
                        <label
                          className="toggle-label"
                          htmlFor={data?.questionBankId}
                        >
                          Toggle
                        </label>
                      </div>
                    </td>
                    <td className="nowrap">
                      <span
                        className="mr-1 btn btn-own-admin primary-btn btn-sm-own table-action"
                        onClick={() => props.editData(data)}
                      >
                        Edit
                      </span>{" "}
                      |{" "}
                      <span
                        className="ml-1 btn btn-own-admin primary-btn btn-sm-own table-action"
                        onClick={() => props.deleteData(data)}
                      >
                        Delete
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default QuestionTable;
