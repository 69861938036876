import React from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import {
  Player,
  ControlBar,
  CurrentTimeDisplay,
  TimeDivider,
  BigPlayButton,
  PlaybackRateMenuButton,
} from "video-react";

const ManageVideos = (props) => {
  return (
    <Modal
      open={props.open}
      onClose={() => props.closeModal()}
      center
      classNames={{ modal: "admin-modal modal-own" }}
    >
      <div className="row my-auto video-wrapper px-3">
        <p className="h4 text-center col-12 video-name">
          {props?.data?.videoTitle}
        </p>
        <div className="d-flex justify-content-center col-12 ">
          <Player>
            <source src={props?.data?.videoPath} />
            <ControlBar>
              <CurrentTimeDisplay order={4.1} />
              <TimeDivider order={4.2} />
              <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
            </ControlBar>
            <BigPlayButton position="center" />
          </Player>
        </div>
      </div>
    </Modal>
  );
};

export default ManageVideos;
