/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";

const HeadingComponent = (props) => {
  const headerRef = useRef(null);
  const { text } = props;

  useEffect(() => {
    if (headerRef.current !== null && props?.getHeight) { 
      // eslint-disable-next-line
      props?.getHeight(headerRef?.current?.clientHeight);
    }
  }, [headerRef]);

  return (
    <div
      className="fixed-top stripe py-3 border-bottom heading-wrapper"
      ref={headerRef}
    >
      <p className="h4 heading text-center">{text}</p>
    </div>
  );
};

export default HeadingComponent;
