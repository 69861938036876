import React, { useState, useEffect } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import ChangePasswordModal from "./changePasswordModal";
import { withRouter } from "react-router-dom";
import { logOut } from "../../_shared/commonFunction";
import {
  globalAlert,
  globalLoader,
  setUserProfile,
} from "../../actions/commonActions";
import { superAdminViewProfile } from "../../superadmin/_actions";
import { adminViewProfile } from "../../admin/_actions";
import { connect } from "react-redux";

const Header = (props) => {
  const [openProfileDropdown, setOpenProfileDropdown] = useState(false);
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);

  useEffect(() => {
    callBasicApi();
  }, []);

  const callBasicApi = async () => {
    try {
      globalLoader(true);
      let res;
      if (props?.roleType === "superadmin") {
        res = await superAdminViewProfile();
      } else if (props?.roleType === "admin") {
        res = await adminViewProfile();
      }
      if (res?.data?.status === 200) {
        // setUserProfile(res?.data?.data?.userProfile);
      } else {
        // globalAlert("error", res?.data?.message);
      }
      globalLoader(false);
    } catch (error) {
      globalAlert("error", error?.response?.data?.message);
      if (error?.response?.data?.status === 401) {
        logOut(props?.history, `${props?.roleType}/login`);
      }
      globalLoader(false);
    }
  };

  const closeChangePasswordModal = () => {
    setOpenChangePasswordModal(false);
  };

  const handleDropdown = () => {
    setOpenProfileDropdown((currentDropdown) => !currentDropdown);
  };

  return (
    <>
      <div className="d-flex justify-content-between admin-header fixed-top">
        <p className="h3 text-start">
          {props?.roleType === "admin"
            ? props?.orgDetails?.orgName
            : "Super Admin"}
        </p>
        <OutsideClickHandler
          onOutsideClick={() => setOpenProfileDropdown(false)}
        >
          <div className="user-details position-relative ml-auto cursor">
            <span className="user-name-text" onClick={handleDropdown}>
              {localStorage.getItem("userEmail")}
              {/* {userProfile?.email} */}
            </span>
            {openProfileDropdown && (
              <div className="user-profile-dropdown">
                <div className="list-unstyled profile-dropdown-list">
                  <li onClick={() => setOpenChangePasswordModal(true)}>
                    <span className="menu-list-txt">Change Password </span>
                  </li>
                  <li
                    onClick={() => {
                      logOut(props?.history, `/${props?.roleType}/login`);
                    }}
                  >
                    <span className="menu-list-txt">Logout</span>
                  </li>
                </div>
              </div>
            )}
          </div>
        </OutsideClickHandler>
      </div>
      {openChangePasswordModal && (
        <ChangePasswordModal
          open={openChangePasswordModal}
          closeModal={closeChangePasswordModal}
          roleType={props?.roleType}
        />
      )}
    </>
  );
};

const mapStateProps = (state) => ({
  orgLogo: state.common.orgLogo,
  orgName: state.common.orgName,
  orgDetails: state.common.orgDetails,
});

export default connect(mapStateProps)(withRouter(Header));
