import React, { useState, useEffect } from "react";
import Header from "../template/_common/header";
import SidebarComponent from "../template/admin/sidebarComponent";
import QuestionTable from "../template/admin/questionTable";
import SidebarArrow from "../template/_common/sidebarArrow";
import { globalAlert, globalLoader } from "../actions/commonActions";
import AddQuestionModal from "../template/admin/addQuestionModal";
import {
  getQuizListApi,
  addQuizApi,
  editQuizApi,
  toggleQuizApi,
  deleteQuizApi,
} from "./_actions";
import ConfirmationModal from "../template/_common/confirmModal";
import PaginationOwn from "../template/_common/ownPagination";
import { compareValues, logOut } from "../_shared/commonFunction";
import { Helmet } from "react-helmet";
import InputComponent from "../template/_common/inputComponent";
import arraySort from 'array-sort';


const Quiz = (props) => {
  const [navbarToggle, setNavbarToggle] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [currentData, setCurrentData] = useState(null);
  const [openAddQuestionModal, setOpenAddQuestionModal] = useState(false);
  const [editDataIs, setEditDataIs] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [confirmaModalOpen, setConfirmaModalOpen] = useState(false);
  const [pageSize, setPageSize] = useState(25);
  const [searchVal, setSearchVal] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentDataList, setCurrentDataList] = useState([]);
  const [sortObj, setSortObj] = useState({});
  const [currentDataSorted, setCurrentDataSorted] = useState([]);
  const [notFilteredRun, setNotFilteredRun] = useState(false);
  const [allowView, setAllowView] = useState(false);
  const [totalCount, setTotalCount] = useState(null);
  const [deleteInfo, setDeleteInfo] = useState(null);

  const handleSidebar = (data) => {
    setNavbarToggle(data);
  };

  useEffect(() => {
    callBasicApi(); // eslint-disable-next-line
  }, []);

  const callBasicApi = async () => {
    try {
      globalLoader(true);
      let res = await getQuizListApi();
      if (res?.data?.status === 200) {
        setAllowView(true);
        // setDataList(res?.data?.data?.listQuestionBank);
        let currData = res?.data?.data?.listQuestionBank;
        setDataList(currData);
        setCurrentDataSorted(res?.data?.data?.listQuestionBank);
        !searchVal && setTotalCount(res?.data?.data?.listQuestionBank.length);
      } else {
        globalAlert("error", res?.data?.message);
      }
      globalLoader(false);
    } catch (error) {
      globalAlert("error", error?.response?.data?.message);
      if (error?.response?.data?.status === 401) {
        logOut(props?.history, "/admin/login");
      }
      globalLoader(false);
    }
  };

  const closeModalMain = async (val = null) => {
    if (val) {
      globalLoader(true);
      let res;
      if (editDataIs) {
        res = await editQuizApi(val, currentData?.questionBankId);
      } else {
        res = await addQuizApi(val);
      }

      if (res?.data?.status === 200) {
        globalAlert("success", res?.data?.message);
        if (editDataIs) {
          setEditDataIs(false);
        } else {
          setSearchVal("");
        }
        setNotFilteredRun(false);
        setCurrentData(null);
        setOpenAddQuestionModal(false);
        callBasicApi();
      } else {
        globalAlert("error", res?.data?.message);
        globalLoader(false);
      }
    } else {
      setEditDataIs(false);
      setCurrentData(null);
      setOpenAddQuestionModal(false);
    }
  };

  const editData = (data) => {
    setEditDataIs(true);
    setCurrentData(data);
    setOpenAddQuestionModal(true);
  };

  const toggleData = async (id) => {
    globalLoader(true);
    let res = await toggleQuizApi(id);
    if (res?.data?.status === 200) {
      setNotFilteredRun(false);
      callBasicApi();
    } else {
      globalAlert("error", res?.data?.message);
      globalLoader(false);
    }
  };

  const confirmModalFunc = async (val) => {
    if (val) {
      globalLoader(true);
      let res = await deleteQuizApi(currentUserId);
      if (res?.data?.status === 200) {
        globalAlert("success", res?.data?.message);
        if (totalCount === 1) {
          setSearchVal("");
        }
        setNotFilteredRun(false);
        setCurrentData(null);
        callBasicApi();
      } else {
        globalAlert("error", res?.data?.message);
        globalLoader(false);
      }
    }
    setConfirmaModalOpen(false);
  };

  const deleteData = async (data) => {
    setCurrentUserId(data?.questionBankId);
    let obj = [`Question Title : ${data?.questionTitle}`];
    setDeleteInfo(obj);
    setConfirmaModalOpen(true);
  };

  useEffect(() => {
    if (!notFilteredRun) {
      filteredDataFunc();
    } // eslint-disable-next-line
  }, [pageSize, currentPage, sortObj, currentDataSorted, dataList]);

  const filteredDataFunc = (dataArray = null, localPage = null) => {
    let arr = [...dataList];
    let filterArr = [];
    let filteredData = [];
    if (dataArray) {
      arr = [...dataArray]
    }
    else {
      arr = arraySort(arr, compareValues(sortObj?.sortVal), { reverse: sortObj?.sortType === 'desc' ? true : false });
    }
    if (searchVal.length > 0) {
      filterArr = arr.filter((data) => {
        if (
          data?.questionTitle.toLowerCase().includes(searchVal.toLowerCase()) ||
          correctAns(data?.questionOptions)
            .toLowerCase()
            .includes(searchVal.toLowerCase())
        ) {
          return data;
        } else {
          return null;
        }
      });
    } else {
      filterArr = arr;
    }
    if (filterArr && filterArr.length > 0) {
      setTotalCount(filterArr.length);
      for (
        let i = (localPage || currentPage) * pageSize - pageSize;
        i < (localPage || currentPage) * pageSize && i < filterArr.length;
        i++
      ) {
        filteredData.push(filterArr[i]);
      }
    }
    setCurrentDataList(filteredData);
  };

  const changePageSizeFunc = async (val) => {
    setNotFilteredRun(true);
    setPageSize(val.target.value);
    setCurrentPage(1);
    setSortObj(null);
  };

  const searchFilter = (ev) => {
    ev.preventDefault();
    let arr = [...dataList];
    let filterArr = arr.filter(
      (data) =>
        data?.questionTitle.toLowerCase().includes(searchVal.toLowerCase()) ||
        correctAns(data?.questionOptions)
          .toLowerCase()
          .includes(searchVal.toLowerCase())
    );
    setTotalCount(filterArr.length);
    setNotFilteredRun(true);
    setCurrentDataList(filterArr);
    setCurrentPage(1);
    setSortObj(null);
  };

  const correctAns = (data) => {
    if (data?.length > 0) {
      let correctAns = data.find((x) => x.isAnswer);
      if (correctAns) {
        return correctAns?.optionTitle;
      } else return "N/A";
    } else return "N/A";
  };

  const goToPageFunc = (val, data) => {
    setNotFilteredRun(false);
    if (data === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (
      data === "next" &&
      currentPage < Math.ceil(dataList?.length / pageSize)
    ) {
      setCurrentPage(currentPage + 1);
    } else {
      setCurrentPage(val?.target?.value);
    }
  };

  const arraySorting = (val) => {
    let arr = [...dataList];
    let sortedArr;
    let sortType = "aesc";
    if (val === sortObj?.sortVal && sortObj?.sortType === "aesc") {
      sortType = "desc";
    }

    sortedArr = arraySort(arr, compareValues(val), { reverse: sortType === 'desc' ? true : false })

    setNotFilteredRun(true);

    setCurrentPage(1);
    setSortObj({
      sortVal: val,
      sortType: sortType,
    });
    filteredDataFunc(sortedArr, 1)

  };

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=1200, initial-scale=1" />
      </Helmet>
      {allowView && (
        <div className="admin-portal">
          <Header roleType="admin" />
          <div className={`${navbarToggle ? "active" : ""} wrapper`}>
            <SidebarComponent
              navbarToggle={navbarToggle}
              active={props.match.path}
            />
            <div className="main">
              <div className="main-wrapper">
                <div>
                  <p className="admin-banner">Quiz - Questions</p>
                </div>
                <SidebarArrow handleSidebarToggle={handleSidebar} />

                <div>
                  <div className="container-fluid">
                    <div className="row admin-controls">
                      <form
                        onSubmit={searchFilter}
                        className="col-12 col-lg-6 admin-search"
                      >
                        <div className="search-wrapper">
                          <InputComponent
                            placeholder="Search"
                            label="Search Question"
                            type="text"
                            name="search"
                            theme="admin"
                            value={searchVal}
                            handleCallback={(val) => {
                              setSearchVal(val);
                            }}
                          />
                        </div>
                        <button type="submit" className="btn secondary-btn">
                          Go
                        </button>
                      </form>
                      <div className="col-12 col-lg-6 admin-add-new mt-4 mt-lg-0 d-flex justify-content-start justify-content-lg-end">
                        <button
                          onClick={() => setOpenAddQuestionModal(true)}
                          className="btn secondary-btn modal-btn"
                        >
                          <i className="fas fa-plus"></i> Add Question
                        </button>
                      </div>
                      {dataList?.length > 0 && (
                        <div
                          className="px-3 col-12 pt-3"
                          style={{ borderTop: "2px solid #ebeef1" }}
                        >
                          <PaginationOwn
                            pageSize={pageSize}
                            changePageSize={changePageSizeFunc}
                            goToPage={goToPageFunc}
                            totalCount={totalCount}
                            currentPage={currentPage}
                            applicationType={
                              currentDataList?.length > 1 ? "questions" : "question"
                            }
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {currentDataList?.length > 0 ? (
                  <QuestionTable
                    dataList={currentDataList}
                    arraySorting={arraySorting}
                    basicApiCall={callBasicApi}
                    sortObj={sortObj}
                    editData={editData}
                    toggleData={toggleData}
                    deleteData={deleteData}
                  />
                ) : (
                  <div className="px-3 pt-3">
                    <div className="no-data-available">No data available</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {openAddQuestionModal && (
        <AddQuestionModal
          open={openAddQuestionModal}
          closeModal={closeModalMain}
          data={currentData}
          edit={editDataIs}
        />
      )}

      {confirmaModalOpen && (
        <ConfirmationModal
          open={confirmaModalOpen}
          deleteInfo={deleteInfo}
          description="Do you want to delete this question?"
          onCloseModal={confirmModalFunc}
        />
      )}
    </>
  );
};

export default Quiz;
