import React, { useState, useEffect } from "react";
import { PAGE_ENTRY_SIZE } from "../../actionTypes/types";
import { Select, FormControl, MenuItem, InputLabel } from "@material-ui/core";

const PaginationOwn = React.memo((props) => {
  const [goToPage, setGoToPage] = useState([]);
  useEffect(() => {
    let totalPages = Math.ceil(props.totalCount / props.pageSize);
    let totalDropdownItem = [];
    for (let index = 1; index <= totalPages; index++) {
      totalDropdownItem.push(index);
    }
    setGoToPage(totalDropdownItem);
  }, [props.totalCount, props.pageSize]);
  return (
    <div className="own-paagination-wrapper d-flex flex-wrap align-items-center">
      {props?.paginationType !== "report" && (
        <div className="show-item-page form-own mr-0 mr-md-3">
          <FormControl variant="outlined">
            <InputLabel id="show_per_page">Show per page</InputLabel>
            <Select
              labelId="show_per_page"
              id="demo-simple-select-outlined"
              value={props.pageSize}
              onChange={(ev) => props.changePageSize(ev)}
              label="Show per page"
              name="pageSize"
            >
              {PAGE_ENTRY_SIZE &&
                PAGE_ENTRY_SIZE.length > 0 &&
                PAGE_ENTRY_SIZE.map((data, index) => (
                  <MenuItem value={data.value} key={index}>
                    {data.value}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
      )}
      {props.totalCount > 0 && (
        <div
          className={`d-flex flex-wrap justify-content-end align-items-center pagination-right-wrapper mt-md-0 mt-3 ${
            props?.paginationType !== "report" ? "flex-1 ml-auto" : ""
          }`}
        >
          <div className="font-16 color-grey-own pr-2">
            <span className="showing-pagination-txt">
              Showing{" "}
              <span>
                {props.currentPage * props.pageSize < props.totalCount ? (
                  <span>
                    <span className="bold-grey-txt">
                      <span>
                        {props.currentPage * props.pageSize -
                          props.pageSize +
                          1}{" "}
                        - {props.currentPage * props.pageSize}
                      </span>
                    </span>
                  </span>
                ) : (
                  <>
                    {}
                    <span>
                      <span className="bold-grey-txt">
                        <span>
                          {props.currentPage * props.pageSize -
                            props.pageSize +
                            1}{" "}
                          - {props.totalCount}
                        </span>
                      </span>{" "}
                    </span>
                  </>
                )}{" "}
                of <span className="bold-grey-txt">{props.totalCount}</span>{" "}
                {props?.applicationType
                  ? props.applicationType
                  : "applications"}
              </span>
            </span>
          </div>
          {props.pageSize < props.totalCount && (
            <>
              <div className="pagination-btn-wrapper">
                <ul className="list-inline pagination-list pr-2">
                  <li className="list-inline-item prev-next-btn">
                    <button
                      onClick={() => props.goToPage(null, "prev")}
                      className="btn"
                      disabled={props.currentPage < 2}
                    >
                      PREV
                    </button>
                  </li>
                  <li className="list-inline-item prev-next-btn">
                    <button
                      onClick={() => props.goToPage(null, "next")}
                      className="btn"
                      disabled={
                        props.currentPage * props.pageSize >= props.totalCount
                      }
                    >
                      NEXT
                    </button>
                  </li>
                </ul>
              </div>

              <div className="d-inline-flex flex-wrap align-items-center jump-page-wrapper">
                <div className="jump-txt px-2 d-none d-md-inline-flex">
                  {" "}
                  Jump to page :{" "}
                </div>
                <div className="jump-to-page form-own">
                  <FormControl variant="outlined">
                    <Select
                      labelId="show_per_page"
                      id="demo-simple-select-outlined"
                      value={props.currentPage}
                      onChange={(ev) => props.goToPage(ev, "jump")}
                      name="jumpToPage"
                    >
                      {goToPage &&
                        goToPage.length > 0 &&
                        goToPage.map((data, index) => (
                          <MenuItem value={data} key={index}>
                            {data}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </>
          )}
        </div>
      )}{" "}
    </div>
  );
});

export default PaginationOwn;
