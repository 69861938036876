import React from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import {
    Player,
    ControlBar,
    CurrentTimeDisplay,
    TimeDivider,
    PlaybackRateMenuButton,
    BigPlayButton,
} from "video-react";

const VideoPlayerModal = (props) => {

    return (
        <>
            <Modal
                open={props.open}
                onClose={() => {
                    props.closeModal();
                }}
                center
                classNames={{ modal: "modal-own", root: "modal-own-wrapper black-cross-btn-modal" }}
                closeOnEsc={false}
                closeOnOverlayClick={true}
            >
                <div className="row my-auto video-wrapper user-video">
                    <p className="text-center col-12 video-name">
                        {props?.title}
                    </p>
                    <div className="d-flex justify-content-center col-12 ">
                        {/*<div className="pdf-viewer-wrapper">
       <Document
        file={props?.src}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page pageNumber={pageNumber} />
      </Document>
      <p>Page {pageNumber} of {numPages}</p>
    </div> */}

                        <Player
                            src={props?.src}
                            autoPlay={props?.autoPlay?true:false}
                            
                        >
                            <source src={props?.src} />

                            <ControlBar>
                                <CurrentTimeDisplay order={4.1} />
                                <TimeDivider order={4.2} />
                                <PlaybackRateMenuButton
                                    rates={[1.5, 1, 0.5, 0.1]}
                                    order={7.1}
                                />
                            </ControlBar>
                            <BigPlayButton position="center" />
                        </Player>


                    </div>


                </div>
            </Modal>
        </>
    );
};

export default VideoPlayerModal;
