import React, { useState, useEffect } from "react";
import AccountComponent from "./accountComponent";
import EducationComponent from "./educationComponent";
import MessageComponent from "./messageComponent";
import VenuesComponent from "./venuesComponent";
import { useSelector } from "react-redux";

const MobileMenu = (props) => {
  const {
    noList,
    storesList,
    currentData,
    getLetLongObj,
    setMapDirection,
    mobileView,
  } = props;
  const [currMenu, setCurrMenu] = useState(0);
  const userMsgCount = useSelector((state) => state.user.msgCount);

  const handleScanner = () => {
    // eslint-disable-next-line
    props?.openScanner();
  };

  useEffect(() => {
    if (props?.activeMenu) {
      setCurrMenu(props?.activeMenu);
    }
  }, [props?.activeMenu]);

  const handleMenuChange = (id) => {
    setCurrMenu(id);
    // eslint-disable-next-line
    props?.currentMenu(id);
  };
  return (
    <>
      <div
        className={`store-list-wrapper active full w-100 ${currMenu === 0 ? "mobile-view" : ""
          }`}
      >
        {currMenu === 0 && (
          <VenuesComponent
            noList={noList}
            storesList={storesList}
            searchList={props.searchList}
            currentData={currentData}
            searchChange={props.searchChange}
            inputVal={props.inputVal}
            getLetLongObj={(lat, lng) => getLetLongObj(lat, lng)}
            setMapDirection={(ev, store) => setMapDirection(ev, store)}
            setActive={props?.setActive}
            active={props?.active}
          />
        )}
        {currMenu === 1 ? <EducationComponent /> :
          currMenu === 2 ? <MessageComponent /> :
            currMenu === 3 ? <AccountComponent {...props} /> : null}
        <div className="dashboard-mobile-menu">
          <div
            className={`menu cursor ${currMenu === 0 ? "active" : ""}`}
            onClick={() => handleMenuChange(0)}
          >
            <i className="fas fa-home"></i>
            <div>Venues</div>
          </div>
          <div
            className={`menu cursor ${currMenu === 1 ? "active" : ""}`}
            onClick={() => handleMenuChange(1)}
          >
            <i className="fas fa-photo-video"></i>
            <div>Education</div>
          </div>
          {mobileView && (
            <div
              className="scanner-icon-wrapper cursor"
              onClick={handleScanner}
            >
              <img
                src={require("../../wallpaper/scanner.png").default}
                alt="scanner"
              />
            </div>
          )}
          <div
            className={`menu cursor ${currMenu === 2 ? "active" : ""}`}
            onClick={() => handleMenuChange(2)}
          >
            <i className="far fa-comment-alt position-relative">
              {" "}
              {userMsgCount && userMsgCount > 0 ? (
                <span
                  className="badge-own"
                  style={{
                    position: "absolute",
                    top: "-8px",
                    left: "17px",
                  }}
                >
                  {userMsgCount < 11 ? userMsgCount : "10+"}
                </span>
              ) : null}
            </i>
            <div>Notification</div>
          </div>
          <div
            className={`menu cursor ${currMenu === 3 ? "active" : ""}`}
            onClick={() => handleMenuChange(3)}
          >
            <i className="far fa-user"></i>
            <div>Account</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileMenu;
