import React from "react";

const TransactionConfirm = (props) => {
  return (
    <div className="transaction-wrapper">
      <div className="msg-wrapper">
        <div className="trans-msg">
          <span>Your transaction is successful</span></div>
        <div className="trans-msg">
        <span>You may now collect the unit from the counter</span>
        </div>
      </div>
      <button className="transaction-btn" onClick={props?.callBasicApi}>Close</button>
    </div>
  );
};

export default TransactionConfirm;
//
