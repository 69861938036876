import React, { useState, useEffect } from "react";
import HeadingComponent from "../template/_common/headingComponent";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { globalAlert, globalLoader } from "../actions/commonActions";
import {
  userLevelProgressApi,
  userViewQuestionsApi,
  userSubmitQuizApi,
} from "./_userActions";
import ProgressBar from "../template/_common/progressBar";
import { logOut } from "../_shared/commonFunction";
import QuizSuccessModal from "../template/user/quizSuccessModal";
import QuizErrorModal from '../template/user/quizErrorModal'

const UserQuiz = (props) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [quiz, setQuiz] = useState([]);
  const [ans, setAns] = useState([]);
  const [currentAns, setCurrentAns] = useState(null);
  const [openQuizSuccessModal, setOpenQuizSuccessModal] = useState(false);
  const [openQuizErrorModal, setOpenQuizErrorModal] = useState(false);
  const [quizText, setQuizText] = useState('');
  const [quizSuccessText, setQuizSuccessText]=useState([]);

  useEffect(() => {
    callBasicApi(); // eslint-disable-next-line
  }, []);

  const callBasicApi = async () => {
    try {
      globalLoader(true);
      let res = await userLevelProgressApi();
      if (res?.data?.status === 200) {
        if(res?.data?.data?.haltProgress?.halt){
          props.history.push(`/user/video`);
        } else if (!res?.data?.data?.haltProgress?.halt && res?.data?.data?.quizLevelPassed) {
          props.history.push(`/user/dashboard`);
        } else if (res?.data?.data?.skipVideosPage && !res?.data?.data?.skipQuizPage) {
          let quesRes = await userViewQuestionsApi();
          if (quesRes?.data?.status === 200) {
            if (quesRes?.data?.data?.quizLevelPassed) {
              props.history.push(`/user/dashboard`);
            } else {
              setQuiz(quesRes?.data?.data?.quizQuestions);
            }
          } else {
            globalAlert("error", res?.data?.message);
            globalLoader(false);
          }
        } else {
          props.history.push(`/user/video`);
          
        }
      } else if (res?.data?.status === 401) {
        globalAlert("error", res?.data?.message);
        logOut(props.history, "/user/login");
      } else {
        globalAlert("error", res?.data?.message);
      }
      globalLoader(false);
    } catch (error) {
      globalAlert("error", error?.response?.data?.message);
      if (error?.response?.data?.status === 401) {
        logOut(props?.history, "/user/login");
      }
      globalLoader(false);
    }
  };

  useEffect(() => {
    let localAns = [...ans];
    let isAnsObj = localAns.find(
      (x) => x.questionId === quiz[currentIndex]?.questionBankId
    );

    if (isAnsObj) {
      setCurrentAns(isAnsObj?.optionId);
    }
  }, [quiz, ans, currentIndex]);

  const handleAns = (data) => {
    let localAns = [...ans];
    let quesId = quiz[currentIndex]?.questionBankId;
    let index = localAns.findIndex((x) => x.questionId === quesId);
    if (index > -1) {
      localAns.splice(index, 1);
    }
    localAns.push({
      questionId: quiz[currentIndex]?.questionBankId,
      optionId: data?._id,
    });
    setAns(localAns);
  };
  const handleNext = async () => {
    if (!currentAns) {
      globalAlert("error", "Please select an option");
    } else if (currentIndex + 1 !== quiz.length) {
      setCurrentIndex(currentIndex + 1);
    } else if (currentIndex + 1 === quiz.length) {
      try {
        globalLoader(true);
        let res = await userSubmitQuizApi({
          quizSubmission: JSON.stringify(ans),
        });

        if (res?.data?.status === 200 && res?.data?.data?.quizLevelPassed) {
          // globalAlert("success", res?.data?.message);
          // props.history.push(`/user/dashboard`);
          setQuizSuccessText(res?.data?.message.split('\n'))
          setOpenQuizSuccessModal(true);
          globalLoader(false);
        } else if (
          res?.data?.status === 200 &&
          !res?.data?.data?.quizLevelPassed
        ) {
          // globalAlert("error", res?.data?.message);
          setQuizText(res?.data?.message)
          setOpenQuizErrorModal(true)
          globalLoader(false);
          // props.history.push(`/user/video`);
        } else if (res?.data?.status === 401) {
          globalAlert("error", res?.data?.message);
          logOut(props.history, "/user/login");
        } else {
          setQuizText(res?.data?.message)
          setOpenQuizErrorModal(true)
          globalLoader(false);
        }
      } catch (error) {
        if(error?.response?.status===401){
          globalAlert("error", "Unauthorised");
          logOut(props?.history, "/user/login");
        }
        else{
          globalAlert("error", "Something went wrong");
          globalLoader(false);
        }
       
      }
    }
  };

  const handlePrev = () => {
    setCurrentIndex(currentIndex - 1);
  };

  const closeQuizSuccessModal = () => {
    setOpenQuizSuccessModal(false);
    props.history.push(`/user/dashboard`);
  };

  const closeQuizErrorModal =()=>{
    setOpenQuizSuccessModal(false);
    props.history.push(`/user/video`);
  }

  return (
    <div>
      <div>
        <HeadingComponent text="Quiz" />
      </div>
      {quiz && quiz.length && (
        <div className="quiz-wrapper mx-auto">
          <div>
            {currentIndex + 1} of {quiz?.length}
          </div>

          <div className="progree-bar-wrapper py-3">
            <ProgressBar count={quiz?.length} currentVal={currentIndex + 1} />
          </div>
          <div
            className="quiz-icon"
            onClick={() => {
              currentIndex === 0
                ? props.history.push("/user/video")
                : handlePrev();
            }}
          >
            <i className="fas fa-chevron-circle-left"></i>
          </div>
          <div className="quiz-group">
            <div className="quiz-question text-justify px-3">
              {quiz[currentIndex]?.questionTitle}
            </div>

            <ul className="quiz-answer-group row ">
              {quiz[currentIndex]?.questionOptions?.length > 0 &&
                quiz[currentIndex]?.questionOptions.map((ans) => (
                  <li
                    className="list-unstyled quiz-answer col-12"
                    key={ans?._id}
                  >
                    <div className="round">
                      <input
                        type="radio"
                        value={ans?._id}
                        checked={ans?._id === currentAns}
                        onChange={() => {
                          handleAns(ans);
                        }}
                        className="checkbox-round"
                        id={ans?._id}
                      />
                      <label
                        htmlFor={ans?._id}
                        className="checkbox-round-label"
                      ></label>
                    </div>
                    <label className="answer-text " htmlFor={ans?._id}>
                      {ans?.optionTitle}
                    </label>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      )}
      <div className="fixed-bottom stripe py-3 border-top fixed-bottom-own">
        <div className="col-md-12 text-center">
          {currentIndex === quiz?.length - 1 ? (
            // <Link>
            <button className="btnFooter footer-width" onClick={handleNext}>
              Finish
            </button>
          ) : (
            // </Link>
            <button className="btnFooter footer-width" onClick={handleNext}>
              Next Question
            </button>
          )}
        </div>
      </div>
      {openQuizSuccessModal && (
        <QuizSuccessModal
          open={openQuizSuccessModal}
          closeModal={closeQuizSuccessModal}
          message={quizSuccessText}
        />
      )}

{openQuizErrorModal && (
        <QuizErrorModal
          open={openQuizErrorModal}
          closeModal={closeQuizErrorModal}
          text={quizText}
        />
      )}


    </div>
  );
};

export default withRouter(UserQuiz);
