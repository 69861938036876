/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { logOut } from '../../_shared/commonFunction'
import InputComponent from "../_common/inputComponent";




const EditStockModal = (props) => {
    const [quantity, setQuantity] = useState("");
    const [quantityErr, setQuantityErr] = useState(false);
    const [storeDropDownList, setStoreDropDownList] = useState(null);
    const [radioVal, setRadioVal] = useState('add')







    const handleSubmit = async (e) => {
        e.preventDefault();

        if(!quantity){
            setQuantityErr(true);
            return
        }
        const obj ={
            quantity:radioVal==="remove"?'-'+quantity:quantity
        }
        props.closeModal(obj);


    };

    console.log('radioVal', radioVal)

    return (
        <Modal
            open={props.open}
            onClose={() => props.closeModal()}
            center
            classNames={{ modal: "admin-modal" }}
        >
            <div>
                <p className="admin-banner">{props?.edit ? "Edit" : "Edit"} Stock</p>
            </div>
            <form onSubmit={handleSubmit} className="form-own px-3">
                <div className="row">
                    <div className="col-12">
                        <div className="form-own-group">
                            <InputComponent
                                placeholder="Quantity"
                                label={
                                    <>
                                        Quantity <span className="color-red">*</span>
                                    </>
                                }
                                type="number"
                                name="town"
                                theme="white"
                                value={quantity}
                                handleCallback={(val) => {
                                    setQuantity(val);
                                }}
                                maxLength="10"
                            />

                            {!quantity && quantityErr && <div className="error-text">Quantity is required</div>}
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="radio-wrapper">
                            <div className="radio">
                                <input
                                    id={'add'}
                                    className="hidden radio-label"
                                    type="radio"
                                    value={'add'}
                                    name="stockAction" // Updated name to be the same for both
                                    checked={radioVal === 'add'}
                                    onChange={() => setRadioVal('add')}
                                />
                                <label className="btn-label" htmlFor={'add'}>
                                    <p>Add Stock</p>
                                </label>
                            </div>

                            <div className="radio">
                                <input
                                    id={'remove'}
                                    className="hidden radio-label"
                                    type="radio"
                                    value={'remove'}
                                    name="stockAction" // Updated name to be the same for both
                                    checked={radioVal === 'remove'}
                                    onChange={() => setRadioVal('remove')}
                                />
                                <label className="btn-label" htmlFor={'remove'}>
                                    <p>Remove Stock</p>
                                </label>
                            </div>
                        </div>

                    </div>


                </div>
                <div className="admin-btn">
                    <button
                        onClick={() => props.closeModal()}
                        className="btn secondary-btn mr-2"
                        type="button"
                    >
                        Back
                    </button>
                    <button type="submit" className="btn primary-btn ml-2">
                        Submit
                    </button>
                </div>
            </form>
        </Modal>
    );
};

export default EditStockModal;
