import React, { useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

const AccessCodeModal = (props) => {
  const [accessCode, setAccessCode] = useState("");
  const [emptyAccessCode, setEmptyAccessCode] = useState(false);

  const handleValidation = () => {
    let flag = true;
    if (!accessCode) {
      setEmptyAccessCode(true);
      flag = false;
    }
    if (accessCode.length < 5) {
      flag = false;
    }
    return flag;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (await handleValidation()) {
      let obj = {
        accessCode: accessCode,
      };
      // eslint-disable-next-line
      props.closeModal(obj);
    }
  };

  return (
    <>
      <Modal
        open={props?.open}
        onClose={() => {
          // eslint-disable-next-line
          props?.closeModal();
        }}
        center
        classNames={{ modal: "modal-own", root: "modal-own-wrapper black-cross-btn-modal" }}
      >
        <form className="form-own px-3 pt-5" onSubmit={handleSubmit}>
          <div className="row px-2">
            <div className="col-12 px-2">
              <div className="form-own-group">
                <label htmlFor="name" className="form-own-label">
                  Please Enter Access Code
                </label>
                <input
                  id="accessCode"
                  name="accessCode"
                  placeholder="Please Enter Access Code"
                  onChange={(ev) => {
                    setAccessCode(ev.target.value);
                  }}
                  value={accessCode}
                  className="form-control"
                  maxLength="20"
                />
                <div className="error-text">
                  {!accessCode && emptyAccessCode && (
                    <span>Access Code is required</span>
                  )}
                  {accessCode && accessCode.length < 5 && (
                    <span>More than 5 characters required</span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="admin-btn">
            <button
              onClick={() => props.closeModal()}
              type="button"
              className="btn secondary-btn mr-md-2 mb-md-0 mb-3"
            >
              Back
            </button>
            <button type="submit" className="btn primary-btn ml-md-2">
              Submit
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default AccessCodeModal;
