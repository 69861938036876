import React from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import * as htmlToImage from 'html-to-image';

const ViewQrModal = (props) => {
  const handleClick = () => {
    htmlToImage.toPng(document.getElementById('my-node'))
      .then(function (dataUrl) {
        var link = document.createElement('a');
        link.download = `${props?.data?.storeName || props?.data?.storeName}.jpeg`;
        link.href = dataUrl;
        link.click();
      });
    // let img = document.createElement("a");
    // img.href = props?.data?.qrCodeImage;
    // img.setAttribute("download", `${props.data?.storeName}.png`);
    // document.body.appendChild(img);
    // fileDownload(img, `${props?.data?.storeName}.png`);
    // img.click();
  };

  return (
    <Modal
      open={props.open}
      onClose={() => props.closeModal()}
      center
      classNames={{ modal: "admin-modal modal-own" }}
    >
      <div>
        <p className="admin-banner">QR code</p>
        <div className="mx-auto" style={{ width: "350px", background: "#fff" }}>
          <div className="img-wrapper text-center" id="my-node">
            <img src={props?.data?.qrCodeImage} alt="QR code" />
            <div className="border-store" style={{ borderBottom: '1px solid rgba(64, 231, 255, .2)', marginBottom: "5px" }} />
            <div className="border-store" style={{ borderBottom: '1px solid rgba(64, 231, 255, .2)', marginBottom: "5px" }} />

            <div className="Store-name">
              {props?.data?.storeName || props?.data?.name}
            </div>
          </div>
        </div>
        <div className="admin-btn">
          <button className="btn primary-btn" onClick={handleClick}>
            Download QR
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ViewQrModal;
