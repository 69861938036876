import React from "react";
import ReactTimeAgo from "react-time-ago";

const DocComp = (props) => {
  const { data } = props;

  return (
    <div className="doc-wrapper">
      <div className="font-600 font-15">{data?.documentTitle}</div>
      <div
        className="d-flex justify-content-between font-13"
        style={{ color: "#7D8491" }}
      >
        {/* <div>Total Pages {data?.documentPages}</div> */}
        <div>{data?.documentSize}</div>
        <div>
          {data?.createdText}
          {/* <ReactTimeAgo date={data?.uploadTime} /> */}
        </div>
      </div>
    </div>
  );
};

export default DocComp;
