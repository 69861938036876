import React, { useState, useEffect } from "react";
import Header from "../template/_common/header";
import SidebarComponent from "../template/admin/sidebarComponent";
import UserTable from "../template/admin/userTable";
import SidebarArrow from "../template/_common/sidebarArrow";
import { globalAlert, globalLoader } from "../actions/commonActions";
import AddUserModal from "../template/admin/addUserModal";
import NotificationModal from '../template/admin/notificationModal'
import {
  getUsersList,
  addUserApi,
  deleteUserApi,
  userEmergencyOverrideApi,
  addNotificationApi,
  adminZonerListApi
} from "./_actions";
import ConfirmationModal from "../template/_common/confirmModal";
import format from "date-fns/format";
import { Helmet } from "react-helmet";
import PaginationOwn from "../template/_common/ownPagination";
import { compareValues, logOut } from "../_shared/commonFunction";
import InputComponent from "../template/_common/inputComponent";
import arraySort from 'array-sort';
import { Select, FormControl, MenuItem, InputLabel } from "@material-ui/core";


const ManageUser = (props) => {
  const [navbarToggle, setNavbarToggle] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [currentData, setCurrentData] = useState(null);
  const [openAddUserModal, setOpenAddUserModal] = useState(false);
  const [editDataIs, setEditDataIs] = useState(false);
  const [confirmaModalOpen, setConfirmaModalOpen] = useState(false);
  const [notificationModalOpen, setNotificationModalOpen] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [pageSize, setPageSize] = useState(25);
  const [searchVal, setSearchVal] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentDataList, setCurrentDataList] = useState([]);
  const [sortObj, setSortObj] = useState({});
  const [currentDataSorted, setCurrentDataSorted] = useState([]);
  const [notFilteredRun, setNotFilteredRun] = useState(false);
  const [totalCount, setTotalCount] = useState(false);
  const [allowView, setAllowView] = useState(false);
  const [confirmInfo, setConfirmInfo] = useState(null);
  const [confirmationType, setConfirmationType] = useState("");
  const [confirmHeading, setConfirmHeading] = useState('');
  const [zoneList, setZoneList] = useState(null);
  const [zoneValue, setZoneValue] = useState('All');



  const handleSidebar = (data) => {
    setNavbarToggle(data);
  };

  useEffect(() => {
    callBasicApi(true); // eslint-disable-next-line
  }, []);

  useEffect(() => {
    callBasicApi(); // eslint-disable-next-line
  }, [zoneValue]);

  const callBasicApi = async (zoneApiCall = null) => {
    try {
      globalLoader(true);

      if (zoneApiCall) {
        const res = await adminZonerListApi();
        if (res?.data?.status === 200) {
          setZoneList(res?.data?.data?.zones);
        }
      }
      const query = `?zone=${zoneValue}`
      let res = await getUsersList(query);
      if (res?.data?.status === 200) {
        setAllowView(true);
        let currData = res?.data?.data?.usersList;
        setDataList(currData);
        // setDataList(res?.data?.data?.usersList);
        setCurrentDataSorted(res?.data?.data?.usersList);
        !searchVal && setTotalCount(res?.data?.data?.usersList.length);
      } else {
        globalAlert("error", res?.data?.message);
      }
      globalLoader(false);
    } catch (error) {
      globalAlert("error", error?.response?.data?.message);
      if (error?.response?.data?.status === 401) {
        logOut(props?.history, "/admin/login");
      }
      globalLoader(false);
    }
  };

  const handleFilterChangeZone = (ev) => {
    if (ev?.target?.value) {
      setZoneValue(ev?.target?.value)
    }
  };

  const closeAddUserModal = async (val = null) => {
    if (val) {
      globalLoader(true);
      let res = await addUserApi(val);
      if (res?.data?.status === 200) {
        globalAlert("success", res?.data?.message);
        setNotFilteredRun(false);
        setEditDataIs(false);
        setCurrentData(null);
        setOpenAddUserModal(false);
        callBasicApi();
      } else {
        globalAlert("error", res?.data?.message);
        globalLoader(false);
      }
    } else {
      setEditDataIs(false);
      setCurrentData(null);
      setOpenAddUserModal(false);
    }
  };

  const editData = (data) => {
    setEditDataIs(true);
    setCurrentData(data);
    setOpenAddUserModal(true);
  };

  const confirmModalFunc = async (val) => {
    try {
      if (val) {
        globalLoader(true);

        let res;
        if (confirmationType === 'override') {
          res = await userEmergencyOverrideApi(currentUserId);
        }
        else {
          res = await deleteUserApi(currentUserId);
        }
        if (res?.data?.status === 200) {
          setEditDataIs(false);
          setCurrentData(null);
          setOpenAddUserModal(false);
          setNotFilteredRun(false);
          if (totalCount === 1) setSearchVal("");
          callBasicApi();

        } else {
          globalAlert("error", res?.data?.message);
          globalLoader(false);
        }
        setConfirmaModalOpen(false);

      }
      else {
        setConfirmaModalOpen(false);

      }
    }

    catch (err) {
      globalAlert("error", err?.response?.data?.message);
      globalLoader(false);
    }

  };

  const deleteData = (data) => {
    setCurrentUserId(data?.userId);
    let obj = [`Mobile : ${data?.mobile}`];
    setConfirmInfo(obj);
    setConfirmHeading("Do you want to delete this user?")
    setConfirmaModalOpen(true);
    setConfirmationType('delete')
  };

  const emergencyOverride = (data) => {
    setCurrentUserId(data?.userId);
    let obj = [`Mobile : ${data?.mobile}`];
    setConfirmInfo(obj);
    setConfirmHeading("Do you want to emergency override?")
    setConfirmaModalOpen(true);
    setConfirmationType('override')
  };

  useEffect(() => {
    if (!notFilteredRun) {
      filteredDataFunc();
    } // eslint-disable-next-line
  }, [pageSize, currentPage, sortObj, currentDataSorted, dataList]);

  const filteredDataFunc = (dataArray = null, localPage = null) => {
    let arr = [...dataList];

    let filterArr = [];
    let filteredData = [];
    if (dataArray) {
      arr = [...dataArray]
    }
    else {
      arr = arraySort(arr, compareValues(sortObj?.sortVal), { reverse: sortObj?.sortType === 'desc' ? true : false });
    }
    if (searchVal.length > 0) {
      filterArr = arr.filter((data) => {
        let dateOfBirth = format(new Date(data?.dateOfBirth), "dd/MM/yyyy");
        if (
          data?.mobile.includes(searchVal) ||
          data?.postalCode.includes(searchVal) ||
          dateOfBirth.toLowerCase().includes(searchVal.toLowerCase())
        ) {
          return data;
        } else {
          return null;
        }
      });
    } else {
      filterArr = arr;
    }
    if (filterArr && filterArr.length > 0) {
      setTotalCount(filterArr.length);
      for (
        let i = (localPage || currentPage) * pageSize - pageSize;
        i < (localPage || currentPage) * pageSize && i < filterArr.length;
        i++
      ) {
        filteredData.push(filterArr[i]);
      }
    }
    setCurrentDataList(filteredData);
  };

  const changePageSizeFunc = async (val) => {
    setNotFilteredRun(false);
    setPageSize(val.target.value);
    setCurrentPage(1);
    setSortObj(null);
  };

  const searchFilter = (ev) => {
    ev.preventDefault();
    let arr = [...dataList];
    let filterArr = arr.filter((data) => {
      let dateOfBirth = format(new Date(data?.dateOfBirth), "dd/MM/yyyy");
      if (
        data?.mobile.includes(searchVal) ||
        data?.postalCode.includes(searchVal) ||
        dateOfBirth.toLowerCase().includes(searchVal.toLowerCase())
      ) {
        return data;
      } else return null;
    });
    setTotalCount(filterArr.length);
    setNotFilteredRun(true);
    setCurrentDataList(filterArr);
    setCurrentPage(1);
    setSortObj(null);
  };

  const goToPageFunc = (val, data) => {
    setNotFilteredRun(false);
    if (data === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (
      data === "next" &&
      currentPage <
      Math.ceil(dataList?.length / pageSize)
    ) {
      setCurrentPage(currentPage + 1);
    } else {
      setCurrentPage(val?.target?.value);
    }
  };

  const arraySorting = (val) => {
    let arr = [...dataList];
    let sortedArr;
    let sortType = "desc";
    if (val && val === sortObj?.sortVal && sortObj?.sortType === "desc") {
      sortType = "asec";
    }
    sortedArr = arraySort(arr, compareValues(val), { reverse: sortType === 'desc' ? true : false })
    setNotFilteredRun(true);
    setCurrentPage(1);
    setSortObj({
      sortVal: val,
      sortType: sortType,
    });
    filteredDataFunc(sortedArr, 1)
  };

  const closeNotificationModal = async (val = null) => {
    if (val) {
      globalLoader(true);
      let res = await addNotificationApi(val);
      if (res?.data?.status === 200) {
        globalAlert("success", res?.data?.message);
        setNotificationModalOpen(false)

      } else {
        globalAlert("error", res?.data?.message);
      }
      globalLoader(false);

    } else {
      setNotificationModalOpen(false)

    }
  }

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=1200, initial-scale=1" />
      </Helmet>
      {allowView && (
        <div className="admin-portal">
          <Header roleType="admin" />
          <div className={`${navbarToggle ? "active" : ""} wrapper`}>
            <SidebarComponent
              navbarToggle={navbarToggle}
              active={props.match.path}
            />
            <div className="main">
              <div className="main-wrapper">
                <div>
                  <p className="admin-banner">Users</p>
                </div>
                <SidebarArrow handleSidebarToggle={handleSidebar} />
                <div>
                  <div className="container-fluid">
                    <div className="row admin-controls">
                      <form
                        onSubmit={searchFilter}
                        className="col-12 col-lg-6 admin-search"
                      >
                        <div className="search-wrapper">
                          <InputComponent
                            placeholder="Search User"
                            label="Search User"
                            type="text"
                            name="search"
                            theme="admin"
                            value={searchVal}
                            handleCallback={(val) => {
                              setSearchVal(val);
                            }}
                          />
                        </div>
                        <button type="submit" className="btn secondary-btn">
                          Go
                        </button>

                        {zoneList?.length > 0 &&

                          <div className="show-item-page filter-type form-own ml-3 form-report-group">
                            <FormControl variant="outlined">
                              <InputLabel id="show_per_page">User Zone</InputLabel>
                              <Select
                                labelId="show_per_page"
                                id="demo-simple-select-outlined"
                                value={zoneValue || ''}
                                onChange={handleFilterChangeZone}
                                label="User Zone"
                                name="pageSize"
                              >
                                {
                                  zoneList.length > 0 &&
                                  zoneList.map((data, index) => (
                                    <MenuItem value={data.value} key={index}>
                                      {data.label}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </div>}
                      </form>
                      <div className="col-12 col-lg-6 admin-add-new mt-lg-0 d-flex justify-content-start justify-content-lg-end">
                        <div>

                          <div>
                            <button
                              onClick={() => setOpenAddUserModal(true)}
                              className="btn secondary-btn modal-btn"
                            >
                              <i className="fas fa-plus"></i> Add User
                            </button>
                          </div>
                        </div>

                      </div>
                      {dataList?.length > 0 && (
                        <div
                          className="px-3 col-12 pt-3"
                          style={{ borderTop: "2px solid #ebeef1" }}
                        >
                          <PaginationOwn
                            pageSize={pageSize}
                            changePageSize={changePageSizeFunc}
                            goToPage={goToPageFunc}
                            // totalCount={dataList?.length}
                            totalCount={totalCount}
                            currentPage={currentPage}
                            applicationType={
                              currentDataList?.length > 1 ? "users" : "user"
                            }
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {currentDataList?.length > 0 ? (
                  <UserTable
                    dataList={currentDataList}
                    arraySorting={arraySorting}
                    basicApiCall={callBasicApi}
                    sortObj={sortObj}
                    emergencyOverride={emergencyOverride}
                    editData={editData}
                    deleteData={deleteData}
                  />
                ) : (
                  <div className="px-3 pt-3">
                    <div className="no-data-available">No data available</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {notificationModalOpen && (
        <NotificationModal
          open={notificationModalOpen}
          closeModal={closeNotificationModal}
          data={currentData}
          edit={editDataIs}
        />
      )}
      {openAddUserModal && (
        <AddUserModal
          open={openAddUserModal}
          closeModal={closeAddUserModal}
          data={currentData}
          edit={editDataIs}
        />
      )}

      {confirmaModalOpen && (
        <ConfirmationModal
          open={confirmaModalOpen}
          deleteInfo={confirmInfo}
          description={confirmHeading}
          onCloseModal={confirmModalFunc}
        />
      )}
    </>
  );
};

export default ManageUser;
