import React, { useEffect, useState } from "react";
import Header from "../template/_common/header";
import SidebarComponent from "../template/admin/sidebarComponent";
import Reports from "../template/admin/reports";
import SidebarArrow from "../template/_common/sidebarArrow";
import { globalAlert, globalLoader } from "../actions/commonActions";
import { adminUserDataReportApi, adminZonerListApi } from "./_actions";
import ReportUserDataTable from "../template/admin/reportUserDataTable";
import { compareValues, logOut } from "../_shared/commonFunction";
import { Helmet } from "react-helmet";
import { addDays } from "date-fns";
import arraySort from 'array-sort';


const UsersDataReport = (props) => {
  const [navbarToggle, setNavbarToggle] = useState(false);
  const [reportData, setReportData] = useState(null);
  const [allowView, setAllowView] = useState(false);
  const [currentReportList, setCurrentReportList] = useState([]);
  const [notFilteredRun, setNotFilteredRun] = useState(false);
  const [pageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(null);
  const [sortObj, setSortObj] = useState({});
  const [reportFilteredData, setReportFilteredData] = useState([]);
  const [filterType, setFilterType] = useState('all');
  const [searchVal, setSearchVal] = useState("");
  const [zoneList, setZoneList] = useState(null);
  const [zoneApiCalled, setZoneApiCalled] = useState(false);



  const handleSidebar = (data) => {
    setNavbarToggle(data);
  };


  // useEffect(() => {
  //   if (reportData) {
  //     callBasicApi(null);

  //   }
  // }, [filterType]);

  useEffect(()=>{

    callZoneApi();

  },[]);

  const callZoneApi = async () => {
    globalLoader(true);
    try {

     
        const res = await adminZonerListApi();
        if(res?.data?.status===200){
          setZoneList(res?.data?.data?.zones);
        } else {
        globalAlert("error", res?.data?.message);
      }
      setZoneApiCalled(true)
    } catch (error) {
      globalAlert("error", error?.response?.data?.message);
      if (error?.response?.data?.status === 401) {
        logOut(props?.history, "/admin/login");
      }
      globalLoader(false);
    }
  };


  const callBasicApi = async (val = null) => {
    globalLoader(true);
    try {
      let obj = val ? val : `fromDate=${addDays(new Date(), -30)}&toDate=${new Date()}`;
      obj = !obj?.includes("&filterType=") ? `${obj}&filterType=${filterType}` : obj;

      let res = await adminUserDataReportApi(obj);
      if (res?.data?.status === 200) {
        setSearchVal('')
        setAllowView(true);
        let report = res?.data?.data?.reportData;
        setReportData(report?.length > 0 ? report : []);
        setReportFilteredData(report?.length > 0 ? report : []);
        setTotalCount(report.length);
      } else {
        globalAlert("error", res?.data?.message);
      }
      globalLoader(false);
    } catch (error) {
      globalAlert("error", error?.response?.data?.message);
      if (error?.response?.data?.status === 401) {
        logOut(props?.history, "/admin/login");
      }
      globalLoader(false);
    }
  };

  const searchFilter = async (ev, val) => {
    ev.preventDefault();
    let arr = [...reportData];
    let filterArr = arr.filter((data) => {
      if (
        data?.userRegisterDate.toLowerCase().includes(val.toLowerCase()) ||
        data?.transactionTime.toLowerCase().includes(val.toLowerCase()) ||
        data?.userGender.toLowerCase().includes(val.toLowerCase()) ||
        data?.userDob.toLowerCase().includes(val.toLowerCase()) ||
        data?.userPostalCode.toLowerCase().includes(val.toLowerCase()) ||
        data?.userMobile.toLowerCase().includes(val.toLowerCase()) ||
        data?.storeName.toLowerCase().includes(val.toLowerCase()) ||
        data?.storeStreet.toLowerCase().includes(val.toLowerCase()) ||
        data?.storeAddress.toLowerCase().includes(val.toLowerCase()) ||
        data?.storeCity.toLowerCase().includes(val.toLowerCase()) ||
        data?.storePostalCode.toLowerCase().includes(val.toLowerCase())
      ) {
        return data;
      } else {
        return null;
      }
    });
    setReportFilteredData(filterArr);
    setTotalCount(filterArr.length);
    setNotFilteredRun(false);
    setCurrentPage(1);
    filteredDataFunc(reportData, 1);

  };

  useEffect(() => {
    if (!notFilteredRun) {
      filteredDataFunc();
    }
  }, [pageSize, currentPage, reportData, sortObj, reportFilteredData, searchVal]);

  const filteredDataFunc = (dataArray = null, localPage = null) => {
    let arr = reportFilteredData;
    let filteredData = [];
    let filterArr = [];

    if (dataArray) {
      arr = [...dataArray]
    }
    else {
      arr = arraySort(arr, compareValues(sortObj?.sortVal), { reverse: sortObj?.sortType === 'desc' ? true : false });
    }

    if (searchVal.length > 0) {
      filterArr = arr.filter((data) => {
        if (
          data?.userRegisterDate.toLowerCase().includes(searchVal.toLowerCase()) ||
          data?.transactionTime.toLowerCase().includes(searchVal.toLowerCase()) ||
          data?.userGender.toLowerCase().includes(searchVal.toLowerCase()) ||
          data?.userDob.toLowerCase().includes(searchVal.toLowerCase()) ||
          data?.userPostalCode.toLowerCase().includes(searchVal.toLowerCase()) ||
          data?.userMobile.toLowerCase().includes(searchVal.toLowerCase()) ||
          data?.storeName.toLowerCase().includes(searchVal.toLowerCase()) ||
          data?.storeStreet.toLowerCase().includes(searchVal.toLowerCase()) ||
          data?.storeAddress.toLowerCase().includes(searchVal.toLowerCase()) ||
          data?.storeCity.toLowerCase().includes(searchVal.toLowerCase()) ||
          data?.storePostalCode.toLowerCase().includes(searchVal.toLowerCase())
        ) {
          return data;
        } else {
          return null;
        }
      });
    } else {
      filterArr = arr;
    }

    if (filterArr && filterArr.length > 0) {
      setTotalCount(filterArr.length);
      for (
        let i = (localPage || currentPage) * pageSize - pageSize;
        i < (localPage || currentPage) * pageSize && i < filterArr.length;
        i++
      ) {
        filteredData.push(filterArr[i]);
      }
    }
    setCurrentReportList(filteredData);
  };

  const changePageSizeFunc = async (val) => {
    setNotFilteredRun(false);
    setPageSize(val.target.value);
    setCurrentPage(1);
  };

  const goToPageFunc = (val, data) => {
    setNotFilteredRun(false);
    if (data === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (
      data === "next" &&
      currentPage <
      // Math.ceil(currentDataCopy?.length /pageSize)
      Math.ceil(reportData?.length / pageSize)
    ) {
      setCurrentPage(currentPage + 1);
    } else {
      setCurrentPage(val?.target?.value);
    }
  };

  const arraySorting = (val) => {
    let arr = [...reportData];
    let sortedArr;
    let sortType = "aesc";
    if (val === sortObj?.sortVal && sortObj?.sortType === "aesc") {
      sortType = "desc";
    }
    sortedArr = arraySort(arr, compareValues(val), { reverse: sortType === 'desc' ? true : false })
    setNotFilteredRun(true);
    setSortObj({
      sortVal: val,
      sortType: sortType,
    });
    filteredDataFunc(sortedArr, 1);

  };

  return (
    <>
      {/* {allowView && (
        <> */}
      <Helmet>
        <meta name="viewport" content="width=1200, initial-scale=1" />
      </Helmet>
      <div className="admin-portal">
        <Header roleType="admin" />
        <div className={`${navbarToggle ? "active" : ""} wrapper`}>
          <SidebarComponent
            navbarToggle={navbarToggle}
            active={props.match.path}
            menu="reports"
            subMenu='users-data-report'
          />
          <div className="main">
            <div className="main-wrapper">
              <div>
                <p className="admin-banner">Users Data Report</p>
              </div>
              <SidebarArrow handleSidebarToggle={handleSidebar} />
              {zoneApiCalled && <Reports type="data" searchTable={callBasicApi} filterType={filterType} changeFilterType={setFilterType} zoneList={zoneList}/>}
              {reportData && (
                <ReportUserDataTable
                  reportData={currentReportList}
                  pageSize={pageSize}
                  changePageSize={changePageSizeFunc}
                  goToPage={goToPageFunc}
                  totalCount={totalCount}
                  currentPage={currentPage}
                  applicationType={totalCount > 1 ? "users" : "user"}
                  arraySorting={arraySorting}
                  sortObj={sortObj}
                  searchFunc={searchFilter}
                  allReport={reportData}
                  searchVal={searchVal}
                  setSearchVal={setSearchVal}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {/* </>
      )} */}
    </>
  );
};

export default UsersDataReport;
