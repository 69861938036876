import React from "react";

const AccessCodesTable = (props) => {
  return (
    <div className="table-wrapper px-3 pt-3">
      <p className="table-type">All Accesss codes</p>
      <div className="table-wrapper-scroll-y table-scrollbar">
        <div className="table-own table-responsive">
          <table className="table table-striped table-hover table-admin">
            <thead className="table-head">
              <tr>
                <th
                  className={`sort-header cursor ${props?.sortObj?.sortVal === "docName" ? "active" : ""
                    } ${props?.sortObj?.sortType ? "aesc" : "desc"}`}
                  onClick={() => props.arraySorting("docName")}
                >
                  <span>Name</span>
                  {props?.sortObj?.sortType === "aesc" && (
                    <i className="fas fa-caret-down"></i>
                  )}
                  {props?.sortObj?.sortType === "desc" && (
                    <i className="fas fa-caret-up"></i>
                  )}
                </th>
                <th
                  className={`sort-header cursor ${props?.sortObj?.sortVal === "docEmail" ? "active" : ""
                    } ${props?.sortObj?.sortType ? "aesc" : "desc"}`}
                  onClick={() => props.arraySorting("docEmail")}
                >
                  <span>Email</span>
                  {props?.sortObj?.sortType === "aesc" && (
                    <i className="fas fa-caret-down"></i>
                  )}
                  {props?.sortObj?.sortType === "desc" && (
                    <i className="fas fa-caret-up"></i>
                  )}
                </th>
                <th>
                  <span>Access code</span>

                </th>
                <th
                  className={`sort-header cursor ${props?.sortObj?.sortVal === "docDesignation" ? "active" : ""
                    } ${props?.sortObj?.sortType ? "aesc" : "desc"}`}
                  onClick={() => props.arraySorting("docDesignation")}
                >
                  <span>Description</span>
                  {props?.sortObj?.sortType === "aesc" && (
                    <i className="fas fa-caret-down"></i>
                  )}
                  {props?.sortObj?.sortType === "desc" && (
                    <i className="fas fa-caret-up"></i>
                  )}
                </th>
                <th className={`sort-header cursor ${props?.sortObj?.sortVal === "isActivated" ? "active" : ""
                  } ${props?.sortObj?.sortType ? "aesc" : "desc"}`}
                  onClick={() => props.arraySorting("isActivated")}>
                  <span>Status</span>
                  {props?.sortObj?.sortType === "aesc" && (
                    <i className="fas fa-caret-down"></i>
                  )}
                  {props?.sortObj?.sortType === "desc" && (
                    <i className="fas fa-caret-up"></i>
                  )}
                </th>
                <th className="sort-header">
                  <span>Action</span>
                </th>
              </tr>
            </thead>
            <tbody className="table-body">
              {props.dataList.map((data) => {
                return (
                  <tr className="add-pincodes" key={data?.accessCodeId}>
                    <td className="username">{data?.docName}</td>
                    <td className="email">{data?.docEmail}</td>
                    <td className="nowrap">
                      {data?.activeAccessCode?.accessCode ? (
                        <>
                          <span className="min-mw-sm d-inline-block">
                            {data?.activeAccessCode?.accessCode}
                          </span>{" "}
                          <button
                            onClick={() => props.regenData(data)}
                            className="ml-2 btn btn-own-admin primary-btn btn-sm-own"
                          >
                            Generate new
                          </button>
                        </>
                      ) : (
                        "N/A"
                      )}
                    </td>
                    <td>{data?.docDesignation}</td>
                    <td className="table-data-status">
                      {data?.isActivated ? (
                        <span className="table-isactive">Active</span>
                      ) : (
                        <span className="table-isinactive">Inactive</span>
                      )}
                    </td>
                    <td className="nowrap">
                      <span
                        className="mr-1 btn btn-own-admin primary-btn btn-sm-own table-action"
                        onClick={() => props.editData(data)}
                      >
                        Edit
                      </span>{" "}
                      |{" "}
                      <span
                        className="ml-1 mr-1 btn btn-own-admin primary-btn btn-sm-own table-action"
                        onClick={() => props?.toggleData(data?.accessCodeId)}
                      >
                        {data?.isActivated ? "Inactivate" : "Activate"}
                      </span>{" "}
                      |{" "}
                      <span
                        className="ml-1 btn btn-own-admin primary-btn btn-sm-own table-action"
                        onClick={() => props.deleteData(data)}
                      >
                        Delete
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AccessCodesTable;
