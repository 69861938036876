import React from "react";

const VenueList = (props) => {
  return (
    <ul className="list-unstyled list-wrapper">
      {props?.storesList?.length > 0 &&
        props?.storesList.map(
          (store) =>
          (
            <li
              key={store?.storeId}
              className="cursor"
              onClick={() =>
                props?.getLetLongObj(store?.latitude, store?.longitude)
              }
            >
              <div className="map-detail-wrapper d-flex flex-wrap">
                <div className="flex-1">
                  <div className="store-name">{store?.storeName}</div>
                  <div className="store-address">
                    {" "}
                    Address :{" "}
                    <span className="font-600">
                      {store?.storeDisplayAddress && (
                        <span>{store?.storeDisplayAddress} </span>
                      )}
                    </span>
                  </div>
                  <div className="store-address">
                    {" "}
                    Notes : <span className="font-600">{store?.notes}</span>
                  </div>
                  <div
                    className="store-direction"
                    onClick={(ev) => props?.setMapDirection(ev, store)}
                  >
                    DIRECTIONS
                  </div>
                </div>

                <div className="store-distance">{store?.distance} mi</div>
              </div>
            </li>
          )
        )}
      <li style={{ height: "70px" }}></li>
    </ul>
  );
};

export default VenueList;
