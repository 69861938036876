import React from "react";
import ReactHtmlParser from 'react-html-parser';

const MsgComp = (props) => {
  const { data } = props;
  return (
    <div className="msg-content-wrapper">
      <div className={`msg-content ${!data?.isRead ? "unread" : 'read'}`}>{ReactHtmlParser(data?.body)}</div>
    </div>
  );
};

export default MsgComp;
