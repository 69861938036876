import React from "react";
import { connect } from "react-redux";

const Loader = (props) => {
  return (
    <React.Fragment>
      {props.loader ? (
        <div className="loader-wrapper">
          <div className="loader"></div>
        </div>
      ) : null}
    </React.Fragment>
  );
};

const mapState = (state) => ({
  loader: state.common.loader,
});

export default connect(mapState)(Loader);
