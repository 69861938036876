import React, { useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const ScannerDetailsModal = React.memo((props) => {

    const [selectdProduct, setSelectedProduct]=useState(null);
    const [step, setStep]=useState(1);

    const handleSubmit =()=>{
        if(step===1 && selectdProduct){
            setStep(2)
        }
        else{
            props.onCloseModal(selectdProduct)   
        }
    }

    const handleCLose =()=>{
        if(step===2){
            setStep(1)
            setSelectedProduct(null)
        }
        else{
            props.onCloseModal() 
        }
    }

  return (
    <Modal
      showCloseIcon={false}
      classNames={{ modal: "modal-own", root: "modal-own-wrapper scanner-modal-wrapper" }}
      open={props.open}
      onClose={() => props.onCloseModal()}
      center
      closeOnOverlayClick={true}
    >

        <div className="header-wrapper bg-white text-center">
            <img src="/assets/icons/eC-Card-Logo-Square.png" alt="logo" />
            <h3 className="heading mb-0">{step===1?"Select Product":"Confirm Selection"}</h3>
        </div>

        {step===1 &&<div className="venue-details mt-3  bg-white text-center">
            <h4 className="heading-own mb-0">{props?.venueDetails?.name}</h4>
            <p className="mb-0">{props?.venueDetails?.address}</p>

        </div>}

        <div className="carousel-details px-3">

            {step===2 &&

            <div className="pt-3">
                <div className="bg-white px-3">
                <div className="carousal-heading py-3">
                <h3 className="heading mb-0">{selectdProduct?.name}</h3>
                </div>
  <div className="img-wrapper p-3">

           
<img className="img-own" src={selectdProduct?.image} alt="img"/>
</div>
<p className="py-3">{selectdProduct?.description}</p>

                </div>
                </div>
            
            
            
            }

{step===1 &&<Carousel autoPlay={false} showThumbs={false} showStatus={false}>

    {props?.productList?.length && props?.productList.map((product, index)=>(
         <div key={index} className="pb-3">
            <div className="bg-white px-3">
            <div className="carousal-heading">
         <div className="ml-auto pl-2 mark-input-wrapper">
         <input
           type="radio"
           value="ansA"
           className="mr-1"
           id="ansAinput"
           checked={selectdProduct?.orgProductId === product?.orgProductId}
           onChange={(evt) => { setSelectedProduct(product); props?.setProductId(product?.orgProductId) }}
         />
         <label htmlFor="ansAinput">{product?.name}</label>


       </div>
            </div>

            <div className="img-wrapper p-3">

           
             <img className="img-own" src={product?.image} alt="img"/>
             </div>
             <p className="py-3">{product?.description}</p>
         </div>
         </div>

    ))}
               
               
            </Carousel>}

            </div>

            <div className="btn-wrapper form-own">
                <div className="btn-contatiner d-flex flex-wrap justify-content-space-around">

                    <button onClick={() => handleCLose()} className="btn secondary-btn ">{step===1?"Cancel":"Back"}</button>
                    <button onClick={handleSubmit} disabled={!selectdProduct} className="btn primary-btn">{step===1?"Next":"Submit"}</button>



                </div>
            </div>
      {/* <div className="video-player-wrapper px-2 text-center">
        {props.description && (
          <h3 className="font-20 primary-color py-3">{props.description}</h3>
        )}
        <div className="primary-color">
          {props?.deleteInfo && (
            <ul className="list-unstyled">
              {props?.deleteInfo?.map((data) => {
                return <li>{data}</li>;
              })}
            </ul>
          )}
        </div>
        <div className="border-bottom-own pt-2 mb-3"></div>
        <div className="pb-3 btn-wrapper pt-3">
          <button
            type="button"
            onClick={() => props.onCloseModal(null)}
            className="btn secondary-btn mb-3 mb-md-0 full-width-xs-mb btn-own btn-own-grey min-height-btn min-width-btn-md mr-md-3"
          >
            NO
          </button>
          <button
            type="submit"
            onClick={() => props.onCloseModal(true)}
            className="btn primary-btn full-width-xs btn-own btn-own-primary min-height-btn min-width-btn-md"
          >
            YES
          </button>
        </div>
      </div> */}
    </Modal>
  );
});

export default ScannerDetailsModal;
