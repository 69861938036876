import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { globalLoader, globalAlert } from "../../actions/commonActions";
import { checkResetApi, resetPasswordApi } from "../../admin/_actions";
import {
  checkResetSuperAdminApi,
  resetPasswordSuperAdminApi,
} from "../../superadmin/_actions";
import { logOut } from "../../_shared/commonFunction";

const ResetTokenComponent = (props) => {
  const [linkSent, setLinkSent] = useState(false);
  const [sentMsg, setSentMsg] = useState(null);
  const [resetToken, setResetToken] = useState(null);
  const [password, setPassword] = useState("");
  const [emptyPassword, setEmptyPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [emptyConfirmPassword, setEmptyConfirmPassword] = useState(false);

  useEffect(() => {
    callBasicApi(); // eslint-disable-next-line
  }, []);

  const callBasicApi = async () => {
    const roleType = props.roleType;
    try {
      let resetToken = window.location.pathname.split("/");
      resetToken = resetToken[resetToken.length - 1];

      if (resetToken) {
        setResetToken(resetToken);
        globalLoader(true);
        let obj = {
          resetToken: resetToken,
        };
        let res;

        if (roleType === "admin") {
          res = await checkResetApi(obj);
        } else {
          res = await checkResetSuperAdminApi(obj);
        }

        if (res?.data?.status !== 200) {
          globalAlert("error", res?.data?.message);
          props.history.push(`/${roleType}/login`);
        } else {
          globalLoader(false);
        }
      }
    } catch (error) {
      globalAlert("error", error?.response?.data?.message);
      if (error?.response?.data?.status === 401) {
        logOut(props?.history, `${props?.roleType}/login`);
      }
      globalLoader(false);
    }
  };

  const validateForm = () => {
    let value = true;
    if (!password) {
      setEmptyPassword(true);
      value = false;
    }
    if (!confirmPassword) {
      setEmptyConfirmPassword(true);
      value = false;
    }

    if ((password || confirmPassword) && password !== confirmPassword) {
      value = false;
    }

    return value;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let value = await validateForm();
    if (value) {
      const roleType = props.roleType;
      globalLoader(true);
      let res;
      let obj = {
        resetToken: resetToken,
        password: password,
      };
      if (roleType === "admin") {
        res = await resetPasswordApi(obj);
      } else {
        res = await resetPasswordSuperAdminApi(obj);
      }

      if (res?.data?.status === 200) {
        setLinkSent(true);
        setSentMsg(res?.data?.message);
      } else {
        globalAlert("error", res?.data?.message);
      }

      globalLoader(false);
    }
  };

  return (
    <>
      <div className="auth-screen-wrapper">
        <div className="fix-width-wrapper px-3 mx-auto">
          <div className="auth-body-wrapper">
            <div className="logo-wrapper text-center">
              <img
                src="/assets/icons/eC-Card-Logo.png"
                alt="EC Card Logo"
                className="logo"
              />
            </div>

            {linkSent ? (
              <div className="forgot-msg-wrapper">
                <div className="forgot-msg-txt py-4">{sentMsg}</div>
                <Link to={`/${props.roleType}/login`}>
                  <Button
                    type="button"
                    className="btn btn-black btn-block btn-own"
                  >
                    Back to Login
                  </Button>
                </Link>
              </div>
            ) : (
              <form onSubmit={(e) => handleSubmit(e)} className="form-own">
                <>
                  <div className="pt-1">
                    <p className="login-text text-center">Enter password</p>

                    <div className="form-group reg-date">
                      <input
                        type="password"
                        value={password}
                        onChange={(ev) => setPassword(ev.target.value)}
                        maxLength="30"
                        className="form-control"
                        placeholder="Password"
                      />
                      <div className="error-text font-12 pl-2">
                        {!password && emptyPassword && (
                          <span>Password is required</span>
                        )}
                      </div>
                    </div>

                    <div className="form-group reg-date">
                      <input
                        type="password"
                        value={confirmPassword}
                        onChange={(ev) => setConfirmPassword(ev.target.value)}
                        maxLength="30"
                        className="form-control"
                        placeholder="Confirm Password"
                      />
                      <div className="error-text font-12 pl-2">
                        {!confirmPassword && emptyConfirmPassword && (
                          <span>Confirm Password is required</span>
                        )}
                        {(confirmPassword || password) &&
                          confirmPassword !== password && (
                            <span>Password not matched</span>
                          )}
                      </div>
                    </div>

                    <div className="form-group pt-3">
                      <Button
                        type="submit"
                        className="btn btn-black btn-block btn-own"
                      >
                        Change Password
                      </Button>
                    </div>

                    <div className="pb-3  pt-2 text-center">
                      <Link to={`/${props.roleType}/login`}>
                        <span className="text-white">Back to Login</span>
                      </Link>
                    </div>
                  </div>
                </>
              </form>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(ResetTokenComponent);
