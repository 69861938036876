import {
  GLOBAL_LOADER,
  GLOBAL_ALERT,
  GLOBAL_ALERT_REMOVE,
  GLOBAL_API_DATA,
  ORG_DETAILS,
  ADD_ORG_NAME,
  IP_KEY,
} from "../actionTypes/types";
import { appStore } from "../store/index";
import { GLOBAL_API } from "../actionTypes/types";
import { getRequest } from "../actionTypes/axiosUtility";

export const globalLoader = (value) => {
  appStore.dispatch({
    type: GLOBAL_LOADER,
    payload: value,
  });
};

export const globalAlert = (alertType, msg) => {
  appStore.dispatch({
    type: GLOBAL_ALERT,
    payload: alertType,
    msg: msg,
  });
};

export const globalAlertRemove = () => {
  appStore.dispatch({
    type: GLOBAL_ALERT_REMOVE,
  });
};

export const commonAppApi = async () => {
  let res = await getRequest(`${GLOBAL_API}/public/v1/global-constants`);
  if (res?.data?.status === 200) {
    appStore.dispatch({
      type: GLOBAL_API_DATA,
      payload: res?.data?.data?.constants,
    });
  }
};

export const geoLocationApi = async () => {
  return getRequest(`https://api.ipdata.co/?api-key=${IP_KEY}`);
 
};

export const addOrgDetailsFunc = (value) => {
  appStore.dispatch({
    type: ORG_DETAILS,
    payload: value,
  });
};

export const addOrgName = (value) => {
  appStore.dispatch({
    type: ADD_ORG_NAME,
    payload: value,
  });
};
