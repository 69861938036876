import React from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import ReactHtmlParser from 'react-html-parser';


const ViewNotificationModal = (props) => {
  return (
    <Modal
      open={props.open}
      onClose={() => props.closeModal()}
      center
      classNames={{ modal: "admin-modal modal-md" }}
    >
      <div>
        <p className="admin-banner">View Notification</p>
      </div>
      <div className="px-2">
        <div className="primary-color weight-600">Notification Title</div>
        <div className="overflow-wrap">
          <p>{props?.data?.title}</p>
        </div>
        <div className="primary-color weight-600">Notification Body</div>
        <div className="overflow-wrap">
          <div>{ReactHtmlParser(props?.data?.body)}</div>
        </div>
        <div className="admin-btn">
          <button
            onClick={() => props.closeModal()}
            className="btn secondary-btn "
            type="button"
          >
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ViewNotificationModal;
