import React, {useState} from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import InputComponent from "../_common/inputComponent";
import { TextField } from "@mui/material";

const SupportCloseModal = (props) => {

  const [notes, setNotes] = useState("");
  const [notesEmpty, setNotesEmpty] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if(!notes){
        setNotesEmpty(true);
        return; 
    }

    let obj = {
      comments: notes,
    };
    props.onCloseModal(obj);
  };


    return (
        <Modal
            showCloseIcon={false}
            classNames={{
                modal: "modal-own",
                root: "modal-own-wrapper confirm-modal",
            }}
            open={props.open}
            onClose={() => props.onCloseModal()}
            center
            closeOnOverlayClick={true}
        >
            <div className="video-player-wrapper px-2">
               

            
                <div className="pb-3 btn-wrapper text-center pt-3">
                <form onSubmit={handleSubmit} className="form-own px-3">
        <div className="row">
          <div className="col-12">
            <div className="form-own-group">
            <TextField
                label={
                  <>
                    Comment <span className="color-red">*</span>
                  </>
                }
                variant="outlined"
                fullWidth
                name="question"
                id="question"
                placeholder="Comment"
                onChange={(evt) => setNotes(evt.target.value)}
                value={notes}
                multiline
                rows={4}
              />
            </div>

            <div className="error-text text-left">
                                {!notes && notesEmpty && <span>Comment is required</span>}
                            </div>
          </div>
          <div className="col-12">
            <div className="admin-btn">
              <button
                onClick={() => props.onCloseModal()}
                className="btn secondary-btn mr-2"
                type="button"
              >
                Cancel
              </button>
              <button type="submit" className="btn primary-btn ml-2">
               Close Request
              </button>
            </div>
          </div>
        </div>
      </form>

                </div>
            </div>
        </Modal>
    );
};

export default SupportCloseModal;
