

const PrivacyPolicy = ()=>{

    return(
        <div>
  <div className="fixed-top stripe py-3 border-bottom heading-wrapper">
    <p className="h4 fw-bold text-center terms-head mb-0">App Privacy Policy</p>
  </div>
  <div className="mx-3 text-justify terms px-3 terms-text">
    <p className="fw-normal">
      Provide Digital Limited (<strong>"Provide Digital", "We", "Us"</strong>)
      as the developer and owner of eC-Card digital platform, including mobile
      application (downloaded from Google App Store and the Apple App Store) and
      web portal (the main domain of https://ec-cardapp.com and its sub-domains)
      (collectively called <strong>"eC-Card Application"</strong>), understands
      that your privacy is important to you and that you care about how your
      information is used and shared. We respect and value the privacy of
      everyone who uses eC-Card, i.e. <strong>Client Admin Users</strong>{" "}
      (healthcare professional or employee of a <strong>Client Organisation</strong> where eC-Card Application has been
      deployed) and <strong>Service Users</strong> (young people who authorised
      by the Client Organisation to use eC-Card), and will only collect and use
      information in ways that are useful to you and in a manner consistent with
      your rights and our obligations under Data Protection law.
    </p>
    <p className="fw-normal">
      Provide Digital plays a dual role when it comes to processing. In some
      situations, we act as a Controller, in others, we are the Processor.
    </p>
    <p className="fw-normal">
      Provide Digital, does on occasion, act as a Controller. That will usually
      be the contact data of the company and/or its representatives where
      eC-Card Application has been deployed and then technical data when you use
      our app.
    </p>
    <p className="fw-normal">
      Being a Controller means that we are trusted to look after and deal with
      your personal information in accordance with this notice. We determine the
      ways and means of processing your data and must therefore be accountable
      for it.
    </p>
    <p className="fw-normal">
      This Privacy Notice refers to the data we process as a Controller only and
      describes what personal information we collect and hold about you, how we
      use it, who can access it and your rights with regards to how we process
      your information.{" "}
    </p>
    <p className="fw-normal">
      The General Data Protection Regulation (GDPR) also gives you the right to
      lodge a complaint with a supervisory authority, in particular in a
      European Economic Area state or in the United Kingdom if you work,
      normally live or if any alleged infringement of data protection laws
      occurred in the relevant state. The supervisory authority in the UK is the
      Information Commissioner who may be contacted at
      https://ico.org.uk/concerns/ or telephone: 0303 123 1113.
    </p>
    <p className="fw-bold">Who we are</p>
    <p className="fw-normal">
      We are Provide Digital Limited, a company incorporated in England and
      Wales with registered number 12377709 at Companies House and whose
      registered office is at 900 The Crescent, Colchester Business Park,
      Colchester, CO4 9YQ, United Kingdom.
    </p>
    <p className="fw-normal">
      If you have any questions about this Privacy Notice, please contact us by
      post (Postal Address: 900 The Crescent, Colchester Business Park,
      Colchester, CO4 9YQ, United Kingdom); or email (provide.infogov@nhs.net).
      Please ensure your query is clear, particularly if it is a request for
      information about the personal data we hold about you. We hope that we can
      resolve any query or concern you raise about our use of your information.
    </p>
    <p className="fw-normal">
      Provide Digital is registered with the Information Commissioner's Office
      (ICO) to process personal and special categories of information
      (Registration number: ZB407604).
    </p>
    <p className="fw-bold">Why we keep personal information about you</p>
    <p className="fw-normal">
      We may use the personal information we have collected about you for the
      following purpose/s:
    </p>
    <ul>
      <li>
        to understand what you want to build, what features your product should
        have, and what purpose it will serve.
      </li>
      <li>to enter into a contract with us and use the app</li>
      <li>
        to optimise the performance of our app, monitor it for security purposes
        and drive improvements for our users
      </li>
      <li>
        to provide operation and/or technical support for the tickets raised
      </li>
      <li>to send you service updates.</li>
    </ul>
    <p />
    <p className="fw-normal">
      Generally, we do not rely on consent as a legal basis for processing your
      personal data although we will get your consent before sending third party
      direct marketing communications to you via email or text message. You have
      the right to withdraw consent to marketing at any time by contacting us.
    </p>
    <p className="fw-normal">
      We will only use your personal data for the purposes for which we
      collected it, unless we reasonably consider that we need to use it for
      another reason and that reason is compatible with the original purpose. If
      you wish to get an explanation as to how the processing for the new
      purpose is compatible with the original purpose, please contact us. If we
      need to use your personal data for an unrelated purpose, we will notify
      you and we will explain the legal basis which allows us to do so.
    </p>
    <p className="fw-bold">What Information do we keep about you?</p>
    <p className="fw-normal">
      We may collect and process the following types of personal information:
    </p>
    <ul>
      <li>Name of the company or representative/s</li>
      <li>Email Address of the company or representative/s</li>
      <li>Phone Number of the company or representative/s</li>
      <li>Address of the company</li>
      <li>Logo and other branding material of the company</li>
      <li>Area Sector codes for deployment purpose</li>
    </ul>
    <p />
    <p className="fw-normal">
      Apart from the above data, we also process data about the way you use our
      app, including your IP address and browsing time. At Provide Digital, we
      believe in being transparent about how we process data about you and how
      we use any third party to process the data.{" "}
    </p>
    <p className="fw-bold">How we collect Information about you</p>
    <p className="fw-normal">
      We may collect personal information about you in a number of ways:
    </p>
    <ul>
      <li>
        When you make use of our contact form or demo request form to request
        information from us
      </li>
      <li>When you provide the data to enter into a contract with us</li>
      <li>
        If you sign up on one of our eC-Card Application that we provide and
        support
      </li>
      <li>When you contact us by phone</li>
      <li>When you use the eC-Card Application</li>
    </ul>
    <p />
    <p className="fw-bold">Our Lawful basis for processing your data</p>
    <p className="fw-normal">
      We may rely on one or more of the following lawful bases for processing
      your personal information:
    </p>
    <ul>
      <li>
        Your consent. You are able to remove your consent at any time. You can
        do this by contacting us through email at provide.digital@nhs.net
      </li>
      <li>
        Legitimate interests: We will use your information for our own
        legitimate interests, for example, to provide you with the best suitable
        content on our Website or app, to improve and promote our services and
        for our own administrative purposes including creating and maintaining
        business records of our relationship with you.
      </li>
      <li>
        We have a contractual obligation and the processing is necessary for the
        performance of Services we provide pursuant to a contract we have
        entered into with you or your representative.
      </li>
      <li>We have a legal obligation</li>
    </ul>
    The specific lawful bases will depend on the engagement or services you have
    with us.
    <p />
    <p className="fw-bold">How and where we hold your personal information</p>
    <p className="fw-normal">
      We create and hold your records electronically and sometimes in hard copy
      where necessary.
      <br /> Any records we hold about you are held securely and are only
      accessible to those who have a legitimate need to access the information.
    </p>
    <p className="fw-normal">
      Your information will be kept securely and will remain in the UK. We
      generally store the information provided via eC-Card Application in Amazon
      Web Services (AWS) Cloud datacentres located in the UK where data is
      encrypted to a high standard (AES256). We also use following third party
      platforms to share and store information (if required). Please refer to
      section - 'Do we share your Information?' for further details.
    </p>
    <p className="fw-normal">
      We do not typically transfer your personal data outside the European
      Economic Area (EEA). However, should we need to transfer your data outside
      the EEA, we will ensure that the appropriate legal safeguard is in place
      to allow the transfer.
    </p>
    <p className="fw-normal">
      All of our staff and contractors receive appropriate and on-going training
      to ensure they are aware of their personal responsibilities and have
      contractual obligations to uphold confidentiality, enforceable through
      disciplinary procedures if necessary. Staff only have access to personal
      information where it is appropriate to their role and is strictly on a
      need-to-know basis.
    </p>
    <p className="fw-bold">How we use your Information</p>
    <p className="fw-normal">
      We ensure that we process your data lawfully, fairly and transparently in
      accordance with Data Protection legislation.
    </p>
    <p className="fw-normal">
      Personal information collected about you may be used for the following
      purpose/s:
    </p>
    <ul>
      <li>Performance of a contract with you</li>
      <li>Responding to communications from you</li>
      <li>Personalising and tailoring your experience on our product/s</li>
      <li>
        Supplying you with any information or newsletter you have subscribed to
      </li>
      <li>Providing support for our product/s</li>
    </ul>
    If we need to use your personal information for any reasons beyond those
    stated above, we will discuss this with you and ask for your permission to
    do so.
    <p />
    <p className="fw-bold">Do we share your Information?</p>
    <p className="fw-normal">
      We may share your personal information with other companies within our
      group. This includes our holding company, Provide CIC and its
      subsidiaries.
      <br />
      <br />
      We may sometimes contract with third parties to supply products and
      services to you on our behalf. These may include payment processing,
      delivery of goods, search engine facilities, cloud storage, advertising
      and marketing. In some cases, the third parties may require access to some
      or all of your data. Where any of your data is required for such a
      purpose, we will take all reasonable steps to ensure that your data will
      be handled safely, securely, and in accordance with your rights, our
      obligations, and the obligations of the third party under the law.
      <br />
      <br />
      We use third party services for the purposes set out in the table below. A
      list of our external service providers can be found below:
    </p>
    <div className="table-responsive">
    <table>
      <tbody>
        <tr>
          <th>Service provider</th>
          <th>Service</th>
        </tr>
        <tr>
          <td>Google Drive</td>
          <td>Document repository</td>
        </tr>
        <tr>
          <td>Microsoft Email</td>
          <td>Client email service</td>
        </tr>
        <tr>
          <td>Microsoft OneDrive</td>
          <td>Document repository</td>
        </tr>
        <tr>
          <td>SendGrid</td>
          <td>Email and Customer service</td>
        </tr>
        <tr>
          <td>IcyCastle</td>
          <td>Software development</td>
        </tr>
        <tr>
          <td>Amazon Web Services (AWS)</td>
          <td>Cloud Infrastructure service</td>
        </tr>
      </tbody>
    </table>
    </div>
    <br />
    In certain circumstances we may be legally required to share certain data
    held by us, which may include your personal information, for example, where
    we are involved in legal proceedings, where we are complying with the
    requirements of legislation, a court order, or a governmental authority. We
    do not require any further consent from you in order to share your data in
    such circumstances and will comply as required with any legally binding
    request that is made of us.
    <p />
    <p className="fw-bold">How long do we keep your Information?</p>
    <p className="fw-normal">
      We will only retain your personal data for as long as reasonably necessary
      to fulfil the purposes we collected it for, including for the purposes of
      satisfying any legal, regulatory, tax, accounting or reporting
      requirements. Usually that means we will keep your personal data for as
      long as you have a registered account with Provide Digital. We may need to
      retain your personal data for a longer period in the event of a complaint,
      if we reasonably believe there is a prospect of litigation in respect to
      our relationship with you or where we have to keep data for regulatory
      purposes.
    </p>
    <p className="fw-normal">
      To determine the appropriate retention period for personal data, we
      consider the amount, nature and sensitivity of the personal data, the
      potential risk of harm from unauthorised use or disclosure of your
      personal data, the purposes for which we process your personal data and
      whether we can achieve those purposes through other means, and the
      applicable legal, regulatory, tax, accounting or other requirements.
    </p>
    <p className="fw-normal">
      You can unsubscribe from Provide Digital emails at any time by clicking on
      the 'unsubscribe' button at the bottom of our emails.
    </p>
    <p className="fw-normal">
      You can request to delete all your personal data (where applicable) by
      contacting us via email at provide.sar@nhs.net, and the appropriate
      personal data will be erased, deleted, destroyed, or otherwise disposed
      of.
    </p>
    <p className="fw-bold">Your Rights</p>
    <p className="fw-normal">
      You have the following rights, under the Data Protection legislation, in
      respect of your personal information that we hold about you:
    </p>
    <ul>
      <li>Right of access - To ask for copies of your personal information</li>
      <li>
        Right to rectification - To ask for your personal information to be
        corrected if it is inaccurate or incomplete.
      </li>
      <li>
        Right to erasure - To ask for your personal information to be deleted or
        erased, where applicable.
      </li>
      <li>
        Right to restriction of processing - To ask us to restrict the use of
        your personal information in some circumstances.
      </li>
      <li>
        Right to object to processing - To object to the processing of your
        personal information in certain circumstances. We may use automated
        decision making in processing your personal information for some
        services. You may object to your information being subjected to
        automated processing and you can request a manual review of the outcome
        of an automated decision if you are unhappy with it.
      </li>
      <li>
        Right to data portability - To request your personal information to be
        transferred to other providers on certain occasions.
      </li>
    </ul>
    <p />
    <p className="fw-normal">
      We do not typically charge a fee for exercising your rights except in
      certain circumstances where permitted by Data Protection law. We have one
      month to respond to your request and may extend by another two months in
      certain circumstances.
    </p>
    <p className="fw-normal">
      If you wish to exercise any of your rights please contact us on
      provide.sar@nhs.net or through our data subject request online form/portal
      by clicking SAR Portal or contacting Subject Access Request, Human
      Resources Team, Provide, 900 The Crescent, Colchester Business Park
      Colchester, Essex CO4 9YQ. Email: provide.sar@nhs.net
    </p>
    <p className="fw-normal">
      Should you have a query or wish to lodge a complaint about the use of your
      personal information, please contact our Information Governance Team in
      the first instance at provide.infogov@nhs.net or write to: <br />
      Information Governance, Provide HQ, 900 The Crescent, Colchester Business
      Park, Colchester, Essex, CO4 9YQ
      <br />
      If you are still unhappy with the outcome of your enquiry concerning how
      we process your personal information you can write to:
      <br />
      The Information Commissioner, Wycliffe House, Water Lane, Wilmslow,
      Cheshire SK9 5AF.
      <br />
      <br />
      Telephone: 0303 123 1113
      <br />
      <br />
      ICO Website: https://www.ico.org.uk
    </p>
    <p className="fw-bold text-end">January 2023</p>
  </div>

  <style jsx>{`
      th {
    display: table-cell;
    vertical-align: inherit;
    font-weight: bold;
    text-align: -internal-center;
    unicode-bidi: isolate;
    text-align:center;
        border: 2px solid;
}
    .th, td {
        border-style: solid;
        min-width: 300px;
        text-align: center;
    }
    
`}</style>
</div>

    )

}

export default PrivacyPolicy