import React from "react";

const AdminTable = (props) => {
  return (
    <div className="table-wrapper px-3 pt-4">
      <p className="table-type">All Admin</p>
      <div className="table-wrapper-scroll-y table-scrollbar">
        <div className="table-responsive table-own">
          <table className="table table-striped table-hover table-admin">
            <thead className="table-head">
              <tr>
                <th
                  className={`sort-header cursor ${
                    props?.sortObj?.sortVal === "firstName" ? "active" : ""
                  } ${props?.sortObj?.sortType ? "aesc" : "desc"}`}
                  onClick={() => props.arraySorting("firstName")}
                >
                  <span>First Name</span>
                  {/* <img
                    src="/assets/img/arrow-side.svg"
                    className="sort-icon"
                    alt="icon"
                  /> */}
                  {props?.sortObj?.sortType === "aesc" && (
                    <i className="fas fa-caret-down"></i>
                  )}
                  {props?.sortObj?.sortType === "desc" && (
                    <i className="fas fa-caret-up"></i>
                  )}
                </th>
                <th
                  className={`sort-header cursor ${
                    props?.sortObj?.sortVal === "lastName" ? "active" : ""
                  } ${props?.sortObj?.sortType ? "aesc" : "desc"}`}
                  onClick={() => props.arraySorting("lastName")}
                >
                  <span>Last Name </span>
                  {/* <img
                    src="/assets/img/arrow-side.svg"
                    className="sort-icon"
                    alt="icon"
                  /> */}
                  {props?.sortObj?.sortType === "aesc" && (
                    <i className="fas fa-caret-down"></i>
                  )}
                  {props?.sortObj?.sortType === "desc" && (
                    <i className="fas fa-caret-up"></i>
                  )}
                </th>
                <th
                  className={`sort-header cursor ${
                    props?.sortObj?.sortVal === "email" ? "active" : ""
                  } ${props?.sortObj?.sortType ? "aesc" : "desc"}`}
                  onClick={() => props.arraySorting("email")}
                >
                  <span>Email</span>

                  {props?.sortObj?.sortType === "aesc" && (
                    <i className="fas fa-caret-down"></i>
                  )}
                  {props?.sortObj?.sortType === "desc" && (
                    <i className="fas fa-caret-up"></i>
                  )}
                </th>
                <th
                  className={`sort-header cursor ${
                    props?.sortObj?.sortVal === "orgName" ? "active" : ""
                  } ${props?.sortObj?.sortType ? "aesc" : "desc"}`}
                  onClick={() => props.arraySorting("orgName")}
                >
                  <span>Organisation</span>

                  {props?.sortObj?.sortType === "aesc" && (
                    <i className="fas fa-caret-down"></i>
                  )}
                  {props?.sortObj?.sortType === "desc" && (
                    <i className="fas fa-caret-up"></i>
                  )}
                </th>
                <th className="sort-header cursor">Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody className="table-body">
              {props.dataList.map((data, index) => {
                return (
                  <tr key={index}>
                    <td>{data?.firstName}</td>
                    <td>{data?.lastName}</td>
                    <td>{data?.email}</td>
                    <td>{data?.orgName}</td>
                    <td className="table-data-status">
                      {data?.isActivated && data.isPublished ? (
                        <span className="table-isactive">Active</span>
                      ) : (
                        <span className="table-isinactive">Inactive</span>
                      )}
                    </td>
                    <td className="nowrap">
                      <button
                        disabled={!data?.isActivated}
                        onClick={() => props?.toggleUser(data)}
                        className="mr-1 btn btn-own-admin primary-btn btn-sm-own table-action"
                      >
                        {!(data?.isActivated && data.isPublished)
                          ? "Active"
                          : "Inactive"}
                      </button>{" "}
                      |{" "}
                      <span
                        onClick={() =>
                          data?.isActivated
                            ? props?.resetUser(data)
                            : props?.resendUser(data)
                        }
                        className="ml-1 btn btn-own-admin primary-btn min-btn-xl btn-sm-own table-action"
                      >
                        {data?.isActivated
                          ? "Reset password"
                          : "Resend activation mail"}
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AdminTable;
