import React from "react";

const EducationTable = (props) => {


    const viewData =(data)=>{
        if(props?.type==='video'){
            props.viewVideo(data)
            return

        }

        window.open(data?.documentFile);
        
    }
    return (
        <div className="table-wrapper px-3 pt-3">
            <p className="table-type">All {props?.type}</p>
            <div className="table-wrapper-scroll-y table-scrollbar">
                <div className="table-own table-responsive">
                    <table className="table table-striped table-hover table-admin">
                        <thead className="table-head">
                            <tr>
                                <th
                                    className={`sort-header cursor ${props?.sortObj?.sortVal === "storeName" ? "active" : ""
                                        } ${props?.sortObj?.sortType ? "aesc" : "desc"}`}
                                    // onClick={() => props.arraySorting("storeName")}
                                >
                                    <span>Title</span>{" "}
                                    {props?.sortObj?.sortType === "aesc" && (
                                        <i className="fas fa-caret-down"></i>
                                    )}
                                    {props?.sortObj?.sortType === "desc" && (
                                        <i className="fas fa-caret-up"></i>
                                    )}
                                </th>
                                {/* {props?.type==='video' &&
                                <th
                                    className={`sort-header cursor ${props?.sortObj?.sortVal === "address" ? "active" : ""
                                        } ${props?.sortObj?.sortType ? "aesc" : "desc"}`}
                                    onClick={() => props.arraySorting("address")}
                                >
                                    <span>Author</span>{" "}
                                    {props?.sortObj?.sortType === "aesc" && (
                                        <i className="fas fa-caret-down"></i>
                                    )}
                                    {props?.sortObj?.sortType === "desc" && (
                                        <i className="fas fa-caret-up"></i>
                                    )}
                                </th>} */}
                                <th className="text-center">
                                    <span>View</span>
                                </th>
                                <th>
                                    <span>Status</span>
                                </th>
                                <th>
                                    <span>Action</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody className="table-body">
                            {props.dataList.map((data) => {

                                return (
                                    <tr key={data?.educationId}>
                                        <td>{props?.type==='video'?data?.videoTitle:data?.documentTitle}</td>
                                        {/* {props?.type==='video' &&<td>{data?.videoAuthor}</td>} */}

                                        <td className="text-center">
                                            <div
                                                className="cursor"
                                                onClick={() => viewData(data)}
                                            >
                                                <i className="far fa-eye primary-color"></i>
                                            </div>
                                        </td>
                                        <td className="table-data-status">
                                            {data?.isActive ? (
                                                <span className="table-isactive">Active</span>
                                            ) : (
                                                <span className="table-isinactive">Inactive</span>
                                            )}
                                        </td>
                                        <td className="nowrap">
                                            <span
                                                className="ml-1 mr-1 btn btn-own-admin primary-btn btn-sm-own table-action"
                                                onClick={() => props?.toggleData(data?.educationId)}
                                            >
                                                {data?.isActive ? "Inactivate" : "Activate"}
                                            </span>{" "}
                                            |{" "}
                                            <span
                                                className="ml-1 btn btn-own-admin primary-btn btn-sm-own table-action"
                                                onClick={() => props.deleteData(data)}
                                            >
                                                Delete
                                            </span>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default EducationTable;
