import React, {useState, useEffect} from "react";
import {
  Player,
  ControlBar,
  CurrentTimeDisplay,
  TimeDivider,
  PlaybackRateMenuButton,
  BigPlayButton,
} from "video-react";
import ReactTimeAgo from "react-time-ago";
import {userViewSingleEducationApi} from '../../user/_userActions';

const MobileVideo = (props) => {

  const [playId, setPlayId]=useState(null)
  const { data } = props;
  useEffect(()=>{
    if(playId && props.callApi){
      callViewApi()

    }
  },[playId]);

  const callViewApi =()=>{
    userViewSingleEducationApi('video', data?.educationId);
  }

  return (
    <>
      {data ? (
        <>
          <div className="pb-3">
            <div className="player-wrapper">
              <Player className="player-own" onPlay={()=>{setPlayId(data?.educationId)}} >
                
                <source src={data?.videoUrl} />
                <ControlBar>
                  <CurrentTimeDisplay order={4.1} />
                  <TimeDivider order={4.2} />
                  <PlaybackRateMenuButton
                    rates={[1.5, 1, 0.5, 0.1]}
                    order={7.1}
                  />
                </ControlBar>
                <BigPlayButton position="center" />
              </Player>
            </div>
            <div className="font-15 color-black font-600 pt-2">
              {data?.videoTitle}
            </div>
            <div className="d-flex font-12">
              <div className="mr-2 text-muted">{data?.views} views</div>
              <div className="ml-3 text-muted">{data?.createdText}</div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default MobileVideo;
