import React from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

const LogoutModal = (props) => {
  return (
    <Modal
      open={props?.open}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      showCloseIcon={false}
      center
      classNames={{ modal: "modal-own", root: "modal-own-wrapper" }}
    >
      <div className="video-player-wrapper px-2 text-center">
        <p className=" primary-color py-3">Are you sure you want to logout?</p>
        <div>
          <button
            className="btnCancel footer-width mx-2 my-2"
            onClick={() => {
              // eslint-disable-next-line
              props?.closeModal(false);
            }}
          >
            No
          </button>
          <button
            className="btnFooter footer-width mx-2 my-2"
            onClick={() => {
              // eslint-disable-next-line
              props?.closeModal(true);
            }}
          >
            Yes
          </button>
        </div>
      </div>
    </Modal>
  );
};
export default LogoutModal;
