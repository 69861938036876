import React, { useState, useEffect } from "react";
import { globalAlert, globalLoader } from "../../actions/commonActions";
import { getCsvFile } from "../../superadmin/_actions";
import FileDownload from "js-file-download";
import { logOut } from "../../_shared/commonFunction";
import ConfirmationModal from "../_common/confirmModal";

const EditAreaCode = (props) => {
  const [logoFile, setLogoFile] = useState("");
  const [postCodeErr, setPostcodeErr] = useState(false);
  const [postCode, setPostcode] = useState(null);
  const [selectedArea, setSelectedArea] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [deletedAny, setDeletedAny] = useState(false);
  const [confirmaModalOpen, setConfirmaModalOpen] = useState(false);

  useEffect(() => {
    setPropsToState(); // eslint-disable-next-line
  }, [props?.data]);

  const setPropsToState = () => {
    if (props?.data?.areaCodeWithZones?.length > 0) {
      let data = [];

      props?.data?.areaCodeWithZones?.forEach((x) => {
        data?.push({
          data: `${x?.areaCode}`,
          display: `${x?.areaCode} (${x?.zone})`,
          checked: false,
          deleted: false,
        });
      });
      setSelectedArea(data);
    }
  };

  const downloadFileFormat = async (e) => {
    e.preventDefault();
    try {
      globalLoader(true);
      let res = await getCsvFile();

      if (res) {
        FileDownload(res, "postalcode.csv");
      } else {
        globalAlert("error", res?.data?.message);
      }
      globalLoader(false);
    } catch (error) {
      globalAlert("error", "Unauthorised");
      logOut(props?.history, "/superadmin/login");
    }
  };

  const setPostalFunc = (ev) => {
    if (ev?.target?.files[0]?.type === "application/vnd.ms-excel" || ev?.target?.files[0]?.type === "text/csv") {
      const file = ev.target.files[0];
      setPostcode(file);
    } else {
      globalAlert("error", "Only CSV files allowed");
    }
  };

  const changeCurrentSelect = (ev, val) => {
    let localArea = [...selectedArea];
    let index = localArea.findIndex((x) => x.data === val);
    if (index >= 0) {
      if (ev.target.checked) {
        localArea[index].checked = true;
      } else {
        localArea[index].checked = false;
      }
    }
    setSelectedArea(localArea);
  };

  const toggleSelect = () => {
    let localArea = [...selectedArea];
    if (!selectAll) {
      localArea.forEach((x) => {
        x.checked = true;
      });
    } else {
      localArea.forEach((x) => {
        x.checked = false;
      });
    }
    setSelectAll(!selectAll);
    setSelectedArea(localArea);
  };
  const confirmModalFunc = async (val) => {
    if (val) {
      let localArea = [...selectedArea];
      localArea.forEach((x) => {
        x.checked ? (x.deleted = true) : (x.deleted = false);
      });
      setDeletedAny(true);
      setSelectedArea(localArea);
    }
    setConfirmaModalOpen(false);
  };

  const deleteAreaCode = () => {
    setConfirmaModalOpen(true);
  };

  const saveData = () => {
    let areaCodesRemovedLocal = [];
    if (deletedAny || postCode) {
      selectedArea.forEach((x) => {
        if (x.deleted) {
          areaCodesRemovedLocal.push(x?.data);
        }
      });

      let editFormData = new FormData();
      if (deletedAny && areaCodesRemovedLocal?.length > 0) {
        editFormData.append(
          "areaCodesRemoved",
          areaCodesRemovedLocal?.toString()
        );
      }
      if (postCode) {
        editFormData.append("areaCodesFile", postCode);
      }

      props.valueSubmit(editFormData);
    }
  };

  return (
    <>
      <div className="col-md-6 px-0 mx-auto pb-2">
        <div className="form-own-group">
          <div className="d-flex flex-wrap pb-3 align-items-center">
            <label className="form-own-label mr-2">
              Upload Sector code file
            </label>

            <button
              className="btn secondary-btn ml-auto"
              onClick={downloadFileFormat}
            >
              <i className="fas fa-arrow-down"></i> Download format
            </button>
          </div>
          <div className="upload-btn upload-btn-dashed" id="previewLogo">
            <div className="show-icon-wrapper pb-2 mb-1 font-14">
              <i className="fas fa-file-csv font-16" aria-hidden="true"></i>
              <span className="pl-1">{postCode?.name}</span>
            </div>

            <div className="upload-btn-txt btn">+ ADD FILE</div>
            <input
              type="file"
              accept=".csv"
              className="upload-input cursor"
              onChange={setPostalFunc}
            />
          </div>
          <div className="error-text">
            {!postCode && postCodeErr && <span>Areacode file is required</span>}
          </div>
        </div>

        <div className="d-flex flex-wrap pb-3 align-items-center mt-3 mb-0 pt-3">
          <label className="form-own-label mb-0">
            Current Sector code
            <button
              className="btn secondary-btn ml-2"
              onClick={toggleSelect}
              disabled={
                selectedArea.every((x) => x.deleted) || selectedArea.length < 1
              }
            >
              Select All
            </button>
          </label>

          <button
            className="btn btn-danger ml-auto"
            onClick={deleteAreaCode}
            disabled={
              selectedArea.every((x) => x.deleted) ||
              !selectedArea.some((x) => x.checked)
            }
          >
            <i className="fas fa-trash-alt"></i> Delete
          </button>
        </div>

        <ul className="list-unstyled org-area-list row px-2">
          {selectedArea?.length > 0 &&
            selectedArea.map(
              (data, i) =>
                !data?.deleted && (
                  <li className="position-relative px-2 col-4" key={i}>
                    <input
                      className="styled-checkbox"
                      id={i + data.data}
                      checked={data?.checked}
                      type="checkbox"
                      onChange={(ev) => changeCurrentSelect(ev, data?.data)}
                      defaultValue="value1"
                    />
                    <label htmlFor={i + data.data}>{data.display}</label>
                  </li>
                )
            )}
        </ul>
        <div className="admin-btn">
          <button
            type="button"
            onClick={saveData}
            className="btn primary-btn ml-2"
          >
            Save
          </button>
        </div>
      </div>

      {confirmaModalOpen && (
        <ConfirmationModal
          open={confirmaModalOpen}
          description="Do you want to delete this sector code?"
          onCloseModal={confirmModalFunc}
        />
      )}
    </>
  );
};

export default EditAreaCode;
