import React from "react";
import format from "date-fns/format";

const AdminTable = (props) => {
  return (
    <div className="table-wrapper px-3 pt-4">
      <p className="table-type">All Organisation</p>
      <div className="table-wrapper-scroll-y table-scrollbar">
        <div className="table-responsive table-own">
          <table className="table table-striped table-hover table-admin">
            <thead className="table-head">
              <tr>
                <th
                  className={`sort-header cursor ${
                    props?.sortObj?.sortVal === "orgName" ? "active" : ""
                  } ${props?.sortObj?.sortType ? "aesc" : "desc"}`}
                  onClick={() => props.arraySorting("orgName")}
                >
                  <span>Name</span>
                  {props?.sortObj?.sortType === "aesc" && (
                    <i className="fas fa-caret-down"></i>
                  )}
                  {props?.sortObj?.sortType === "desc" && (
                    <i className="fas fa-caret-up"></i>
                  )}
                </th>

                <th>
                  <span>Sector Code </span>
                </th>
                <th
                  className={`sort-header cursor ${
                    props?.sortObj?.sortVal === "createdAt" ? "active" : ""
                  } ${props?.sortObj?.sortType ? "aesc" : "desc"}`}
                  onClick={() => props.arraySorting("createdAt")}
                >
                  <span>Created At </span>
                  {props?.sortObj?.sortType === "aesc" && (
                    <i className="fas fa-caret-down"></i>
                  )}
                  {props?.sortObj?.sortType === "desc" && (
                    <i className="fas fa-caret-up"></i>
                  )}
                </th>
                <th>
                  <span>Logo </span>
                </th>
                <th className="sort-header cursor">Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody className="table-body">
              {props.dataList.map((data, index) => {
                return (
                  <tr key={index}>
                    <td>{data?.orgName}</td>
                    <td className="pl-4">
                      {data?.areaCode?.length > 0 ? (
                        <div
                          className="cursor"
                          onClick={() => props?.viewAreaCodeData(data)}
                        >
                          <i className="far fa-eye primary-color"></i>
                        </div>
                      ) : (
                        <div>N/A</div>
                      )}
                    </td>
                    <td>{format(new Date(data?.created), "dd/MM/yyyy")}</td>
                    <td>
                      {data?.brandLogo ? (
                        <img src={data?.brandLogo} alt="logo" width={30} />
                      ) : (
                        <span>N/A</span>
                      )}
                    </td>
                    <td className="table-data-status">
                      {data?.isActivated ? (
                        <span className="table-isactive">Active</span>
                      ) : (
                        <span className="table-isinactive">Inactive</span>
                      )}
                    </td>
                    <td className="nowrap">
                      <span
                        onClick={() => props?.editData(data)}
                        className="mr-1 btn btn-own-admin primary-btn btn-sm-own table-action"
                      >
                        Edit
                      </span>{" "}
                      |{" "}
                      <span
                        onClick={() => props?.toggleOrg(data)}
                        className="ml-1 btn btn-own-admin primary-btn btn-sm-own table-action"
                      >
                        {!data.isActivated ? "Active" : "Inactive"}
                      </span>{" "}
                      |{" "}
                      <span
                        onClick={() => props?.deleteOrg(data)}
                        className="ml-1 btn btn-own-admin primary-btn btn-sm-own table-action"
                      >
                        Delete
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AdminTable;
