import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "video-react/dist/video-react.css";
import App from "./App";
import TimeAgo from "javascript-time-ago";
import { BrowserRouter } from "react-router-dom";
import { appStore, persistor } from "./store/index";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import "bootstrap/dist/css/bootstrap.min.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

import en from "javascript-time-ago/locale/en.json";

TimeAgo.addDefaultLocale(en);

ReactDOM.render(
    <Provider store={appStore}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </PersistGate>
    </Provider>,
  document.querySelector("#root")
);

// serviceWorkerRegistration.register();
serviceWorkerRegistration.unregister();

reportWebVitals();
