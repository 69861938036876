import {
  GLOBAL_LOADER,
  GLOBAL_ALERT,
  GLOBAL_ALERT_REMOVE,
  GLOBAL_API_DATA,
  ORG_DETAILS,
  ADD_ORG_NAME,
} from "../actionTypes/types";

const INITIAL_STATE = {
  loader: false,
  alertArray: [],
  alertArrayLength: 0,
  globalApiData: null,
  orgDetails:null,
  orgLogo: null,
  orgName: null,
};

const commonReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GLOBAL_LOADER: {
      return {
        ...state,
        loader: action.payload,
      };
    }

    case GLOBAL_ALERT: {
      let obj = { alertType: action.payload, alertMessage: action.msg };
      return { ...state, alertArray: [obj] };
    }

    case GLOBAL_ALERT_REMOVE: {
      let arr = state.alertArray;
      if (arr.length) {
        state.alertArray.shift();
      }
      return { ...state, alertArray: [...arr] };
    }
    case GLOBAL_API_DATA: {
      return { ...state, globalApiData: { ...action.payload } };
    }
    case ORG_DETAILS: {
      return { ...state, orgDetails: action.payload };
    }

    case ADD_ORG_NAME: {
      return { ...state, orgName: action.payload };
    }
    default:
      return state;
  }
};

export default commonReducer;
