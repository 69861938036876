/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { globalLoader } from "../../actions/commonActions";
import InputComponent from "../_common/inputComponent";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import ReactHtmlParser from 'react-html-parser';

const MAX_LENGTH = 300;


const NotificationModal = (props) => {
  const [name, setName] = useState("");
  const [nameErr, setNameErr] = useState(false);
  const [title, setTitle] = useState("");
  const [titleErr, setTitleErr] = useState(false);
  const [bodyErr, setBodyErr] = useState(false);
  const [editorState, setEditorState] = useState(null);
  const [preview, setPreview] = useState(false);

  useEffect(() => {
    setPropsData();


  }, [props?.data]);

  const setPropsData = () => {
    setName(props?.data?.name);
    setTitle(props?.data?.title);
    if (props?.data?.body) {
      const contentBlock = htmlToDraft(props?.data?.body);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorStateLocal = EditorState.createWithContent(contentState);
        setEditorState(editorStateLocal)
      }
    }
  };

  const handleValidation = () => {
    let flag = true;
    if (!props?.edit && !name) {
      setNameErr(true);
      flag = false;
    }
    if (!title) {
      setTitleErr(true);
      flag = false;
    }
    if (!editorState) {
      setBodyErr(true);
      flag = false;
    }
    return flag;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();



    if (await handleValidation()) {
      if (!preview) {
        setPreview(true)
      }
      else {
        globalLoader(true);

        let obj = {
          title: title,
          body: draftToHtml(convertToRaw(editorState.getCurrentContent())),
        };

        if (!props?.edit) obj = { ...obj, name: name };
        props.closeModal(obj);
      }

    }


  };


  const getLengthOfSelectedText = () => {
    const currentSelection = editorState.getSelection();
    const isCollapsed = currentSelection.isCollapsed();

    let length = 0;

    if (!isCollapsed) {
      const currentContent = editorState.getCurrentContent();
      const startKey = currentSelection.getStartKey();
      const endKey = currentSelection.getEndKey();
      const startBlock = currentContent.getBlockForKey(startKey);
      const isStartAndEndBlockAreTheSame = startKey === endKey;
      const startBlockTextLength = startBlock.getLength();
      const startSelectedTextLength = startBlockTextLength - currentSelection.getStartOffset();
      const endSelectedTextLength = currentSelection.getEndOffset();
      const keyAfterEnd = currentContent.getKeyAfter(endKey);
      if (isStartAndEndBlockAreTheSame) {
        length += currentSelection.getEndOffset() - currentSelection.getStartOffset();
      } else {
        let currentKey = startKey;

        while (currentKey && currentKey !== keyAfterEnd) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1;
          } else if (currentKey === endKey) {
            length += endSelectedTextLength;
          } else {
            length += currentContent.getBlockForKey(currentKey).getLength() + 1;
          }

          currentKey = currentContent.getKeyAfter(currentKey);
        };
      }
    }

    return length;
  }

  const handleBeforeInput = () => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;
    const selectedTextLength = getLengthOfSelectedText();

    if (currentContentLength - selectedTextLength > MAX_LENGTH - 1) {
      return 'handled';
    }
  }

  const handlePastedText = (pastedText) => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;
    const selectedTextLength = getLengthOfSelectedText();

    if (currentContentLength + pastedText.length - selectedTextLength > MAX_LENGTH) {
      return 'handled';
    }
  }

  return (
    <Modal
      open={props.open}
      onClose={() => props.closeModal()}
      center
      classNames={{ modal: "admin-modal modal-md" }}
    >
      <div>
        <p className="admin-banner">{props?.heading}</p>
      </div>
      <form onSubmit={handleSubmit} className="form-own px-3">
        <div className="row">
          {!props?.edit && (
            <div className="col-12">
              <div className="form-own-group">
                {preview ? <>

                  <label className="custom-label primary-color">
                    Notification Name <span className="color-red">*</span>
                  </label>
                  <div>{name}</div>

                </> :
                  <InputComponent
                    placeholder="Notification Name"
                    label={
                      <>
                        Notification Name <span className="color-red">*</span>
                      </>
                    }
                    type="text"
                    theme="white"
                    value={name}
                    handleCallback={(ev) => {
                      setName(ev);
                    }}
                  />}
                <div className="error-text">
                  {!name && nameErr && <span>Name is required</span>}
                </div>
              </div>
            </div>
          )}
          <div className="col-12">
            <div className="form-own-group">
              {preview ? <>

                <label className="custom-label primary-color">
                  Notification Title <span className="color-red">*</span>
                </label>
                <div>{title}</div>

              </> :
                <InputComponent
                  placeholder="Notification Title"
                  label={
                    <>
                      Notification Title <span className="color-red">*</span>
                    </>
                  }
                  type="text"
                  theme="white"
                  value={title}
                  handleCallback={(ev) => {
                    setTitle(ev);
                  }}
                  disabled={props?.view ? true : false}
                  maxLength="30"
                />}
              <div className="error-text">
                {!title && titleErr && <span>Title is required</span>}
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="form-own-group own-textarea-wrapper position-relative">

              <label className={`custom-label ${preview ? 'primary-color' : ''}`}>
                Notification Body  <small className="font-12">(Max 300 character)</small> <span className="color-red">*</span>
              </label>

              {preview ? <div className="overflow-wrap">
                <div>{editorState && ReactHtmlParser(draftToHtml(convertToRaw(editorState.getCurrentContent())))}</div>
              </div> :

                <Editor
                  editorState={editorState}
                  toolbarClassName="own-editor-toolbar"
                  wrapperClassName="own-editor-wrapper"
                  editorClassName="own-editor-box"
                  onEditorStateChange={setEditorState}
                  handlePastedText={handlePastedText}
                  handleBeforeInput={handleBeforeInput}

                  toolbar={
                    {
                      options: ['inline', 'fontSize', 'list', 'textAlign'],
                      inline: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['bold', 'italic', 'underline', 'strikethrough']
                      },
                    }
                  }
                />}

              {/* <InputComponent
                placeholder="Post Code"
                label={
                  <>
                    Post Code <span className="color-red">*</span>
                  </>
                }
                type="text"
                name="postCode"
                inputFor="text"
                theme="white"
                value={postalcode}
                handleCallback={(val) => handlePostalCode(val)}
                maxLength="10"
              /> */}
              <div className="error-text">
                {!editorState && bodyErr && <span>Field is required</span>}
              </div>
            </div>
          </div>
        </div>
        <div className="admin-btn">
          <button
            onClick={() => preview ? setPreview(false) : props.closeModal()}
            className="btn secondary-btn mr-2"
            type="button"
          >
            Back
          </button>
          <button type="submit" className="btn primary-btn ml-2">
            {preview ? props?.type === 'template' && !props?.edit ? 'Add Template' : "Create Notification" : "Next"}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default NotificationModal;
