import React, { useEffect, useState } from "react";
import { DocFiles } from "../../actionTypes/docFiles";
import DocComp from "./docComp";

const DocumentsComponent = (props) => {
  const [dataList, setDataList] = useState(null);
  useEffect(() => {
    callBasicApi();
  }, []);

  const callBasicApi = async () => {
    let res = DocFiles;
    setDataList(res);
  };

  const startDownload=(url)=> {

    window.location.href = url;
}

  return (
    <>
      <ul className="list-unstyled mb-3">
        {props?.data?.length>0 &&
          props?.data?.map((data, index) => (
            <li key={index} className="my-3 cursor" onClick={()=>startDownload(data?.documentFile)}>
              <DocComp data={data} />
            </li>
          ))}
        <li style={{ height: "100px" }}></li>
      </ul>
    </>
  );
};

export default DocumentsComponent;
