import axios from "axios";
import { GLOBAL_API } from "./types";

import https from "https";

export const getToken = () => localStorage.getItem("edAuthToken");
export const getSecureToken = () => localStorage.getItem("edSecureToken");

let BASE_URL = GLOBAL_API;

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  httpsAgent: new https.Agent({
    rejectUnauthorized: false,
  }),
});

//on successful response
axiosInstance.interceptors.response.use(
  (config) => {
    if (config?.headers?.ecauthtoken) {
      localStorage.setItem("ecauthtoken", config?.headers?.ecauthtoken);
    }
    if (config?.headers?.ecsecuretoken) {
      localStorage.setItem("ecsecuretoken", config?.headers?.ecsecuretoken);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const setAuthorizationToken = () => {
  var token = localStorage.getItem("ecauthtoken");
  if (token) {
    axiosInstance.defaults.headers.common["ecauthtoken"] = token;
  }

  let ecsecuretoken = localStorage.getItem("ecsecuretoken");
  if (ecsecuretoken) {
    axiosInstance.defaults.headers.common["ecsecuretoken"] = ecsecuretoken;
  }
};

// Send a POST request
export const postRequest = (url, data) => {
  setAuthorizationToken();
  return axiosInstance({
    method: "post",
    url,
    data,
  });
};

// Send a GET request
export const getRequest = (url) => {
  setAuthorizationToken();
  return axiosInstance({
    method: "get",
    url,
  });
};

// Send a DELETE request
export const deleteRequest = (url, obj = null) => {
  setAuthorizationToken();
  if (obj) {
    return axios.delete(url, {
      headers: {
        ecauthtoken: localStorage.getItem("ecauthtoken"),
        ecsecuretoken: localStorage.getItem("ecsecuretoken"),
      },
      data: obj,
    });
  } else {
    return axiosInstance({
      method: "delete",
      url,
      config: {
        data: obj,
      },
    });
  }
};

// Send a PUT request
export const putRequest = (url, data) => {
  setAuthorizationToken();
  return axiosInstance({
    method: "put",
    url,
    data,
  });
};
