import {
    USER_MSG_COUNT
  } from "../actionTypes/types";

const INITIAL_STATE = {
    msgCount: 0,
};

const userReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case USER_MSG_COUNT: {
            return {
                ...state,
                msgCount: action.payload,
            };
        }
        default:
            return state;
    }
};

export default userReducer;