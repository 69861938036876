import React, { useState, useEffect } from "react";
import Header from "../template/_common/header";
import SidebarComponent from "../template/admin/sidebarComponent";
import SupportTable from "../template/admin/supportTable";
import SidebarArrow from "../template/_common/sidebarArrow";
import { globalAlert, globalLoader } from "../actions/commonActions";
import {
    getSupportListApi,
    supportCloseApi
} from "./_actions";
import SupportViewModal from "../template/admin/supportViewModal";
import PaginationOwn from "../template/_common/ownPagination";
import { compareValues, logOut } from "../_shared/commonFunction";
import { Helmet } from "react-helmet";
import arraySort from 'array-sort';
import InputComponent from "../template/_common/inputComponent";
import SupportCloseModal from "../template/admin/closeSupportModal";



const SupportModule = (props) => {
    const [navbarToggle, setNavbarToggle] = useState(false);
    const [dataList, setDataList] = useState([]);
    const [currentData, setCurrentData] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [pageSize, setPageSize] = useState(25);
    const [searchVal, setSearchVal] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [currentDataList, setCurrentDataList] = useState([]);
    const [sortObj, setSortObj] = useState({});
    const [currentDataSorted, setCurrentDataSorted] = useState([]);
    const [notFilteredRun, setNotFilteredRun] = useState(false);
    const [totalCount, setTotalCount] = useState(null);
    const [allowView, setAllowView] = useState(false);
    const [type, setType] = useState("open");
    const [openModalClose, setOpenModalClose] = useState(false);



    const handleSidebar = (data) => {
        setNavbarToggle(data);
    };

    useEffect(() => {
        callBasicApi(); // eslint-disable-next-line
    }, []);

    useEffect(() => {
        callBasicApi(); // eslint-disable-next-line
    }, [type]);

    const callBasicApi = async () => {
        try {
            globalLoader(true);
            let res = await getSupportListApi(type === "close" ? "?showClosed=true" : null);
            if (res?.data?.status === 200) {

                setAllowView(true);
                let currData = res?.data?.data?.list;
                setDataList(currData);
                setCurrentDataSorted(res?.data?.data?.list);
                !searchVal && setTotalCount(res?.data?.data?.list?.length);
                setAllowView(true);

            } else {
                globalAlert("error", res?.data?.message);
            }
            globalLoader(false);
        } catch (error) {
            globalAlert("error", error?.response?.data?.message);
            if (error?.response?.data?.status === 401) {
                logOut(props?.history, "/admin/login");
            }
            globalLoader(false);
        }
    };







    const closeModalFunc = async (val) => {
        setCurrentData(null)
        setOpenModal(false);
    };

    const openModalCloseFunc = async (data) => {
        setCurrentData(data)
        setOpenModalClose(true);

    };

    const closeModalCloseFunc = async (val) => {
        if (val) {

            try {
                globalLoader(true);
                let res = await supportCloseApi(currentData?.supportId, val);
                if (res?.data?.status === 200) {

                    setOpenModalClose(false);
                    setCurrentData(null);
                    callBasicApi()
                    globalAlert("success", res?.data?.message);
                } else {
                    globalAlert("error", res?.data?.message);
                    globalLoader(false);

                }
            } catch (error) {
                globalAlert("error", error?.response?.data?.message);
                if (error?.response?.data?.status === 401) {
                    logOut(props?.history, "/admin/login");
                }
                globalLoader(false);
            }

        }
        else {
            setOpenModalClose(false);
            setCurrentData(null)

        }

    };

    const openModalFunc = (data) => {
        setCurrentData(data)
        setOpenModal(true);
    };

    useEffect(() => {
        if (!notFilteredRun && dataList?.length > 0) {
            filteredDataFunc();
        } // eslint-disable-next-line
        else {
            setCurrentDataList([]);

        }
    }, [pageSize, currentPage, sortObj, currentDataSorted, dataList]);

    const filteredDataFunc = (dataArray = null, localPage = null) => {
        let arr = [...dataList];
        let filterArr = [];
        let filteredData = [];
        if (dataArray) {
            arr = [...dataArray]
        }
        else {
            arr = arraySort(arr, compareValues(sortObj?.sortVal), { reverse: sortObj?.sortType === 'desc' ? true : false });
        }
        if (searchVal.length > 0) {
            filterArr = arr.filter((data) => {
                let name = `${data?.firstName} ${data.lastName}`;
                if (
                    name.toLowerCase().includes(searchVal.toLowerCase()) ||
                    data?.email.toLowerCase().includes(searchVal.toLowerCase())
                ) {
                    return data;
                } else {
                    return null;
                }
            });
        } else {
            filterArr = arr;
        }
        if (filterArr && filterArr.length > 0) {
            setTotalCount(filterArr.length);
            for (
                let i = (localPage || currentPage) * pageSize - pageSize;
                i < (localPage || currentPage) * pageSize && i < filterArr.length;
                i++
            ) {
                filteredData.push(filterArr[i]);
            }
        }
        setCurrentDataList(filteredData);
    };

    const changePageSizeFunc = async (val) => {
        setNotFilteredRun(false);
        setPageSize(val.target.value);
        setCurrentPage(1);
        setSortObj(null);
    };

    const searchFilter = (ev) => {
        ev.preventDefault();
        let arr = [...dataList];
        let filterArr = arr.filter(
            (data) =>
                data?.title.toLowerCase().includes(searchVal.toLowerCase()) ||
                data?.email.toLowerCase().includes(searchVal.toLowerCase()) ||
                data?.mobile.toLowerCase().includes(searchVal.toLowerCase())
        );
        setTotalCount(filterArr.length);
        setNotFilteredRun(true);
        setCurrentDataList(filterArr);
        setCurrentPage(1);
        setSortObj(null);
    };

    const goToPageFunc = (val, data) => {
        setNotFilteredRun(false);
        if (data === "prev" && currentPage > 1) {
            setCurrentPage(currentPage - 1);
        } else if (
            data === "next" &&
            currentPage < Math.ceil(dataList.length / pageSize)
        ) {
            setCurrentPage(currentPage + 1);
        } else {
            setCurrentPage(val.target.value);
        }
    };

    const arraySorting = (val) => {
        let arr = [...dataList];
        let sortedArr;
        let sortType = "aesc";
        if (val === sortObj?.sortVal && sortObj?.sortType === "aesc") {
            sortType = "desc";
        }
        sortedArr = arraySort(arr, compareValues(val), { reverse: sortType === 'desc' ? true : false })

        setNotFilteredRun(true);

        setCurrentPage(1);
        setSortObj({
            sortVal: val,
            sortType: sortType,
        });
        filteredDataFunc(sortedArr, 1)

    };

    console.log('log', currentData)
    return (
        <>
            <Helmet>
                <meta name="viewport" content="width=1200, initial-scale=1" />
            </Helmet>
            {allowView && (
                <div className="admin-portal">
                    <Header roleType="admin" />
                    <div className={`${navbarToggle ? "active" : ""} wrapper`}>
                        <SidebarComponent
                            navbarToggle={navbarToggle}
                            active={props.match.path}
                        />
                        <div className="main">
                            <div className="main-wrapper">
                                <div>
                                    <p className="admin-banner">Support</p>
                                </div>

                                <div className="tab-own-wrapper text-center pt-1">
                                    <ul className="tab-wrapper-list d-inline-flex flex-wrap list-unstyled">
                                        <li
                                            className={`${type === "open" ? "active" : ""}`}
                                            onClick={() => setType("open")}
                                        >
                                            Open
                                        </li>

                                        <li
                                            className={`${type === "close" ? "active" : ""}`}
                                            onClick={() => setType("close")}
                                        >
                                            Close
                                        </li>
                                    </ul>
                                </div>

                                <SidebarArrow handleSidebarToggle={handleSidebar} />
                                <div>
                                    <div className="container-fluid">
                                        <div className="row admin-controls">
                                            <form
                                                onSubmit={searchFilter}
                                                className="col-12 col-lg-6 admin-search"
                                            >
                                                <div className="search-wrapper">
                                                    <InputComponent
                                                        placeholder="Search"
                                                        label="Search Support"
                                                        type="text"
                                                        name="search"
                                                        theme="admin"
                                                        value={searchVal}
                                                        handleCallback={(val) => {
                                                            setSearchVal(val);
                                                        }}
                                                    />
                                                </div>
                                                <button type="submit" className="btn secondary-btn">
                                                    Go
                                                </button>
                                            </form>

                                            {dataList?.length > 0 && (
                                                <div
                                                    className="px-3 col-12 pt-3"
                                                    style={{ borderTop: "2px solid #ebeef1" }}
                                                >
                                                    <PaginationOwn
                                                        pageSize={pageSize}
                                                        changePageSize={changePageSizeFunc}
                                                        goToPage={goToPageFunc}
                                                        totalCount={totalCount}
                                                        currentPage={currentPage}
                                                        applicationType={
                                                            currentDataList?.length > 1 ? "support" : "support"
                                                        }
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {currentDataList?.length > 0 ? (
                                    <SupportTable
                                        dataList={currentDataList}
                                        arraySorting={arraySorting}
                                        basicApiCall={callBasicApi}
                                        sortObj={sortObj}
                                        openModalFunc={openModalFunc}
                                        type={type}
                                        openModalCloseFunc={openModalCloseFunc}

                                    />
                                ) : (
                                    <div className="px-3 pt-3">
                                        <div className="no-data-available">No data available</div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {openModal && (
                <SupportViewModal
                    data={currentData}
                    open={openModal}
                    description="Details"
                    onCloseModal={closeModalFunc}
                />
            )}

            {openModalClose && <SupportCloseModal
                open={openModalClose}
                description="CLose"
                onCloseModal={closeModalCloseFunc}
            />}


        </>
    );
};

export default SupportModule;
