import React from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import ReactHtmlParser from 'react-html-parser';


const viewNotificationModal = (props) => {
  return (
    <Modal
      center
      open={props?.open}
      closeOnOverlayClick={false}
      closeOnEsc={false}
      showCloseIcon={false}
      classNames={{ modal: "success-modal", root: "modal-own-wrapper" }}
    >
      <div className="settings-wrapper mx-auto">
        <div className="stripe border-bottom heading-wrapper">
          <p className="h4 font-weight-bold text-center terms-head text-break">
            {props?.data?.title}
          </p>
          <p>{ReactHtmlParser(props?.data?.body)}</p>
        </div>

        <div className="stripe pt-3 border-top">
          <div className="col-md-12 text-center">
            <button
              className="btn secondary-btn footer-width py-2 px-2 mx-2 "
              onClick={() => props?.closeModal()}
            >
              Close
            </button>
            {props?.data?.type === 'educationVideo' &&
              <button
                className="btn primary-btn footer-width py-2 px-2 mx-2 mt-3 mt-md-0"
                onClick={() => props?.viewVideo(props?.data)}
              >
                View Video
              </button>}
            {props?.data?.type === 'educationDocument' && <button
              className="btn primary-btn footer-width py-2 px-2 mx-2 mt-3 mt-md-0"
              onClick={() => props?.viewEducation(props?.data)}
            >
              View Document
            </button>}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default viewNotificationModal;
