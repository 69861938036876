import React from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

const QuizSuccessModal = (props) => {
  return (
    <Modal
      center
      open={props?.open}
      closeOnOverlayClick={false}
      closeOnEsc={false}
      showCloseIcon={false}
      classNames={{ modal: "success-modal", root: "modal-own-wrapper" }}
    >
      <div className="settings-wrapper mx-auto">
        <div className="stripe border-bottom heading-wrapper">
          <div className="d-flex justify-content-center">
            <img
              alt="..."
              src={require("../../wallpaper/quizSuccess.png").default}
            />
          </div>
          <p className="h4 font-weight-bold text-center terms-head">
            Congratulations
          </p>
        </div>
        <div className="mx-lg-3 text-justify p-3 terms-text">
          {props?.message?.length > 0 ?

            <>

              {props.message.map((data, i) => (
                <p className="font-weight-normal" key={i}>
                  {data}

                </p>

              ))}

            </> :

            <>
              <p className="font-weight-normal">
                You have successfully completed the quiz and been accepted into the
                eC-Card scheme.
              </p>
              <p className="font-weight-normal">
                You will now be able to access our list of approved venues and
                collect your free condom supply. Please ensure that the App can
                access location services and your camera.
              </p>
              <p className="font-weight-normal">
                When you visit your chosen venue please notify them that you are a
                member of the Sexual Health eC-Card scheme and that you would like
                to collect your free condoms.You will be asked to scan a QR code
                using the App to approve the transaction before being given your
                condom supply.
              </p>
              <p className="font-weight-normal">
                If you have any problem please contact the service intelligence
                center.
              </p>
              <p className="font-weight-normal">
                Please note: Condoms are supplied in a fixed quantity, in one size
                and type. You can only make one collection in any 7 day period.
              </p>

            </>

          }
        </div>
        <div className="stripe pt-3 border-top">
          <div className="col-md-12 text-center">
            <button
              className="btnFooter footer-width py-2 px-2"
              onClick={() => props?.closeModal()}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default QuizSuccessModal;
