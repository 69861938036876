import React from "react";
import { Route, Switch } from "react-router-dom";
import SuperAdminLogin from "./superAdminLogin";
import SuperAdminTerms from "./superAdminTerms";
import SuperAdminRegister from "./superAdminRegister";
import ManageAdmin from "./manageAdmin";
import ManageOrganisations from "./manageOrganisations";
import ForgotPassword from "./forgotPassword";
import ResetTokenComponent from "./resetPassword";
import VerifySuperAdmin from "./verifySuperAdmin";
import ManageSuperAdmin from "./manageSuperAdmin";
import EditOrganisations from './editOrg'

const SuperAdminRouter = (props) => {
  return (
    <Switch>
      <Route
        exact
        path={`${props.match.path}/login`}
        component={SuperAdminLogin}
      />
      <Route
        exact
        path={`${props.match.path}/activate-account`}
        component={VerifySuperAdmin}
      />
      <Route
        exact
        path={`${props.match.path}/manage-superadmin`}
        component={ManageSuperAdmin}
      />
      <Route
        exact
        path={`${props.match.path}/terms`}
        component={SuperAdminTerms}
      />
      <Route
        exact
        path={`${props.match.path}/register`}
        component={SuperAdminRegister}
      />
      <Route
        exact
        path={`${props.match.path}/manage-admin`}
        component={ManageAdmin}
      />
      <Route
        exact
        path={`${props.match.path}/manage-organisations`}
        component={ManageOrganisations}
      />
      <Route
        exact
        path={`${props.match.path}/forgot-password`}
        component={ForgotPassword}
      />
      <Route
        exact
        path={`${props.match.path}/reset-token/:id`}
        component={ResetTokenComponent}
      />
      <Route
        path={`${props?.match.path}/manage-organisations`}
        component={ManageOrganisations}
      />
      <Route
        path={`${props?.match.path}/edit-organisation/:id`}
        component={EditOrganisations}
      />
    </Switch>
  );
};

export default SuperAdminRouter;
