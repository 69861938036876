import React, { useState, useEffect } from "react";
import format from "date-fns/format";
import Header from "../template/_common/header";
import SidebarComponent from "../template/superadmin/sidebarComponent";
import OrganisationTable from "../template/superadmin/organisationTable";
import SidebarArrow from "../template/_common/sidebarArrow";
import { globalAlert, globalLoader } from "../actions/commonActions";
import AddOrgModal from "../template/superadmin/addOrgModal";
import {
  organizationListApi,
  addOrganizationApi,
  editOrgApi,
  deleteOrgApi,
  superAdminToggleOrg,
} from "./_actions";
import { compareValues, logOut } from "../_shared/commonFunction";
import PaginationOwn from "../template/_common/ownPagination";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import ConfirmationModal from "../template/_common/confirmModal";
import ViewAreaCodeModal from "../template/superadmin/viewAreaCodeModal";
import InputComponent from "../template/_common/inputComponent";
import arraySort from 'array-sort';


const ManageOrganisations = (props) => {
  const [openAddOrgModal, setOpenAddOrgModal] = useState(false);
  const [navbarToggle, setNavbarToggle] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [currentData, setCurrentData] = useState(null);
  const [editDataIs, setEditDataIs] = useState(false);
  const [pageSize, setPageSize] = useState(25);
  const [searchVal, setSearchVal] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentDataList, setCurrentDataList] = useState([]);
  const [sortObj, setSortObj] = useState({});
  const [currentDataSorted, setCurrentDataSorted] = useState([]);
  const [notFilteredRun, setNotFilteredRun] = useState(false);
  const [totalCount, setTotalCount] = useState(null);
  const [allowView, setAllowView] = useState(false);
  const [confirmaModalOpen, setConfirmaModalOpen] = useState(false);
  const [confirmInfo, setConfirmInfo] = useState(null);
  const [confirmHeading, setConfirmHeading] = useState("");
  const [currentAction, setCurrentAction] = useState("");
  const [currentId, setCurrentId] = useState("");
  const [openViewAreaCodeModal, setOpenViewAreaCodeModal] = useState(false);
  const [currentAreaCodeData, setCurrentAreaCodeData] = useState(null);

  const handleSidebar = (data) => {
    setNavbarToggle(data);
  };
  useEffect(() => {
    callBasicApi(); // eslint-disable-next-line
  }, []);

  const callBasicApi = async () => {
    try {
      globalLoader(true);
      let res = await organizationListApi();
      if (res?.data?.status === 200) {
        setAllowView(true);
        // setDataList(res?.data?.data?.organizationList);
        let currData = res?.data?.data?.organizationList;
        setDataList(currData);
        setCurrentDataSorted(res?.data?.data?.organizationList);
        !searchVal && setTotalCount(res?.data?.data?.organizationList.length);
      } else {
        globalAlert("error", res?.data?.message);
      }
      globalLoader(false);
    } catch (error) {
      globalAlert("error", error?.response?.data?.message);
      if (error?.response?.data?.status === 401) {
        logOut(props?.history, "/superadmin/login");
      }
      globalLoader(false);
    }
  };

  const closeAddOrgModal = async (val = null) => {
    if (val) {
      let res;
      if (editDataIs) {
        res = await editOrgApi(val, currentId);
      } else {
        res = await addOrganizationApi(val);
      }
      if (res?.data?.status === 200) {
        if (editDataIs) {
          setEditDataIs(false);
        } else {
          setSearchVal("");
        }
        setNotFilteredRun(false);
        setCurrentData(null);
        setOpenAddOrgModal(false);
        callBasicApi();
      } else {
        globalAlert("error", res?.data?.message);
        globalLoader(false);
      }
    } else {
      setEditDataIs(false);
      setCurrentData(null);
      setOpenAddOrgModal(false);
    }
  };

  const editData = (data) => {
    props.history.push(`/superadmin/edit-organisation/${data?.orgId}`);
  };

  useEffect(() => {
    if (!notFilteredRun) {
      filteredDataFunc();
    } // eslint-disable-next-line
  }, [pageSize, currentPage, sortObj, currentDataSorted, dataList]);

  const filteredDataFunc = (dataArray = null, localPage = null) => {
    let arr = [...dataList];
    let filterArr = [];
    let filteredData = [];
    if (dataArray) {
      arr = [...dataArray]
    }
    else {
      arr = arraySort(arr, compareValues(sortObj?.sortVal), { reverse: sortObj?.sortType === 'desc' ? true : false });
    }
    if (searchVal.length > 0) {
      filterArr = arr.filter((data) => {
        let dateCreated = `${format(new Date(data?.created), "dd/MM/yyyy")}`;
        if (
          data?.orgName.toLowerCase().includes(searchVal.toLowerCase()) ||
          data?.postalCode
            .toString()
            .toLowerCase()
            .includes(searchVal.toLowerCase()) ||
          data?.areaCode
            .toString()
            .toLowerCase()
            .includes(searchVal.toLowerCase()) ||
          dateCreated.toLowerCase().includes(searchVal.toLowerCase())
        ) {
          return data;
        } else {
          return null;
        }
      });
    } else {
      filterArr = arr;
    }
    if (filterArr && filterArr.length > 0) {
      setTotalCount(filterArr.length);
      for (
        let i = (localPage || currentPage) * pageSize - pageSize;
        i < (localPage || currentPage) * pageSize && i < filterArr.length;
        i++
      ) {
        filteredData.push(filterArr[i]);
      }
    }
    setCurrentDataList(filteredData);
  };

  const changePageSizeFunc = async (val) => {
    setNotFilteredRun(false);
    setPageSize(val.target.value);
    setCurrentPage(1);
    setSortObj(null);
  };

  const searchFilter = (ev) => {
    ev.preventDefault();
    let arr = [...dataList];
    let filterArr = arr.filter((data) => {
      let dateCreated = `${format(new Date(data?.created), "dd/MM/yyyy")}`;
      if (
        data?.orgName.toLowerCase().includes(searchVal.toLowerCase()) ||
        data?.postalCode
          .toString()
          .toLowerCase()
          .includes(searchVal.toLowerCase()) ||
        data?.areaCode
          .toString()
          .toLowerCase()
          .includes(searchVal.toLowerCase()) ||
        dateCreated.toLowerCase().includes(searchVal.toLowerCase())
      ) {
        return data;
      } else {
        return null;
      }
    });
    setNotFilteredRun(true);
    setCurrentDataList(filterArr);
    setCurrentPage(1);
    setSortObj(null);
  };

  const goToPageFunc = (val, data) => {
    setNotFilteredRun(false);
    if (data === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (
      data === "next" &&
      currentPage < Math.ceil(dataList?.length / pageSize)
    ) {
      setCurrentPage(currentPage + 1);
    } else {
      setCurrentPage(val?.target?.value);
    }
  };

  const arraySorting = (val) => {
    let arr = [...dataList];
    let sortedArr;
    let sortType = "aesc";
    if (val === sortObj?.sortVal && sortObj?.sortType === "aesc") {
      sortType = "desc";
    }
    sortedArr = arraySort(arr, compareValues(val), { reverse: sortType === 'desc' ? true : false })
    setNotFilteredRun(true);
    setCurrentPage(1);
    setSortObj({
      sortVal: val,
      sortType: sortType,
    });
    filteredDataFunc(sortedArr, 1);
  };

  const confirmModalFunc = async (val) => {
    if (val) {
      globalLoader(true);
      let res;

      if (currentAction === "delete") {
        res = await deleteOrgApi(currentId);
      } else if (currentAction === "toggle") {
        res = await superAdminToggleOrg(currentId);
      }
      if (res?.data?.status === 200) {
        globalAlert("success", res?.data?.message);
        setConfirmaModalOpen(false);
        setNotFilteredRun(false);
        callBasicApi();
      } else {
        globalAlert("error", res?.data?.message);
        globalLoader(false);
      }
    }
    setConfirmaModalOpen(false);
    setEditDataIs(false);
  };

  const toggleOrg = (data) => {
    setCurrentId(data?.orgId);
    let obj = [`Name : ${data?.orgName}`];
    setConfirmInfo(obj);
    setConfirmHeading(
      `Do you want to ${data.isActivated ? "inactivate" : "activate"
      } this organisation?`
    );
    setCurrentAction("toggle");
    setConfirmaModalOpen(true);
  };
  const deleteOrg = (data) => {
    setCurrentId(data?.orgId);
    let obj = [`Name : ${data?.orgName}`];
    setConfirmInfo(obj);
    setConfirmHeading(`Do you want to delete this organisation?`);
    setCurrentAction("delete");
    setConfirmaModalOpen(true);
  };

  const viewAreaCodeData = (data) => {
    setCurrentAreaCodeData(data);
    setOpenViewAreaCodeModal(true);
  };

  const closeAreaCodeModal = () => {
    setOpenViewAreaCodeModal(false);
  };

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=1200, initial-scale=1" />
      </Helmet>
      {allowView && (
        <div className="admin-portal">
          <Header roleType="superadmin" />
          <div className={`${navbarToggle ? "active" : ""} wrapper`}>
            <SidebarComponent
              navbarToggle={navbarToggle}
              active={props.match.path}
              roleType={"superadmin"}
            />
            <div className="main">
              <div className="main-wrapper">
                <div>
                  <p className="admin-banner">Organisations</p>
                </div>
                <SidebarArrow handleSidebarToggle={handleSidebar} />

                <div>
                  <div className="container-fluid">
                    <div className="row admin-controls">
                      <form
                        onSubmit={searchFilter}
                        className="col-12 col-lg-6 admin-search"
                      >
                        <div className="search-wrapper">
                          <InputComponent
                            placeholder="Search"
                            label="Search Organisations"
                            type="text"
                            name="search"
                            theme="admin"
                            value={searchVal}
                            handleCallback={(val) => {
                              setSearchVal(val);
                            }}
                          />
                        </div>
                        <button type="submit" className="btn secondary-btn">
                          Go
                        </button>
                      </form>
                      <div className="col-12 col-lg-6 admin-add-new mt-4 mt-lg-0 d-flex justify-content-start justify-content-lg-end">
                        <button
                          onClick={() => setOpenAddOrgModal(true)}
                          className="btn secondary-btn modal-btn"
                        >
                          <i className="fas fa-plus"></i> Add organisation
                        </button>
                      </div>
                      {dataList?.length > 0 && (
                        <div
                          className="px-3 col-12 pt-3 mt-4"
                          style={{ borderTop: "2px solid #ebeef1" }}
                        >
                          <PaginationOwn
                            pageSize={pageSize}
                            changePageSize={changePageSizeFunc}
                            goToPage={goToPageFunc}
                            totalCount={totalCount}
                            currentPage={currentPage}
                            applicationType={
                              currentDataList?.length > 1
                                ? "organisations"
                                : "organisation"
                            }
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {currentDataList?.length > 0 ? (
                  <OrganisationTable
                    dataList={currentDataList}
                    arraySorting={arraySorting}
                    basicApiCall={callBasicApi}
                    sortObj={sortObj}
                    editData={editData}
                    deleteOrg={deleteOrg}
                    toggleOrg={toggleOrg}
                    viewAreaCodeData={viewAreaCodeData}
                  />
                ) : (
                  <div className="px-3 pt-3">
                    <div className="no-data-available">No data available</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {openAddOrgModal && (
        <AddOrgModal
          open={openAddOrgModal}
          closeModal={closeAddOrgModal}
          data={currentData}
          edit={editDataIs}
        />
      )}

      {confirmaModalOpen && (
        <ConfirmationModal
          open={confirmaModalOpen}
          deleteInfo={confirmInfo}
          description={confirmHeading}
          onCloseModal={confirmModalFunc}
        />
      )}

      {openViewAreaCodeModal && (
        <ViewAreaCodeModal
          open={openViewAreaCodeModal}
          data={currentAreaCodeData}
          onCloseModal={closeAreaCodeModal}
        />
      )}
    </>
  );
};

export default withRouter(ManageOrganisations);
