import React, { useState, useEffect } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { globalAlert, globalLoader } from "../../actions/commonActions";
import InputComponent from "../_common/inputComponent";

const AddVideoModal = (props) => {
  const [videoTitle, setVideoTitle] = useState("");
  const [videoDescription, setVideoDescription] = useState("");
  const [file, setFile] = useState(null);
  const [emptyVideoTitle, setEmptyVideoTitle] = useState(false);
  const [emptyVideoDescription, setEmptyVideoDescription] = useState(false);
  const [emptyFile, setEmptyFile] = useState(false);

  useEffect(() => {
    setPropsToState(); // eslint-disable-next-line
  }, [props?.data]);

  const setPropsToState = () => {
    setVideoTitle(props?.data?.videoTitle);
    setVideoDescription(props?.data?.videoDescription);
    setFile(props?.data?.file);
  };

  const handleValidation = () => {
    let flag = true;
    if (!videoTitle) {
      setEmptyVideoTitle(true);
      flag = false;
    }
    if (!videoDescription) {
      setEmptyVideoDescription(true);
      flag = false;
    }

    if (!file) {
      setEmptyFile(true);
      flag = false;
    }
    return flag;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (await handleValidation()) {
      globalLoader(true);
      let formData = new FormData();
      formData.append("videoTitle", videoTitle);
      formData.append("videoDescription", videoDescription);
      formData.append("file", file);
      props.closeModal(formData);
    }
  };

  const setFileFunc = (ev) => {
    if (
      ev?.target?.files[0]?.type === "video/mp4" ||
      ev?.target?.files[0]?.type === "video/x-m4v"
    ) {
      const file = ev.target.files[0];
      setFile(file);
    } else {
      globalAlert("error", "Only MP4 files allowed");
    }
  };

  return (
    <Modal
      open={props.open}
      onClose={() => props.closeModal()}
      center
      classNames={{ modal: "admin-modal modal-own" }}
    >
      <div>
        <p className="admin-banner">{props?.edit ? "Edit" : "Add"} Video</p>
      </div>
      <form onSubmit={handleSubmit} className="form-own px-3">
        <div className="row px-2">
          <div className="col-6 px-2">
            <div className="form-own-group">
              {/* <label htmlFor="name" className="form-own-label">
                Title <span className="color-red">*</span>
              </label>
              <input
                id="name"
                name="name"
                placeholder="Title"
                onChange={(ev) => {
                  setVideoTitle(ev.target.value);
                }}
                value={videoTitle}
                className="form-control"
              /> */}
              <InputComponent
                placeholder="Title"
                label={
                  <>
                    Title <span className="color-red">*</span>
                  </>
                }
                type="text"
                name="videoTitle"
                theme="white"
                value={videoTitle}
                handleCallback={(val) => {
                  setVideoTitle(val);
                }}
              />
              <div className="error-text">
                {!videoTitle && emptyVideoTitle && (
                  <span>Title is required</span>
                )}
              </div>
            </div>
          </div>
          <div className="col-6 px-2">
            <div className="form-own-group">
              {/* <label htmlFor="postCode" className="form-own-label">
                Description <span className="color-red">*</span>
              </label>
              <input
                id="postCode"
                name="postCode"
                placeholder="Description"
                className="form-control"
                onChange={(evt) => {
                  setVideoDescription(evt.target.value);
                }}
                value={videoDescription}
              /> */}
              <InputComponent
                placeholder="Description"
                label={
                  <>
                    Description <span className="color-red">*</span>
                  </>
                }
                type="text"
                name="videoDescription"
                theme="white"
                value={videoDescription}
                handleCallback={(val) => {
                  setVideoDescription(val);
                }}
              />
              <div className="error-text">
                {!videoDescription && emptyVideoDescription && (
                  <span>Description is required</span>
                )}
              </div>
            </div>
          </div>
          <div className="col-12 px-2">
            <div className="form-own-group">
              <label className="form-own-label">
                Upload file <small>(Only MP4 files allowed)</small> <span className="color-red">*</span>
              </label>
              <div className="upload-btn upload-btn-dashed" id="previewLogo">
                {file && file.name && (
                  <div className="show-icon-wrapper pb-2 mb-1">{file.name}</div>
                )}

                <div className="upload-btn-txt btn ">
                  {file ? "CHANGE FILE" : "+ ADD FILE"}
                </div>
                <input
                  type="file"
                  accept="video/mp4,video/x-m4v"
                  className="upload-input cursor"
                  onChange={setFileFunc}
                />
              </div>
              <div className="error-text">
                {!file && emptyFile && <span>File is required</span>}
              </div>
            </div>
          </div>
        </div>
        <div className="admin-btn">
          <button
            onClick={() => props.closeModal()}
            className="btn secondary-btn mr-2"
            type="button"
          >
            Back
          </button>
          <button type="submit" className="btn primary-btn ml-2">
            Submit
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default AddVideoModal;
