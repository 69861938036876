import React, { useState } from "react";
import SearchList from "./searchList";
import VendingList from "./vendingList";
import VenueList from "./venueList";

const VenuesComponent = (props) => {
  const { noList, storesList, searchList, inputVal, currentData, getLetLongObj, setMapDirection, searchChange } =
    props;

  return (
    <>
      <div className="venues-menu">
        <div
          className={`menu ${props.active === 0 ? "active" : null}`}
          onClick={() => props.setActive(0)}
          style={props.active === 0 ? { borderBottom: "3px solid #08BBCF" } : null}
        >
          <div className="img-wrapper" style={{ backgroundColor: "#08BBCF" }}>
            <img
              src={require("../../wallpaper/stores.png").default}
              alt="stores"
            />
          </div>
          <p>Stores</p>
        </div>
        <div
          className={`menu ${props.active === 1 ? "active" : null}`}
          onClick={() => props.setActive(1)}
          style={props.active === 1 ? { borderBottom: "3px solid #F06292" } : null}
        >
          <div className="img-wrapper" style={{ backgroundColor: "#F06292" }}>
            <img
              src={require("../../wallpaper/vending.png").default}
              alt="vending"
            />
          </div>

          <p>Vending Machines</p>
        </div>
        <div
          className={`menu ${props.active === 2 ? "active" : null}`}
          onClick={() => props.setActive(2)}
          style={props.active === 2 ? { borderBottom: "3px solid #F29900" } : null}
        >
          <div className="img-wrapper" style={{ backgroundColor: "#F29900" }}>
            <img
              src={require("../../wallpaper/search.png").default}
              alt="search"
            />
          </div>

          <p>Search</p>
        </div>
      </div>

      {props.active === 0 ? (
        <VenueList
          storesList={storesList}
          currentData={currentData}
          getLetLongObj={(lat, lng) => getLetLongObj(lat, lng)}
          setMapDirection={(ev, store) => setMapDirection(ev, store)}
        />
      ) : props.active === 1 ? (

        // <div className="text-center pt-4" style={{ minHeight: '200px' }}>Coming soon</div>
        <VendingList
          storesList={storesList}
          currentData={currentData}
          getLetLongObj={(lat, lng) => getLetLongObj(lat, lng)}
          setMapDirection={(ev, store) => setMapDirection(ev, store)}
        />
      ) : props.active === 2 ? (
        <>
          <div className="search-input-wrapper py-3 px-3">
            <input className="form-control" placeholder="Search" onChange={(ev) => searchChange(ev.target.value)} value={inputVal} />
          </div>
          <SearchList
            storesList={searchList}
            currentData={currentData}
            getLetLongObj={(lat, lng) => getLetLongObj(lat, lng)}
            setMapDirection={(ev, store) => setMapDirection(ev, store)}
          />
        </>
      ) : null}
    </>
  );
};

export default VenuesComponent;
