import React, { useState } from "react";
import DeleteAccountModal from "./deleteAccountModal";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { globalLoader, globalAlert } from "../../actions/commonActions";
import { userSelfDeleteConfirmApi } from "../../user/_userActions";
import { withRouter } from "react-router-dom";
import { logOut } from "../../_shared/commonFunction";
import LogoutModal from "../_common/logoutModal";
import {useSelector} from 'react-redux'


const UserSettingsModal = (props) => {
  const [openDeleteAccModal, setOpenDeleteAccModal] = useState(false);
  const [openLogoutModal, setOpenLogoutModal] = useState(false);

  const userMsgCount = useSelector((state)=>state.user.msgCount)

  const closeOpenDeleteAccModal = () => {
    setOpenDeleteAccModal(false);
  };
  const deleteAcc = async (obj) => {
    globalLoader(true);
    let res = await userSelfDeleteConfirmApi(obj);
    if (res?.data?.status === 200) {
      globalAlert("success", res?.data?.message);// eslint-disable-next-line
      // eslint-disable-next-line
      props?.history?.push("/user/login");
    } else {
      globalAlert("error", res?.data?.message);
      globalLoader(false);
    }
  };

  const closeLogoutModal = (val) => {
    if (val) {
      logOut(props?.history, "/user/login");
    } else {
      setOpenLogoutModal(false);
    }
  };

  return (
    <Modal
      open={props?.open}
      onClose={() => {
        // eslint-disable-next-line
        props?.closeModal();
      }}
      center
      classNames={{
        modal: "setting-modal-own",
        overlay: "customOverlay",
        root: "modal-own-wrapper white-header-strip",
      }}
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <div className="settings-wrapper mx-auto">
        <div className="stripe pb-3 pt-5 border-bottom heading-wrapper">
          <p className="h4 heading text-center pt-3">Settings</p>
        </div>
        <div className="settings-menu pt-lg-5 px-lg-5 p-3 ">
          <ul className="list-unstyled settings-menu-list">
            {/* <li className="cursor">
              <div>Privacy Policy</div>
            </li> */}
            <li
              className="cursor"
              onClick={() => {
                window.open("/user/terms-and-conditions", "_blank");
              }}
            >
              <div className="settings-icons">
                <i className="far fa-file-alt"></i>
              </div>
              <div>Terms and Conditions</div>
            </li>
            <li
              className="cursor "
              onClick={() => {
                // eslint-disable-next-line
                props?.setCurrentMenu(1);
                // eslint-disable-next-line
                props?.closeModal()
              }}
            >
              <div className="settings-icons">
                <i className="far fa-chalkboard-teacher"></i>
              </div>
              <div>Education</div>
            </li>
            <li
              className="cursor "
              onClick={() => {
                // eslint-disable-next-line
                props?.setCurrentMenu(2);
                // eslint-disable-next-line
                props?.closeModal()
              }}
            >
              <div className="settings-icons">
                <i className="far fa-comment"></i>
              </div>
              <div>Notifications {userMsgCount&& userMsgCount>0 ?<span className="badge-own">{userMsgCount}</span>:null}</div>
            </li>
           
            <li
              className="cursor"
              onClick={() => {
                setOpenLogoutModal(true);
                // logOut(props?.history, "/user/login");
              }}
            >
              <div className="settings-icons">
                <i className="fas fa-sign-out-alt"></i>
              </div>
              <div>Logout</div>
            </li>
            <li
              className="cursor "
              onClick={() => {
                setOpenDeleteAccModal(true);
              }}
            >
              <div className="settings-icons">
                <i className="far fa-trash-alt"></i>
              </div>
              <div>Delete Account</div>
            </li>
          </ul>
        </div>
      </div>
      {openDeleteAccModal && (
        <DeleteAccountModal
          open={openDeleteAccModal}
          closeModal={closeOpenDeleteAccModal}
          deleteAcc={deleteAcc}
        />
      )}
      {openLogoutModal && (
        <LogoutModal open={openLogoutModal} closeModal={closeLogoutModal} />
      )}
    </Modal>
  );
};

export default withRouter(UserSettingsModal);
