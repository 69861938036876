export const DocFiles = [
  {
    name: "Document 1",
    pages: "12",
    size: "65",
    uploadTime: "Wed Oct 06 2021 10:26:31 GMT+0530",
  },
  {
    name: "Document 2",
    pages: "4",
    size: "10",
    uploadTime: "Wed Oct 06 2021 04:26:31 GMT+0530",
  },
  {
    name: "Document 3",
    pages: "12",
    size: "65",
    uploadTime: "Wed Oct 05 2021 16:26:31 GMT+0530",
  },
  {
    name: "Document 4",
    pages: "15",
    size: "30",
    uploadTime: "Wed Oct 05 2021 15:26:31 GMT+0530",
  },
  {
    name: "Document 5",
    pages: "12",
    size: "65",
    uploadTime: "Wed Oct 05 2021 14:26:31 GMT+0530",
  },
  {
    name: "Document 6",
    pages: "15",
    size: "30",
    uploadTime: "Wed Oct 05 2021 13:26:31 GMT+0530",
  },
];
