import React, { useState, useEffect } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { globalAlert, globalLoader } from "../../actions/commonActions";
import {
  userSelfDeleteApi,
  userSelfDeleteConfirmApi,
} from "../../user/_userActions";
import { withRouter } from "react-router-dom";
import { logOut } from "../../_shared/commonFunction";
import TimerComp from "../../_shared/timer";
import InputComponent from "../_common/inputComponent";

const DeleteAccountModal = (props) => {
  const [otp, setOtp] = useState("");
  const [emptyOtp, setEmptyOtp] = useState(false);
  const [otpSent, setOtpSent] = useState(false);

  useEffect(() => {
    callBasicApi(); // eslint-disable-next-line
  }, []);

  const callBasicApi = async () => {
    try {
      globalLoader(true);
      let res = await userSelfDeleteApi();
      if (res?.data?.status === 200) {
        globalAlert("success", res?.data?.message);
        setOtpSent(true);
      } else {
        globalAlert("error", res?.data?.message);
      }
      globalLoader(false);
    } catch (error) {
      globalAlert("error", error?.response?.data?.message);
      if (error?.response?.data?.status === 401) {
        logOut(props?.history, "/user/login");
      }
      globalLoader(false);
    }
  };

  const handleOtp = () => {
    callBasicApi();
  };

  const handleValidation = () => {
    let flag = true;
    if (!otp) {
      setEmptyOtp(true);
      flag = false;
    }
    return flag;
  };

  const setTimerOver = () => {
    setOtpSent(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (await handleValidation()) {
      globalLoader(true);
      let obj = { otp: otp };
      let res = await userSelfDeleteConfirmApi(obj);
      if (res?.data?.status === 200) {
        globalAlert("success", res?.data?.message);
        logOut(props?.history, "/user/login");
      } else {
        globalAlert("error", res?.data?.message);
        globalLoader(false);
      }
    }
  };

  return (
    <Modal
      open={props?.open}
      onClose={() => props?.closeModal()}
      center
      classNames={{
        modal: "setting-modal-own",
        overlay: "customOverlay",
        root: "modal-own-wrapper white-header-strip",
      }}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      showCloseIcon={false}
    >
      <div className="video-player-wrapper px-2">
        <p className=" primary-color py-3 text-center">
          You are about to delete your account
        </p>
        <div className="border-bottom-own pt-2 mb-3"></div>
        <form className="form-own px-3" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-12">
              <div className="form-own-group d-flex flex-column align-items-center">
                {/* <label htmlFor="otp" className="form-own-label">
                  Enter Otp
                </label> */}
                {/* <input
                  id="otp"
                  name="otp"
                  placeholder="OTP"
                  className="form-control"
                  onChange={(evt) => {
                    setOtp(evt.target.value);
                  }}
                  value={otp}
                /> */}
                <InputComponent
                  placeholder="Enter OTP"
                  label="Enter OTP"
                  type="text"
                  name="otp"
                  inputFor="text"
                  theme="white"
                  maxLength={null}
                  value={otp}
                  autoComplete="off"
                  handleCallback={(val) => {
                    setOtp(val);
                  }}
                />
                <div className="error-text">
                  {!otp && emptyOtp && <span>Otp is required</span>}
                </div>
              </div>
              <div className="col-12 text-center resend-otp-txt">
                {otpSent ? (
                  <TimerComp setTimerOver={setTimerOver} />
                ) : (
                  <span className="cursor" onClick={handleOtp}>
                    Resend OTP
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="text-center">
            <button
              className="btnCancel footer-width mx-2 my-2"
              // className="btn secondary-btn btn-own my-2"
              onClick={() => {
                // eslint-disable-next-line
                props?.closeModal();
              }}
            >
              Go Back
            </button>
            <button className="btnFooter footer-width mx-2 my-2">
              Delete Account
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default withRouter(DeleteAccountModal);
