/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Header from "../template/_common/header";
import SidebarComponent from "../template/admin/sidebarComponent";
import SidebarArrow from "../template/_common/sidebarArrow";
import StocksTable from "../template/admin/stocksTable";
import ManageStockModal from "../template/admin/manageStockModal";
import { logOut } from "../_shared/commonFunction";
import { globalAlert, globalLoader } from "../actions/commonActions";
import AddNotesModal from "../template/admin/addNotesModal";
import ConfirmModal from "../template/admin/confirmModal";
import { viewIvtsApi, addIvtsStockApi } from "./_actions";
import InputComponent from "../template/_common/inputComponent";
import { withRouter } from "react-router";
import IvtsStocksTable from "../template/admin/ivtsStocksTable";

const ManageStocksKiosk = (props) => {
    const [navbarToggle, setNavbarToggle] = useState(false);
    const [quantity, setQuantity] = useState(null);

    const [currentViewData, setCurrentViewData] = useState(null);

    const [openStockModal, setOpenStockModal] = useState(false);
    // const { data } = props?.location?.state;
    const [currentDataList, setCurrentDataList] = useState(null);
    const [openAddNotesModal, setOpenAddNotesModal] = useState(false);
    const [updateActive, setUpdateActive] = useState(false);
    const [confirmaModalOpen, setConfirmaModalOpen] = useState(false);
    const [notes, setNotes] = useState("");
    const [btnActive, setBtnActive] = useState('');
    const [allowView, setAllowView] = useState(false);


    useEffect(() => {
        callBasicApi();
    }, []);


    const callBasicApi = async () => {
        const { id } = props?.match?.params;

        if (id) {
            try {
                globalLoader(true);
                const res = await viewIvtsApi(id);
                debugger
                if (res?.data?.status === 200) {
                    setCurrentDataList(res?.data?.data?.orgIvts);
                    setAllowView(true)
                    globalLoader(false);

                }

                else {
                    globalAlert("error", res?.data?.message);
                    props.history.push('/admin/manage-kiosk')
                }
            }
            catch (error) {
                globalAlert("error", error?.response?.data?.message);

                if (error?.response?.data?.status === 401) {
                    logOut(props?.history, "/admin/login");
                }
                globalLoader(false);
            }

        }
    };

    const manageStockData = (obj) => {
        setCurrentViewData(obj);
        setOpenStockModal(true);
    };

    const closeStockModal = (obj = null) => {
        setCurrentViewData(null);
        setOpenStockModal(false);
        if (obj) {
            setUpdateActive(true);
            let data = currentDataList;
            data["stockQuantity"] = obj?.stockQuantity;
            setCurrentDataList(data);
        }
        globalLoader(false);
    };

    const addNotes = (obj) => {
        setCurrentViewData(obj);
        setOpenAddNotesModal(true);
    };

    const closeAddNotes = (obj = null) => {
        setCurrentViewData(null);
        setOpenAddNotesModal(false);
        if (obj) {
            setUpdateActive(true);
        }
    };

    const openConfirmModal = () => {
        setConfirmaModalOpen(true);
    };

    const confirmModalFunc = async (val = null) => {
        if (val) {
            try {
                globalLoader(true);
                let obj = {
                    stockQuantity: btnActive === 'minus' ? -quantity : quantity,
                    notes: notes,
                };
                let res = await addIvtsStockApi(currentDataList?.ivtsId, obj);
                if (res?.data?.status === 200) {
                    callBasicApi();
                } else {
                    globalAlert("error", res?.data?.message);
                    globalLoader(false);
                }
            } catch (error) {
                globalAlert("error", error?.response?.data?.message);

                if (error?.response?.data?.status === 401) {
                    logOut(props?.history, "/admin/login");
                }
                globalLoader(false);
            }

        }
        setBtnActive('');
        setConfirmaModalOpen(false);
    };
    useEffect(() => {
        if (btnActive) {
            if (quantity) {
                if (btnActive === 'plus' || Number(quantity) <= Number(currentDataList?.stockQuantity)) {
                    setConfirmaModalOpen(true);

                }
                else {
                    globalAlert("error", "Cannot remove more than available stock");

                }
            }
            else {
                globalAlert("error", "Quantity is empty");

            }
        }

    }, [btnActive, quantity, currentDataList?.stockQuantity])

    const handleSidebar = (data) => {
        setNavbarToggle(data);
    };

    return (
        <>
            <Helmet>
                <meta name="viewport" content="width=1200, initial-scale=1" />
            </Helmet>
            {allowView && (<div className="admin-portal">
                <Header roleType="admin" />
                <div className={`${navbarToggle ? "active" : ""} wrapper`}>
                    <SidebarComponent
                        navbarToggle={navbarToggle}
                        // active={'/admin/manage-kiosk'}
                        menu="manageVenue"
                        subMenu='manage-kiosk'
                    />
                    <div className="main">
                        <div className="main-wrapper">
                            <div>
                                <p className="admin-banner">Manage Stocks</p>
                            </div>
                            <SidebarArrow handleSidebarToggle={handleSidebar} />
                            <div>
                                <div className="container-fluid pt-1">
                                    <div className="admin-add-new mt-lg-0">
                                        <div className="pr-2"></div>
                                        <div className="row px-2">
                                            <div className="col-12 px-2">
                                            </div>
                                            <div className="col-4 px-2">
                                                <div>
                                                    <span className="primary-color weight-600">
                                                        Kiosk Name:
                                                    </span>
                                                </div>
                                                <div>
                                                    {currentDataList?.name}
                                                </div>
                                            </div>
                                            <div className="col-4 px-2">
                                                <div>
                                                    <span className="primary-color weight-600">
                                                        Stock Quantity:
                                                    </span>
                                                </div>
                                                <div>
                                                    {currentDataList?.stockQuantity}
                                                </div>
                                            </div>
                                            <div className="col-4 px-2">
                                                <div>
                                                    <span className="primary-color weight-600">
                                                        Address:
                                                    </span>
                                                </div>
                                                <div>
                                                    {currentDataList?.storeNumber ? `${currentDataList?.storeNumber} ,` : null} {currentDataList?.street}, {currentDataList?.address},{" "}
                                                    {currentDataList?.city}, {currentDataList?.areaCode}
                                                </div>
                                            </div>
                                            <div className="col-12 px-2 py-2 pt-3">
                                                <form className="form-own row px-2">
                                                    <div className="col-12 px-2">
                                                        <h3 className="color-primary font-18">Manage Stock</h3>

                                                    </div>
                                                    <div className="col-md-4 px-2">
                                                        <InputComponent
                                                            placeholder="Quantity"
                                                            label="Quantity"
                                                            type="text"
                                                            name="quantity"
                                                            theme="white"
                                                            value={quantity}
                                                            onlyNumber={true}
                                                            handleCallback={(val) => {
                                                                setQuantity(val);
                                                                setBtnActive(null);
                                                            }}
                                                            maxLength={8}
                                                        />

                                                    </div>

                                                    <div className="col-md-4 px-2">
                                                        <InputComponent
                                                            placeholder="Notes"
                                                            label="Notes (Max 30 character)"
                                                            type="text"
                                                            name="notes"
                                                            theme="white"
                                                            value={notes}
                                                            handleCallback={(val) => {
                                                                setNotes(val);
                                                            }}
                                                            maxLength={30}
                                                        />
                                                    </div>
                                                    <div className="col-md-4 px-2 d-flex flex-wrap">
                                                        <div
                                                            className={`stock-count ${btnActive === "minus" ? "minus-active" : "minus"
                                                                } cursor mr-1`}
                                                            onClick={() => setBtnActive('minus')}
                                                        >
                                                            <i className="fas fa-minus "></i>
                                                        </div>
                                                        <div
                                                            className={`stock-count ${btnActive === "plus" ? "plus-active" : "plus"
                                                                } cursor ml-1`}
                                                            onClick={() => setBtnActive("plus")}
                                                        >
                                                            <i className="fas fa-plus "></i>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>

                                            {/* <div className="col-12 ">
                        <div className="admin-add-new mt-lg-0 d-flex">
                          <button className="btn secondary-btn modal-btn">
                            <i className="fas fa-plus"></i> Add Stock Details
                          </button>
                        </div>
                      </div> */}
                                        </div>
                                    </div>
                                    <div className="row admin-controls"></div>
                                </div>
                            </div>

                            {currentDataList?.stockUpdateHistory?.length > 0 ? (
                                <IvtsStocksTable
                                    dataList={currentDataList?.stockUpdateHistory}
                                    // arraySorting={arraySorting}
                                    // basicApiCall={callBasicApi}
                                    // sortObj={sortObj}
                                    manageStockData={manageStockData}
                                    addNotes={addNotes}
                                    updateActive={updateActive}
                                    confirmModal={openConfirmModal}
                                />
                            ) : (
                                <div className="px-3 pt-3">
                                    <div className="no-data-available">No data available</div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>)}
            {openStockModal && (
                <ManageStockModal
                    open={openStockModal}
                    closeModal={closeStockModal}
                    data={currentViewData}
                />
            )}
            {openAddNotesModal && (
                <AddNotesModal
                    open={openAddNotesModal}
                    closeModal={closeAddNotes}
                    data={currentViewData}
                />
            )}
            {confirmaModalOpen && (
                <ConfirmModal
                    open={confirmaModalOpen}
                    description={`Do you want to ${btnActive === 'minus' ? 'remove' : 'add'} stock?`}
                    currentStock={quantity}
                    data={currentDataList?.stockQuantity}
                    btnActive={btnActive}
                    onCloseModal={confirmModalFunc}
                />
            )}
        </>
    );
};

export default withRouter(ManageStocksKiosk);
