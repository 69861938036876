import React from "react";
import MobileVideo from "./mobileVideo";

const VideosComponent = (props) => {


  return (
    <>
      <ul className="list-unstyled mb-3">
        {props?.data?.length > 0 &&
          props?.data?.map((data, index) => (
            <li key={index}>
              <MobileVideo data={data} callApi={true} />
            </li>
          ))}
        <li style={{ height: "100px" }}></li>
      </ul>
    </>
  );
};

export default VideosComponent;
