import {
  getRequest,
  postRequest,
  deleteRequest,
  putRequest,
} from "../actionTypes/axiosUtility";
import { GLOBAL_API, USER_MSG_COUNT } from "../actionTypes/types";
import { appStore } from "../store/index";


export const userLoginApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/user/v1/login`, obj);
};

export const userVerifyLoginApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/user/v1/verify-login`, obj);
};

export const userRegisterNumber = async (obj) => {
  return postRequest(`${GLOBAL_API}/user/v1/register-number`, obj);
};

export const userCheckDob = async (obj) => {
  return postRequest(`${GLOBAL_API}/user/v1/check-date-of-birth`, obj);
};


export const userRegisterVerifyNumber = async (obj) => {
  return postRequest(`${GLOBAL_API}/user/v1/verify-register-number`, obj);
}

export const userBrandInfo = async () => {
  return getRequest(`${GLOBAL_API}/user/v1/brand-info`);
}



export const userSelfRegister = async (obj) => {
  return postRequest(`${GLOBAL_API}/user/v1/self-register`, obj);
};
export const userVerifyRegisterApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/user/v1/verify-register`, obj);
};
export const userResendRegisterCodeApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/user/v1/resend-register-code`, obj);
};

export const userLevelProgressApi = async (quizCheck = true) => {
  return getRequest(`${GLOBAL_API}/user/v1/level-progress?quizCheck=${quizCheck}`);
};

export const userViewQuestionsApi = async () => {
  return getRequest(`${GLOBAL_API}/user/v1/view-questions`);
};

export const userSubmitQuizApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/user/v1/submit-quiz-answers`, obj);
};

export const videoIsWatchedApi = async (obj) => {
  return getRequest(`${GLOBAL_API}/user/v1/mark-as-watched/${obj}`);
};

export const userSelfDeleteApi = async () => {
  return getRequest(`${GLOBAL_API}/user/v1/self-delete`);
};

export const userSelfDeleteConfirmApi = async (obj) => {
  return deleteRequest(`${GLOBAL_API}/user/v1/self-delete`, obj);
};

//bypass quiz and video api
export const bypassAccessCodeApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/user/v1/bypass-quiz-stage`, obj);
};

export const listProductsByQrApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/user/v1/products-by-qr`, obj);
};

export const claimTransactionApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/user/v1/claim-transaction`, obj);
};

export const viewStoresApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/user/v1/view-stores`, obj);
};

export const searchIvtsStoresApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/user/v1/search-ivts-stores`, obj);
};



export const viewIvtsApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/user/v1/view-ivts`, obj);
};
export const userEligibleTransactionApi = async () => {
  return getRequest(`${GLOBAL_API}/user/v1/eligible-transaction`);
};

export const userViewEducationApi = async (type, search) => {
  return getRequest(`${GLOBAL_API}/user/v1/education/${type}/view?search=${search}`);
};

export const userViewSingleEducationApi = async (type, id) => {
  return putRequest(`${GLOBAL_API}/user/v1/education/${type}/${id}`);
};

export const getNotificationApi = async () => {
  return getRequest(`${GLOBAL_API}/user/v1/notifications`);
};

export const readNotificationApi = async (id) => {
  let res = await getRequest(`${GLOBAL_API}/user/v1/notifications/${id}`);
  if (res?.data?.status === 200) {
    getDashboardApi()
  }
  return res;
};

export const getDashboardApi = async () => {
  let res = await getRequest(`${GLOBAL_API}/user/v1`);

  if (res?.data?.status === 200) {
    appStore.dispatch({
      type: USER_MSG_COUNT,
      payload: res?.data?.data?.unReadNotifications,
    });
  }
  return res
};
