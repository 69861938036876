

const TermsAndCondition = ()=>{

    return(
        <div>
        <div className="fixed-top stripe py-3 border-bottom heading-wrapper">
          <p className="h4 fw-bold text-center terms-head">App Terms and Condtions</p>
        </div>
        <div className="mx-3 text-justify terms px-3 terms-text">
          <p className="fw-normal">
            These Terms (together with the documents referred to in it) describe how
            you may use our web-based software as a service, eC-Card ("Services"). The
            Services are made available to you via our web app (the main domain of{" "}
            <a href="https://ec-cardapp.com/" target="_blank">
              https://ec-cardapp.com/
            </a>{" "}
            and its sub-domains) and mobile app (Android and iOS) (each such
            application together with any applicable documentation thereto, and
            programming and user interfaces therefor, a “Platform”), as well as any
            relevant information, reports and data made available to you.
            <br />
            Please read these Terms carefully before you start to use the Services
            <br />
            These Terms refer to the following additional policies which also apply to
            your use of the Services. By agreeing to these Terms, you also agree to
            each of these additional policies:
          </p>
          <ul>
            <li>
              our Privacy Notice (https://ec-cardapp.com/privacy-notice), which sets
              out the terms on which we process any personal data we collect from you,
              or that you provide to us.
            </li>
          </ul>
          <p />
          <strong className="fw-bold">Introduction</strong>
          <p className="fw-normal">
            Provide Digital Limited ("Provide Digital", "us", "our" or "we") is the
            provider of the Services and operator of the Platform. We are registered
            in England and Wales under company number 12377709 and have our registered
            office at 900, the Crescent, Colchester Business Park, Colchester, England
            C04 9YQ. You are the employees, representatives, and service users
            ("User", "you", "your") of our client organisation ("Client Organisation")
            whose details are set out on the on-line registration form which can be
            accessed using the Services. By registering an account, you agree to
            subscribe to the Services and to these Terms.
          </p>
          <p className="fw-bold">Registration</p>
          <p className="fw-normal">
            If you are an employee or representative of the Client Organisation
            ("Client Admin User") you will be registered for the Services by the
            Client Organisation and you will then be asked, via email, to authorise
            your account and create your own secure password.
          </p>
          <p className="fw-normal">
            If you are a service user who has been authorised by the Client
            Organisation to use the Services ("Service Users"), you will be able to
            register yourself using the online registration form. You will need to
            answer certain questions about yourself to enable you to complete the
            registration form. In particular, you will need to confirm your telephone
            number, your date of birth and your postcode.
          </p>
          <p className="fw-bold">User account</p>
          <p className="fw-normal">
            You are responsible for any use of our Services with your account details,
            which includes all user passwords generated and for protecting your
            account details from unauthorised use. You are also responsible for the
            security of any computer or mobile device from which you sign in to your
            account.
          </p>
          <p className="fw-bold">Services</p>
          <p className="fw-normal">
            We undertake that the Services will be performed in a professional manner
            in accordance with industry standards using reasonable skill and care, and
            in conformance with the description of the Services in these Terms or
            otherwise set out on our website. This undertaking shall not apply to the
            extent of any non-conformance which is caused by your use of the Services
            contrary to our instructions or these Terms, or any alteration or
            modification made to the Services or the software used in the provision of
            the Services by a third party who is not authorised by us. You understand
            and agree that we have no obligation to modify software to support your
            use of the Services.
          </p>
          <p className="fw-normal">
            From time to time, we may introduce new services, features or
            functionality to the Services. These Terms will apply to such new
            services, features or functionality, unless they come with separate or
            additional terms, in which case you will be required to agree to such
            separate or additional terms before being permitted to use the new
            services, features or functionality.
          </p>
          <p className="fw-bold">Accessing the Services</p>
          <p className="fw-normal">
            Whilst we make reasonable efforts to ensure the Services are operational
            24 hours a day, 7 days a week, we do not guarantee that the Services will
            always be available or be uninterrupted. In particular, but without
            limitation, the Services will not be available to you when we carry out
            maintenance services. We endeavour to carry out maintenance services
            outside of normal business hours and to give three hours' notice where
            possible, however you acknowledge that this may not always be possible.
          </p>
          <p className="fw-normal">
            The Services may be subject to limitations, delays and other problems
            inherent in the use of communication networks and facilities. We will not
            be liable to you if the Services are unavailable at any time, or for any
            period due to an event or cause outside of our control.
          </p>
          <p className="fw-normal">
            We reserve the right to suspend your access to or use of the Services
            without notice in the event you breach these Terms or if we reasonably
            suspect that you have breached these Terms. We further reserve the right
            to suspend your access to or use of the Services if you use the Services
            inappropriately or at the reasonable request of the Client Organisation.
          </p>
          <p className="fw-bold">Using the Services</p>
          <p className="fw-normal">You will:</p>
          <ul>
            <li>
              provide us with all necessary cooperation in relation to this Agreement
              and all necessary access to information that we require to deliver the
              Services;
            </li>
            <li>
              comply with all applicable laws and regulations with respect to your
              activities under this Agreement;
            </li>
            <li>
              obtain and maintain all necessary licences, consents and permissions
              necessary for you to perform your obligations and exercise your rights
              under this Agreement, including, without limitation, to use the
              Services;
            </li>
            <li>fill in the Registration Form;</li>
            <li>
              ensure that your network and systems comply with the relevant
              specifications provided by us from time to time;
            </li>
            <li>
              be solely responsible for procuring and maintaining your network
              connections and telecommunications links from your systems to our data
              centres, and for all problems, conditions, delays, delivery failures and
              all other loss or damage arising from or relating to your network
              connections or telecommunications links or caused by the internet; and
            </li>
            <li>
              be solely responsible for the management of your health and well-being,
              and safety.
            </li>
          </ul>
          <p className="fw-normal">
            We are giving you personally the right to access and use the Services. If
            others you know wish to access the Services they should create their own
            account.
          </p>
          <p className="fw-normal">
            This licence granted to you to use of the Services does not permit you to
            do, and you shall not do nor permit any third party to do, any of the
            following:
          </p>
          <ul>
            <li>
              embed our Services or Service data into any product of yours or any
              third party;
            </li>
            <li>
              make available through automated or manual means any part of the
              Services by way of crawling, scraping, spidering or otherwise;
            </li>
            <li>
              copy or access all or any part of the Services other than via the
              interface(s) provided to you by us; or
            </li>
            <li>
              circumvent or attempt to override any security features we have
              installed around the Services.
            </li>
          </ul>
          <p className="fw-bold">
            Intellectual Property Rights and Rights we grant you
          </p>
          <p className="fw-normal">For the purposes of these Terms:</p>
          <ul>
            <li>
              <span className="fw-bold">Client Organisation Content:</span> means any
              form of information or data and includes, without limitation,
              photographs, images, illustrations, animations, tools, texts, tables,
              communications, replies, likes, and any other intellectual property
              therein any of which may be generated, provided or otherwise made
              accessible on or through the Services.
            </li>
            <li>
              <span className="fw-bold">Intellectual Property Rights:</span> means
              patents, utility models, rights to inventions, copyright and related
              rights, trade marks and service marks, trade names and domain names,
              rights in get-up, goodwill and the right to sue for passing off or
              unfair competition, rights in designs, rights in computer software,
              database rights, rights to preserve the confidentiality of information
              (including know-how and trade secrets) and any other intellectual
              property rights, including all applications for (and rights to apply for
              and be granted), renewals or extensions of, and rights to claim priority
              from, such rights and all similar or equivalent rights or forms of
              protection which subsist or will subsist, now or in the future, in any
              part of the world.
            </li>
            <li>
              <span className="fw-bold">
                Ownership of Intellectual Property Rights:
              </span>{" "}
              You agree that all Intellectual Property Rights:
              <ul>
                <li>
                  in the Services anywhere in the world belong to Provide Digital or
                  its licensors and that you have no rights in or to the Services
                  other than as expressly set out in this Agreement; and
                </li>
                <li>
                  in Client Organisation Content and updates thereof belong and shall
                  belong to the Client Organisation;
                </li>
              </ul>
            </li>
            <li>
              <p className="fw-bold">Our licence to you</p>
              <p className="fw-normal">
                Provide Digital retains ownership and responsibility for, and other
                applicable rights, in the Services. Except as expressly provided in
                these Terms nothing grants you a right or licence to use the Services,
                including any Client Organisation Content which is owned and/or
                controlled by the Client Organisation. You agree not to duplicate
                publish, display, modify or create derivative works from the software,
                Platform or material presented through the Services unless
                specifically authorised to do so by us.
              </p>
              <p className="fw-normal">
                Subject to your compliance with these Terms, we grant you a limited,
                revocable, personal, non-transferable, and non-exclusive right and
                licence to access and use the Servicesfor your own personal,
                non-commercial purposes, provided that you do not (and do not allow
                any third party to) copy, modify, create a derivative work from,
                reverse engineer, sell, assign, sublicense, grant a security interest
                in, transfer or otherwise commercially exploit any right in the
                Services.
              </p>
              <p className="fw-normal">
                We also hereby grant you a limited personal non-exclusive,
                non-transferable, non-sublicensable, revocable licence to use the
                eC-Card Platform downloaded directly from a legitimate market place,
                solely in object code format and solely for your personal use for
                lawful purposes.
              </p>
              <p className="fw-normal">
                We are giving you personally the right to access and use the Services.
                If others you know wish to access the Services they should create
                their own account.
              </p>
              <p className="fw-normal">
                This licence granted to you to use of the Services does not permit you
                to do, and you shall not do nor permit any third party to do, any of
                the following:
              </p>
              <ul>
                <li>
                  embed our Services into any product of yours or any third party;
                </li>
                <li>
                  make available through automated or manual means any part of the
                  Services by way of crawling, scraping, spidering or otherwise;
                </li>
                <li>
                  copy or access all or any part of the Services other than via the
                  interface(s) provided to you by us; or
                </li>
                <li>
                  circumvent or attempt to override any security features we have
                  installed around the Services.
                </li>
              </ul>
              <p />
              <p className="fw-normal">
                We make no representations or warranties as to the accuracy,
                reliability, completeness of any Client Organisation Content available
                through the Services, and we make no commitment to update such Client
                Organisation Content. All content is provided as is without any
                representation, warranty or condition as to its accuracy or
                reliability.
              </p>
            </li>
          </ul>
          <p className="fw-bold">Data and rights you give us</p>
          <p className="fw-normal">
            We claim no intellectual property rights in and to any material you
            provide or otherwise transmit to us via the Registration Form. All data
            and information that you collect and input into the Platform shall belong
            to you.
          </p>
          <p className="fw-normal">
            However, to enable your use of the Services, we will need to collect data
            and information about you, including personal data (
            <strong> "User Data"</strong>). As such, we require, and you hereby grant
            us, a worldwide, non-exclusive, royalty free licence to store, use,
            reproduce, display and transmit the User Data and any other materials
            transmitted via the Service to the extent necessary to enable your use of
            the Services, including monitoring services.
          </p>
          <p className="fw-bold"> Data Protection and Privacy</p>
          <p className="fw-normal">
            We will use User Data only in order to provide the Services to you and
            only as permitted by applicable law, these Terms and our Privacy
            Notice(https://ec-cardapp.com/privacy-notice).
          </p>
          <p className="fw-normal">
            For the purposes of providing the Services, we may collect, process and
            store certain data including personal data, for which we will be the data
            controller. To the extent that we process any User Data on your behalf
            when providing the Services, you will be the data controller and we will
            be a data processor.
          </p>
          <p className="fw-normal">
            For the purposes of this Agreement, Data Protection Laws means (i) The
            Data Protection Act 2018 and Regulation 2016/679 of the European
            Parliament and of the Council on the protection of natural persons with
            regard to the Processing of Personal Data and on the free movement of such
            data (General Data Protection Regulation) (the "GDPR"); (ii) the EU
            e-Privacy Directive (Directive 2002/58/EC); and (iii) any and all
            applicable national data protection laws made under or pursuant to (i),
            (ii); in each case as may be amended or superseded from time to time; and
            "Personal Data", "Data Subject", "Data Controller", "Data Processor" and
            "Process" shall have the meaning given to them by the Data Protection
            Laws.
          </p>
          <p className="fw-bold">Security</p>
          <p className="fw-normal">
            Provide Digital will implement appropriate technical and organisational
            measures to protect your confidential information and data, including User
            Data as defined in clause 9 against unauthorised or unlawful processing
            and accidental loss or damage.
          </p>
          <p className="fw-bold">Limitation of liability</p>
          <p className="fw-normal">
            You agree to the following limitations on our liability to each other:
          </p>
          <ul>
            <li>
              exclusion of certain losses: the parties shall not be liable to each
              other for any loss of profits, business, anticipated savings, goodwill
              or business opportunity, business interruption, loss or corruption of
              data or information, or for any special, indirect or consequential loss
              or damage, howsoever arising under these Terms; and
            </li>
            <li>
              cap on liability: our maximum aggregate liability to each other in
              contract, tort (including negligence or breach of statutory duty),
              misrepresentation, restitution or otherwise, arising in connection with
              the performance or contemplated performance of these Terms shall be
              limited to £100.
            </li>
          </ul>
          <p className="fw-normal">
            Further, we do not take responsibility for any damage or loss caused by
            errors or omissions in any content included in the registration form. You
            are solely responsible for your health and well being and safety.
          </p>
          <p className="fw-normal">
            Nothing in these Terms shall exclude either party's liability for death or
            personal injury caused by negligence, fraud or fraudulent
            misrepresentation or any other liability that cannot be excluded or
            limited by law. All warranties, conditions, representations or other terms
            implied by statute or common law in relation to the Services, the Service
            data and the Platform provided by us are excluded to the fullest extent
            permitted by law.
          </p>
          <p className="fw-bold">Indemnity</p>
          <p className="fw-normal">
            Subject to the cap on liability in clause 11 above, you will defend,
            indemnify and hold harmless Provide Digital against claims, actions,
            proceedings, losses, damages, expenses and costs (including without
            limitation court costs and reasonable legal fees) arising out of or in
            connection with your use of the Services, Service data or Platform in
            breach of these Terms or other agreements referred to in these Terms.
          </p>
          <p className="fw-normal">
            Subject to the cap on liability in clause 11 above, Provide Digital will
            defend, indemnify and hold you harmless against claims, actions,
            proceedings, losses, damages and costs arising out of any claim made
            against you by a third party alleging infringement of their intellectual
            property rights through or in connection with your use of the Services,
            Service data or Platform as long as:
          </p>
          <ul>
            <li>you give us prompt notice of any claim;</li>
            <li>
              you provide us reasonable co-operation in the defence and settlement of
              such claim, at our expense; and
            </li>
            <li>you give us sole authority to defend or settle the claim.</li>
          </ul>
          <p className="fw-normal">
            In the defence or settlement of any such claim, we may procure the right
            for you to continue using the Services, Service data and/or the Platform,
            replace or modify the Services, Service data and/or the 6 Platform so that
            they become non-infringing or terminate this agreement without any
            additional liability or obligation to pay you damages or additional costs.
          </p>
          <p className="fw-normal">
            This clause 12 sets out your sole and exclusive rights and remedies, and
            our entire obligations and liability, for infringement of any third party
            intellectual property rights.
          </p>
          <p className="fw-bold">Termination and renewal</p>
          <p className="fw-normal">
            We may terminate these Terms with you immediately, without notice, in the
            event you commit a material or persistent breach of these Terms , the
            Services are discontinued, we lose the right to provide you with the
            Services, you no longer fall within the age range specified by the Client
            Organisation to be able to use the Services, or where the provision of the
            Services becomes unlawful. If you request access to copies of data
            supplied by you to the Platform, we will provide this to you in electronic
            form within 5 working days of your request.{" "}
          </p>
          <p className="fw-normal">Upon termination of these terms:</p>
          <ul>
            <li>
              all rights granted to you under these Terms, including the right of
              access in clause 7, shall cease;
            </li>
            <li>
              you must cease all activities authorised by these Terms, including use
              of the Services, Service data and Platform; and
            </li>
            <li>
              you must immediately uninstall, delete or remove from all computer
              equipment in your possession or control, and destroy or return to us all
              copies of, any software used in the provision of the Services.
            </li>
          </ul>
          <p className="fw-bold">Confidentiality</p>
          <p className="fw-normal">
            You and Provide Digital agree that each shall maintain the confidentiality
            of and shall not disclose to any third party all confidential documents
            and information of the other party, its respective vendors, licensors and
            other third parties, that such party may furnish (the “Disclosing Party”)
            to the other party or which the Disclosing Party makes available to the
            other party (the “Receiving Party”) and that the Disclosing Party treats
            as confidential, including without limitation, business information,
            customer and client lists, pricing of products or services, technology,
            agreements, business plans, software, technical documentation, and other
            information which is not publicly available.
          </p>
          <p className="fw-normal">
            The Receiving Party agrees to use all such information solely for the
            performance of its obligations under this Agreement. The parties'
            obligations under this provision shall survive termination of this
            Agreement, but such obligations shall not include information that is or
            becomes part of the public domain through no act or omission of the
            Receiving Party or breach by the Receiving Party of this Agreement.
          </p>
          <p className="fw-bold">Acceptable Use</p>
          <p className="fw-normal">
            Services provided by us may only be used for lawful purposes. You agree to
            comply with all applicable laws, rules, and regulations in connection with
            your use of the services. Any material or conduct that in our judgment
            violates this policy in any manner may result in suspension or termination
            of the services or removal of user's account with or without notice.
          </p>
          <p className="fw-bold">Prohibited use</p>
          <p className="fw-normal">
            You may not use the services to publish content or engage in activity that
            is illegal under applicable law, that is harmful to others, or that would
            subject us to liability, including, without limitation, in connection with
            any of the following, each of which is prohibited under this AUP:
          </p>
          <ul>
            <li>Phishing or engaging in identity theft</li>
            <li>
              Distributing computer viruses, worms, Trojan horses, or other malicious
              code
            </li>
            <li>
              Distributing pornography or adult related content or offering any escort
              services
            </li>
            <li>Promoting or facilitating violence or terrorist activities</li>
            <li>
              Infringing the intellectual property or other proprietary rights of
              others
            </li>
          </ul>
          <p className="fw-bold">Enforcement</p>
          <p className="fw-normal">
            Your services may be suspended or terminated with or without notice upon
            any violation of this policy. Any violations may result in the immediate
            suspension or termination of your account.
          </p>
          <p className="fw-bold">Reporting violations</p>
          <p className="fw-normal">
            To report a violation of this policy, please contact us at
            info@providedigital.com
          </p>
          <p className="fw-bold">Other important terms</p>
          <p className="fw-normal">
            <strong> Assignment and other dealings:</strong> You may not assign,
            transfer, sub-licence or deal in any other manner with any or all of your
            rights under these Terms, without our prior written consent.
            <strong> Waiver</strong>: A waiver of any right or remedy under these Terms
            or by law is only effective if given in writing and shall not be deemed a
            waiver of any subsequent breach or default. A failure or delay by a party
            to exercise any right or remedy provided under these Terms or by law shall
            not constitute a waiver of that or any other right or remedy, nor shall it
            prevent or restrict any further exercise of that or any other right or
            remedy.
            <strong> Severance:</strong> If any provision or part-provision of these
            Terms is or becomes invalid, illegal or unenforceable, it shall be deemed
            modified to the minimum extent necessary to make it valid, legal and
            enforceable. If such modification is not possible, the relevant provision
            or part-provision shall be deemed deleted. Any such modification to or
            deletion of a provision or part-provision shall not affect the validity
            and enforceability of the rest of these Terms.
            <strong> Relationship:</strong> Nothing in these Terms is intended to, or
            shall be deemed to, establish any partnership or joint venture between any
            of the parties or constitute any party the agent of another party.
            <strong> Third Party Rights:</strong> No one other than a party to these
            Terms, their successors and permitted assignees, shall have any right to
            enforce any of its terms. <strong> Entire Agreement:</strong> These Terms and all documents referred
            to in them, constitute the entire agreement between the parties and
            supersedes and extinguishes all previous agreements, promises, assurances,
            warranties, representations and understandings between them, whether
            written or oral, relating to its subject matter.
            <strong> Jurisdiction/Governing Law:</strong> These Terms, their subject
            matter and its formation (and any non-contractual disputes or claims) are
            governed by English law. We both agree to the exclusive jurisdiction of
            the courts of England and Wales.
          </p>
          <p className="fw-bold">Contact us</p>
          <p className="fw-normal">
            To contact us, or if you are experiencing problems with the Services,
            please email info@providedigital.com.
          </p>
          <p className="fw-bold text-end">March 2024</p>
        </div>
        <style jsx>{`
      th {
    display: table-cell;
    vertical-align: inherit;
    font-weight: bold;
    text-align: -internal-center;
    unicode-bidi: isolate;
    text-align:center;
        border: 2px solid;
}
    .th, td {
        border-style: solid;
        min-width: 300px;
        text-align: center;
    }
    
`}</style>
      </div>
      

    )

}

export default TermsAndCondition