import { createStore, combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import commonReducer from "../reducers/commonReducer";
import storage from "redux-persist/lib/storage";
import userReducer from '../reducers/userReducer'

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["common", "user"],
};

let reducer = combineReducers({
  common: commonReducer,
  user: userReducer,
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const appStore = createStore(persistedReducer);
export const persistor = persistStore(appStore);
