import React from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

const ConfirmModal = (props) => {
  return (
    <Modal
      showCloseIcon={false}
      classNames={{
        modal: "modal-own",
        root: "modal-own-wrapper confirm-modal",
      }}
      open={props.open}
      onClose={() => props.onCloseModal()}
      center
      closeOnOverlayClick={true}
    >
      <div className="video-player-wrapper px-2 text-center">
        {props.description && (
          <h3 className="font-20 primary-color py-3">{props.description}</h3>
        )}
        {props?.data &&
          <>
            <div className="pb-1"><span>Available Stock :</span> {props?.data}</div>
            <div className="pb-1"><span>{props.btnActive === 'minus' ?'Remove':'Add'} Stock :</span> {props?.currentStock}</div>
            <div className="pb-3"><span>Total Stock :</span> {props.btnActive === 'minus' ? Number(props?.data) - Number(props?.currentStock) : Number(props?.data) + Number(props?.currentStock)}</div>
          </>
        }
        <div className="pb-3 btn-wrapper pt-3">
          <button
            type="button"
            onClick={() => props.onCloseModal()}
            className="btn secondary-btn mb-3 mb-md-0 full-width-xs-mb btn-own btn-own-grey min-height-btn min-width-btn-md mr-md-3"
          >
            NO
          </button>
          <button
            type="submit"
            onClick={() => props.onCloseModal(true)}
            className="btn primary-btn full-width-xs btn-own btn-own-primary min-height-btn min-width-btn-md"
          >
            YES
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
