import React from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

const RegSuccessModal = (props) => {
  return (
    <Modal
      center
      open={props?.open}
      closeOnOverlayClick={false}
      closeOnEsc={false}
      showCloseIcon={false}
      classNames={{
        modal: "success-modal modal-own",
        root: "modal-own-wrapper",
      }}
    >
      <div className="settings-wrapper mx-auto">
        <div className="stripe border-bottom heading-wrapper">
          <div className="d-flex justify-content-center">
            <img
              alt="..."
              src={require("../../wallpaper/regSuccess.png").default}
            />
          </div>
          <p className="h4 font-weight-bold text-center terms-head">
            Well Done! Registration Successfull
          </p>
        </div>
        <div className="mx-lg-3 text-justify p-3 terms-text">
          <p className="font-weight-normal">
            {props?.message}
          </p>
        </div>
        <div className="stripe pt-3 border-top">
          <div className="col-md-12 text-center">
            <button
              className="btnFooter footer-width py-2 px-2"
              onClick={() => props?.closeModal()}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RegSuccessModal;
