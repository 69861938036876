import React from "react";
import { Switch, Route,Redirect } from "react-router-dom";
import Home from "./home";
import SuperAdminRouter from "./superadmin/superAdminRouter";
import AdminRouter from "./admin/adminRouter";
import UserRouter from "./user/userRouter";
import PrivacyPolicy from "./staticPages/privacePolicy";
import TermsAndCondition from "./staticPages/termsAndCondition";

const Router = () => {
  return (
    <Switch>
     
      <Route path="/superadmin" component={SuperAdminRouter} />
      <Route path="/admin" component={AdminRouter} />
      <Route path="/user" component={UserRouter} />
      <Route path="/privacy-policy" component={PrivacyPolicy}/>  
      <Route path="/privacy-notice" component={PrivacyPolicy}/> 
      <Route path="/terms-and-conditions" component={TermsAndCondition}/> 
   

      <Route exact path="/">
      <Redirect to="/user/login"/>
        </Route>
      <Route path="/">
     


      <Redirect to="/user/login"/>
        </Route>
    </Switch>
  );
};

export default Router;
