import React, { useState } from "react";
import { Button, Popover, MenuItem, Typography } from '@mui/material';


const Dropdown = ({ list }) => {



  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleMenuItemClick = (onClick) => {
    console.log('onClick', onClick)
    onClick()
    handleClose();
  };

  return (

    <div>

      <Button aria-describedby="simple-popover" className="dropdown" onClick={handleClick}>
        <div>
          <span className='triple-dot ' />
          <span className='triple-dot' />
          <span className='triple-dot' />
        </div>
      </Button>
      <Popover
        id="simple-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Typography sx={{ p: 2 }}>
          {list?.length > 0 && list.map((ele, index) => (
            <MenuItem key={index} onClick={()=>handleMenuItemClick(ele.onClick)}>
              {ele.text}
            </MenuItem>
          ))}
        </Typography>
      </Popover>
    </div>
  )


}

export default Dropdown