import React, { useState, useEffect } from "react";
import Header from "../template/_common/header";
import SidebarComponent from "../template/admin/sidebarComponent";
import NotificationTable from "../template/admin/notificationTable";
import SidebarArrow from "../template/_common/sidebarArrow";
import { globalAlert, globalLoader } from "../actions/commonActions";
import NotificationModal from "../template/admin/notificationModal";
import {
    getNotificationTemplatesList,
    getNotificationReportsList,
    addTemplateApi,
    sendNotificationApi,
    deleteTemplateApi,
    deleteNotificationApi
} from "./_actions";
import ConfirmationModal from "../template/_common/confirmModal";
import PaginationOwn from "../template/_common/ownPagination";
import { compareValues, logOut } from "../_shared/commonFunction";
import { Helmet } from "react-helmet";
import ViewNotificationModal from "../template/admin/viewNotificationModal";
import VideoPlayerModal from "../template/_common/videoPlayerModal";
import arraySort from 'array-sort';

const NotificationManagement = (props) => {
    const [navbarToggle, setNavbarToggle] = useState(false);
    const [dataList, setDataList] = useState([]);
    const [currentData, setCurrentData] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [editDataIs, setEditDataIs] = useState(false);
    const [currentViewData, setCurrentViewData] = useState(null);
    const [currentUserId, setCurrentUserId] = useState(null);
    const [confirmaModalOpen, setConfirmaModalOpen] = useState(false);
    const [pageSize, setPageSize] = useState(25);
    const [searchVal, setSearchVal] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [currentDataList, setCurrentDataList] = useState([]);
    const [sortObj, setSortObj] = useState({});
    const [currentDataSorted, setCurrentDataSorted] = useState([]);
    const [notFilteredRun, setNotFilteredRun] = useState(false);
    const [totalCount, setTotalCount] = useState(false);
    const [allowView, setAllowView] = useState(false);
    const [deleteInfo, setDeleteInfo] = useState(null);
    const [type, setType] = useState("notifications");
    const [videoModalOpen, setVideoModalOpen] = useState(false);
    const [modalHeading, setModalHeading] = useState("");
    const [viewNotification, setViewNotification] = useState(false);
    const [deleteDesc, setDeleteDesc] = useState('')

    const handleSidebar = (data) => {
        setNavbarToggle(data);
    };

    useEffect(() => {
        callBasicApi(); // eslint-disable-next-line
    }, []);
    useEffect(() => {
        callBasicApi(); // eslint-disable-next-line
    }, [type]);

    const callBasicApi = async () => {
        try {
            globalLoader(true);
            let res;

            if (type === "template") {
                res = await getNotificationTemplatesList();
            } else {
                res = await getNotificationReportsList();
            }

            if (res?.data?.status === 200) {
                setAllowView(true);
                setDataList(res?.data?.data?.storesList);
                let currData =
                    type === "template"
                        ? res?.data?.data?.templateRecords
                        : res?.data?.data?.records;
                setDataList(currData);
                setCurrentDataSorted(
                    type === "template"
                        ? res?.data?.data?.templateRecords
                        : res?.data?.data?.records
                );
                !searchVal &&
                    setTotalCount(
                        type === "template"
                            ? res?.data?.data?.templateRecords?.length
                            : res?.data?.data?.records?.length
                    );
            } else {
                globalAlert("error", res?.data?.message);
            }
            globalLoader(false);
        } catch (error) {
            globalAlert("error", error?.response?.data?.message);

            if (error?.response?.data?.status === 401) {
                logOut(props?.history, "/admin/login");
            }
            globalLoader(false);
        }
    };

    const confirmModalFunc = async (val) => {
        try {
            if (val) {
                globalLoader(true);

                let res;

                if (type === "notifications") {
                    res = await deleteNotificationApi(currentUserId)
                }
                else {
                    res = await deleteTemplateApi(currentUserId);
                }

                if (res?.data?.status === 200) {
                    setEditDataIs(false);
                    setCurrentData(null);
                    setNotFilteredRun(false);
                    if (totalCount === 1) setSearchVal("");
                    callBasicApi();
                } else {
                    globalAlert("error", res?.data?.message);
                    globalLoader(false);
                }
            }
            setConfirmaModalOpen(false);
        }
        catch (err) {
            globalAlert("error", err?.response?.data?.message);
            globalLoader(false);
        }
    };

    const deleteData = (data) => {
        let obj;
        if (type === 'notifications') {
            obj = [`Title : ${data?.title}`];
            setCurrentUserId(data?.notificationReportId);
            setDeleteDesc('Do you want to delete this notification?')


        }
        else {
            obj = [`Title : ${data?.title}`, `Name :${data?.name}`];
            setCurrentUserId(data?.notificationTemplateId);
            setDeleteDesc('Do you want to delete this template?')



        }

        setDeleteInfo(obj);
        setConfirmaModalOpen(true);
    };

    useEffect(() => {
        if (!notFilteredRun) {
            filteredDataFunc();
        } // eslint-disable-next-line
    }, [pageSize, currentPage, sortObj, currentDataSorted, dataList]);

    const filteredDataFunc = (dataArray = null, localPage = null) => {
        if (dataList?.length > 0) {
            let arr = [...dataList];
            let filterArr = [];
            let filteredData = [];
            if (dataArray) {
                arr = [...dataArray]
            }
            else {
                arr = arraySort(arr, compareValues(sortObj?.sortVal), { reverse: sortObj?.sortType === 'desc' ? true : false });
            }
            if (searchVal.length > 0) {
                filterArr = arr.filter((data) => {
                    if (
                        data?.storeName.toLowerCase().includes(searchVal.toLowerCase()) ||
                        data?.address.toLowerCase().includes(searchVal.toLowerCase())
                    ) {
                        return data;
                    } else {
                        return null;
                    }
                });
            } else {
                filterArr = arr;
            }
            if (filterArr && filterArr.length > 0) {
                setTotalCount(filterArr.length);
                for (
                    let i = (localPage || currentPage) * pageSize - pageSize;
                    i < (localPage || currentPage) * pageSize && i < filterArr.length;
                    i++
                ) {
                    filteredData.push(filterArr[i]);
                }
            }

            setCurrentDataList(filteredData);
        } else {
            setCurrentDataList([]);
        }
    };

    const changePageSizeFunc = async (val) => {
        setNotFilteredRun(false);
        setPageSize(val.target.value);
        setCurrentPage(1);
        setSortObj(null);
    };

    const goToPageFunc = (val, data) => {
        setNotFilteredRun(false);
        if (data === "prev" && currentPage > 1) {
            setCurrentPage(currentPage - 1);
        } else if (
            data === "next" &&
            currentPage < Math.ceil(dataList?.length / pageSize)
        ) {
            setCurrentPage(currentPage + 1);
        } else {
            setCurrentPage(val?.target?.value);
        }
    };

    const arraySorting = (val) => {
        let arr = [...dataList];
        let sortedArr;
        let sortType = "aesc";
        if (val === sortObj?.sortVal && sortObj?.sortType === "aesc") {
            sortType = "desc";
        }
        sortedArr = arraySort(arr, compareValues(val), { reverse: sortType === 'desc' ? true : false })

        setNotFilteredRun(true);

        setCurrentPage(1);
        setSortObj({
            sortVal: val,
            sortType: sortType,
        });
        filteredDataFunc(sortedArr, 1);

    };

    const openAddTemplateModal = () => {
        setModalHeading("Add Template");
        setOpenModal(true);
    };

    const sendNotification = (data = null) => {

        setModalHeading("Create Notification");
        setEditDataIs(true);
        if (data) {
            setCurrentData(data);
            setModalHeading("View Template");
        }
        setOpenModal(true);
    };

    const closeNotificationModal = async (val = null) => {
        if (val) {
            try {
                globalLoader(true);
                let res;

                if (editDataIs) {
                    res = await sendNotificationApi(val);
                } else {
                    res = await addTemplateApi(val);
                }

                if (res?.data?.status === 200) {
                    globalAlert("success", res?.data?.message);
                    if (!editDataIs) {
                        setSearchVal("");
                    }
                    setModalHeading("");
                    setNotFilteredRun(false);
                    setEditDataIs(false);
                    setCurrentData(null);
                    setOpenModal(false);
                    callBasicApi();
                } else {
                    globalAlert("error", res?.data?.message);
                    globalLoader(false);
                }
            } catch (err) {
                globalLoader(false);
            }
        } else {
            setModalHeading("");
            setEditDataIs(false);
            setCurrentData(null);
            setOpenModal(false);
        }
    };

    const viewNotificationOpen = (obj) => {
        setViewNotification(true);
        setCurrentData(obj);
    };

    const viewNotificationClose = () => {
        setViewNotification(false);
        setCurrentData(null);
    };

    return (
        <>
            <Helmet>
                <meta name="viewport" content="width=1200, initial-scale=1" />
            </Helmet>
            {allowView && (
                <div className="admin-portal">
                    <Header roleType="admin" />
                    <div className={`${navbarToggle ? "active" : ""} wrapper`}>
                        <SidebarComponent
                            navbarToggle={navbarToggle}
                            active={props.match.path}
                        />
                        <div className="main">
                            <div className="main-wrapper">
                                <div>
                                    <p className="admin-banner">Notification Management</p>
                                </div>

                                {/* <div className="tab-own-wrapper text-center pt-1">
                                    <ul className="tab-wrapper-list d-inline-flex flex-wrap list-unstyled">
                                        <li
                                            className={`${type === "notifications" ? "active" : ""}`}
                                            onClick={() => setType("notifications")}
                                        >
                                            Notifications
                                        </li>

                                        <li
                                            className={`${type === "template" ? "active" : ""}`}
                                            onClick={() => setType("template")}
                                        >
                                            Templates
                                        </li>
                                    </ul>
                                </div> */}
                                <SidebarArrow handleSidebarToggle={handleSidebar} />

                                <div>
                                    <div className="container-fluid">
                                        <div className="row admin-controls">
                                            <div className="text-right px-3 ml-auto"></div>

                                            <>
                                                {/* <form
                                                        onSubmit={searchFilter}
                                                        className="col-12 col-lg-6 admin-search"
                                                    >
                                                        <div className="search-wrapper">
                                                            <InputComponent
                                                                placeholder="Search"
                                                                label="Search Template"
                                                                type="text"
                                                                name="search"
                                                                theme="admin"
                                                                value={searchVal}
                                                                handleCallback={(val) => {
                                                                    setSearchVal(val);
                                                                }}
                                                            />
                                                        </div>
                                                        <button type="submit" className="btn secondary-btn">
                                                            Go
                                                        </button>
                                                    </form> */}
                                                <div className="admin-add-new ml-auto px-3 d-flex justify-content-start justify-content-lg-end">
                                                    {type === "template" ? (
                                                        <button
                                                            onClick={() => openAddTemplateModal()}
                                                            className="btn secondary-btn modal-btn"
                                                        >
                                                            <i className="fas fa-plus"></i> Add Template
                                                        </button>
                                                    ) : (
                                                        <button
                                                            onClick={() => sendNotification()}
                                                            className="btn secondary-btn modal-btn"
                                                        >
                                                            {/* <i className="fas fa-paper-plane"></i> Send */}
                                                            Create Notification
                                                        </button>
                                                    )}
                                                </div>
                                            </>
                                            {dataList?.length > 0 && (
                                                <div
                                                    className="px-3 col-12 pt-3"
                                                    style={{ borderTop: "2px solid #ebeef1" }}
                                                >
                                                    <PaginationOwn
                                                        pageSize={pageSize}
                                                        changePageSize={changePageSizeFunc}
                                                        goToPage={goToPageFunc}
                                                        totalCount={totalCount}
                                                        currentPage={currentPage}
                                                        applicationType={type}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {currentDataList?.length > 0 ? (
                                    <NotificationTable
                                        dataList={currentDataList}
                                        arraySorting={arraySorting}
                                        basicApiCall={callBasicApi}
                                        sortObj={sortObj}
                                        deleteData={deleteData}
                                        type={type}
                                        sendNotification={sendNotification}
                                        viewNotification={viewNotificationOpen}
                                    />
                                ) : (
                                    <div className="px-3 pt-3">
                                        <div className="no-data-available">No data available</div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {openModal && (
                <NotificationModal
                    open={openModal}
                    closeModal={closeNotificationModal}
                    data={currentData}
                    edit={editDataIs}
                    type={type}
                    heading={modalHeading}
                />
            )}

            {confirmaModalOpen && (
                <ConfirmationModal
                    open={confirmaModalOpen}
                    deleteInfo={deleteInfo}
                    description={deleteDesc}
                    onCloseModal={confirmModalFunc}
                />
            )}

            {videoModalOpen && currentViewData && (
                <VideoPlayerModal
                    open={videoModalOpen}
                    closeModal={() => {
                        setVideoModalOpen(false);
                        setCurrentViewData(null);
                    }}
                    src={currentViewData?.videoUrl}
                    title={currentViewData?.videoTitle}
                />
            )}
            {viewNotification && (
                <ViewNotificationModal
                    open={viewNotification}
                    closeModal={viewNotificationClose}
                    data={currentData}
                />
            )}
        </>
    );
};

export default NotificationManagement;
