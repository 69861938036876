import React, { useEffect } from "react";
import Router from "./router";
import { withRouter } from "react-router-dom";
import Loader from "./_shared/loader";
import AlertComponent from "./_shared/alert";
import { commonAppApi } from "./actions/commonActions";

let myInterval;

const App = () => {

  useEffect(() => {
  
    callBasicApi();
  
  }, []);

  const callBasicApi = async () => {
    await commonAppApi();
  };

  const checkClickEvent = () => {
    clearTimeout(myInterval);   
  };

  return (
    <React.Fragment>
      <Loader />
      <AlertComponent />
      <div onClick={checkClickEvent}>
        <Router />
      </div>
    </React.Fragment>
  );
};

export default withRouter(App);
