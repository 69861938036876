import React, { useState, useEffect } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { globalLoader } from "../../actions/commonActions";
import { validEmail } from "../../_shared/commonFunction";
import InputComponent from "../_common/inputComponent";

const AddAccessCodeModal = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [designation, setDesigantion] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [nameErr, setNameErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [descriptionErr, setDescriptionErr] = useState("");
  const [invalidEmail, setInvalidEmail] = useState(false);

  useEffect(() => {
    setPropsToState(); // eslint-disable-next-line
  }, [props?.data]);

  const setPropsToState = () => {
    setName(props?.data?.docName);
    setEmail(props?.data?.docEmail);
    setDesigantion(props?.data?.docDesignation);
    setIsActive(props?.data?.isActivated);
  };

  const handleValidation = () => {
    let flag = true;
    if (!name) {
      setNameErr(true);
      flag = false;
    }
    if (!email) {
      setEmailErr(true);
      flag = false;
    }
    if (!designation) {
      setDescriptionErr("Designation is empty");
      flag = false;
    }
    if (invalidEmail) {
      flag = false;
    }
    return flag;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (await handleValidation()) {
      globalLoader(true);
      let obj = {
        docName: name,
        docEmail: email,
        docDesignation: designation,
        isActivated: isActive,
      };
      props.closeModal(obj);
    }
  };

  const validateMail = async (ev) => {
    
    let value = ev;
    let valid = await validEmail(value);
    if (valid) {
      setInvalidEmail(false);
    } else {
      setInvalidEmail(true);
    }
    setEmail(value);
  };

  return (
    <Modal
      open={props.open}
      onClose={() => props.closeModal()}
      center
      classNames={{ modal: "admin-modal" }}
    >
      <div>
        <p className="admin-banner">
          {props?.edit ? "Edit" : "Add"} Access Code
        </p>
      </div>
      <form onSubmit={handleSubmit} className="form-own px-3">
        <div className="row">
          <div className="col-6">
            <div className="form-own-group">
              {/* <label htmlFor="name" className="form-own-label">
                Name <span className="color-red">*</span>
              </label>
              <input
                id="name"
                name="name"
                placeholder="Name"
                className="form-control"
                onChange={(evt) => {
                  setName(evt.target.value);
                }}
                value={name}
              /> */}
              <InputComponent
                placeholder="Name"
                label={
                  <>
                    Name <span className="color-red">*</span>
                  </>
                }
                type="text"
                name="name"
                theme="white"
                value={name}
                handleCallback={(val) => {
                  setName(val);
                }}
              />
              <div className="error-text">
                {!name && nameErr && <span>Name is required</span>}
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="form-own-group">
              {/* <label htmlFor="email" className="form-own-label">
                Email <span className="color-red">*</span>
              </label>
              <input
                id="email"
                name="email"
                placeholder="Email"
                className="form-control"
                onChange={(evt) => {
                  validateMail(evt);
                }}
                value={email}
                disabled={props?.data}
              /> */}
              <InputComponent
                placeholder="Email"
                label={
                  <>
                    Email <span className="color-red">*</span>
                  </>
                }
                type="text"
                name="email"
                theme="white"
                value={email}
                disabled={props?.data}
                handleCallback={(val) => {
                  validateMail(val);
                }}
              />
              <div className="error-text">
                {!email && emailErr && <span>Email is required</span>}
                {email && invalidEmail && <span>Email is invalid</span>}
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="form-own-group">
              {/* <label htmlFor="Designation" className="form-own-label">
                Description <span className="color-red">*</span>
              </label>
              <input
                id="Designation"
                name="Designation"
                placeholder="Description"
                className="form-control"
                onChange={(evt) => {
                  setDesigantion(evt.target.value);
                }}
                value={designation}
              /> */}
              <InputComponent
                placeholder="Designation"
                label={
                  <>
                    Designation <span className="color-red">*</span>
                  </>
                }
                type="text"
                name="designation"
                theme="white"
                value={designation}
                handleCallback={(val) => {
                  setDesigantion(val);
                }}
              />
              <div className="error-text">
                {!designation && descriptionErr && (
                  <span>Description is required</span>
                )}
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="form-own-group" style={{marginTop:'-5px'}}>
              <label htmlFor="" className="form-own-label mb-0">
                Active
              </label>
              {/* <input
                type="checkbox"
                id="isActive"
                name="isActive"
                onChange={(ev) => setIsActive(ev.target.checked)}
                checked={isActive}
              /> */}

              <input
                className="toggle-input"
                type="checkbox"
                id="switch"
                onChange={(ev) => {
                  setIsActive(ev.target.checked);
                }}
                checked={isActive}
              />
              <label className="toggle-label" htmlFor="switch">
                Toggle
              </label>
            </div>
          </div>
        </div>
        <div className="admin-btn">
          <button
            onClick={() => props.closeModal()}
            className="btn secondary-btn mr-md-2 mb-md-0 mb-3"
            type="button"
          >
            Back
          </button>
          <button type="submit" className="btn primary-btn ml-md-2">
            Submit
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default AddAccessCodeModal;
