import React, { useState, useEffect } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { Link } from "react-router-dom";
import {
  Player,
  ControlBar,
  CurrentTimeDisplay,
  TimeDivider,
  PlaybackRateMenuButton,
  BigPlayButton,
} from "video-react";
import ProgressBar from "../_common/progressBar";

const VideoModal = (props) => {
  const [videoSrc, setVideoSrc] = useState(null);
  const [videoCount, setVideoCount] = useState(null);
  const [videoId, setVideoId] = useState(null);

  useEffect(() => {
    setPropsToState(); // eslint-disable-next-line
  }, [props?.data]);
  const setPropsToState = () => {
    setVideoSrc(props?.data?.videoPath);
    setVideoCount(props?.data?.count);
    setVideoId(props?.data?.videoId);
  };
  const handleClick = () => {
    props.openNextVideo(videoId);
  };

  return (
    <>
      <Modal
        open={props.open}
        onClose={() => {
          props.closeModal();
        }}
        center
        classNames={{ modal: "modal-own", root: "modal-own-wrapper black-cross-btn-modal" }}
        closeOnEsc={false}
        closeOnOverlayClick={true}
      >
        <div className="row my-auto video-wrapper user-video">
          <p className="text-center col-12 video-name">
            {props?.data?.videoTitle}
          </p>
          <div className="d-flex justify-content-center col-12 ">
            <Player
              src={videoSrc}
              onPlay={() => props?.videoEnded(props?.data?.videoId)}
            >
              <source src={videoSrc} />

              <ControlBar>
                <CurrentTimeDisplay order={4.1} />
                <TimeDivider order={4.2} />
                <PlaybackRateMenuButton
                  rates={[1.5, 1, 0.5, 0.1]}
                  order={7.1}
                />
              </ControlBar>
              <BigPlayButton position="center" />
            </Player>
          </div>
          <div className="col-12 video-text">
            <p className="px-3 text-color-primary">
              Introduction Video {videoCount} of {props?.totalVideo}
            </p>
          </div>
          <div className="col-12">
            <ProgressBar count={props?.totalVideo} currentVal={videoCount} />
          </div>
          <div className="d-flex justify-content-center col-12 user-modal-btn video-modal-btn">
            {props?.allWatched ? (
              <Link to={props?.nextPageLink}>
                <button className="btnFooter  footer-width">
                  {props?.nextPageText}
                </button>
              </Link>
            ) : (
              <button
                className={`btnFooter  ${
                  props?.nextVideo ? "footer-width" : ""
                }`}
                onClick={() => {
                  props?.nextVideo ? handleClick() : console.log("Last Video");
                }}
              >
                {props?.nextVideo
                  ? `Continue to Video ${props?.nextVideo}`
                  : `Watch video then ${props?.nextPageText?.toLowerCase()}`}
              </button>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default VideoModal;
