/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Header from "../template/_common/header";
import SidebarComponent from "../template/admin/sidebarComponent";
import SidebarArrow from "../template/_common/sidebarArrow";
import StocksTable from "../template/admin/stocksTable";
import ManageStockModal from "../template/admin/manageStockModal";
import { logOut, compareValues } from "../_shared/commonFunction";
import { globalAlert, globalLoader } from "../actions/commonActions";
import AddNotesModal from "../template/admin/addNotesModal";
import ConfirmModal from "../template/admin/confirmModal";
import { viewStoreApi, addStockApi, getSKUAddRemoveApi, getSKUStoreListApi, getSKUStockToggleApi, getSKUStockUpdateApi } from "./_actions";
import InputComponent from "../template/_common/inputComponent";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import AddStockModal from "../template/admin/addStockModal";
import EditStockModal from "../template/admin/editStockModal";
import ConfirmationModal from "../template/_common/confirmModal";
import arraySort from 'array-sort';


const ManageStocks = (props) => {
  const [navbarToggle, setNavbarToggle] = useState(false);
  const [quantity, setQuantity] = useState(null);

  const [currentViewData, setCurrentViewData] = useState(null);

  const [openStockModal, setOpenStockModal] = useState(false);

  const [currentDataList, setCurrentDataList] = useState(null);
  const [storeDetails, setStoreDetails] = useState(null);
  const [openAddNotesModal, setOpenAddNotesModal] = useState(false);
  const [updateActive, setUpdateActive] = useState(false);
  const [confirmaModalOpen, setConfirmaModalOpen] = useState(false);
  const [notes, setNotes] = useState("");
  const [openAddStockModal, setOpenAddStockModal] = useState(false)
  const [btnActive, setBtnActive] = useState('');
  const [allowView, setAllowView] = useState(false);
  const [storeId, setStoreId] = useState(null);
  const [deleteInfo, setDeleteInfo] = useState(null);
  const [toggleInfo, setToggleInfo] = useState(null);
  const [currentData, setCurrentData] = useState(null);
  const [sortObj, setSortObj] = useState({});
  const [dataList, setDataList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalCount, setTotalCount] = useState(false);
  const [openEditModal, setOpenEditModal]=useState(false)


  useEffect(() => {

    filteredDataFunc();

  }, [pageSize, currentPage, sortObj, dataList]);


  useEffect(() => {
    callBasicApi();
  }, []);

  const callBasicApi = async () => {
    const { id } = props?.match?.params;
    setStoreId(id)

    if (id) {
      try {
        globalLoader(true);
        const res = await getSKUStoreListApi(id);
        if (res?.data?.status === 200) {
          setDataList(res?.data?.data?.list);
          setStoreDetails(res?.data?.data?.venue);
          setAllowView(true)
          globalLoader(false);

        }

        else {
          globalAlert("error", res?.data?.message);
          props.history.push('/admin/manage-stores')
        }
      }
      catch (error) {
        globalAlert("error", error?.response?.data?.message);

        if (error?.response?.data?.status === 401) {
          logOut(props?.history, "/admin/login");
        }
        globalLoader(false);
      }

    }
  };

  const manageStockData = (obj) => {
    setCurrentViewData(obj);
    setOpenStockModal(true);
  };

  const closeStockModal = (obj = null) => {
    setCurrentViewData(null);
    setOpenStockModal(false);
    if (obj) {
      setUpdateActive(true);
      let data = currentDataList;
      data["stockQuantity"] = obj?.stockQuantity;
      setCurrentDataList(data);
    }
    globalLoader(false);
  };

  const closeAddStockModal = async (obj = null) => {
    if (obj) {
      try {
        globalLoader(true);
        let res = await getSKUAddRemoveApi('add', storeId, obj?.orgProductId);
        if (res?.data?.status === 200) {
          globalAlert('success', res?.data?.message);
          setOpenAddStockModal(false)
          callBasicApi();
        } else {
          globalAlert("error", res?.data?.message);
          globalLoader(false);
        }
      } catch (error) {
        globalAlert("error", error?.response?.data?.message);

        if (error?.response?.data?.status === 401) {
          logOut(props?.history, "/admin/login");
        }
        globalLoader(false);
      }

    }
    else {
      setOpenAddStockModal(false)
    }
  };

  const closeEditStockModal = async (obj = null) => {
    if (obj) {
      try {
        globalLoader(true);
        let res = await getSKUStockUpdateApi(storeId, currentData?.orgProductId, obj?.quantity);
        if (res?.data?.status === 200) {
          globalAlert('success', res?.data?.message);
          setOpenEditModal(false)
          callBasicApi();
        } else {
          globalAlert("error", res?.data?.message);
          globalLoader(false);
        }
      } catch (error) {
        globalAlert("error", error?.response?.data?.message);

        if (error?.response?.data?.status === 401) {
          logOut(props?.history, "/admin/login");
        }
        globalLoader(false);
      }

    }
    else {
      setOpenEditModal(false)
    }
  };

  const addNotes = (obj) => {
    setCurrentViewData(obj);
    setOpenAddNotesModal(true);
  };

  const closeAddNotes = (obj = null) => {
    setCurrentViewData(null);
    setOpenAddNotesModal(false);
    if (obj) {
      setUpdateActive(true);
    }
  };

  const openConfirmModal = () => {
    setConfirmaModalOpen(true);
  };

  const confirmModalFunc = async (val) => {
    if (val) {
      globalLoader(true);

      let res;

      if (toggleInfo) {
        res = await getSKUStockToggleApi(storeId, currentData?.orgProductId);
      }
      else {
        res = await getSKUAddRemoveApi('remove', storeId, currentData?.orgProductId);
      }

      if (res?.data?.status === 200) {
        setCurrentData(null);
        setToggleInfo(null);
        setDeleteInfo(null)
        callBasicApi();
      } else {
        globalAlert("error", res?.data?.message);
        globalLoader(false);
      }
    }
    setConfirmaModalOpen(false);
    setDeleteInfo(null);
    setToggleInfo(null);
  };


  const handleSidebar = (data) => {
    setNavbarToggle(data);
  };


  const arraySorting = (val) => {
    let arr = [...dataList];
    let sortedArr;
    let sortType = "aesc";
    if (val === sortObj?.sortVal && sortObj?.sortType === "aesc") {
      sortType = "desc";
    }
    sortedArr = arraySort(arr, compareValues(val), { reverse: sortType === 'desc' ? true : false });

    setCurrentPage(1);
    setSortObj({
      sortVal: val,
      sortType: sortType,
    });
    filteredDataFunc(sortedArr, 1);
  };

  const filteredDataFunc = (dataArray = null, localPage = null) => {
    let arr = [...dataList];
    let filterArr = [];
    let filteredData = [];

    if (dataArray) {
      arr = [...dataArray]
    }
    else {
      arr = arraySort(arr, compareValues(sortObj?.sortVal), { reverse: sortObj?.sortType === 'desc' ? true : false });
    }

    filterArr = arr;




    if (filterArr && filterArr.length > 0) {
      setTotalCount(filterArr.length);
      for (
        let i = (localPage || currentPage) * pageSize - pageSize;
        i < (localPage || currentPage) * pageSize && i < filterArr.length;
        i++
      ) {
        filteredData.push(filterArr[i]);
      }
    }

    setCurrentDataList(filteredData);
  };

  const editData = (data) => {
    // setEditDataIs(true);
    setCurrentData(data);
    setOpenEditModal(true)
  };

  const deleteData = (data) => {

    // setCurrentUserId(data?.orgProductId);
    let obj = [`Product Name : ${data?.name}`, `Description : ${data?.description}`];
    setDeleteInfo(obj);
    setCurrentData(data);

    setConfirmaModalOpen(true);
  };

  const toggleData = (data) => {

    // setCurrentUserId(data?.orgProductId);
    let obj = [`Product Name : ${data?.name}`, `Description : ${data?.description}`];
    setToggleInfo(obj);
    setCurrentData(data)
    setConfirmaModalOpen(true);
  };
  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=1200, initial-scale=1" />
      </Helmet>
      {allowView && (<div className="admin-portal-new">
        <Header roleType="admin" />
        <div className={`${navbarToggle ? "active" : ""} wrapper`}>
          <SidebarComponent
            navbarToggle={navbarToggle}
            // active={'/admin/manage-stores'}
            menu="manageVenue"
            subMenu='manage-stores'
          />
          <div className="main">


            <div className="main-wrapper-new">
              <div>
                <p className="breadcum-wrapper admin-banner-new">
                  <span className="path"> <Link to={"/admin/manage-stores"}>Stock</Link></span>
                  <span className="active">Manage Stocks</span></p>
              </div>
              <SidebarArrow handleSidebarToggle={handleSidebar} />
              <div>

                <div className="container-fluid pt-1">
                  <div className="admin-add-new mt-lg-0">
                    <div className="pr-2"></div>
                    <div className="row px-2">
                      <div className="col-12 px-2">
                      </div>
                      <div className="col-6 px-2">
                        <div>
                          <span className="primary-color weight-600">
                            Store Name:
                          </span>
                        </div>
                        <div>
                          {storeDetails?.name}
                        </div>
                      </div>

                      <div className="col-6 px-2">
                        <div>
                          <span className="primary-color weight-600">
                            Address:
                          </span>
                        </div>
                        <div>
                          {storeDetails?.address}
                        </div>
                      </div>

                      {/* <div className="col-12 ">
                        <div className="admin-add-new mt-lg-0 d-flex">
                          <button className="btn secondary-btn modal-btn">
                            <i className="fas fa-plus"></i> Add Stock Details
                          </button>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div className="row admin-controls"></div>
                </div>
              </div>


              <div className="admin-add-new mt-lg-0 d-flex justify-content-end ml-auto px-3 py-2">
                <button
                  onClick={() => setOpenAddStockModal(true)}
                  className="btn btn-new secondary-btn modal-btn"
                >
                  Add Product
                </button>
              </div>

              {currentDataList?.length > 0 ? (
                <StocksTable
                  dataList={currentDataList}
                  arraySorting={arraySorting}
                  basicApiCall={callBasicApi}
                  sortObj={sortObj}
                  manageStockData={manageStockData}
                  addNotes={addNotes}
                  updateActive={updateActive}
                  confirmModal={openConfirmModal}
                  editData={editData}
                  deleteData={deleteData}
                  toggleData={toggleData}
                />
              ) : (
                <div className="px-3 pt-3">
                  <div className="no-data-available">No data available</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>)}

      {openAddStockModal && <AddStockModal

        open={openAddStockModal}
        closeModal={closeAddStockModal}
        data={currentViewData}
        id={storeId}
      />
      }
      {openStockModal && (
        <ManageStockModal
          open={openStockModal}
          closeModal={closeStockModal}
          data={currentViewData}
        />
      )}
      {openAddNotesModal && (
        <AddNotesModal
          open={openAddNotesModal}
          closeModal={closeAddNotes}
          data={currentViewData}
        />
      )}

      {openEditModal &&
        <EditStockModal
          open={openEditModal}
          closeModal={closeEditStockModal}
          data={currentData}
        />
      }
      {confirmaModalOpen && (
        <ConfirmationModal
          open={confirmaModalOpen}
          deleteInfo={toggleInfo || deleteInfo}
          description={toggleInfo ? `Do you want to ${currentData?.isActive ? 'deactivate' : 'activate'} this stock?` : "Do you want to delete this stock?"}
          onCloseModal={confirmModalFunc}
        />
      )}
    </>
  );
};

export default withRouter(ManageStocks);
