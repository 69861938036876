import React, { useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { globalAlert, globalLoader } from "../../actions/commonActions";
import { superAdminUpdatePassword } from "../../superadmin/_actions";
import { adminUpdatePassword } from "../../admin/_actions";

const ChangePasswordModal = (props) => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [currentPasswordEmpty, setCurrentPasswordEmpty] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordEmpty, setNewPasswordEmpty] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordEmpty, setConfirmPasswordEmpty] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [minSixChar, setMinSixChar] = useState(false);
  const [oneVarIncluded, setOneVarIncluded] = useState(false);
  const [numberIncluded, setNumberIncluded] = useState(false);
  const [specialCharacter, setSpecialCharacter] = useState(false);
  const [currentPasswordVisible, setCurrentPasswordVisible] = useState(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setconfirmPasswordVisible] = useState(false);

  const handleCurrPassChange = (evt) => {
    setCurrentPassword(evt.target.value);
  };
  const handleNewPassChange = (evt) => {
    let val = evt.target.value;
    let containsNumber = /\d+/; // eslint-disable-next-line
    let specailChar = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    let variableChar = /[A-z]/;
    if (variableChar.test(val)) {
      setOneVarIncluded(true);
    } else {
      setOneVarIncluded(false);
    }
    if (containsNumber.test(val)) {
      setNumberIncluded(true);
    } else {
      setNumberIncluded(false);
    }
    if (specailChar.test(val)) {
      setSpecialCharacter(true);
    } else {
      setSpecialCharacter(false);
    }
    if (val.length < 6) {
      setMinSixChar(false);
    } else {
      setMinSixChar(true);
    }

    setNewPassword(evt.target.value);
  };

  const handleConfirmPassChange = (evt) => {
    setConfirmPassword(evt.target.value);
  };

  const handleValidation = () => {
    let flag = true;
    if (!currentPassword) {
      setCurrentPasswordEmpty(true);
      flag = false;
    }
    if (!newPassword) {
      setNewPasswordEmpty(true);
      flag = false;
    }
    if (!confirmPassword) {
      setConfirmPasswordEmpty(true);
      flag = false;
    }
    if (newPassword && confirmPassword && newPassword !== confirmPassword) {
      setPasswordMatch(false);
      flag = false;
    }
    return flag;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let valid = await handleValidation();
    if (valid) {
      try {
        globalLoader(true);
        let obj = {
          currentPassword: currentPassword,
          newPassword: newPassword,
        };
        let res;

        if (props?.roleType === "superadmin") {
          res = await superAdminUpdatePassword(obj);
        } else if (props?.roleType === "admin") {
          res = await adminUpdatePassword(obj);
        }
        globalLoader(false);
        if (res?.data?.status === 200) {
          globalAlert("success", res?.data?.message);// eslint-disable-next-line
          props?.closeModal();
        } else {
          globalAlert("error", res?.data?.message);
        }
      } catch (err) {
        globalLoader(false);
      }
    }
  };

  return (
    <Modal
      open={props?.open}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      showCloseIcon={false}
      center
      classNames={{ modal: "modal-own", root: "modal-own-wrapper" }}
    >
      <div className="px-3">
        <p className=" primary-color h4 py-3 font-weight-bold text-center">
          Change Password
        </p>
        <form className="form-own" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="form-own-group position-relative">
                <label htmlFor="phone" className="form-own-label">
                  Enter Current Password <span className="color-red">*</span>
                </label>
                <input
                  className="form-control"
                  type={currentPasswordVisible ? "text" : "password"}
                  name="currentPassword"
                  id="currentPassword"
                  placeholder="Current Password"
                  onChange={(evt) => handleCurrPassChange(evt)}
                  value={currentPassword}
                />
                <div
                  className="form-img-wrapper cursor"
                  onClick={() => {
                    setCurrentPasswordVisible((current) => !current);
                  }}
                >
                  {currentPasswordVisible ? (
                    <i className="far fa-eye"></i>
                  ) : (
                    <i className="far fa-eye-slash"></i>
                  )}
                </div>

                <div className="error-text">
                  {!currentPassword && currentPasswordEmpty && (
                    <span>Current Password is required</span>
                  )}
                </div>
              </div>
              <div className="form-own-group position-relative">
                <label htmlFor="phone" className="form-own-label">
                  Enter New Password <span className="color-red">*</span>
                </label>
                <input
                  className="form-control"
                  type={newPasswordVisible ? "text" : "password"}
                  name="newPassword"
                  id="newPassword"
                  placeholder="New Password"
                  onChange={(evt) => handleNewPassChange(evt)}
                  value={newPassword}
                />
                <div
                  className="form-img-wrapper cursor"
                  onClick={() => {
                    setNewPasswordVisible((current) => !current);
                  }}
                >
                  {newPasswordVisible ? (
                    <i className="far fa-eye"></i>
                  ) : (
                    <i className="far fa-eye-slash"></i>
                  )}
                </div>
                <div className="error-text">
                  {!newPassword && newPasswordEmpty && (
                    <span>New Password is required</span>
                  )}
                </div>
              </div>
              <div className="form-own-group position-relative">
                <label htmlFor="phone" className="form-own-label">
                  Confirm Password <span className="color-red">*</span>
                </label>
                <input
                  className="form-control"
                  type={confirmPasswordVisible ? "text" : "password"}
                  name="confirmPassword"
                  id="confirmPassword"
                  placeholder="Confirm Password"
                  onChange={(evt) => handleConfirmPassChange(evt)}
                  value={confirmPassword}
                />
                <div
                  className="form-img-wrapper cursor"
                  onClick={() => {
                    setconfirmPasswordVisible((current) => !current);
                  }}
                >
                  {confirmPasswordVisible ? (
                    <i className="far fa-eye"></i>
                  ) : (
                    <i className="far fa-eye-slash"></i>
                  )}
                </div>
                <div className="error-text">
                  {!confirmPassword && confirmPasswordEmpty && (
                    <span>Confirm Password is required</span>
                  )}
                  {!passwordMatch && <span>Passwords do not match</span>}
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <p className="primary-color font-12 pt-4">
                You must enter your current password to confirm changes
              </p>
              <div className="password-helper pt-4">
                <ul className="list-unstyled helper-list font-12">
                  <li className={`${minSixChar ? "active" : ""}`}>
                    Minimum 6 character
                  </li>
                  <li className={`${oneVarIncluded ? "active" : ""}`}>
                    At least one variable case
                  </li>
                  <li className={`${numberIncluded ? "active" : ""}`}>
                    At least one number
                  </li>
                  <li className={`${specialCharacter ? "active" : ""}`}>
                    At least one special character
                  </li>
                </ul>
              </div>
            </div>
            <div className="form-own col-12">
              <div className="admin-btn mr-2">
                <button
                  onClick={() => props.closeModal()}
                  className="btn secondary-btn"
                  type="button"
                >
                  Cancel
                </button>
                <button type="submit" className="btn primary-btn ml-2">
                  Change Password
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ChangePasswordModal;
