import React, { useState, useEffect } from "react";

import { globalAlert, globalLoader } from "../../actions/commonActions";
import { UPLOAD_IMAGE } from "../../actionTypes/types";
import InputComponent from "../_common/inputComponent";

const EditBrandInfo = (props) => {
  const [orgName, setOrgName] = useState("");
  const [isActive, setIsActive] = useState(false);
 
  const [logo, setLogo] = useState("");
  const [logoImg, setLogoImg] = useState("");
  const [orgNameErr, setOrgNameErr] = useState(false);
  const [logoErr, setLogoErr] = useState(false);
  const [logoFile, setLogoFile] = useState("");
  const [copyrightText, setCopyrightText] = useState("");

  useEffect(() => {
    setPropsToState(); // eslint-disable-next-line
  }, [props?.data]);

  const setPropsToState = () => {
    setOrgName(props?.data?.orgName);
    setLogoImg(props?.data?.brandLogo);
    setCopyrightText(props?.data?.copyrightText);
    setIsActive(props?.data?.isAdminMfaEnabled || false)
    // setCreateDate(props?.data?.created);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (await handleValidation()) {
      globalLoader(true);
      let formData = new FormData();
      formData.append("copyrightText", copyrightText);
      formData.append("orgName", orgName);
      formData.append("file", logo);
      formData.append("isAdminMfaEnabled", isActive);
      props.valueSubmit(formData);
    }
  };
  const handleValidation = () => {
    let flag = true;
    if (!orgName) {
      setOrgNameErr(true);
      flag = false;
    }

    if (!logoImg) {
      setLogoErr(true);
      flag = false;
    }
    return flag;
  };

  const setLogoFunc = (ev) => {
    if (
      ev?.target?.files[0]?.type === "image/jpeg" ||
      ev?.target?.files[0]?.type === "image/png"
    ) {
      const file = ev.target.files[0];
      setLogo(file);
      const url = URL.createObjectURL(file);
      setLogoFile(url);
    } else {
      globalAlert("error", "Only PNG/JPEG files allowed");
    }
  };
  return (
    <>
      <div className="col-md-6 px-0 mx-auto pb-2">
        <form onSubmit={handleSubmit} className="form-own px-3">
          <div className="row px-2">
            {props?.data?.areaCode?.length > 0 && props?.edit && (
              <div className="col-12 px-2">
                <div className="form-own-group">
                  <label htmlFor="postCode" className="form-own-label">
                    Current areacode list
                  </label>
                  <ul className="list-unstyled arecode-list">
                    {props.data.areaCode.map((area, index) => (
                      <li key={index}>{area}</li>
                    ))}
                  </ul>
                </div>
              </div>
            )}

            <div className="col-6 px-2">
              <div className="form-own-group">
                <InputComponent
                  placeholder="Name"
                  label="Name"
                  type="text"
                  name="name"
                  theme="admin"
                  value={orgName}
                  handleCallback={(val) => {
                    setOrgName(val);
                  }}
                />
                <div className="error-text">
                  {!orgName && orgNameErr && (
                    <span>Organisation Name is required</span>
                  )}
                </div>
              </div>
            </div>
            <div className="col-6 px-2">
              <div className="form-own-group">
                {/* <label htmlFor="postCode" className="form-own-label">
                  Copyright text
                </label>
                <input
                  id="postCode"
                  name="postCode"
                  placeholder="Copyright text"
                  className="form-control"
                  onChange={(evt) => {
                    setCopyrightText(evt.target.value);
                  }}
                  value={copyrightText}
                  maxLength="50"
                /> */}
                <InputComponent
                  placeholder="Copyright text"
                  label="Copyright text"
                  type="text"
                  name="Copyright text"
                  theme="admin"
                  value={copyrightText}
                  handleCallback={(val) => {
                    setCopyrightText(val);
                  }}
                />
              </div>
            </div>

            <div className="form-own-group px-2" style={{marginTop:'-5px'}}>
              <label htmlFor="" className="form-own-label mb-0">
                Is Admin MFA required?
              </label>
             

              <input
                className="toggle-input"
                type="checkbox"
                id="switch"
                onChange={(ev) => {
                  setIsActive(ev.target.checked);
                }}
                checked={isActive}
              />
              <label className="toggle-label" htmlFor="switch">
                Toggle
              </label>
            </div>
            <div className="col-12 px-2">
              <div className="form-own-group">
                <label className="form-own-label">Upload Logo</label>
                <div className="upload-btn upload-btn-dashed" id="previewLogo">
                  <div className="show-icon-wrapper pb-2 mb-1">
                    <img
                      alt="preview logo"
                      id="previewLogo"
                      src={
                        logoFile ? logoFile : logoImg ? logoImg : UPLOAD_IMAGE
                      }
                    />
                  </div>

                  <div className="upload-btn-txt btn">
                    + {logoImg || logoFile ? "CHANGE" : "ADD"} FILE
                  </div>
                  <input
                    type="file"
                    accept="image/x-png,image/jpeg"
                    className="upload-input cursor"
                    onChange={setLogoFunc}
                  />
                </div>
                <div className="error-text">
                  {!logoFile && !logoImg && logoErr && (
                    <span>Logo is required</span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="admin-btn">
            <button type="submit" className="btn primary-btn ml-2">
              Save
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default EditBrandInfo;
