import React from "react";

const VideoTable = (props) => {
  return (
    <>
      <div className="table-wrapper px-3 pt-3">
        <p className="table-type">All Videos</p>
        <div className="table-wrapper-scroll-y table-scrollbar">
          <div className="table-own table-responsive">
            <table className="table table-striped table-hover table-admin">
              <thead className="table-head">
                <tr>
                  <th
                    className={`sort-header cursor ${props?.sortObj?.sortVal === "videoTitle" ? "active" : ""
                      } ${props?.sortObj?.sortType ? "aesc" : "desc"}`}
                    onClick={() => props.arraySorting("videoTitle")}
                  >
                    <span>Title</span>
                    {props?.sortObj?.sortType === "aesc" && (
                      <i className="fas fa-caret-down"></i>
                    )}
                    {props?.sortObj?.sortType === "desc" && (
                      <i className="fas fa-caret-up"></i>
                    )}
                  </th>
                  <th
                    className={`sort-header cursor ${props?.sortObj?.sortVal === "videoDescription"
                        ? "active"
                        : ""
                      } ${props?.sortObj?.sortType ? "aesc" : "desc"}`}
                    onClick={() => props.arraySorting("videoDescription")}
                  >
                    <span>Description</span>
                    {props?.sortObj?.sortType === "aesc" && (
                      <i className="fas fa-caret-down"></i>
                    )}
                    {props?.sortObj?.sortType === "desc" && (
                      <i className="fas fa-caret-up"></i>
                    )}
                  </th>
                  <th className={`sort-header cursor ${props?.sortObj?.sortVal === "isActivated"
                      ? "active"
                      : ""
                    } ${props?.sortObj?.sortType ? "aesc" : "desc"}`}
                    onClick={() => props.arraySorting("isActivated")}>
                    <span>Status</span>
                    {props?.sortObj?.sortType === "aesc" && (
                      <i className="fas fa-caret-down"></i>
                    )}
                    {props?.sortObj?.sortType === "desc" && (
                      <i className="fas fa-caret-up"></i>
                    )}
                  </th>
                  <th className="sort-header ">
                    <span>View</span>
                  </th>
                  <th className="sort-header ">
                    <span>Action</span>
                  </th>
                </tr>
              </thead>
              <tbody className="table-body">
                {props.dataList.map((data) => {
                  return (
                    data?.isPublished && (
                      <tr key={data?.videoId}>
                        <td>{data?.videoTitle}</td>
                        <td>{data?.videoDescription}</td>
                        <td className="table-data-status">
                          {data.isActivated ? (
                            <span className="table-isactive">Active</span>
                          ) : (
                            <span className="table-isinactive">Inactive</span>
                          )}
                        </td>
                        <td
                          className="cursor"
                          onClick={() => {
                            props.viewVideo(data);
                          }}
                        >
                          <i className="far fa-eye primary-color"></i>
                        </td>
                        <td className="nowrap">
                          <span
                            onClick={() => props?.toggleData(data?.videoId)}
                            className="mr-1 btn btn-own-admin primary-btn btn-sm-own table-action"
                          >
                            {!data.isActivated ? "Active" : "Inactive"}
                          </span>{" "}
                          |{" "}
                          <span
                            onClick={() => props?.deleteData(data)}
                            className="ml-1 btn btn-own-admin primary-btn btn-sm-own table-action"
                          >
                            Delete
                          </span>
                        </td>
                      </tr>
                    )
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoTable;
