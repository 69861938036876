import React, { useState, useEffect } from "react";
import Header from "../template/_common/header";
import SidebarComponent from "../template/superadmin/sidebarComponent";
import SidebarArrow from "../template/_common/sidebarArrow";
import { globalAlert, globalLoader } from "../actions/commonActions";
import {

  viewOrgApi,
  editOrgApi,
  editOrgZoneApi,
} from "./_actions";
import { logOut } from "../_shared/commonFunction";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";

import EditBrandInfo from "../template/superadmin/editBrandInfo";
import EditAreaCode from "../template/superadmin/editAreaCode";
import EditZones from "../template/superadmin/editZones";

const EditOrganisations = (props) => {
  const [navbarToggle, setNavbarToggle] = useState(false);
  const [activeTab, setActiveTab] = useState("brand");

  const [orgData, setOrgData] = useState({});
  const [allowView, setAllowView] = useState(true);

  const [id, setId] = useState("");

  const handleSidebar = (data) => {
    setNavbarToggle(data);
  };
  useEffect(() => {
    callBasicApi(); // eslint-disable-next-line
  }, []);

  const callBasicApi = async () => {
    try {
      globalLoader(true);
      let pathName = window.location.pathname.split("/");
      pathName = pathName[pathName.length - 1];
      let res = await viewOrgApi(pathName);
      if (res?.data?.status === 200) {
        setId(pathName);
        setAllowView(true);
        setOrgData(res?.data?.data?.organization);
      } else {
        props.history.push("/superadmin/manage-organisations");
        globalAlert("error", res?.data?.message);
      }
      globalLoader(false);
    } catch (error) {
      globalAlert("error", error?.response?.data?.message);
      if (error?.response?.data?.status === 401) {
        logOut(props?.history, "/superadmin/login");
      }
      globalLoader(false);
    }
  };

  const valueSubmit = async (val) => {
    if (val) {
      globalLoader(true);
      try {
        globalLoader(true);

        let res = await editOrgApi(val, id);
        if (res?.data?.status === 200) {
          globalAlert("success", res?.data?.message);
          props.history.push("/superadmin/manage-organisations");
        } else {
          globalAlert("error", res?.data?.message);
        }
        globalLoader(false);
      } catch (error) {
        if (error?.response?.status === 401) {
          globalAlert("error", error?.response?.data?.message);
        } else {
          globalAlert("error", "Something went wrong");
        }
        globalLoader(false);
        logOut(props?.history, "/superadmin/login");
      }
    }
  };

  const changeNameSubmit= async (val) => {
    if (val) {
      globalLoader(true);
      try {
        

        let res = await editOrgZoneApi(val, id);
        if (res?.data?.status === 200) {
          globalAlert("success", res?.data?.message);
        } else {
          globalAlert("error", res?.data?.message);
        }
        globalLoader(false);
      } catch (error) {
        if (error?.response?.status === 401) {
          globalAlert("error", error?.response?.data?.message);
        } else {
          globalAlert("error", "Something went wrong");
        }
        globalLoader(false);
        logOut(props?.history, "/superadmin/login");
      }
    }
  };

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=1200, initial-scale=1" />
      </Helmet>
      {allowView && (
        <div className="admin-portal">
          <Header roleType="superadmin" />
          <div className={`${navbarToggle ? "active" : ""} wrapper`}>
            <SidebarComponent
              navbarToggle={navbarToggle}
              active={props.match.path}
              roleType={"superadmin"}
            />
            <div className="main">
              <div className="main-wrapper">
                <div>
                  <p className="admin-banner">
                    Edit Organisation - {orgData?.orgName}
                  </p>
                </div>
                <SidebarArrow handleSidebarToggle={handleSidebar} />
                <div className="px-3">
                  <ul className="tab-list pt-2 list-unstyled text-center">
                    <li
                      className={`${activeTab === "brand" ? "active" : ""
                        } d-inline-block`}
                      onClick={() => setActiveTab("brand")}
                    >
                      Brand Info
                    </li>
                    <li
                      className={`${activeTab === "area" ? "active" : ""
                        } d-inline-block`}
                      onClick={() => setActiveTab("area")}
                    >
                      Sector Code
                    </li>
                    <li
                      className={`${activeTab === "zones" ? "active" : ""
                        } d-inline-block`}
                      onClick={() => setActiveTab("zones")}
                    >
                      Zones
                    </li>
                  </ul>
                  {activeTab === "brand" ? (
                    <EditBrandInfo data={orgData} valueSubmit={valueSubmit} />
                  ) : activeTab === "area"?
                    <EditAreaCode data={orgData} valueSubmit={valueSubmit} />
                    :<EditZones data={orgData} valueSubmit={changeNameSubmit} />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default withRouter(EditOrganisations);
