import React, { useState, useEffect } from "react";
import "react-responsive-modal/styles.css";
import DatePicker from "react-date-picker";
import { Modal } from "react-responsive-modal";
import { globalAlert, globalLoader } from "../../actions/commonActions";
import { UPLOAD_IMAGE } from "../../actionTypes/types";
import { getCsvFile } from "../../superadmin/_actions";
import FileDownload from "js-file-download";
import { logOut } from "../../_shared/commonFunction";
import InputComponent from "../_common/inputComponent";

const AddOrgModal = (props) => {
  const [orgName, setOrgName] = useState("");
  const [postCode, setPostcode] = useState(null);
  const [logo, setLogo] = useState("");
  const [logoImg, setLogoImg] = useState("");
  const [orgNameErr, setOrgNameErr] = useState(false);
  const [postCodeErr, setPostcodeErr] = useState(false);
  const [logoErr, setLogoErr] = useState(false);
  const [logoFile, setLogoFile] = useState("");
  const [copyrightText, setCopyrightText] = useState("");
  const [isActive, setIsActive] = useState(false);


  useEffect(() => {
    setPropsToState(); // eslint-disable-next-line
  }, [props?.data]);

  const setPropsToState = () => {
    setOrgName(props?.data?.orgName);
    setLogoImg(props?.data?.brandLogo);
    setCopyrightText(props?.data?.copyrightText);
  };
  const handleValidation = () => {
    let flag = true;
    if (!orgName) {
      setOrgNameErr(true);
      flag = false;
    }
    if (!postCode && !props?.edit) {
      setPostcodeErr(true);
      flag = false;
    }
    if (!logo && !props?.edit) {
      setLogoErr(true);
      flag = false;
    }

    return flag;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (await handleValidation()) {
      globalLoader(true);
      let formData = new FormData();
      formData.append("copyrightText", copyrightText);
      formData.append("orgName", orgName);
      formData.append("file", logo);
      formData.append("areaCodesFile", postCode);
      formData.append('isAdminMfaEnabled', isActive)
      props.closeModal(formData);
    }
  };

  const setLogoFunc = (ev) => {
    if (
      ev?.target?.files[0]?.type === "image/jpeg" ||
      ev?.target?.files[0]?.type === "image/png"
    ) {
      const file = ev.target.files[0];
      setLogo(file);
      const url = URL.createObjectURL(file);
      setLogoFile(url);
    } else {
      globalAlert("error", "Only PNG/JPEG files allowed");
    }
  };

  const setPostalFunc = (ev) => {
    if (
      ev?.target?.files[0]?.type === "application/vnd.ms-excel" ||
      ev?.target?.files[0]?.type === "text/csv"
    ) {
      const file = ev.target.files[0];
      setPostcode(file);
    } else {
      globalAlert("error", "Only CSV files allowed");
    }
  };

  const downloadFileFormat = async (e) => {
    e.preventDefault();
    try {
      globalLoader(true);
      let res = await getCsvFile();

      if (res) {
        FileDownload(res, "postalcode.csv");
      } else {
        globalAlert("error", res?.data?.message);
      }
      globalLoader(false);
    } catch (error) {
      globalAlert("error", "Unauthorised");
      logOut(props?.history, "/superadmin/login");
    }
  };

  return (
    <Modal
      open={props.open}
      onClose={() => props.closeModal()}
      center
      classNames={{ modal: "admin-modal modal-own" }}
    >
      <div>
        <p className="admin-banner">
          {props?.edit ? "Edit" : "Add"} Organisation
        </p>
      </div>
      <form onSubmit={handleSubmit} className="form-own px-3">
        <div className="row px-2">
          <div className="col-12 px-2 pb-3">
            <div className="form-own-group">
              <div className="d-flex flex-wrap pb-3 align-items-center">
                <label className="form-own-label mr-2">
                  Upload Sector code file <span className="color-red">*</span>
                </label>

                <button
                  className="btn secondary-btn ml-auto"
                  onClick={downloadFileFormat}
                >
                  <i className="fas fa-arrow-down"></i> Download format
                </button>
              </div>
              <div className="upload-btn upload-btn-dashed" id="previewLogo">
                <div className="show-icon-wrapper pb-2 mb-1 font-14">
                  <i className="fas fa-file-csv font-16" aria-hidden="true"></i>
                  <span className="pl-1">{postCode?.name}</span>
                </div>

                <div className="upload-btn-txt btn">+ ADD FILE</div>
                <input
                  type="file"
                  accept=".csv"
                  className="upload-input cursor"
                  onChange={setPostalFunc}
                />
              </div>
              <div className="error-text">
                {!postCode && postCodeErr && (
                  <span>Areacode file is required</span>
                )}
              </div>
            </div>
          </div>
          {props?.data?.areaCode?.length > 0 && props?.edit && (
            <div className="col-12 px-2">
              <div className="form-own-group">
                <label htmlFor="postCode" className="form-own-label">
                  Current areacode list
                </label>
                <ul className="list-unstyled arecode-list">
                  {props.data.areaCode.map((area, index) => (
                    <li key={index}>{area}</li>
                  ))}
                </ul>
              </div>
            </div>
          )}

          <div className="col-6 px-2">
            <div className="form-own-group">
              <InputComponent
                placeholder="Name"
                label={
                  <>
                    Name <span className="color-red">*</span>
                  </>
                }
                type="text"
                name="name"
                theme="white"
                value={orgName}
                handleCallback={(val) => {
                  setOrgName(val);
                }}
              />
              <div className="error-text">
                {!orgName && orgNameErr && (
                  <span>Organisation Name is required</span>
                )}
              </div>
            </div>
          </div>
          <div className="col-6 px-2">
            <div className="form-own-group">
              <InputComponent
                placeholder="Copyright Text"
                label="Copyright Text"
                type="text"
                name="postCode"
                theme="white"
                maxLength="50"
                value={copyrightText}
                handleCallback={(val) => {
                  setCopyrightText(val);
                }}
              />
            </div>
          </div>
          <div className="form-own-group px-2" style={{marginTop:'-5px'}}>
              <label htmlFor="" className="form-own-label mb-0">
                Is Admin MFA required?
              </label>
             

              <input
                className="toggle-input"
                type="checkbox"
                id="switch"
                onChange={(ev) => {
                  setIsActive(ev.target.checked);
                }}
                checked={isActive}
              />
              <label className="toggle-label" htmlFor="switch">
                Toggle
              </label>
            </div>
          <div className="col-12 px-2">
            <div className="form-own-group">
              <label className="form-own-label">
                Upload Logo <span className="color-red">*</span>
              </label>
              <div className="upload-btn upload-btn-dashed" id="previewLogo">
                <div className="show-icon-wrapper pb-2 mb-1">
                  <img
                    alt="preview logo"
                    id="previewLogo"
                    src={logoFile ? logoFile : logoImg ? logoImg : UPLOAD_IMAGE}
                  />
                </div>

                <div className="upload-btn-txt btn">
                  + {logoImg || logoFile ? "CHANGE" : "ADD"} FILE
                </div>
                <input
                  type="file"
                  accept="image/x-png,image/jpeg"
                  className="upload-input cursor"
                  onChange={setLogoFunc}
                />
              </div>
              <div className="error-text">
                {!logo && logoErr && <span>Logo is required</span>}
              </div>
            </div>
          </div>
        </div>
        <div className="admin-btn">
          <button
            onClick={() => props.closeModal()}
            className="btn secondary-btn mr-2"
            type="button"
          >
            Back
          </button>
          <button type="submit" className="btn primary-btn ml-2">
            Submit
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default AddOrgModal;
