import React from "react";
import { CSVLink } from "react-csv";
import InputComponent from "../_common/inputComponent";
import PaginationOwn from "../_common/ownPagination";

const ReportStoreTable = (props) => {
  const headers = [
    { label: "Venue Name", key: "storeName" },
    { label: "Venue Address", key: "address" },
    { label: "Venue Postcode", key: "storePostalCode" },
    { label: "Venue Zone", key: "zone" },
    { label: "Total condom(s) collected", key: "count" },
  ];
  return (
    <>
      <div className="pt-4 admin-controls">
        <form
          onSubmit={(ev) => // eslint-disable-next-line
            
            props?.searchFunc(ev, props?.searchVal)}
          className="col-12 col-lg-6 admin-search"
        >
          <div className="search-wrapper">
            <InputComponent
              placeholder="Search Venue"
              label="Search Venue"
              type="text"
              name="search"
              theme="admin"
              value={props?.searchVal}
              handleCallback={(val) => {
                // eslint-disable-next-line
                props?.setSearchVal(val);
              }}
            />
          </div>
          <button type="submit" className="btn secondary-btn">
            Go
          </button>
        </form>
      </div>
      <div className="report-wrapper row">
        <div className="col-12">
          <div className="download-btn pb-3 pb-md-4 pt-3">
            <CSVLink
              className="btn admin-btn secondary-btn"
              data={props?.allReport}
              filename={"store-report.csv"}
              headers={headers}
            >
              Download CSV
            </CSVLink>
            {props?.reportData?.length > 0 && (
              <div className="px-3 col-12 pt-3">
                <PaginationOwn
                  pageSize={props?.pageSize}
                  changePageSize={props?.changePageSize}
                  goToPage={props?.goToPage}
                  totalCount={props?.totalCount}
                  currentPage={props?.currentPage}
                  applicationType={props?.applicationType}
                  paginationType="report"
                />
              </div>
            )}
          </div>
        </div>
        <div className="report-table-wrapper col-6">
          <p className="report-type">Condoms used per venue</p>
          <div className="table-wrapper-scroll-y table-scrollbar">
            <div className="table-responsive table-own">
              <table className="table table-striped table-hover table-admin">
                <thead className="table-head">
                  <tr>
                    <th
                      className={`sort-header cursor ${props?.sortObj.sortVal === "storeName" ? "active" : ""
                        } ${props?.sortObj.sortType ? "aesc" : "desc"}`}
                      onClick={() => // eslint-disable-next-line
                        
                        props?.arraySorting("storeName")}
                    >
                      <span>Venue Name</span>
                      {props?.sortObj?.sortType === "aesc" && (
                        <i className="fas fa-caret-down"></i>
                      )}
                      {props?.sortObj?.sortType === "desc" && (
                        <i className="fas fa-caret-up"></i>
                      )}
                    </th>
                    <th
                      className={`sort-header cursor ${props?.sortObj.sortVal === "address" ? "active" : ""
                        } ${props?.sortObj.sortType ? "aesc" : "desc"}`}
                      onClick={() => // eslint-disable-next-line
                        
                        props?.arraySorting("address")}
                    >
                      <span>Venue Address</span>
                      {props?.sortObj?.sortType === "aesc" && (
                        <i className="fas fa-caret-down"></i>
                      )}
                      {props?.sortObj?.sortType === "desc" && (
                        <i className="fas fa-caret-up"></i>
                      )}
                    </th>
                    <th
                      className={`sort-header cursor ${props?.sortObj.sortVal === "storePostalCode" ? "active" : ""
                        } ${props?.sortObj.sortType ? "aesc" : "desc"}`}
                      onClick={() => // eslint-disable-next-line
                        
                        props?.arraySorting("storePostalCode")}
                    >
                      <span>Venue Postcode</span>
                      {props?.sortObj?.sortType === "aesc" && (
                        <i className="fas fa-caret-down"></i>
                      )}
                      {props?.sortObj?.sortType === "desc" && (
                        <i className="fas fa-caret-up"></i>
                      )}
                    </th>
                    <th
                      className={`sort-header cursor ${props?.sortObj.sortVal === "zone" ? "active" : ""
                        } ${props?.sortObj.sortType ? "aesc" : "desc"}`}
                      onClick={() => props?.arraySorting("zone")}>
                      <span>Venue Zone</span>
                      {props?.sortObj?.sortType === "aesc" && (
                        <i className="fas fa-caret-down"></i>
                      )}
                      {props?.sortObj?.sortType === "desc" && (
                        <i className="fas fa-caret-up"></i>
                      )}
                    </th>
                    <th
                      className={`sort-header cursor ${props?.sortObj.sortVal === "count" ? "active" : ""
                        } ${props?.sortObj.sortType ? "aesc" : "desc"}`}
                      onClick={() => props?.arraySorting("count")}
                    >
                      <span>Total condom(s) collected</span>
                      {props?.sortObj?.sortType === "aesc" && (
                        <i className="fas fa-caret-down"></i>
                      )}
                      {props?.sortObj?.sortType === "desc" && (
                        <i className="fas fa-caret-up"></i>
                      )}
                    </th>
                  </tr>
                </thead>
                <tbody className="table-body">
                  {props?.reportData?.map((data, index) => {
                    return (
                      <tr key={index}>
                        <td>{data.storeName}</td>
                        <td>{data.address}</td>
                        <td>{data.storePostalCode}</td>
                        <td>{data.zone}</td>
                        <td>{data.count}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="reports-stats-wrapper col-4">
          <p className="report-type">Totals per date range</p>
          <div className="reports-stats">
            <ul className="list-unstyled">
              <li className="list-group-item-success d-flex justify-content-between report-data">
                <span>Stores Added</span>
                <span className="pr-2"> {props?.totalStats?.storesAdded}</span>
              </li>

              <li className="list-group-item-primary d-flex justify-content-between report-data">
                <span>Kiosk Added</span>
                <span className="pr-2"> {props?.totalStats?.ivtsAdded}</span>
              </li>

              <li className="list-group-item-warning d-flex justify-content-between report-data">
                <span>New Users</span>
                <span className="pr-2"> {props?.totalStats?.newUsers}</span>
              </li>
              <li className="list-group-item-primary d-flex justify-content-between report-data">
                <span>Active Users</span>
                <span className="pr-2"> {props?.totalStats?.activeUsers}</span>
              </li>
              <li className="list-group-item-danger d-flex justify-content-between report-data">
                <span>Total Condoms Collected</span>
                <span className="pr-2">
                  {props?.totalStats?.totalCondomsCollected}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportStoreTable;
