import React from "react";
import { Link } from "react-router-dom";
import { SUPERADMIN_SIDEBAR, APP_VERSION } from "../../actionTypes/types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

export const SidebarComponent = ({
  navbarToggle,
  active,
  roleType = null,
  ...props
}) => {
  return (
    <nav className="sidebar" id="sidebar">
      <div className="sidebar-header pb-3">
        <div>
          <img
            alt="EC Card"
            className="sidebar-header-logo"
            src="/assets/icons/eC-Card-Logo-Square.png"
          />
        </div>
      </div>
      {SUPERADMIN_SIDEBAR && SUPERADMIN_SIDEBAR.length ? (
        <ul className="list-unstyled components">
          {SUPERADMIN_SIDEBAR.map((item) => {
            return (
              <li key={item.text}>
                <Link
                  to={`/superadmin/${item.link}`}
                  className={`sidebar-link ${
                    active === `/superadmin/${item.link}` ? "active-tab" : ""
                  }`}
                >
                  <i className={`sidebar-icon ${item.icon}`}></i>
                  <span className="sidebar-text">{item.text}</span>
                </Link>
              </li>
            );
          })}
        </ul>
      ) : null}
       <div className="footer-logo text-right">
          <p className="text-white text-right w-100">v {APP_VERSION}</p>
        </div>
    </nav>
  );
};

const mapStateProps = (state) => ({
  orgLogo: state.common.orgLogo,
});

export default connect(mapStateProps)(withRouter(SidebarComponent));
