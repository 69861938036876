import React, { useState, useEffect } from "react";
import QrReader from "react-qr-reader";
import { globalAlert, globalLoader } from "../../actions/commonActions";
import ConfirmationModal from "../_common/confirmModal";
import {
  claimTransactionApi,
  listProductsByQrApi,
  userEligibleTransactionApi,
} from "../../user/_userActions";
import { logOut } from "../../_shared/commonFunction";
import { withRouter } from "react-router-dom";
import CountdownTimer from "./countdownTimer";
import TransactionConfirm from "./transactionConfirm";
import ScannerDetailsModal from "./scannerDetailsModal";

const ScannerComponent = (props) => {
  const [result, setResult] = useState(null);
  const [productId, setProductId] = useState(null);
  const [venueDetails, setVenueDetails] = useState(null);
  const [productList, setProductList] = useState([]);
  const [directAvail, setDirectAvail] = useState(false);
  const [confirmaModalOpen, setConfirmaModalOpen] = useState(false);
  const [view, setView] = useState(false);
  const [showScanner, setShowscanner] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [transactionStatus, setTransactionStatus] = useState(false);
  const [pendingScreenMsg, setPendingScreenMsg] = useState('')

  useEffect(() => {
    callBasicApi(); // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(result?.length > 0) {
      fetchProductsListApi({ qrTransactionCode: result }); // eslint-disable-next-line
    }
  }, [result]);

  const callBasicApi = async () => {
    try {
      globalLoader(true);
      let res = await userEligibleTransactionApi();
      if (res?.data?.status === 200) {
        setTransactionStatus(false);

        if (res?.data?.data?.isEligible) {
          setShowscanner(true);
        } else {
          if (res?.data?.data?.pendingScreen) {
            setPendingScreenMsg(res?.data?.data?.message)
          }
          else {
            setTimeRemaining(res?.data?.data);
          }
          // setTimeRemaining(86400);
        }
        setView(true);
      } else {
        globalAlert("error", res?.data?.message);
      }
      globalLoader(false);
    } catch (error) {
      globalAlert("error", error?.response?.data?.message);
      if (error?.response?.data?.status === 400) {
        logOut(props?.history, "/user/login");
      }
      globalLoader(false);
    }
  };

  const fetchProductsListApi = async (obj) => {
    try {
      globalLoader(true);
      let res = await listProductsByQrApi(obj);
      if (res?.data?.status === 200) {
        if(res?.data?.data?.noListRequired) {
          setDirectAvail(true);
        } else {
          setProductList(res?.data?.data?.products);
          setVenueDetails(res?.data?.data?.venue);
        }
      } else {
        globalAlert("error", res?.data?.message);
      }
      globalLoader(false);
    } catch (error) {
      globalAlert("error", error?.response?.data?.message);
      if (error?.response?.data?.status === 400) {
        logOut(props?.history, "/user/login");
      }
      globalLoader(false);
    }
  };

  const handleScan = (data) => {
    if (data) {
      setResult(data);
      setConfirmaModalOpen(true);
      setTimeout(() => {
        setShowscanner(false);
      }, 300);
    } else {
      // globalAlert('error', 'Something went wrong');
    }
  };

  const handleError = (err) => {
    globalAlert("error", "Your device does not support");
    props.setCurrentTabActive("map");
    console.log(err);
  };

  const confirmModalFunc = async (val) => {
    if (val) {
      try {
        globalLoader(true);
        let obj = {
          qrTransactionCode: result,
          orgProductId: productId,
        };
        let res = await claimTransactionApi(obj);
        //let res = { data: { status: 200, message: "Success" } }; //ONLY FOR TESTING PURPOSE
        if (res?.data?.status === 200) {
          globalAlert("success", res?.data?.message);
          setView(false);
          setView(true);
          globalLoader(false);
          // props.setCurrentTabActive("map");
          setTransactionStatus(true);
        } else {
          globalAlert("error", res?.data?.message);
          globalLoader(false);
        }
      } catch (error) {
        globalAlert("error", "unauthorised");
        logOut(props?.history, "/user/login");
      }
    } else {
      props.setCurrentTabActive("map");
    }
    setConfirmaModalOpen(false);
  };


  return (
    <>
      {view && (

        <>
          <div className="text-right col-12 py-2">
            <span className="back-btn text-color-primary" onClick={() => { props.setCurrentTabActive("map") }}>Back</span>
          </div>
          {transactionStatus ? (
            <TransactionConfirm
              callBasicApi={props.setCurrentTabActive("map")}
            />
          ) : (
            <>
              {pendingScreenMsg ?

                <div className="pending-message text-color-primary col-12 pt-4 text-center">{pendingScreenMsg}</div>

                : <>
                  {showScanner ? (
                    <>
                      <QrReader
                        delay={500}
                        onError={handleError}
                        onScan={handleScan}
                        style={{ width: "100%", marginTop: "20vh" }}
                      />
                      <div className="mx-auto mt-5">
                        <button
                          className="btnRed width-300"
                          onClick={() => props.setCurrentTabActive("map")}
                        >
                          Cancel
                        </button>
                      </div>
                    </>
                  ) : (
                    !confirmaModalOpen && (<CountdownTimer
                      timeRemaining={timeRemaining}
                      callBack={setShowscanner}
                    />)
                  )}
                </>}
            </>
          )}
        </>
      )}

      {confirmaModalOpen && productList?.length > 0 && !directAvail && <ScannerDetailsModal

        open={true}
        description="Do you want to avail this?"
        productList={productList}
        venueDetails={venueDetails}
        setProductId={setProductId}
        onCloseModal={confirmModalFunc}

      />}
      {confirmaModalOpen && directAvail && (
        <ConfirmationModal
          open={confirmaModalOpen}
          description="Do you want to avail this?"
          onCloseModal={confirmModalFunc}
        />
      )}
    </>
  );
};

export default withRouter(ScannerComponent);
