import {
  postRequest,
  getRequest,
  deleteRequest,
} from "../actionTypes/axiosUtility";
import { GLOBAL_API } from "../actionTypes/types";
import Axios from "axios";

export const superAdminLoginApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/superAdmin/v1/login`, obj);
};

export const userVerifyLoginApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/user/v1/verify-login`, obj);
};

export const userSelfRegister = async (obj) => {
  return postRequest(`${GLOBAL_API}/user/v1/self-register`, obj);
};

export const organizationListApi = async () => {
  return getRequest(`${GLOBAL_API}/superAdmin/v1/list-organizations`);
};

export const adminListApi = async () => {
  return getRequest(`${GLOBAL_API}/superAdmin/v1/list-admins`);
};

export const addAdminApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/superAdmin/v1/add-admin`, obj);
};

export const superAdminListApi = async () => {
  return getRequest(`${GLOBAL_API}/superAdmin/v1/list-super-admins`);
};

export const addSuperAdminApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/superAdmin/v1/add-super-admin`, obj);
};

export const addOrganizationApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/superAdmin/v1/add-organization`, obj);
};

export const superAdminValidateMailApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/superAdmin/v1/validate-email`, obj);
};

export const superAdminGenResetApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/superAdmin/v1/gen-reset`, obj);
};

export const superAdminResetPasswordApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/superAdmin/v1/reset-password`, obj);
};

export const superAdminCheckResetApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/superAdmin/v1/check-reset`, obj);
};

export const superAdminActivate = (id) => {
  // return postRequest(`${GLOBAL_API}/superadmin/v1/verify`,obj);
  // return postRequest(`${GLOBAL_API}/superAdmin/v1/activate-admin/:id`)
};

//view profile
export const superAdminViewProfile = async () => {
  return getRequest(`${GLOBAL_API}/superAdmin/v1/view-profile`);
};

//update password
export const superAdminUpdatePassword = async (obj) => {
  return postRequest(`${GLOBAL_API}/superAdmin/v1/update-password`, obj);
};

//activate superadmin
export const activateSuperAdminUserApi = (obj) => {
  return postRequest(`${GLOBAL_API}/superadmin/v1/activate-user`, obj);
};

export const checkResetSuperAdminApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/superAdmin/v1/check-reset`, obj);
};

export const resetPasswordSuperAdminApi = async (obj) => {
  return postRequest(`${GLOBAL_API}/superAdmin/v1/reset-password`, obj);
};

export const superAdminResendActivationAdmin = async (id) => {
  return getRequest(
    `${GLOBAL_API}/superAdmin/v1/resend-activation/admin/${id}`
  );
};

export const superAdminResetAdmin = async (id) => {
  return getRequest(`${GLOBAL_API}/superAdmin/v1/reset-password/admin/${id}`);
};
export const superAdminToggleAdmin = async (id) => {
  return getRequest(`${GLOBAL_API}/superAdmin/v1/toggle-user/admin/${id}`);
};
export const superAdminResendActivationApi = async (id) => {
  return getRequest(
    `${GLOBAL_API}/superAdmin/v1/resend-activation/super-admin/${id}`
  );
};
export const superAdminResetSuperAdmin = async (id) => {
  return getRequest(
    `${GLOBAL_API}/superAdmin/v1/reset-password/super-admin/${id}`
  );
};
export const superAdminToggleSuperAdmin = async (id) => {
  return getRequest(
    `${GLOBAL_API}/superAdmin/v1/toggle-user/super-admin/${id}`
  );
};

export const editOrgApi = async (data, id) => {
  return postRequest(
    `${GLOBAL_API}/superAdmin/v1/edit-organization/${id}`,
    data
  );
};

export const editOrgZoneApi = async (data, id) => {
  return postRequest(
    `${GLOBAL_API}/superAdmin//v1/zones/rename/${id}`,
    data
  );
};
export const viewOrgApi = async (id) => {
  return getRequest(
    `${GLOBAL_API}/superAdmin/v1/view-organization/${id}`
  );
};
export const deleteOrgApi = async (id) => {
  return deleteRequest(`${GLOBAL_API}/superAdmin/v1/delete-organization/${id}`);
};
export const superAdminToggleOrg = async (id) => {
  return getRequest(`${GLOBAL_API}/superAdmin/v1/toggle-organization/${id}`);
};

export const getCsvFile = async () => {
  return Axios({
    url: `${GLOBAL_API}/superAdmin/v1/area-codes-csv-download`,
    method: "get",
    responseType: "blob", // Important
    headers: {
      ecauthtoken: localStorage.getItem("ecauthtoken"),
      ecsecuretoken: localStorage.getItem("ecsecuretoken"),
    },
  })
    .then((response) => {
      if (response?.headers?.ecauthtoken) {
        localStorage.setItem("ecauthtoken", response?.headers?.ecauthtoken);
      }
      if (response?.headers?.ecsecuretoken) {
        localStorage.setItem("ecsecuretoken", response?.headers?.ecsecuretoken);
      }
      return response?.data;
    })
    .catch((err) => {
      console.log(err);
    });
};
