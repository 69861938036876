import React, { useState, useEffect } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { GENDER_LIST } from "../../actionTypes/types";
import DatePicker from "react-date-picker";
import { globalLoader } from "../../actions/commonActions";
import InputComponent from "../_common/inputComponent";
import { validPostCodeFunc } from '../../_shared/commonFunction'

const AddUserModal = (props) => {
  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState("");
  const [phoneErr, setPhoneErr] = useState(false);
  const [postCodeErr, setPostCodeErr] = useState(false);
  const [validPostcodeVal, setValidPostcodeVal] = useState(false);

  const [birthDateErr, setBirthDateErr] = useState(false);
  const [genderErr, setGenderErr] = useState(false);
  const [dob, setDob] = useState(null);
  const [postalcode, setPostalcode] = useState("");

  useEffect(() => {
    setPropsToState(); // eslint-disable-next-line
  }, [props?.data]);


  const setPropsToState = () => {
    setPhone(props?.data?.phone);
    setGender(props?.data?.gender);
  };

  const handleValidation = () => {
    debugger
    let flag = true;
    if (!phone) {
      setPhoneErr(true);
      flag = false;
    }
    if (phone?.length < 10) {
      flag = false;
    }
    if (!postalcode) {
      setPostCodeErr(true);
      flag = false;
    }

    if (!dob) {
      setBirthDateErr("You have not selected your date of birth");
      flag = false;
    }
    if (!gender) {
      setGenderErr(true);
      flag = false;
    }
    if (!validPostcodeVal) {
      flag = false;
    }
    return flag;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (await handleValidation()) {
      globalLoader(true);
      let obj = {
        // postalCode: postalcode.replace(/\s+/g, ""),
        postalCode: postalcode,
        mobile: phone,
        dateOfBirth: dob,
        gender: gender,
      };
      props.closeModal(obj);
    }
  };

  const handlePostalCode = async (ev) => {
    let data = ev;
    if (data.length < 8) {
      //   if (data.length > 3) {
      //     data = data.replace(/\s+/g, "");
      //     data = data.substr(0, 3) + " " + data.substr(3, 7);
      //   }
      let value = data.split(" ").join("");



      setPostalcode(value);
      setValidPostcodeVal(await validPostCodeFunc(value))
    }
  };

  const handlePhoneInput = (evt) => {
    const regex = /^[0-9\b]+$/;
    const value = evt;
    if (value === "" || regex.test(value)) {
      setPhone(value);
    } else if (!phone) {
      // setPhone("");
    }
  };

  return (
    <Modal
      open={props.open}
      onClose={() => props.closeModal()}
      center
      classNames={{ modal: "admin-modal" }}
    >
      <div>
        <p className="admin-banner">{props?.edit ? "Edit" : "Add"} User</p>
      </div>
      <form onSubmit={handleSubmit} className="form-own pt-3 px-3">
        <div className="row">
          <div className="col-6 px-2">
            <div className="form-own-group">
              <InputComponent
                placeholder="Mobile Number"
                label={
                  <>
                    Mobile Number <span className="color-red">*</span>
                  </>
                }
                type="text"
                name="phone"
                theme="white"
                value={phone}
                handleCallback={(val) => {
                  handlePhoneInput(val);
                }}
                maxLength="11"
              />
              <div className="error-text">
                {!phone && phoneErr && <span>Mobile Number is required</span>}
                {phone && phone.length < 10 && (
                  <span>Mobile Number must have 10 digits</span>
                )}
              </div>
            </div>
          </div>
          <div className="col-6 px-2">
            <div className="form-own-group">
              <InputComponent
                placeholder="Postcode"
                label={
                  <>
                    Postcode <span className="color-red">*</span>
                  </>
                }
                type="text"
                name="postCode"
                inputFor="text"
                theme="white"
                value={postalcode}
                handleCallback={(val) => handlePostalCode(val)}
                maxLength="7"
                // className="text-uppercase"
                capsValue={true}
              />
              <div className="error-text">
                {!postalcode && postCodeErr && (
                  <span>Postal Code is required</span>
                )}
                {postalcode && !validPostcodeVal && (
                  <span>Invalid Postcode </span>
                )}
              </div>
            </div>
          </div>
          <div className="col-6 px-2">
            <div className="form-own-group">
              <label
                className="datepicker-label primary-color"
                htmlFor="birthDate"
              >
                Date of Birth <span className="color-red">*</span>
              </label>
              <div className="own-datepicker">
                <DatePicker
                  onChange={(evt) => {
                    setDob(evt);
                    setBirthDateErr("");
                  }}
                  value={dob}
                  maxDate={new Date()}
                  format={"dd/MM/yyy"}
                />
              </div>
              <div className="error-text">
                {!dob && birthDateErr && <span>Date of Birth is required</span>}
              </div>
            </div>
          </div>
          <div className="col-12 px-2">
            <div className="form-own-group">
              <label className="form-own-label" htmlFor="gender">
                Gender <span className="color-red">*</span>
              </label>
              <div className="radio-wrapper">
                {GENDER_LIST.map((i) => {
                  return (
                    <div key={i.id} className="radio" id="gender">
                      <input
                        id={i.id}
                        className="hidden radio-label"
                        type="radio"
                        value={i.value}
                        name="gender"
                        checked={gender === i.value}
                        onChange={(evt) => setGender(evt.target.value)}
                      />
                      <label className="btn-label" htmlFor={i.id}>
                        <p>{i.text}</p>
                      </label>
                    </div>
                  );
                })}
              </div>
              <div className="error-text">
                {!gender && genderErr && <span>Gender is required</span>}
              </div>
            </div>
          </div>
        </div>
        <div className="admin-btn">
          <button
            onClick={() => props.closeModal()}
            className="btn secondary-btn mr-2"
            type="button"
          >
            Back
          </button>
          <button type="submit" className="btn primary-btn ml-2">
            Submit
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default AddUserModal;
