import React, { useState, useEffect } from "react";
import { globalAlert, globalLoader } from "../../actions/commonActions";
import { getCsvFile } from "../../superadmin/_actions";
import FileDownload from "js-file-download";
import { logOut } from "../../_shared/commonFunction";
import ConfirmationModal from "../_common/confirmModal";
import InputComponent from "../_common/inputComponent";
import { adminZonerListApi } from "../../admin/_actions";


const EditZones = (props) => {
  const [zoneList, setZoneList] = useState(null);

  const [editCurrent, setEditCurrent] = useState(null);

  const [editData, setEditData] = useState('');

  const [editDataErr, setEditDataErr] = useState(false);

  const [confirmaModalOpen, setConfirmaModalOpen] = useState(false);

  useEffect(() => {
    setPropsToState(); // eslint-disable-next-line
  }, [props?.data]);

  const setPropsToState = () => {
    if (props?.data?.zones?.length) {

      setZoneList(props?.data?.zones);
      setEditCurrent(null);
      setEditData('');
      
    }
  };

  const clickChangeName =(val)=>{

    if(editCurrent===val?.index){
      if(editData){
        props?.valueSubmit({
          index:val?.index,
          zone:editData
        })

      }
      else{
        setEditDataErr(true)
      }

    }
    else{
      setEditCurrent(val?.index);
      setEditData(val?.zone);
    }

  }

  const clickCancelName = ()=>{
    setEditCurrent(null);
    setEditData('');
  }



console.log('editCurrent', editCurrent)


  return (
    <>

      <div className="col-12">

        {zoneList?.length > 0 ?

          <ul className="zone-list-wrapper form-own-group list-unstyled">

            {zoneList.map(ele => (
              <li className="pb-3" key={ele?.index}>

                <div className="box-wrapper d-flex flex-wrap w-100 w-full">

              <div className="flex-1 d-flex flex-wrap col-6 justify-content-end">
                <InputComponent
                  placeholder="Name"
                  label="Name"
                  type="text"
                  name="name"
                  theme="admin"
                  value={ele?.index===editCurrent?editData:ele?.zone}
                  disabled={ele?.index===editCurrent?false:true}
                  handleCallback={(val) => 
                    setEditData(val)}
                />
                </div>

                <div className="d-flex flex-wrap col-6 ml-auto justify-content-end">

                {ele?.index===editCurrent && <button type="button" onClick={()=>clickCancelName(ele)} className="min-width-btn-xs btnCancel ml-3">
                   Cancel
                  </button>}

<button type="button" onClick={()=>clickChangeName(ele)} className={`btn ${ele?.index===editCurrent?"primary-btn":"secondary-btn"} ml-3`}>
                    {ele?.index===editCurrent? "Save":"Edit"}
                  </button>

                
                  </div>

</div>
                <div className="error-text pl-3">
                  {!editData && editDataErr && ele?.index===editCurrent && (
                    <span>Name is required</span>
                  )}
                </div>

              
              </li>
            ))}

          </ul>

          : <div className="no-data text-center">No data available</div>
        }

      </div>

    </>
  );
};

export default EditZones;
