import React, { useState, useEffect } from "react";
import Header from "../template/_common/header";
import SidebarComponent from "../template/superadmin/sidebarComponent";
import SuperAdminTable from "../template/superadmin/superAdminTable";
import SidebarArrow from "../template/_common/sidebarArrow";
import { globalAlert, globalLoader } from "../actions/commonActions";
import AddSuperAdminModal from "../template/superadmin/addSuperAdminModal";
import {
  superAdminListApi,
  addSuperAdminApi,
  superAdminResendActivationApi,
  superAdminResetSuperAdmin,
  superAdminToggleSuperAdmin,
} from "./_actions";
import PaginationOwn from "../template/_common/ownPagination";
import { compareValues, logOut } from "../_shared/commonFunction";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import ConfirmationModal from "../template/_common/confirmModal";
import InputComponent from "../template/_common/inputComponent";
import arraySort from 'array-sort';


const ManageSuperAdmin = (props) => {
  const [navbarToggle, setNavbarToggle] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [currentData, setCurrentData] = useState(null);
  const [openAddSuperAdminModal, setOpenAddSuperAdminModal] = useState(false);
  const [editDataIs, setEditDataIs] = useState(false);
  const [pageSize, setPageSize] = useState(25);
  const [searchVal, setSearchVal] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentDataList, setCurrentDataList] = useState([]);
  const [sortObj, setSortObj] = useState({});
  const [currentDataSorted, setCurrentDataSorted] = useState([]);
  const [notFilteredRun, setNotFilteredRun] = useState(false);
  const [totalCount, setTotalCount] = useState(null);
  const [allowView, setAllowView] = useState(false);
  const [confirmaModalOpen, setConfirmaModalOpen] = useState(false);
  const [confirmInfo, setConfirmInfo] = useState(null);
  const [confirmHeading, setConfirmHeading] = useState("");
  const [currentAction, setCurrentAction] = useState("");
  const [currentId, setCurrentId] = useState("");

  useEffect(() => {
    callBasicApi(); // eslint-disable-next-line
  }, []);

  const callBasicApi = async () => {
    try {
      globalLoader(true);
      let res = await superAdminListApi();
      if (res?.data?.status === 200) {
        setAllowView(true);
        // setDataList(res?.data?.data?.superAdminsList);
        let currData = res?.data?.data?.superAdminsList;
        setDataList(currData);
        setCurrentDataSorted(res?.data?.data?.superAdminsList);
        !searchVal && setTotalCount(res?.data?.data?.superAdminsList.length);
      } else {
        globalAlert("error", res?.data?.message);
      }
      globalLoader(false);
    } catch (error) {
      globalAlert("error", error?.response?.data?.message);
      if (error?.response?.data?.status === 401) {
        logOut(props?.history, "/superadmin/login");
      }
      globalLoader(false);
    }
  };

  const handleSidebar = (data) => {
    setNavbarToggle(data);
  };

  const closeAddSuperAdminModal = async (val = null) => {
    try {
      if (val) {
        let res = await addSuperAdminApi(val);
        if (res?.data?.status === 200) {
          if (editDataIs) {
            setEditDataIs(false);
          } else {
            setSearchVal("");
          }
          setCurrentData(null);
          setOpenAddSuperAdminModal(false);
          callBasicApi();
        } else {
          globalAlert("error", res?.data?.message);
          globalLoader(false);
        }
      } else {
        setEditDataIs(false);
        setCurrentData(null);
        setOpenAddSuperAdminModal(false);
      }
    } catch (error) {
      globalAlert("error", "Unauthorised");
      logOut(props?.history, "/superadmin/login");
    }
  };

  const editData = (data) => {
    setEditDataIs(true);
    setCurrentData(data);
    setOpenAddSuperAdminModal(true);
  };

  useEffect(() => {
    if (!notFilteredRun) {
      filteredDataFunc();
    } // eslint-disable-next-line
  }, [pageSize, currentPage, sortObj, currentDataSorted, dataList]);

  const filteredDataFunc = (dataArray = null, localPage = null) => {
    let arr = [...dataList];
    let filterArr = [];
    let filteredData = [];
    if (dataArray) {
      arr = [...dataArray]
    }
    else {
      arr = arraySort(arr, compareValues(sortObj?.sortVal), { reverse: sortObj?.sortType === 'desc' ? true : false });
    }
    if (searchVal.length > 0) {
      filterArr = arr.filter((data) => {
        let name = `${data?.firstName} ${data.lastName}`;
        if (
          name.toLowerCase().includes(searchVal.toLowerCase()) ||
          data?.email.toLowerCase().includes(searchVal.toLowerCase())
        ) {
          return data;
        } else {
          return null;
        }
      });
    } else {
      filterArr = arr;
    }
    if (filterArr && filterArr.length > 0) {
      setTotalCount(filterArr.length);
      for (
        let i = (localPage || currentPage) * pageSize - pageSize;
        i < (localPage || currentPage) * pageSize && i < filterArr.length;
        i++
      ) {
        filteredData.push(filterArr[i]);
      }
    }
    setCurrentDataList(filteredData);
  };

  const changePageSizeFunc = async (val) => {
    setNotFilteredRun(false);
    setPageSize(val.target.value);
    setCurrentPage(1);
    setSortObj(null);
  };

  const searchFilter = (ev) => {
    ev.preventDefault();
    let arr = [...dataList];
    let filterArr = arr.filter((data) => {
      let name = `${data?.firstName} ${data.lastName}`;
      if (
        name.toLowerCase().includes(searchVal.toLowerCase()) ||
        data?.email.toLowerCase().includes(searchVal.toLowerCase())
      ) {
        return data;
      }
    });
    setTotalCount(filterArr.length);
    setNotFilteredRun(true);
    setCurrentDataList(filterArr);
    setCurrentPage(1);
    setSortObj(null);
  };

  const goToPageFunc = (val, data) => {
    setNotFilteredRun(false);
    if (data === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (
      data === "next" &&
      currentPage < Math.ceil(dataList?.length / pageSize)
    ) {
      setCurrentPage(currentPage + 1);
    } else {
      setCurrentPage(val?.target?.value);
    }
  };

  const arraySorting = (val) => {
    let arr = [...dataList];
    let sortedArr;
    let sortType = "aesc";
    if (val === sortObj?.sortVal && sortObj?.sortType === "aesc") {
      sortType = "desc";
    }
    sortedArr = arraySort(arr, compareValues(val), { reverse: sortType === 'desc' ? true : false })
    setNotFilteredRun(true);
    setCurrentPage(1);
    setSortObj({
      sortVal: val,
      sortType: sortType,
    });
    filteredDataFunc(sortedArr, 1);
  };

  const confirmModalFunc = async (val) => {
    if (val) {
      globalLoader(true);
      let res;
      if (currentAction === "reset") {
        res = await superAdminResetSuperAdmin(currentId);
      } else if (currentAction === "resend") {
        res = await superAdminResendActivationApi(currentId);
      } else if (currentAction === "toggle") {
        res = await superAdminToggleSuperAdmin(currentId);
      }
      if (res?.data?.status === 200) {
        globalAlert("success", res?.data?.message);
        setConfirmaModalOpen(false);
        setNotFilteredRun(false);
        callBasicApi();
      } else {
        globalAlert("error", res?.data?.message);
        globalLoader(false);
      }
    }
    setConfirmaModalOpen(false);
  };

  const resetUser = (data) => {
    setCurrentId(data?.superAdminId);
    let obj = [
      `User : ${data?.firstName} ${data?.lastName}`,
      `Email : ${data?.email}`,
    ];
    setConfirmInfo(obj);
    setConfirmHeading(
      "Do you want to send the mail for reset-password to this user?"
    );
    setCurrentAction("reset");
    setConfirmaModalOpen(true);
  };
  const resendUser = (data) => {
    setCurrentId(data?.superAdminId);
    let obj = [
      `User : ${data?.firstName} ${data?.lastName}`,
      `Email : ${data?.email}`,
    ];
    setConfirmInfo(obj);
    setConfirmHeading(
      "Do you want to send the mail for activation to this user?"
    );
    setCurrentAction("resend");
    setConfirmaModalOpen(true);
  };
  const toggleUser = (data) => {
    setCurrentId(data?.superAdminId);
    let obj = [
      `User : ${data?.firstName} ${data?.lastName}`,
      `Email : ${data?.email}`,
    ];
    setConfirmInfo(obj);
    setConfirmHeading(
      `Do you want to ${data.isActivated ? "inactivate" : "activate"
      } this user?`
    );
    setCurrentAction("toggle");
    setConfirmaModalOpen(true);
  };

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=1200, initial-scale=1" />
      </Helmet>
      {allowView && (
        <div className="admin-portal">
          <Header roleType="superadmin" />
          <div className={`${navbarToggle ? "active" : ""} wrapper`}>
            <SidebarComponent
              navbarToggle={navbarToggle}
              active={props.match.path}
              roleType={"superadmin"}
            />
            <div className="main ">
              <div className="main-wrapper">
                <div>
                  <p className="admin-banner">Super Admins</p>
                </div>
                <SidebarArrow handleSidebarToggle={handleSidebar} />
                <div>
                  <div className="container-fluid">
                    <div className="row admin-controls">
                      <form
                        onSubmit={searchFilter}
                        className="col-12 col-lg-6 admin-search"
                      >
                        <div className="search-wrapper">
                          <InputComponent
                            placeholder="Search"
                            label="Search super admin"
                            type="text"
                            name="search"
                            theme="admin"
                            value={searchVal}
                            handleCallback={(val) => {
                              setSearchVal(val);
                            }}
                          />
                        </div>
                        <button type="submit" className="btn secondary-btn">
                          Go
                        </button>
                      </form>
                      <div className="col-12 col-lg-6 admin-add-new mt-4 mt-lg-0 d-flex justify-content-start justify-content-lg-end">
                        <button
                          onClick={() => setOpenAddSuperAdminModal(true)}
                          className="btn secondary-btn modal-btn"
                        >
                          <i className="fas fa-plus"></i> Add super admin
                        </button>
                      </div>
                      {dataList?.length > 0 && (
                        <div
                          className="px-3 col-12 pt-3 mt-4"
                          style={{ borderTop: "2px solid #ebeef1" }}
                        >
                          <PaginationOwn
                            pageSize={pageSize}
                            changePageSize={changePageSizeFunc}
                            goToPage={goToPageFunc}
                            totalCount={totalCount}
                            currentPage={currentPage}
                            applicationType={
                              currentDataList?.length > 1
                                ? "super-admins"
                                : "super-admin"
                            }
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {currentDataList?.length > 0 ? (
                  <SuperAdminTable
                    dataList={currentDataList}
                    arraySorting={arraySorting}
                    basicApiCall={callBasicApi}
                    sortObj={sortObj}
                    editData={editData}
                    resetUser={resetUser}
                    resendUser={resendUser}
                    toggleUser={toggleUser}
                  />
                ) : (
                  <div className="px-3 pt-3">
                    <div className="no-data-available">No data available</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {openAddSuperAdminModal && (
        <AddSuperAdminModal
          open={openAddSuperAdminModal}
          closeModal={closeAddSuperAdminModal}
          data={currentData}
          editData={editDataIs}
          role="Super Admin"
        />
      )}
      {confirmaModalOpen && (
        <ConfirmationModal
          open={confirmaModalOpen}
          deleteInfo={confirmInfo}
          description={confirmHeading}
          onCloseModal={confirmModalFunc}
        />
      )}
    </>
  );
};

export default withRouter(ManageSuperAdmin);
