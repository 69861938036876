import React from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { Scrollbars } from "react-custom-scrollbars";

const ViewAreaCodeModal = (props) => {
  return (
    <Modal
      open={props?.open}
      onClose={() => props?.onCloseModal()}
      center
      classNames={{ modal: "admin-modal modal-own" }}
    >
      <div>
        <p className="admin-banner text-center px-2">{props?.data?.orgName}</p>
        <div className="pl-3 pt-2">
          <Scrollbars style={{ width: '550px', height: 300 }} autoHide>
            <ul className="list-unstyled  row px-2">
              {props?.data?.areaCode?.length > 0 &&
                props?.data?.areaCode.map((data, index) => {
                  return (
                    <li key={index} className="col-3 px-2 pb-3">
                      <p className="px-3">{data}</p>
                    </li>
                  );
                }
                )}
                
            </ul>
          </Scrollbars>
        </div>
      </div>
    </Modal>
  );
};

export default ViewAreaCodeModal;
